import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {FaSearch} from 'react-icons/fa';
import {Collapse} from 'reactstrap';
import {MdKeyboardArrowUp, MdCheck} from 'react-icons/md';
import {ImCross} from 'react-icons/im';
import {createAction} from "../../core/utils/appUtil";
import {
    DASHBOARD_RESET_FILTERS,
    DASHBOARD_SET_GLOBAL_FILTER,
    DASHBOARD_TOGGLE_FILTER
} from "../../constants/actiontypes/dashboard";
import moment from 'moment';
import {RESERVATION_STATUS_COLOR, RESERVATION_STATUSES} from "../../core/constants/reservationStatuses";
import {useIntl} from 'react-intl';
import {trans} from "../../core/utils/translationUtil";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import plLocale from "date-fns/locale/pl";

const CalendarFilterPanel = ({opened, goToDate}) => {

    let intl = useIntl();

    let dispatch = useDispatch();

    let changeCalendarDate = val => {
        goToDate(moment(val).format('YYYY-MM-DD'))
    }

    let currentDate = useSelector(state => state.dashboard.currentDate)

    const [serviceOpened, setServiceOpened] = React.useState(false);

    const [partnersOpened, setPartnersOpened] = React.useState(false);

    const [facilitiesOpened, setFacilitiesOpened] = React.useState(false);

    const [statusOpened, setStatusOpened] = React.useState(false);

    let allServices = useSelector(state => state.service.byId);

    let allPartners = useSelector(state => state.partner.byId);

    let {services, partners, facilities} = useSelector(state => state.dashboard);

    let allFacilities = useSelector(state => state.facility.byId);

    let {filters} = useSelector(state => state.dashboard);

    let globalFilter = useSelector(state => state.dashboard.globalFilter);

    let onGlobalFilterChanged = e =>  dispatch(createAction(DASHBOARD_SET_GLOBAL_FILTER, e.target.value))

    let toggleFilter = (type, id) => {
        dispatch(createAction(DASHBOARD_TOGGLE_FILTER, {type, id}))
    }

    let hasFilter = (type, id) => {
        return filters[type].filter(filter => filter === id).length > 0
    }

    let countFilters = (type) => {
        return filters[type].length
    }

    let resetFilters = () => {
        dispatch(createAction(DASHBOARD_RESET_FILTERS))
    }

    return <div className={'calendar-filter-panel ' + (opened ? '' : 'hidden')}>
        <div className='filter-panel-content'>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={moment(currentDate).toDate()}
                    onChange={changeCalendarDate}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <div className='search'>
                <input type='text' name='search' placeholder='Szukaj według nr rezerwacji' autoComplete='off'
                       value={globalFilter}
                       onChange={onGlobalFilterChanged}/>
                <FaSearch size={16}/>
            </div>
            <div className='filter'>
                <div className='header'
                     onClick={() => setFacilitiesOpened(!facilitiesOpened)}>
                    <span className='name'>Obiekty {countFilters('facilities') > 0 &&
                        <span className='counter'>({countFilters('facilities')})</span>}</span>
                    <MdKeyboardArrowUp size={16} className={'expand ' + (facilitiesOpened ? '' : 'reverse')}
                    />
                </div>
                <Collapse isOpen={facilitiesOpened}>
                    <div className='item-list'>
                        {
                            facilities.map(facility =>
                                <div className='item' key={facility}
                                     onClick={() => toggleFilter('facilities', facility)}>
                                    <div className='checkbox-wrapper'>
                                        {hasFilter('facilities', facility) && <MdCheck size={16}/>}
                                    </div>
                                    <span>{allFacilities[facility].name}</span>
                                </div>)
                        }
                    </div>
                </Collapse>
            </div>
            <div className='filter'>
                <div className='header'
                     onClick={() => setServiceOpened(!serviceOpened)}>
                    <span className='name'>Usługi {countFilters('services') > 0 &&
                        <span className='counter'>({countFilters('services')})</span>}</span>
                    <MdKeyboardArrowUp size={16} className={'expand ' + (serviceOpened ? '' : 'reverse')}
                    />
                </div>
                <Collapse isOpen={serviceOpened}>
                    <div className='item-list'>
                        {
                            services.map(service => <div className='item'
                                                         onClick={() => toggleFilter('services', service)}
                                                         key={service}
                            >
                                <div className='checkbox-wrapper'>
                                    {hasFilter('services', service) && <MdCheck size={16}/>}
                                </div>
                                <span>{allServices[service].name}</span>
                            </div>)
                        }
                    </div>
                </Collapse>
            </div>
            <div className='filter'>
                <div className='header'
                     onClick={() => setPartnersOpened(!partnersOpened)}>
                    <span className='name'>Personel {countFilters('partners') > 0 &&
                        <span className='counter'>({countFilters('partners')})</span>}</span>
                    <MdKeyboardArrowUp size={16} className={'expand ' + (partnersOpened ? '' : 'reverse')}
                    />
                </div>
                <Collapse isOpen={partnersOpened}>
                    <div className='item-list'>
                        {
                            partners.map(partner => <div className='item'
                                                         onClick={() => toggleFilter('partners', partner)}
                                                         key={partner}
                            >
                                <div className='checkbox-wrapper'>
                                    {hasFilter('partners', partner) && <MdCheck size={16}/>}
                                </div>
                                <span>{allPartners[partner].fullname}</span>
                            </div>)
                        }
                    </div>
                </Collapse>
            </div>
            {/*<div className='filter'>*/}
            {/*    <div className='header'*/}
            {/*         onClick={() => setStatusOpened(!statusOpened)}>*/}
            {/*        <span className='name'>Statusy {countFilters('statuses') > 0 &&*/}
            {/*            <span className='counter'>({countFilters('statuses')})</span>}</span>*/}
            {/*        <MdKeyboardArrowUp size={16} className={'expand ' + (statusOpened ? '' : 'reverse')}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <Collapse isOpen={statusOpened}>*/}
            {/*        <div className='item-list'>*/}
            {/*            {*/}
            {/*                RESERVATION_STATUSES.map(status => <div className='item'*/}
            {/*                                                        key={status}*/}
            {/*                                                        onClick={() => toggleFilter('statuses', status)}>*/}
            {/*                    <div className='checkbox-wrapper'>*/}
            {/*                        {hasFilter('statuses', status) && <MdCheck size={16}/>}*/}
            {/*                    </div>*/}
            {/*                    <span*/}
            {/*                        style={{color: RESERVATION_STATUS_COLOR[status]}}>{trans(intl, 'reservationstatus.' + status)}</span>*/}
            {/*                </div>)*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </Collapse>*/}
            {/*</div>*/}
            <div className='button-panel'>
                <button onClick={resetFilters}>
                    <ImCross size={10}/>
                    Wyczyść filtry
                </button>
            </div>
        </div>
    </div>;

}

export default CalendarFilterPanel;
