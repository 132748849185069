import React from "react";
import api from "../../../../../core/api";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AppModal from "../../../../modals/AppModal";
import {createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {useDispatch, useSelector} from 'react-redux'
import {RESERVATION_UPDATE_SUCCESS} from "../../../../../reducers/reservation";
import {RESERVATION_MODAL_CLOSE} from "../../../../../reducers/reservationModal";
import {
    RESERVATION_CYCLE_UPDATE_SUCCESS
} from "../../../../../reducers/reservationCycle";
import schemas from "../../../../../reducers/schemas";
import {normalize} from 'normalizr'

const ConfirmSingleRentalReservation = ({reservationId, modalOpened, setModalOpened}) => {

    let dispatch = useDispatch()

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let [confirmOnlySingle, setConfirmOnlySingle] = React.useState(true)

    let confirmReservation = () => {
        if (confirmOnlySingle) {
            api.reservation.update(reservationId, {confirmed: true}).then((response) => {
                toastSuccess('Rezerwacja została potwierdzona')
                setModalOpened(false)
                dispatch(createAction(RESERVATION_MODAL_CLOSE))
                dispatch(createAction(RESERVATION_UPDATE_SUCCESS, normalize(response.data, schemas.reservationSchema)))
            }).catch(() => {
                toastError('Wystąpił błąd');
                setModalOpened(false)
            })
        } else {
            api.reservationCycle.update(reservation.reservation_cycle_id, {confirmed: true}).then((response) => {
                toastSuccess('Cykl rezerwacji został potwierdzony')
                setModalOpened(false)
                dispatch(createAction(RESERVATION_MODAL_CLOSE))
                dispatch(createAction(RESERVATION_CYCLE_UPDATE_SUCCESS, normalize(response.data, schemas.reservationCycleSchema)))
            }).catch((e) => {
                console.log(e)
                toastError('Wystąpił błąd');
                setModalOpened(false)
            })
        }
    }

    return <AppModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        title={'Czy na pewno chcesz potwierdzić rezerwację'}
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
        submitAction={confirmReservation}
    >
        {reservation.reservation_cycle_id !== null && <RadioGroup defaultValue={true} onChange={(e) => setConfirmOnlySingle(e.target.value === 'true')} value={confirmOnlySingle}>
            <FormControlLabel value={true} control={<Radio/>} label='Tylko ta rezerwacja'/>
            <FormControlLabel value={false} control={<Radio/>} label='Cały cykl rezerwacji'/>
        </RadioGroup>}
    </AppModal>
}

export default ConfirmSingleRentalReservation;

