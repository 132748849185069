import React from "react";
import {MdClose} from 'react-icons/md'
import {useDispatch} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import {createAction} from "../../../core/utils/appUtil";
import {useSelector} from 'react-redux';
import {
    handleEdit,
    handleRemove,
    getHeaderText,
    handleConfirm,
    MODAL_FORM_BLOCK, MODAL_FORM_RENTAL_CYCLE,
    MODAL_FORM_RENTAL_RESERVATION,
    MODAL_STATE_CREATE, MODAL_STATE_SHOW, MODAL_STATE_EDIT, MODAL_FORM_TRAINING_RESERVATION, MODAL_FORM_TRAINING_CYCLE,
} from '../../../actions/reservationModal'
import Slide from '@mui/material/Slide';
import {
    RESERVATION_MODAL_CLOSE, RESERVATION_MODAL_NEW_BLOCK,
    RESERVATION_MODAL_NEW_RENTAL_CYCLE,
    RESERVATION_MODAL_NEW_RENTAL_RESERVATION, RESERVATION_MODAL_NEW_TRAINING_CYCLE,
    RESERVATION_MODAL_NEW_TRAINING_RESERVATION
} from "../../../reducers/reservationModal";
import BlockForm from "./block/BlockForm";
import icon1 from '../../../assets/img/icons/reservationmodal1.svg'
import icon2 from '../../../assets/img/icons/reservationmodal2.svg'
import icon3 from '../../../assets/img/icons/reservationmodal3.svg'
import SingleRentalReservationForm from "./rental/single/SingleRentalReservationForm";
import RentalCycleForm from "./rental/cycle/RentalCycleForm";
import ShowRentalReservation from "./rental/single/ShowRentalReservation";
import ShowRentalCycle from "./rental/cycle/ShowRentalCycle";
import {MdEdit} from "react-icons/md";
import {BiTrash} from 'react-icons/bi'
import {RiEdit2Fill} from 'react-icons/ri'
import {TiTick} from 'react-icons/ti'
import EditSingleRentalReservationForm from "./rental/single/EditSingleRentalReservationForm";
import Button from "../../../core/buttons/Button";
import SingleTrainingReservationForm from "./training/single/SingleTrainingReservationForm";
import TrainingCycleForm from "./training/cycle/TrainingCycleForm";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

let ReservationModal = () => {

    let dispatch = useDispatch()

    let {
        opened: reservationModalOpened,
        reservationId,
        form,
        formState
    } = useSelector(state => state.reservationModal)

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    return <Dialog onClose={() => dispatch(createAction(RESERVATION_MODAL_CLOSE))} open={reservationModalOpened}
                   className={'appdialog reservation-modal ' + (reservationId ? 'edit' : '')}
                   TransitionComponent={Transition}>
        <div className='header d-flex' style={{justifyContent: 'space-between'}}>
            <div style={{lineHeight: '49px'}}>{getHeaderText()}</div>
            <div className='d-flex'>
                {(reservation && !reservation.deleted_at) && <>
                    {handleEdit() && <div className='edit' onClick={handleEdit()}><RiEdit2Fill size={20}/></div>}
                    {handleConfirm() && <div className='confirm' onClick={handleConfirm()}><TiTick size={20}/></div>}
                    {handleRemove() && <div  className='remove' onClick={handleRemove()}><BiTrash/></div>}
                </>}
                <div className='closee'><MdClose onClick={() => dispatch(createAction(RESERVATION_MODAL_CLOSE))}/></div>
            </div>
        </div>
        {
            form === null && <div className='init-page'>
                <div className='menuitem' onClick={() => dispatch(createAction(RESERVATION_MODAL_NEW_RENTAL_RESERVATION))}>
                    <div className='img'>
                        <img src={icon1}/>
                    </div>
                    <div className='info'>
                        <div className='head'>Wynajem obiektu - jednorazowo</div>
                        <div className='infotext'>Wybierz tą opcję, jeśli chcesz utworzyć jednorazową rezerwację obiektu w
                            imieniu klienta.
                        </div>
                    </div>
                </div>
                <div className='menuitem' onClick={() => dispatch(createAction(RESERVATION_MODAL_NEW_RENTAL_CYCLE))}>
                    <div className='img'>
                        <img src={icon2}/>
                    </div>
                    <div className='info'>
                        <div className='head'>Wynajem obiektu - cyklicznie</div>
                        <div className='infotext'>Wybierz tą opcję, jeśli chcesz utworzyć rezerwację obiektu w imieniu
                            klienta,
                            która powtarza się
                            cyklicznie, np. w każdy poniedziałek.
                        </div>
                    </div>
                </div>
                <div className='menuitem' onClick={() => dispatch(createAction(RESERVATION_MODAL_NEW_TRAINING_RESERVATION))}>
                    <div className='img'>
                        <img src={icon1}/>
                    </div>
                    <div className='info'>
                        <div className='head'>Trening lub wizyta - jednorazowo</div>
                        <div className='infotext'>Wybierz tą opcję, jeśli chcesz utworzyć jednorazowy trening lub wizytę w
                            imieniu klienta.
                        </div>
                    </div>
                </div>
                <div className='menuitem' onClick={() => dispatch(createAction(RESERVATION_MODAL_NEW_TRAINING_CYCLE))}>
                    <div className='img'>
                        <img src={icon2}/>
                    </div>
                    <div className='info'>
                        <div className='head'>Trening lub wizyta - cyklicznie</div>
                        <div className='infotext'>Wybierz tą opcję, jeśli chcesz utworzyć trening lub wizytę w imieniu
                            klienta,
                            która powtarza się
                            cyklicznie, np. w każdy poniedziałek.
                        </div>
                    </div>
                </div>
                <div className='menuitem' onClick={() => dispatch(createAction(RESERVATION_MODAL_NEW_BLOCK))}>
                    <div className='img'>
                        <img src={icon3}/>
                    </div>
                    <div className='info'>
                        <div className='head'>Blokada czasu</div>
                        <div className='infotext'>Wybierz tą opcję, jeśli chcesz zarezerwować czas pracownika, obiektu lub
                            zasobu
                            np. spotkanie
                            wewnętrzne czy zalanie kortu.
                        </div>
                    </div>
                </div>
            </div>
        }
        {(form === MODAL_FORM_TRAINING_RESERVATION && formState === MODAL_STATE_CREATE) && <SingleTrainingReservationForm/>}
        {(form === MODAL_FORM_RENTAL_RESERVATION && formState === MODAL_STATE_CREATE) && <SingleRentalReservationForm/>}
        {(form === MODAL_FORM_RENTAL_RESERVATION && formState === MODAL_STATE_SHOW) && <ShowRentalReservation/>}
        {(form === MODAL_FORM_RENTAL_CYCLE && formState === MODAL_STATE_SHOW) && <ShowRentalCycle/>}
        {(form === MODAL_FORM_RENTAL_CYCLE && formState === MODAL_STATE_CREATE) && <RentalCycleForm/>}
        {(form === MODAL_FORM_TRAINING_CYCLE && formState === MODAL_STATE_CREATE) && <TrainingCycleForm/>}
        {(form === MODAL_FORM_BLOCK) && <BlockForm/>}
        {(form === MODAL_FORM_RENTAL_RESERVATION && formState === MODAL_STATE_EDIT) && <EditSingleRentalReservationForm/>}
    </Dialog>
}

export default ReservationModal