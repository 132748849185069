import React from "react";
import CardFooter from "../../core/card/CardFooter";
import Button from "../../core/buttons/Button";
import SubmitFormButton from "../../core/buttons/SubmitFormButton";
import {useFormContext} from 'react-hook-form'

let SubmitButtonsFooter = () => {

    let {formState: {isDirty, isSubmitted}, reset} = useFormContext()

    return isDirty && <CardFooter>
        <Button variant='secondary' text='Anuluj' onClick={reset}/>
        <SubmitFormButton loading={isSubmitted} text='Zapisz'/>
    </CardFooter>

}
export default SubmitButtonsFooter