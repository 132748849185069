import React from "react";
import TextInput from "../../../core/form/TextInput";
import TextEditorInput from "../../../core/form/TextEditorInput";

let BusinessDescriptionField = ({loading}) => {

    return <TextEditorInput
        name='description'
        label='form.business.name'
        required={false}
        loading={loading}
    />

}

export default BusinessDescriptionField