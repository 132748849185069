import React from "react";
import SportsSelect from "../select/SportsSelect";

let ServiceSportsField = ({loading = false}) => {

    return <SportsSelect
        name='sport_id'
        label='Wybierz sport'
        required={true}
        isMulti={false}
        loading={loading}
    />

}

export default ServiceSportsField