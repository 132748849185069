import {
    MODAL_FORM_BLOCK,
    MODAL_FORM_RENTAL_CYCLE,
    MODAL_FORM_RENTAL_RESERVATION,
    MODAL_FORM_TRAINING_CYCLE,
    MODAL_FORM_TRAINING_RESERVATION,
    MODAL_STATE_CREATE,
    MODAL_STATE_EDIT,
    MODAL_STATE_SHOW
} from "../actions/reservationModal";

export const RESERVATION_MODAL_OPEN_AND_RESET = 'RESERVATION_MODAL_OPEN_AND_RESET'
export const RESERVATION_MODAL_OPEN_MAIN_FORM = 'RESERVATION_MODAL_OPEN_MAIN_FORM'
export const RESERVATION_MODAL_CLOSE = 'RESERVATION_MODAL_CLOSE'
export const RESERVATION_MODAL_SHOW_RENTAL_RESERVATION = 'RESERVATION_MODAL_SHOW_RENTAL_RESERVATION'
export const RESERVATION_MODAL_NEW_RENTAL_RESERVATION = 'RESERVATION_MODAL_NEW_RENTAL_RESERVATION'
export const RESERVATION_MODAL_EDIT_RENTAL_RESERVATION = 'RESERVATION_MODAL_EDIT_RENTAL_RESERVATION'
export const RESERVATION_MODAL_SHOW_RENTAL_CYCLE = 'RESERVATION_MODAL_SHOW_RENTAL_CYCLE'
export const RESERVATION_MODAL_NEW_RENTAL_CYCLE = 'RESERVATION_MODAL_NEW_RENTAL_CYCLE'
export const RESERVATION_MODAL_EDIT_RENTAL_CYCLE = 'RESERVATION_MODAL_EDIT_RENTAL_CYCLE'
export const RESERVATION_MODAL_SHOW_TRAINING_RESERVATION = 'RESERVATION_MODAL_SHOW_TRAINING_RESERVATION'
export const RESERVATION_MODAL_NEW_TRAINING_RESERVATION = 'RESERVATION_MODAL_NEW_TRAINING_RESERVATION'
export const RESERVATION_MODAL_NEW_TRAINING_CYCLE = 'RESERVATION_MODAL_NEW_TRAINING_CYCLE'
export const RESERVATION_MODAL_EDIT_TRAINING_RESERVATION = 'RESERVATION_MODAL_EDIT_TRAINING_RESERVATION'
export const RESERVATION_MODAL_SHOW_BLOCK = 'RESERVATION_MODAL_SHOW_BLOCK'
export const RESERVATION_MODAL_NEW_BLOCK = 'RESERVATION_MODAL_NEW_BLOCK'
export const RESERVATION_MODAL_EDIT_BLOCK = 'RESERVATION_MODAL_EDIT_BLOCK'
export const RESERVATION_MODAL_FETCH_BLOCK = 'RESERVATION_MODAL_FETCH_BLOCK'
export const RESERVATION_MODAL_FETCH_CYCLE = 'RESERVATION_MODAL_FETCH_CYCLE'
export const RESERVATION_MODAL_DELETE_MODAL_HIDE = 'RESERVATION_MODAL_DELETE_MODAL_HIDE'
export const RESERVATION_MODAL_DELETE_MODAL_OPEN = 'RESERVATION_MODAL_DELETE_MODAL_OPEN'
export const RESERVATION_MODAL_CONFIRM_MODAL_OPEN = 'RESERVATION_MODAL_CONFIRM_MODAL_OPEN'
export const RESERVATION_MODAL_CONFIRM_MODAL_HIDE = 'RESERVATION_MODAL_CONFIRM_MODAL_HIDE'

var initialState = {
    opened: false,
    deleteModalOpened: false,
    confirmModalOpened: false,
    form: null,
    formState: null,
    reservationId: null,
    reservationCycleId: null,
    date: null,
    blockId: null,
    loading: false,
    resourceId: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESERVATION_MODAL_DELETE_MODAL_OPEN: {
            return {...state, deleteModalOpened: true}
        }
        case RESERVATION_MODAL_DELETE_MODAL_HIDE: {
            return {...state, deleteModalOpened: false}
        }
        case RESERVATION_MODAL_CONFIRM_MODAL_OPEN: {
            return {...state, confirmModalOpened: true}
        }
        case RESERVATION_MODAL_CONFIRM_MODAL_HIDE: {
            return {...state, confirmModalOpened: false}
        }
        case RESERVATION_MODAL_OPEN_MAIN_FORM: {
            return {...state, form: null}
        }
        case RESERVATION_MODAL_OPEN_AND_RESET: {
            return {
                ...state,
                reservationId: null,
                opened: true,
                form: null,
                date: action.payload.date,
                resourceId: action.payload.resourceId
            }
        }
        case RESERVATION_MODAL_CLOSE: {
            return {
                ...state,
                form: null,
                blockId: null,
                reservationId: null,
                opened: false
            }
        }
        case RESERVATION_MODAL_NEW_TRAINING_RESERVATION: {
            return {
                ...state,
                reservationId: null,
                form: MODAL_FORM_TRAINING_RESERVATION,
                formState: MODAL_STATE_CREATE
            }
        }
        case RESERVATION_MODAL_NEW_RENTAL_RESERVATION: {
            return {
                ...state,
                reservationId: null,
                form: MODAL_FORM_RENTAL_RESERVATION,
                formState: MODAL_STATE_CREATE
            }
        }
        case RESERVATION_MODAL_NEW_RENTAL_CYCLE: {
            return {
                ...state,
                form: MODAL_FORM_RENTAL_CYCLE,
                reservationCycleId: null,
                formState: MODAL_STATE_CREATE
            }
        }
        case RESERVATION_MODAL_NEW_TRAINING_CYCLE: {
            return {
                ...state,
                form: MODAL_FORM_TRAINING_CYCLE,
                reservationCycleId: null,
                formState: MODAL_STATE_CREATE
            }
        }
        case RESERVATION_MODAL_NEW_BLOCK: {
            return {
                ...state,
                blockId: null,
                form: MODAL_FORM_BLOCK,
                formState: MODAL_STATE_CREATE
            }
        }
        case RESERVATION_MODAL_SHOW_RENTAL_RESERVATION: {
            return {
                ...state,
                opened: true,
                form: MODAL_FORM_RENTAL_RESERVATION,
                formState: MODAL_STATE_SHOW,
                reservationId: action.payload
            }
        }
        case RESERVATION_MODAL_SHOW_RENTAL_CYCLE: {
            return {
                ...state,
                opened: true,
                form: MODAL_FORM_RENTAL_CYCLE,
                formState: MODAL_STATE_SHOW,
                reservationCycleId: action.payload
            }
        }
        case RESERVATION_MODAL_EDIT_RENTAL_RESERVATION: {
            return {
                ...state,
                opened: true,
                form: MODAL_FORM_RENTAL_RESERVATION,
                formState: MODAL_STATE_EDIT,
                reservationId: action.payload,
                loading: true
            }
        }
        case RESERVATION_MODAL_EDIT_BLOCK: {
            return {
                ...state,
                opened: true,
                form: MODAL_FORM_BLOCK,
                formState: MODAL_STATE_EDIT,
                blockId: action.payload
            }
        }
        case RESERVATION_MODAL_EDIT_RENTAL_CYCLE: {
            return {
                ...state,
                opened: true,
                form: MODAL_FORM_RENTAL_CYCLE,
                formState: MODAL_STATE_EDIT,
                reservationCycleId: action.payload
            }
        }
        default:
            return state;
    }
};