import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import ServicePage from "./ServicePage";
import {fetchServiceDetails} from "../../../actions/services";
import ServiceReservationConfigForm from "../../../components/forms/app/service/ServiceReservationConfigForm";
import {fetchAllResourceTypes} from "../../../actions/resourceTypes";
import api from "../../../core/api";
import {createAction} from "../../../core/utils/appUtil";
import {SERVICE_UPDATE_SUCCESS} from "../../../reducers/service";
import schemas from "../../../reducers/schemas";
import SelectInput from "../../../core/form/select/SelectInput";
import SwitchInput from "../../../core/form/SwitchInput";
import CardForm from "../../../core/form/CardForm";
import AppLoading from "../../../components/AppLoading";

let ServiceReservationConfigPage = () => {

    let loading = useSelector(state => state.service.loadingDetails)

    let params = useParams();

    let serviceId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchServiceDetails(serviceId, ['facilities']))
    }, [dispatch, serviceId])

    let prepareData = data => {
        return {
            id: data.id,
            required_personnel: data.required_personnel,
            required_facility: data.type === 'facility',
            required_facility_parts: data.type === 'facility_part' ? 1 : 0,
            facilities: data.type !== 'none' ? data.facilities : [],
        }
    }

    let service = useSelector(state => state.service.byId[serviceId])

    if(loading) {
        return <AppLoading/>
    }

    return <ServicePage serviceId={serviceId}>
        <Row className='fitheight'>
            <Col lg={{size: 8, offset: 2}}>
                <CardForm
                title='Informacje o usłudze'
                prepareData={prepareData}
                data={{
                    required_personnel: service.required_personnel,
                    type: (service.required_facility_parts ? 'facility_part' : (service.required_facility ? 'facility' : 'none')),
                    facilities: service.facilities,
                    required_facility: service.required_facility,
                    required_facility_parts: service.required_facility_parts
                }}
                getPromise={(id, data) => api.service.update(service.id, data)}
                onSubmit={data => dispatch(createAction(SERVICE_UPDATE_SUCCESS, normalize(data, schemas.serviceSchema)))}
            >
                <ServiceReservationConfigForm/>
            </CardForm>
            </Col>
        </Row>
    </ServicePage>

}

export default ServiceReservationConfigPage
