import React from "react";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

let FacilitySkeleton = ({}) => {

    return <div className='facility'>
        <SkeletonTheme baseColor="#F3F9FF" highlightColor="#E7F2FE" className='facility'>
    <span className='title'>
            <Skeleton count={1}/>
    </span>
            <div className='img'>
                <Skeleton count={1} height={200}/>
            </div>
            <span className='type'>
    <Skeleton count={1}/>
        </span>
            <span className='address'>
    <Skeleton count={1}/>
        </span>
            <span className='address'>
    <Skeleton count={1}/>
        </span>
            <Skeleton count={1} height={46}/>
        </SkeletonTheme>
    </div>

}

export default FacilitySkeleton;

