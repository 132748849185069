import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessPhoneField = (props) => {

    return <TextInput
        mask='+48 999 999 999'
        name='phone'
        label='form.facility.phone'
        {...props}
    />

}

export default BusinessPhoneField