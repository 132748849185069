import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";
import {useDispatch} from 'react-redux'
import { PieChart } from '@mui/x-charts/PieChart';

let ReservationsByPaymentType = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('reservations-by-payment-type', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result.data)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }

    let sumCount = data.reduce((partialSum, a) => partialSum + a.count, 0)

    return <div>
        <PieChart
            // axisTickLabel={() => console.log('ffff')}
            series={[
                {
                    arcLabel: () => '',
                    valueFormatter: (v) => v.value + ' PLN (' + (Math.round(v.percent/sumCount * 100)) + '%)',
                    data: data.map((row, key) => {
                        return { id: key, value: row.price, percent: row.count, label: row.payment_method ?? 'Nieopłacona'}
                    }),
                },
            ]}
            width={400}
            height={200}
        />
    </div>
}

export default ReservationsByPaymentType