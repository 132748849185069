import React from "react";
import TextInput from "../../../core/form/TextInput";

let ServiceNameField = (props) => {

    return <TextInput
        name='name'
        label='form.service.name'
        required={true}
        {...props}
    />

}

export default ServiceNameField