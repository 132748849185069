import React, {useState} from "react";
import {useDispatch} from "react-redux";
import api from "../../../../core/api";
import {createAction, toastSuccess} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import {FACILITY_DETAILS_SUCCESS} from "../../../../reducers/facility";
import RentalForm from "../../../facility/RentalForm";
import {useForm} from 'react-hook-form'
import AppForm from "../../AppForm";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import Button from "../../../../core/buttons/Button";
import Card from "../../../../core/card/Card";
import CardBody from "../../../../core/card/CardBody";
import CardFooter from "../../../../core/card/CardFooter";

let FacilityRentalForm = function ({facilityId}) {
    let dispatch = useDispatch()

    let [loading, setLoading] = useState(true)

    const loadDefaultValues = async () =>
        await api.facility.get(facilityId, ['type', 'parts'])
            .then(response => {
                dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(response.data, schemas.facilitySchema)))
                setLoading(false)
                let data = response.data;
                if (data.rental.variants.length === 0) {
                    data.rental.variants.push({})
                }
                return {
                    parts: data.parts.length ? data.parts.length : null,
                    rental: data.rental,
                    type_id: response.data.type_id
                };
            })

    let formContext = useForm({defaultValues: loadDefaultValues})

    let onSubmit = async formData => {
        formData.include = ['parts']
        let result = await api.facility.update(facilityId, formData);
        dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(result.data, schemas.facilitySchema)))
        formContext.reset({
            parts: result.data.parts.length,
            rental: result.data.rental,
            type_id: result.data.type_id
        })
        toastSuccess('Zapisano obiekt')
    }

    return <AppForm formContext={formContext} onSubmit={onSubmit}>
        <Card title={''}>
            <CardBody>
                {!loading && <RentalForm edit={true}/>}
            </CardBody>
            {(formContext.formState.isDirty) && <CardFooter>
                <Button variant='secondary' text='Anuluj' onClick={() => formContext.reset()}/>
                <SubmitFormButton loading={loading} text='Zapisz'/>
            </CardFooter>}
        </Card>
    </AppForm>

}
export default FacilityRentalForm