import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import {fetchServiceDetails} from "../../../actions/services";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import {fetchAllResources} from "../../../actions/resources";
import SelectResourcesModal from "../../../components/modals/SelectResourcesModal";
import ServicePage from "./ServicePage";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import ResourceName from "../../../components/resource/ResourceName";

let ServiceResourcesPage = () => {

    let params = useParams();

    let serviceId = params.id

    let service = useSelector(state => state.service.byId[serviceId])
    let loading = useSelector(state => state.service.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
            dispatch(fetchAllResources())
            dispatch(fetchServiceDetails(serviceId, ['resources']))
    }, [serviceId, dispatch])

    let allResources = useSelector(state => state.resource.byId)

    let hasResources = service?.resources && service?.resources.length > 0

    let [selectResourcesModalOpened, setSelectResourcesModalOpened] = useState(false);

    return <ServicePage serviceId={serviceId}>
        {selectResourcesModalOpened && service && <SelectResourcesModal
            type='service'
            modalOpened={selectResourcesModalOpened}
            setModalOpened={setSelectResourcesModalOpened}
            defaultSelected={service?.resources ?? []}
            objectId={service?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Dostępne zasoby'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasResources && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasResources && service?.resources.map(resourceId => <tr>
                                    <td>
                                        <ResourceName resourceId={allResources[resourceId].id}/>
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz zasoby dostępne w tej usłudze'
                                  variant='forward'
                                  onClick={() => setSelectResourcesModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </ServicePage>

}

export default ServiceResourcesPage
