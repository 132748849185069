import React from "react";
import {useSelector} from "react-redux";
import {FaCalendarCheck} from 'react-icons/fa';

let ReservationCancelled = ({notificationId}) => {

    let notification = useSelector(state => state.notification.byId[notificationId])

    return <>
        <div className='icon red'>
            <FaCalendarCheck size={20}/>
        </div>
        <div className='content'>
            <div className='header'>
                Odwołanie rezerwacji!
            </div>
            <div className='text'>
                Klient <span className='bold'>{notification?.data?.client}</span> anulował rezerwację
                usługi <span className='bold'>{notification?.data?.serviceName} </span> w
                obiekcie <span className='bold'>{notification?.data?.facilityName} </span> w
                terminie <span className='bold'>{notification?.data?.reservationDate} </span>.
                Numer rezerwacji: <span className='bold'>#{notification?.data?.number}</span>
            </div>
        </div>
    </>
}

export default ReservationCancelled