import React from "react";

import AppModal from "../../../../modals/AppModal";
import {createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {useDispatch} from 'react-redux'
import {RESERVATION_MODAL_CLOSE} from "../../../../../reducers/reservationModal";
import api from "../../../../../core/api";
import {
    RESERVATION_CYCLE_DELETE_SUCCESS,
    RESERVATION_CYCLE_UPDATE_SUCCESS
} from "../../../../../reducers/reservationCycle";
import schemas from "../../../../../reducers/schemas";
import {normalize} from 'normalizr'

const ConfirmCycleRentalReservation = ({reservationCycleId, modalOpened, setModalOpened}) => {

    let dispatch = useDispatch()

    let deleteReservation = () => {
        api.reservationCycle.update(reservationCycleId, {confirmed: true}).then((response) => {
            toastSuccess('Cykl rezerwacji został potwierdzony')
            setModalOpened(false)
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            dispatch(createAction(RESERVATION_CYCLE_UPDATE_SUCCESS, normalize(response.data, schemas.reservationCycleSchema)))
        }).catch((e) => {
            console.log(e)
            toastError('Wystąpił błąd');
            setModalOpened(false)
        })
    }

    return <AppModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        title='Czy na pewno chcesz potwierdzić cały cykl rezerwacji?'
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
        submitAction={deleteReservation}
    />
}

export default ConfirmCycleRentalReservation;

