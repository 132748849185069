import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {IntlProvider} from "react-intl";
import Routes from "./routes/Routes";
import AppLoading from "./components/AppLoading";
import * as actions from "./actions/app";
import langData from './lang/langData';
import ErrorModal from "./components/ErrorModal";
import {changeLanguage} from "./actions/app";
import TestInfo from './layouts/TestInfo/TestInfo'

let App = () => {

    let dispatch = useDispatch()

    let {
        error,
        is_loading: isLoading,
        current_language: currentLanguage
    } = useSelector(state => state.app)

    React.useEffect(() => {
        let hubspotKey = process.env.REACT_APP_HUBSPOT_KEY
        if(hubspotKey) {
            const script = document.createElement("script");
            script.src="//js.hs-scripts.com/" + hubspotKey + ".js";
            script.async = true;
            document.body.appendChild(script);
        }

        let storageLang = localStorage.getItem('current_language');
        if (!storageLang) {
            localStorage.setItem('current_language', 'pl')
        }
        dispatch(changeLanguage(localStorage.getItem('current_language')))
        dispatch(actions.initApp())
    }, [dispatch])

    if (isLoading) {
        return <AppLoading/>
    }

    return <>
        {!process.env.REACT_APP_SKIP_TEST_INFO && <TestInfo/>}
        {error && <ErrorModal/>}
        <IntlProvider
            key={currentLanguage}
            locale={currentLanguage}
            messages={langData[currentLanguage]}>
            <Routes/>
        </IntlProvider>
    </>
}

export default App;