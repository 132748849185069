import React, {useState} from "react";
import {useForm} from "react-hook-form";
import Button from "../../../../../core/buttons/Button";
import SubmitFormButton from "../../../../../core/buttons/SubmitFormButton";
import AppForm from "../../../../forms/AppForm";
import RentalDetailsForm from "../../RentalDetailsForm";
import {useSelector} from 'react-redux';
import moment from 'moment';
import TermForm from "./TermForm";
import TermListForm from "./TermListForm";
import {addMinutesToTime} from "../../../../../core/utils/appUtil";
import {RiEdit2Fill} from 'react-icons/ri'
import {BsTrash} from 'react-icons/bs'

let InvalidDay = ({term, day, timeFrom, duration, removeTerm, availableTerms, setTerm}) => {
    let [opened, setOpened] = useState(false)
    let availableSlots = availableTerms.filter(daySlots => daySlots.date === day)[0]?.slots ?? [];
    return <div>
        <div className='d-flex flex-row term invalid'>
            <div className='d-flex flex-row'>
                <div className='counter'>{term.index}.</div>
                <div className='date'>{moment(day).format('YYYY.MM.DD')}</div>
                <div className='time'>{timeFrom} - {addMinutesToTime(timeFrom, duration)}</div>
            </div>
            <div className='info'>obiekt jest już zarezerwowany</div>
            <div className='actions'>
                <RiEdit2Fill size={20} onClick={() => setOpened(!opened)}/>
                <BsTrash onClick={() => removeTerm(day)}/>
            </div>
        </div>
        {opened && <div className='proposals'>
            {availableSlots.length === 0 && <div className='error'>
                Brak wolnych terminów w tym dniu
            </div>}
            {availableSlots.length > 0 && <>
                <div>
                    Wybierz inny termin tego dnia:
                </div>
                <div className='d-flex slots'>
                    {
                        availableSlots.map(slot => <div className='slot'
                                                        onClick={() => setTerm(day, slot)}>{slot.time_from}-{slot.time_to}</div>)
                    }
                </div>
            </>}
        </div>}
    </div>
}

export default InvalidDay