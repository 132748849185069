import React, {useEffect} from 'react';
import CalendarFilterPanel from "../../components/calendar/CalendarFilterPanel";
import Sidebar from "../../components/sidebar/Sidebar";
import Main from "../../components/Main";
import {useSelector, useDispatch} from 'react-redux';
import {
    Spinner
} from "reactstrap";
import CalendarNavigation from "../../components/dashboard/CalendarNavigation";
import ReservationCalendar from "../../components/dashboard/ReservationCalendar";
import {createAction} from "../../core/utils/appUtil";
import moment from 'moment';
import {
    DASHBOARD_SET_CURRENT_DATE,
    DASHBOARD_TOGGLE_FILTER_PANEL
} from "../../constants/actiontypes/dashboard";
import {initDashboard} from "../../actions/dashboard";
import arrow from "../../assets/img/arrow.svg";
import DashboardLayout from "../../layouts/Dashboard";
import {useLocation} from "react-router-dom";
import QueryString from "query-string";
let CalendarPage = () => {

    let dispatch = useDispatch();

    const location = useLocation();

    let type = QueryString.parse(location.search)?.type ?? 'timeGridWeek'

    let date = QueryString.parse(location.search)?.date ?? moment().format('YYYY-MM-DD')

    let calendarRef = React.createRef();

    let [view, setView] = React.useState(type);

    useEffect(() => {
        if (calendarRef.current && calendarRef.current.getApi) {
            calendarRef.current.getApi().changeView(view);
        }
    }, [
        view, calendarRef, dispatch
    ])

    let next = () => {
        if (calendarRef.current && calendarRef.current.getApi) {
            calendarRef.current.getApi().next();
            dispatch(createAction(DASHBOARD_SET_CURRENT_DATE, moment(calendarRef.current.getApi().getDate()).format('YYYY-MM-DD')))
        }
    }

    let prev = () => {
        if (calendarRef.current && calendarRef.current.getApi) {
            calendarRef.current.getApi().prev();
            dispatch(createAction(DASHBOARD_SET_CURRENT_DATE, moment(calendarRef.current.getApi().getDate()).format('YYYY-MM-DD')))
        }
    }
    let today = () => {
        if (calendarRef.current && calendarRef.current.getApi) {
            calendarRef.current.getApi().today();
            dispatch(createAction(DASHBOARD_SET_CURRENT_DATE, moment(calendarRef.current.getApi().getDate()).format('YYYY-MM-DD')))
        }
    }
    let goToDate = (date) => {
        calendarRef.current.getApi().gotoDate(date)
        dispatch(createAction(DASHBOARD_SET_CURRENT_DATE, moment(calendarRef.current.getApi().getDate()).format('YYYY-MM-DD')))
    }


    let calendarFilterOpened = useSelector(state => state.dashboard.filterOpen)

    let changeCalendarFilterOpened = () => {
        dispatch(createAction(DASHBOARD_TOGGLE_FILTER_PANEL));
        setTimeout(() => {
            if (calendarRef.current && calendarRef.current.getApi) {
                calendarRef.current.getApi().updateSize();
            }
        }, 500)
    }

    useEffect(() => {
        dispatch(initDashboard(date))
    }, [dispatch])

    let sidebarOpen = useSelector(state => state.sidebar.isOpen);

    let {loadingCalendar, loading} = useSelector(state => state.dashboard);
    if (loading) {
        return <div className='wrapper'>
            <Sidebar/>
            <Main className='dashboard-page'>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    opacity: 0.5,
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '100px',
                    zIndex: 999
                }}>
                    <Spinner color="primary" style={{opacity: 1}}/>
                </div>
            </Main>
        </div>
    }

    return <DashboardLayout extraClasses='dashboard-page' navbarContent={() => <CalendarNavigation calendarRef={calendarRef} view={view} setView={setView} next={next} prev={prev} today={today}/>}
        beforeMain={() => <>
            <img src={arrow}
                 className={'calendar-filter-panel-arrow ' + (sidebarOpen ? 'sidebaropen ' : ' ') + (calendarFilterOpened ? '  ' : 'rotated ')}
                 onClick={() => changeCalendarFilterOpened(!calendarFilterOpened)}/>
            <CalendarFilterPanel opened={calendarFilterOpened} goToDate={goToDate}/>
        </>}
    >
        {loadingCalendar &&
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                opacity: 0.5,
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '100px',
                zIndex: 999
            }}>
                <Spinner color="primary" style={{opacity: 1}}/>
            </div>}
        <ReservationCalendar calendarRef={calendarRef} view={view}/>
    </DashboardLayout>

    // return <div className='wrapper'>
    //     <Main className='dashboard-page'>
    //         {loadingCalendar &&
    //             <div style={{
    //                 position: 'absolute',
    //                 width: '100%',
    //                 height: '100%',
    //                 backgroundColor: 'white',
    //                 opacity: 0.5,
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 paddingTop: '100px',
    //                 zIndex: 999
    //             }}>
    //                 <Spinner color="primary" style={{opacity: 1}}/>
    //             </div>}
    //         <div className='d-flex flex-column w-100'>
    //             <Navbar breadcrumbs={false}>
    //                 <CalendarNavigation view={view} setView={setView} next={next} prev={prev} today={today}/>
    //             </Navbar>
    //             <div className="content">
    //                 <ReservationCalendar calendarRef={calendarRef} view={view}/>
    //             </div>
    //             <Footer/>
    //         </div>
    //     </Main>
    //     <ReservationModal/>
    // </div>

}

export default CalendarPage;