import React, {useState} from "react";
import PaymentsPage from "./PaymentsPage";
import Datatable from "../../../core/components/datatable/Datatable";
import {Col} from "reactstrap";
import api from "../../../core/api";
import handleError from "../../../core/utils/errorUtil";
import {useDispatch} from 'react-redux'

import moment from 'moment'
import {getPriceStatusText, openReservation, updateReservationStatus} from "../../../actions/reservation";
import {Button} from 'reactstrap';
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import {RESERVATION_STATUS_CANCELLED} from "../../../core/constants/reservationStatuses";

let PaymentsListPage = ({status}) => {

    let dispatch = useDispatch()

    let [loading, setLoading] = useState(true)

    let [data, setData] = useState([])

    let [modalOpened, setModalOpened] = React.useState(false)

    let [currentPayment, setCurrentPayment] = React.useState(false)

    let openReturnPaymentModal = payment => {
        setCurrentPayment(payment)
        setModalOpened(true)
    }

    let confirmAction = () => {
        setModalOpened(false)
        setCurrentPayment(null)
        api.payment.update(currentPayment.id, {status: 'returned'}).then(r => {
            loadPayments()
        })
    };

    let loadPayments = () => {
        setLoading(true)
        api.payment.list({status: status}, [])
            .then((response) => {
                let newData = response.data.map(payment => [
                    <td><b style={{cursor: 'pointer'}} onClick={() => openReservation(payment.reservation.id, dispatch)}>#{payment.reservation.number}</b></td>,
                    <td>{getPriceStatusText(payment.status)}</td>,
                    <td>{payment.payment_method?.name ?? 'BRAK'}</td>,
                    <td>{payment.reservation.client.fullname}</td>,
                    <td>{payment.reservation.service.name}</td>,
                    <td>{payment.value} PLN</td>,
                    <td style={{minWidth: '105px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span
                                style={{textWrap: 'nowrap'}}>{moment(payment.reservation.starttime).format('YYYY-MM-DD')}</span>
                            <span
                                style={{textWrap: 'nowrap'}}>{moment(payment.reservation.starttime).format('HH:mm')}</span>
                        </div>
                    </td>,
                    (status === 'cancelled' || status === 'to-return' || status === 'returned') ? <td style={{minWidth: '105px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{moment(payment.cancelled_at).format('YYYY-MM-DD')}</span>
                            <span>{moment(payment.cancelled_at).format('HH:mm')}</span>
                        </div>
                    </td> : null,
                    status === 'paid' ? <td style={{minWidth: '105px'}}>
                        {payment.paid_at && <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{moment(payment.paid_at).format('YYYY-MM-DD')}</span>
                            <span>{moment(payment.paid_at).format('HH:mm')}</span>
                        </div>}
                        {!payment.paid_at && <p>-</p>}
                    </td> : null,
                    status === 'returned' ? <td style={{minWidth: '105px'}}>
                        {payment.returned_at && <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{moment(payment.returned_at).format('YYYY-MM-DD')}</span>
                            <span>{moment(payment.returned_at).format('HH:mm')}</span>
                        </div>}
                        {!payment.returned_at && <p>-</p>}
                    </td> : null,
                    status === 'to-return' ? <td style={{minWidth: '100px', fontSize: '10px'}}>
                        <Button color='success' size='xs' text='Zwrócono' variant='secondary' style={{fontSize: '10px'}} onClick={() => openReturnPaymentModal(payment)}>Zwrócono</Button>
                    </td> : null,
                ])
                setData(newData)
                setLoading(false)
            })
            .catch(error => handleError(error, dispatch));
    }

    React.useEffect(() => {
        loadPayments()
    }, [status])

    let headers = ['Rezerwacja', 'Status płatności', 'Forma płatności', 'Klient', 'Produkt', 'Cena', 'Data usługi']

    if (status === 'cancelled' || status === 'to-return' || status === 'returned') {
        headers.push('Data anulowania')
    }
    if (status === 'paid') {
        headers.push('Data opłacenia')
    }
    if (status === 'returned') {
        headers.push('Data zwrotu')
    }
    if (status === 'to-return') {
        headers.push('Zwrot')
    }


    return <PaymentsPage>
        {modalOpened && <ConfirmationModal
            submitAction={confirmAction}
            setModalOpened={setModalOpened}
            modalOpened={modalOpened}
            message={'Czy na pewno chcesz zwrócić płatność?'}
        />}
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={headers}
                data={data}
            />
        </Col>
    </PaymentsPage>
}
export default PaymentsListPage;

