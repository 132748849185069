import React from 'react';
import {useParams} from "react-router-dom";
import {createAction} from "../../core/utils/appUtil";
import * as authTypes from "../../constants/actiontypes/auth";
import * as routes from "../../routes/routeDefinitions";
import {useDispatch} from "react-redux";

let LoginAs = () => {
    let params = useParams();

    let token = params.token

    let partnerId = params.partnerId

    let dispatch = useDispatch();

    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
    dispatch(createAction(authTypes.SET_LOGGED_USER, {result: partnerId}));
    window.location.href = routes.ROUTE_DASHBOARD

    return <p></p>
}

export default LoginAs;