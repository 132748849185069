import React from "react";
import {MdKeyboardArrowRight, MdKeyboardArrowLeft, MdKeyboardArrowDown} from 'react-icons/md';
import {BiPlus} from 'react-icons/bi';
import {
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";
import {useSelector} from 'react-redux';
import moment from 'moment';
import {createAction} from "../../core/utils/appUtil";
import {DASHBOARD_CREATE_RESERVATION_MODAL_SHOW} from "../../constants/actiontypes/dashboard";
import {useDispatch} from 'react-redux'
import {openReservationModal} from "../../actions/reservationModal";

const CalendarNavigation = (props) => {

    let {myFacilities, byId: allFacilities} = useSelector(state => state.facility)

    let dispatch = useDispatch()

    let {view, setView} = props;

    let currentDate = useSelector(state => state.dashboard.currentDate);

    let calendarTypes = {
        timeGridDay: 'Dzień',
        timeGridWeek: 'Tydzień',
        dayGridMonth: 'Miesiąc',
        listWeek: 'Lista',
        resourceTimeGridDay: 'Obiekty'
    }

    return <div className='calendar-navigation'>
        <button className='add-btn' onClick={() => dispatch(openReservationModal())}>
            <BiPlus/>
            Dodaj
        </button>
        <div className='d-inline-flex calendar-panel'>
            <button onClick={props.today}
                    className='today'
            >
                <MdKeyboardArrowRight/>
                Dzisiaj
                <MdKeyboardArrowLeft/>
            </button>
            <button onClick={props.prev} className='prev'>{'<'}</button>
            <button onClick={props.next} className='next'>{'>'}</button>
            <span className='current-date'>{moment(currentDate).format('MMMM YYYY')}</span>

            <UncontrolledDropdown className='calendar-type'>
                            <span className="d-sm-inline-block">
                              <DropdownToggle nav>
                                  <span>{calendarTypes[view]}</span>
                                    <MdKeyboardArrowDown/>
                              </DropdownToggle>
                            </span>
                <DropdownMenu className="dropdown-menu-lg py-0">
                    <DropdownItem onClick={() => setView('timeGridDay')}
                                  className={view === 'timeGridDay' ? 'hidden' : ''}>Dzień</DropdownItem>
                    <DropdownItem onClick={() => setView('timeGridWeek')}
                                  className={view === 'timeGridWeek' ? 'hidden' : ''}>Tydzień</DropdownItem>
                    <DropdownItem onClick={() => setView('dayGridMonth')}
                                  className={view === 'dayGridMonth' ? 'hidden' : ''}>Miesiąc</DropdownItem>
                    <DropdownItem onClick={() => setView('listWeek')}
                                  className={view === 'listWeek' ? 'hidden' : ''}>Lista</DropdownItem>
                    <DropdownItem onClick={() => setView('resourceTimeGridDay')}
                                  className={view === 'resourceTimeGridDay' ? 'hidden' : ''}>Obiekty</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    </div>

}

export default CalendarNavigation;
