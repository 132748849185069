import React from "react";

import {BiInfoCircle} from 'react-icons/bi';

const PartnerAvailabilityColumn = ({active}) => {
    return <div className={'partner-availability ' + (active ? 'active' : '')}>
        <BiInfoCircle size={15}/>
        <span>
            {active ? 'Dostępny w aplikacji' : 'Niedostępny w aplikacji'}
        </span>
    </div>
}

export default PartnerAvailabilityColumn;
