import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let FacilityClientReservationsField = (props) => {

    return <SwitchInput
        name='client_reservations'
        label='Rezerwacje online'
        {...props}
    />

}

export default FacilityClientReservationsField