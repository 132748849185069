import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let NotificationEmailField = (props) => {

    return <SwitchInput
        name='notification_email'
        label='form.partner.notification_email'
        {...props}
    />

}

export default NotificationEmailField