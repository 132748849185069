import React from "react";
import TextField from '@mui/material/TextField';
import FormWrapper from "./FormWrapper";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {trans} from "../utils/translationUtil";
import {useIntl} from 'react-intl';

let DisabledTextField = ({text, label, loading = false}) => {

    let intl = useIntl();

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    return <FormWrapper>
        <TextField
            label={trans(intl, label)}
            disabled={true}
            value={text}
        />
    </FormWrapper>

}

export default DisabledTextField