import React from 'react';
import {Row, Col} from 'reactstrap'
import AppForm from "../components/forms/AppForm";
import {useForm} from 'react-hook-form'
import DateInput from "../core/form/DatetInput";
import moment from 'moment'
import CardHeader from "../core/card/CardHeader";
import CardBody from "../core/card/CardBody";
import NewestReservations from "../components/dashboard/NewestReservations";
import Card from "../core/card/Card";
import TopClients from "../components/dashboard/TopClients";
import SubmitFormButton from "../core/buttons/SubmitFormButton";
import ReservationsByPaymentType from "../components/dashboard/ReservationsByPaymentType";
import Button from "../core/buttons/Button";
import {useDispatch} from 'react-redux';
import {fetchAllServices} from "../actions/services";
import {resetBreadcrumbs} from "../actions/navbar";
import Sales from "../components/dashboard/Sales";
import FacilitySales from "../components/dashboard/FacilitySales";
import ReservationSourceSales from "../components/dashboard/ReservationSourceSales";
import ReservationsPerDay from "../components/dashboard/ReservationsPerDay";
import FacilityUsage from "../components/dashboard/FacilityUsage";

let DashboardPage = () => {

    let dispatch = useDispatch();

    const formContext = useForm({
        defaultValues: {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        }
    });

    let from = formContext.watch('from')

    let to = formContext.watch('to')

    React.useEffect(() => {
        dispatch(resetBreadcrumbs())
    }, [dispatch])

    let submit = (formData) => {
    }

    let lastMonth = () => {
        formContext.reset({
            from: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            to: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        })
    }

    let last30Days = () => {
        formContext.reset({
            from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
        })
    }

    let currentMonth = () => {
        formContext.reset({
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD'),
        })
    }

    return <>
        <Row style={{width: '100%', marginTop: '10px'}}>
            <Col lg={{size: 8, offset: 2}}>
                <AppForm onSubmit={submit} formContext={formContext} style={{display: 'flex', gap: '5px'}}>
                    <DateInput
                        name='from'
                        label='Od'
                        maxDate={moment(to)}
                    />
                    <DateInput
                        name='to'
                        label='Do'
                        minDate={moment(from)}
                    />
                    <Button text='Aktualny miesiąc' onClick={currentMonth}></Button>
                    <Button text='Poprzedni miesiąc' onClick={lastMonth}></Button>
                    <Button text='Ostatnie 30 dni' onClick={last30Days}></Button>
                </AppForm>
            </Col>
        </Row>
        {moment(from) <= moment(to) &&
            <>
                <Row style={{width: '100%'}}>
                    <Col lg={4}>
                        <Card>
                            <CardHeader>Najbardziej dochodowi klienci</CardHeader>
                            <CardBody>
                                <TopClients from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardHeader>Rezerwacje wg formy płatności</CardHeader>
                            <CardBody>
                                <ReservationsByPaymentType from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardHeader>Sprzedaż w tym okresie</CardHeader>
                            <CardBody>
                                <Sales from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{width: '100%'}}>
                    <Col lg={6}>
                        <Card>
                            <CardHeader>Najbardziej dochodowe obiekty</CardHeader>
                            <CardBody>
                                <FacilitySales from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <CardHeader>Podział na źródło</CardHeader>
                            <CardBody>
                                <ReservationSourceSales from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{width: '100%'}}>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>Rezerwacje dodane w kazdym dniu</CardHeader>
                            <CardBody>
                                <ReservationsPerDay from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row style={{width: '100%'}} className={'facility-usage-chart'}>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>Użycie obiektów</CardHeader>
                            <CardBody>
                                <FacilityUsage from={from} to={to}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        }

    </>

}

export default DashboardPage;