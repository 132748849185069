import React from "react";
import TimeSelect from "../../../core/form/TimeSelect";

let ReservationTimeFromField = (props) => {

    return <TimeSelect
        name='time_from'
        label='form.reservation.time_from'
        {...props}
    />

}

export default ReservationTimeFromField