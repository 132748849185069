import React from "react";
import {trans} from "../../../../core/utils/translationUtil";
import {useSelector, useDispatch} from 'react-redux';
import * as api from "../../../../core/api";
import {useIntl} from 'react-intl';
import SwitchInput from "../../../../core/form/SwitchInput";
import {createAction} from "../../../../core/utils/appUtil";
import {FETCH_ROLES_SUCCESS} from "../../../../constants/actiontypes/permission";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import CardForm from "../../../../core/form/CardForm";
import Datatable from "../../../../core/components/datatable/Datatable";
import Switch from "@mui/material/Switch";

let PermissionsForm = (props) => {

    let {allIds: allRoles, byId: roles} = useSelector(state => state.role)

    let {allIds: allPermissions, byId: permissions, loading} = useSelector(state => state.permissions)

    let dispatch = useDispatch();

    let updateForm = (formData) => {
        dispatch(createAction(FETCH_ROLES_SUCCESS, normalize(formData, [schemas.roleSchema])))
    }

    let defaultValues = {}

    for (let i = 0; i < allRoles.length; i++) {
        let perms = {};
        for (let j = 0; j < allPermissions.length; j++) {
            console.log('x', roles[allRoles[i]])
            perms[allPermissions[j]] = roles[allRoles[i]].permissions?.includes(allPermissions[j])
        }
        defaultValues[allRoles[i]] = perms;
    }

    let intl = useIntl();

    let getData = form => allPermissions.map(permission => {
        let perm = permissions[permission]
        return [
            <td>{trans(intl, 'permission.' + perm.name)}</td>,
            <td>
                <Switch checked={true} disabled={true}/>
            </td>,
            ...allRoles.map(role => <td>
                <SwitchInput
                    required={false}
                    name={role + '[' + permission + ']'}
                    {...form}
                />
            </td>)
        ]
    })

    let headers = ['Uprawnienie', 'Właściciel', ...allRoles.map(r => roles[r].name)];

    let renderForm = (form) => <>
        <Datatable
            rowNumbers={false}
            extraClasses='permissions-table'
            loading={loading}
            columnHeaders={headers}
            data={getData(form)}
            loaderCols={6}
            loaderRows={20}
            loaderRowHeight={30}
        />
    </>

    return <CardForm
        renderForm={renderForm}
        clearFormOnSuccess={true}
        data={defaultValues}
        cancelText='Anuluj'
        getPromise={(id, data) => api.updatePermissions(data)}
        onSubmit={data => updateForm(data)}/>
}

export default PermissionsForm