import {
    FETCH_PERMISSIONS_REQUEST,
    FETCH_PERMISSIONS_SUCCESS
} from "../constants/actiontypes/permission";

var initialState = {
    byId: [],
    allIds: [],
    loading: false
};

export const PERMISSIONS_LOADING = 'PERMISSIONS_LOADING'

export default (state = initialState, action) => {
    if (action.payload?.entities?.permission) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.permission
            }
        }
    }
    switch (action.type) {
        case PERMISSIONS_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case FETCH_PERMISSIONS_REQUEST: {
            return {
                ...state,
                loadingPermissions: true
            }
        }
        case FETCH_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result
            }
        }

        default:
            return state;
    }
};