import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectServicesModal from "../../../components/modals/SelectServicesModal";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import {fetchResource} from "../../../actions/resources";
import ResourcePage from "./ResourcePage";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import {getPriceText, getServicePriceText} from "../../../core/utils/appUtil";
import {useIntl} from 'react-intl'

let ResourceServicesPage = () => {

    let params = useParams();

    let intl = useIntl()

    let resourceId = params.id

    let resource = useSelector(state => state.resource.byId[resourceId])

    let loading = useSelector(state => state.resource.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchResource(resourceId))
        dispatch(fetchResource(resourceId, ['services']))
    }, [resourceId, dispatch])

    let allServices = useSelector(state => state.service.byId)

    let resourceServices = (resource?.services ?? [])
        .map(serviceId => allServices[serviceId])
        .filter(service => service.type !== 'rental')

    let hasServices = resourceServices.length > 0

    let [selectServiceModalOpened, setSelectServiceModalOpened] = useState(false);

    return <ResourcePage resourceId={resourceId}>
        {selectServiceModalOpened && resource && <SelectServicesModal
            type='resource'
            modalOpened={selectServiceModalOpened}
            setModalOpened={setSelectServiceModalOpened}
            defaultSelected={resource?.services}
            objectId={resourceId}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Świadczone usługi'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasServices && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasServices && resourceServices
                                    .map(service => <tr>
                                    <td>
                                        {service.name}
                                    </td>
                                    <td>
                                        {getServicePriceText(service.price)}
                                    </td>
                                    <td>
                                        {service.duration} min
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz usługi, w których wykorzystywany jest ten zasób'
                                  variant='forward'
                                  onClick={() => setSelectServiceModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </ResourcePage>

}

export default ResourceServicesPage
