import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessNameField = ({loading}) => {

    return <TextInput
        name='name'
        label='form.business.name'
        required={true}
        loading={loading}
    />

}

export default BusinessNameField