import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {FaPlus} from 'react-icons/fa'
import {fetchClients} from "../../../actions/clients";
import {RiEdit2Fill} from 'react-icons/ri'
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_CLIENT_SHOW} from "../../../routes/routeDefinitions";
import {Link} from "react-router-dom";
import ClientModal from "../../../components/client/ClientModal";
import {Col} from 'reactstrap'
import {resetBreadcrumbs} from "../../../actions/navbar";
import IconButton from "../../../core/buttons/IconButton";
import AvatarColumn from "../../../core/components/datatable/AvatarColumn";
import Datatable from "../../../core/components/datatable/Datatable";

let ClientsPage = () => {

    let dispatch = useDispatch();

    let {allIds: allClients, loading, pagination} = useSelector(state => state.clients)

    let clients = useSelector(state => state.clients.byId)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
        dispatch(fetchClients())
    }, [dispatch])

    let [addModalOpened, setAddModalOpened] = React.useState(false);

    let data = allClients.map(clientId => {
        let client = clients[clientId]
        return [
            <td><AvatarColumn name={client.fullname} id={client.id}/></td>,
            <td>{client.fullname}</td>,
            <td>{client.email}</td>,
            <td>{client.phone}</td>,
            <td>
                <Link to={getRoutePath(ROUTE_CLIENT_SHOW, clientId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    })

    let loadClients = (page, perPage, searchText) => {
        dispatch(fetchClients([], page, perPage, searchText))
    }

    return <div className='list-page'>
        {addModalOpened && <ClientModal isOpen={addModalOpened} closeModal={() => setAddModalOpened(() => false)}/>}
        <div className='button-panel'>
            <Button text='Dodaj nowego klienta' Icon={FaPlus} onClick={() => setAddModalOpened(() => true)}/>
        </div>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['', 'Imię i nazwisko', 'Adres e-mail', 'Telefon', 'Edycja']}
                data={data}
                search={true}
                pagination={pagination}
                onChange={loadClients}
            />
        </Col>
    </div>
}

export default ClientsPage;

