import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {useIntl} from 'react-intl';
import {setErrors} from "../utils/formUtil";
import {toastSuccess} from "../utils/appUtil";
import {MdClose} from 'react-icons/md'
import handleError from "../utils/errorUtil";
import {useDispatch} from 'react-redux';
import Button from "../buttons/Button";
import SubmitFormButton from "../buttons/SubmitFormButton";
import Dialog from "@mui/material/Dialog";
import AppForm from "../../components/forms/AppForm";

let ModalForm = function ({
                              prepareData = data => data,
                              renderForm = null,
                              title = 'Informacje',
                              data = null,
                              saveText = 'Zapisano',
                              onSubmit = null,
                              getPromise = null,
                              isOpen,
                              closeModal,
                              submitText = 'Dodaj',
                              className = '',
                              onChange = null,
                              cancelButton = true,
                              isMobile = false,
                              children = null,
                              customSubmit = null
                          }) {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false)

    let intl = useIntl()

    let dispatch = useDispatch()

    const form = useForm({defaultValues: (data ?? {})})

    let submit = async (formData) => {
        if (prepareData) {
            formData = prepareData(formData)
        }
        setIsDuringSubmit(true)
        if (getPromise) {
            let result = await getPromise(data?.id, formData)
                .then(response => {
                    onSubmit(response?.data?.data ?? response.data)
                    toastSuccess(saveText)
                })
                .catch(error => {
                    if ([401, 422].includes(error?.response?.status)) {
                        return error?.response?.data;
                    }
                    handleError(error, dispatch)
                })
            setErrors(result, form.setError, intl)
            setIsDuringSubmit(false)
        } else {
            onSubmit(formData)
            setIsDuringSubmit(false)
        }
    }

    React.useEffect(() => {
        if (data) {
            form.reset(data)
        }
    }, [data, form])

    let watch = form.watch

    React.useEffect(() => {
        const subscription = form.watch((data, event) => {
            if (onChange) {
                onChange(data, event)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, onChange, form])

    return <Dialog onClose={closeModal} open={isOpen}
                   className={className + ' appdialog ' + (isMobile ? 'mobile' : '')}>
        <AppForm onSubmit={submit} formContext={form}>
            <div className='dialog-header'>
                <span>{title}</span>
                <MdClose onClick={closeModal} className='close'/>
            </div>
            {isMobile && <div className='header'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none"
                     onClick={closeModal}>
                    <path
                        d="M21.9989 8.6994H5.05758L10.2428 2.47139C10.4853 2.17968 10.6019 1.8036 10.5671 1.42588C10.5323 1.04816 10.3488 0.699746 10.0571 0.457284C9.76542 0.214822 9.38934 0.0981725 9.01162 0.132998C8.6339 0.167823 8.28549 0.35127 8.04302 0.642982L0.900808 9.21364C0.852756 9.28181 0.809786 9.35343 0.772248 9.42791C0.772248 9.49933 0.772248 9.54218 0.672257 9.61361C0.607511 9.77739 0.57361 9.95173 0.572266 10.1278C0.57361 10.304 0.607511 10.4783 0.672257 10.6421C0.672257 10.7135 0.672256 10.7564 0.772248 10.8278C0.809786 10.9023 0.852756 10.9739 0.900808 11.0421L8.04302 19.6127C8.17733 19.774 8.34552 19.9036 8.53562 19.9925C8.72572 20.0814 8.93307 20.1273 9.14293 20.1269C9.47669 20.1276 9.80014 20.0114 10.0571 19.7984C10.2018 19.6785 10.3213 19.5312 10.409 19.365C10.4966 19.1988 10.5506 19.017 10.5679 18.8299C10.5851 18.6428 10.5653 18.4541 10.5095 18.2747C10.4537 18.0953 10.3631 17.9286 10.2428 17.7843L5.05758 11.5563H21.9989C22.3778 11.5563 22.7411 11.4058 23.009 11.1379C23.2769 10.87 23.4274 10.5067 23.4274 10.1278C23.4274 9.749 23.2769 9.38567 23.009 9.11778C22.7411 8.8499 22.3778 8.6994 21.9989 8.6994Z"
                        fill="#231F20"/>
                </svg>
                <span>{title}</span>
            </div>}
            <div className='dialog-body'>
                {children}
                {typeof renderForm === 'function' && renderForm(form)}
            </div>
            <div className='dialog-footer'>
                <div className='buttons'>
                    {cancelButton && <><Button text='Anuluj' onClick={() => closeModal()} variant='secondary'/>
                        <div className='divider'/>
                    </>}
                    <SubmitFormButton loading={isDuringSubmit} text={submitText}/>
                </div>
            </div>
        </AppForm>
    </Dialog>

}
export default ModalForm