import React, {useRef} from "react";
import {BsTrash} from 'react-icons/bs'
import ConfirmationModal from "../modals/ConfirmationModal";
import {useDrag, useDrop} from 'react-dnd';
import {useSelector} from "react-redux";
import {GALLERY_EDITOR_DELETE} from "../../reducers/galleryEditor";
import {createAction} from "../../core/utils/appUtil";
import {useDispatch} from 'react-redux'

const style = {
    cursor: 'move',
};

let Image = ({moveImage, index, id, main = false}) => {
    let image = useSelector(state => state.galleryEditor.images[index])
    let dispatch = useDispatch()
    const ref = useRef(null);
    const [{handlerId}, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveImage(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{isDragging}, drag] = useDrag({
        type: 'card',
        item: () => {
            return {id, index};
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let [modalOpened, setModalOpened] = React.useState(false);
    let clickDelete = () => {
        setModalOpened(() => true)
    }

    let confirmAction = () => {
        dispatch(createAction(GALLERY_EDITOR_DELETE, image.editorId))
        setModalOpened(() => false)
    }
    return <div className='col col-lg-3' ref={ref} style={{...style, opacity}} data-handler-id={handlerId}>
        {modalOpened && <ConfirmationModal modalOpened={modalOpened}
                                           setModalOpened={setModalOpened} message='Czy na pewno chcesz usunąć zdjęcie?'
                                           submitAction={confirmAction}
        />}
        <div className='tile image' ref={ref} style={{...style, opacity}} data-handler-id={handlerId}>
            {main && <div className='mainimage'>GŁÓWNE</div>}
            <div className='delete-btn'>
                <div className='icon' onClick={clickDelete}>
                    <BsTrash size={20}/>
                </div>
            </div>
            <img src={image.url} alt='gallery'/>
        </div>
    </div>
}

export default Image