import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {
    FETCH_PERMISSIONS_SUCCESS,
    FETCH_ROLES_SUCCESS
} from "../constants/actiontypes/permission";
import handleError from "../core/utils/errorUtil";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {PERMISSIONS_LOADING} from "../reducers/permissions";

export function initPermissionPage() {
    return function (dispatch) {
        dispatch(createAction(PERMISSIONS_LOADING, true))
        Promise.all([
            api.role.list(),
            api.permission.list()
        ]).then(responses => {
            dispatch(createAction(FETCH_ROLES_SUCCESS, normalize(responses[0].data, [schemas.roleSchema])))
            dispatch(createAction(FETCH_PERMISSIONS_SUCCESS, normalize(responses[1].data, [schemas.permissionSchema])))
            dispatch(createAction(PERMISSIONS_LOADING, false))
        })
            .catch(error => handleError(error, dispatch))
    }
}

export function getAllRoles() {
    return function (dispatch) {
        Promise.all([
            api.getRoles()
        ]).then(responses => {
            dispatch(createAction(FETCH_ROLES_SUCCESS, normalize(responses[0].data, [schemas.roleSchema])))
        }).catch(error => handleError(error, dispatch))
    }
}