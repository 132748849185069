import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";

let Sales = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('sales', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result.data)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }

    return <div className='d-flex justify-content-center'>{data.price} PLN</div>
}

export default Sales