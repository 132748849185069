import React from "react";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import schemas from "../../reducers/schemas";
import {normalize} from 'normalizr';
import {useDispatch} from "react-redux";
import ResourceNameField from "../forms/resource/ResourceNameField";
import ResourceTypeField from "../forms/resource/ResourceTypeField";
import ResourceDescriptionField from "../forms/resource/ResourceDescriptionField";
import {RESOURCE_ADD_SUCCESS} from "../../reducers/resource";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_RESOURCE_SHOW} from "../../routes/routeDefinitions";
import {useNavigate} from 'react-router-dom'
import ModalForm from "../../core/modal/ModalForm";

let ResourceModal = ({isOpen, closeModal}) => {

    let dispatch = useDispatch();

    let navigate = useNavigate();

    let onSubmit = (data) => {
        dispatch(createAction(RESOURCE_ADD_SUCCESS, normalize(data, [schemas.resourceSchema])))
        closeModal();
        navigate(getRoutePath(ROUTE_RESOURCE_SHOW, data[0].id))
    }

    let prepareData = data => {
        return {
            name: data.name,
            description: data.description,
            type_id: data.type_id
        }
    }

    return <ModalForm
        prepareData={prepareData}
        isOpen={isOpen}
        closeModal={closeModal}
        title='Dodaj zasób'
        getPromise={(id, data) => api.resource.create(data)}
        onSubmit={onSubmit}
    >
        <ResourceTypeField/>
        <ResourceNameField/>
        <ResourceDescriptionField/>
    </ModalForm>

}

export default ResourceModal