import React from 'react';
import {MdCheck} from 'react-icons/md';

let Checkbox = ({onChange, value, label}) => <div className={'checkbox ' + (value ? 'checked' : '')} onClick={() => onChange && onChange(!value)}>
        <div className='checkbox-wrapper'>
            {value && <MdCheck size={16}/>}
        </div>
        <span className='checkbox-label'>{label && (typeof label === 'function' ? label() : label)}</span>
    </div>

export default Checkbox