import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerPhoneField = () => {

    return <TextInput
        mask='+48 999 999 999'
        name='phone'
        label='form.partner.phone'
        required={true}
    />

}

export default PartnerPhoneField