import {createAction} from "../core/utils/appUtil";
import {toastError, toastSuccess} from "../core/utils/appUtil";
import * as apiLegacy from "../core/api";
import api from "../core/api";
import schemas from "../reducers/schemas";
import {
    FACILITY_ADD_IMAGE_SUCCESS,
    FACILITY_DELETE_IMAGE_SUCCESS,
    FACILITY_DETAILS_REQUEST,
    FACILITY_DETAILS_SUCCESS,
    FACILITY_LIST_REQUEST,
    FACILITY_LIST_SUCCESS,
} from "../reducers/facility";
import {normalize} from 'normalizr';
import handleError from "../core/utils/errorUtil";
import {
    GALLERY_EDITOR_ADD,
    GALLERY_EDITOR_DELETE,
    GALLERY_EDITOR_INIT,
    GALLERY_EDITOR_RESET, GALLERY_EDITOR_SAVED, GALLERY_EDITOR_SAVING
} from "../reducers/galleryEditor";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../reducers/openingHourForm";
import _ from "lodash";
import {FACILITY_PART_DETAILS_SUCCESS, FACILITY_PART_LIST_SUCCESS} from "../reducers/facilityPart";

export function fetchAllFacilities(includes = []) {
    return function (dispatch) {
        dispatch(createAction(FACILITY_LIST_REQUEST))
        return api.facility.list({}, includes)
            .then(response => {
                let normalizedData = normalize(response.data, [schemas.facilitySchema])
                dispatch(createAction(FACILITY_LIST_SUCCESS, normalizedData))
            })
            .catch(error => handleError(error, dispatch))
    }
}

export function fetchFacilityDetails(id, includes = []) {
    return function (dispatch) {
        dispatch(createAction(FACILITY_DETAILS_REQUEST));
        return api.facility.get(id, includes)
            .then(response => dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(response.data, schemas.facilitySchema))))
            .catch(error => handleError(error, dispatch));
    };
}

export function openFacilityOpeningHoursPage(id) {
    return function (dispatch) {
        dispatch(createAction(OPENING_HOUR_FORM_RESET));
        Promise.all([
            apiLegacy.getBusiness({}, ['openingHours']),
            api.facility.get(id, ['openingHours'])
        ]).then(response => {
            let businessResponse = response[0].data.data
            let facilityResponse = response[1].data
            let normalizedFacilityData = normalize(facilityResponse, schemas.facilitySchema)
            let normalizedBusinessData = normalize(businessResponse, schemas.businessSchema)
            let facility = normalizedFacilityData.entities.facility[normalizedFacilityData.result]
            let business = normalizedBusinessData.entities.business[normalizedBusinessData.result]
            let payload = {
                inheritedOpeningHours: business.openingHours.map(openingHour => normalizedBusinessData.entities.openingHour[openingHour]),
                availability_type: facility.availability_type,
                entities: _.merge(normalizedFacilityData.entities, normalizedBusinessData.entities),
                openingHours: facility.openingHours.map(openingHour => normalizedFacilityData.entities.openingHour[openingHour])
            }
            dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
        }).catch(error => handleError(error, dispatch));
    };
}

export function fetchFacilityImages(id, includes = []) {
    return function (dispatch) {
        dispatch(createAction(GALLERY_EDITOR_RESET))
        return api.facility.get(id, ['images', 'type.resourceTypes'])
            .then(response => {
                let normalizedData = normalize(response.data, schemas.facilitySchema)
                let images = normalizedData.entities.facility[normalizedData.result].images.map((i, key) => {
                    return {
                        imageId: i,
                        url: process.env.REACT_APP_API_URL + '/image/' + normalizedData.entities.image[i].image_id
                    }
                })
                dispatch(createAction(GALLERY_EDITOR_INIT, images))
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function uploadFacilityImage(dispatch, base64content, file, facilityId) {
    let formData = {};
    formData.file = {};
    formData.file.content = base64content.replace('data:image/jpeg;base64,', '')
        .replace('data:image/jpg;base64,', '')
        .replace('data:image/png;base64,', '');
    formData.file.name = file.name;
    formData.file.mimetype = file.type;
    return apiLegacy.addFacilityImage(formData, facilityId)
        .then((response) => {
            let data = normalize(response.data, schemas.imageSchema)
            dispatch(createAction(FACILITY_ADD_IMAGE_SUCCESS, data));
            dispatch(createAction(GALLERY_EDITOR_ADD, data.result))
            toastSuccess('Dodano');
        })
        .catch(error => {
            if (error?.response?.status === 400) {
                toastError('Zły format pliku!')
            } else {
                toastError('Wystąpił błąd podczas uploadu pliku!')
                handleError(error, dispatch);
            }
        });
}

export async function convertImagesToBase64(images) {
    const promises = images.map(async ({imageId, url, name, mimetype}) => {
        try {
            if (imageId) {
                return new Promise((resolve, reject) => {
                    resolve({
                        imageId: imageId
                    })
                });
            }
            const response = await fetch(url);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
                    resolve({
                        content: base64data,
                        name: name,
                        mimetype: mimetype
                    });
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Błąd podczas przetwarzania obrazka:', error);
            throw error;
        }
    });

    try {
        return await Promise.all(promises);
    } catch (error) {
        throw error;
    }
}

export function updateFacilityImages(dispatch, facilityId, images) {
    dispatch(createAction(GALLERY_EDITOR_SAVING));
    return apiLegacy.updateFacilityGallery(facilityId, images)
        .then((response) => {
            dispatch(createAction(GALLERY_EDITOR_SAVED));
            toastSuccess('Zapisano');
        })
        .catch(error => handleError(error, dispatch))
}

export function deleteFacilityImage(dispatch, uuid, facilityId) {
    return apiLegacy.deleteFacilityImage(facilityId, uuid)
        .then(() => {
            dispatch(createAction(FACILITY_DELETE_IMAGE_SUCCESS, {id: uuid, facility_id: facilityId}));
            dispatch(createAction(GALLERY_EDITOR_DELETE, uuid))
            toastSuccess('Usunięto');
        })
        .catch(error => handleError(error, dispatch));
}


export function fetchFacilityPart(partId, includes = []) {
    return function (dispatch) {
        return api.facilityPart.get(partId, includes)
            .then(response => dispatch(createAction(FACILITY_PART_DETAILS_SUCCESS, normalize(response.data, schemas.facilityPartSchema))))
            .catch(error => handleError(error, dispatch))
    }
}

export function fetchAllFacilityParts() {
    return function (dispatch) {
        return api.facilityPart.list()
            .then((response) => {
                dispatch(createAction(FACILITY_PART_LIST_SUCCESS, normalize(response.data, [schemas.facilityPartSchema])))
            })
            .catch(error => handleError(error, dispatch))
    }
}
