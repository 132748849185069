import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {RESOURCE_TYPE_LIST_REQUEST, RESOURCE_TYPE_LIST_SUCCESS} from "../reducers/resourceType";

export function fetchAllResourceTypes(serviceId, system = null) {
    return dispatch => {
        let filters = {}
        if(serviceId) {
            filters.service_id = serviceId
        }
        if(system !== null) {
            filters.system = system
        }
        dispatch(createAction(RESOURCE_TYPE_LIST_REQUEST));
        return api.resourceType.list(filters).then(response => {
            let normalizedData = normalize(response.data, [schemas.resourceTypeSchema]);
            dispatch(createAction(RESOURCE_TYPE_LIST_SUCCESS, normalizedData));
        }).catch(error => handleError(error, dispatch));
    }
}