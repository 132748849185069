export let mapCustomFieldFormData = formData => {
    let customFieldData = []
    if (formData.custom_fields) {
        for (let i = 0; i < Object.keys(formData.custom_fields).length; i++) {
            let key = Object.keys(formData.custom_fields)[i]
            let value = formData.custom_fields[key]
            customFieldData.push({
                custom_field_id: key,
                value: Array.isArray(value) ? null : value,
                values: Array.isArray(value) ? value : null
            })
        }
    }
    return customFieldData
}