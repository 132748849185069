import _ from "lodash";

export const BUSINESS_GET_DETAILS = 'BUSINESS_GET_DETAILS';
export const BUSINESS_UPDATE_SUCCESS = 'BUSINESS_UPDATE_SUCCESS';
export const BUSINESS_OPEN_SUBSCRIPTION_PAGE_REQUEST = 'BUSINESS_OPEN_SUBSCRIPTION_PAGE_REQUEST';
export const BUSINESS_OPEN_SUBSCRIPTION_PAGE_SUCCESS = 'BUSINESS_OPEN_SUBSCRIPTION_PAGE_SUCCESS';

var initialState = {
    subscription_page_loading: true,
    byId: []
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.business) {
        return {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.business)
        }
    }
    switch (action.type) {
        case BUSINESS_OPEN_SUBSCRIPTION_PAGE_REQUEST: {
            return {
                ...state,
                subscription_page_loading: true,
            }
        }
        case BUSINESS_OPEN_SUBSCRIPTION_PAGE_SUCCESS: {
            return {
                ...state,
                subscription_page_loading: false,
            }
        }
        default:
            return state;
    }
}