import React from "react";
import SelectInput from "../../../../core/form/select/SelectInput";

let BlockTypeSelect = (props) => {

    let types = [{
        value: 'business',
        label: 'Przerwa w funkcjonowaniu biznesu'
    },{
        value: 'facility',
        label: 'Przerwa w funkcjonowaniu obiektu'
    },{
        value: 'partner',
        label: 'Niedostępność pracownika'
    }]


    return <SelectInput
        name='type'
        label='Typ blokady'
        options={types}
        {...props}
    />
}

export default BlockTypeSelect