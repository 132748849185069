export default {
    entire_rental_header: 'Wynajem całego obiektu',
    partial_rental_header: 'Wynajem części obiektu',
    resource_quantity_question: 'Ile części ma twój obiekt?',
    resource_quantity_question2: 'Ile części ma twój obiekt?',
    entire_price: 'Cena wynajmu całości',
    partial_price: 'Cena wynajmu jednej części',
    "bowling-club": {
        resource_quantity_question: 'Ile torów do kręgli znajduje się w tej kręgielni?',
        partial_rental_header: 'Czy chcesz dodać usługę wynajmu torów do kręgli?',
        entire_price: 'Cena wynajmu jednego toru',
    },
    "sports-hall": {
        entire_rental_header: 'Czy chcesz dodać usługę wynajmu hali sportowej?',
        partial_rental_header: 'Czy chcesz oferować usługę wynajmu hali sportowej na sektory?',
        resource_quantity_question: 'Ile sektorów ma Twoja hala sportowa?',
        facility_form_entire_rental_header: 'Wynajem całej hali sportowej',
        facility_form_partial_rental_header: 'Wynajem hali sportowej na sektory'

    },
    "tennis-court": {
        entire_price: 'Cena wynajmu jednego kortu',
        resource_quantity_question: 'Ile takich samych kortów posiadasz?',
        partial_rental_header: 'Czy chcesz dodać usługę wynajmu kortu tenisowego?'
    },
    "squash": {
        entire_price: 'Cena wynajmu jednego kortu',
        resource_quantity_question: 'Ile kortów do gry w Squasha posiadasz?',
        partial_rental_header: 'Czy chcesz dodać usługę wynajmu kortu do gry w Squasha?'
    },
    "sport-field": {
        resource_quantity_question: 'Na ile części dzieli się Twoje boisko?',
        entire_rental_header: 'Czy chcesz dodać usługę wynajmu boiska sportowego?',
        partial_rental_header: 'Czy chcesz oferować usługę wynajmu boiska sportowego na części?',
        facility_form_entire_rental_header: 'Wynajem całego boiska sportowego',
        facility_form_partial_rental_header: 'Wynajem boiska sportowego na części'
    },
    gym: {
        entire_rental_header: 'Czy chcesz dodać usługę wynajmu sali gimnastycznej?',
    }
}