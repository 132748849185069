import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {MdClose} from 'react-icons/md'
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllResourceTypes} from "../../../actions/resourceTypes";
import {FaPlus} from "react-icons/fa";
import ResourceTypeRow from "./ResourceTypeRow";
import Button from "../../../core/buttons/Button";

let ResourceTypeModal = function ({isOpen, closeModal}) {

    let dispatch = useDispatch();

    let resourceTypes = useSelector(state => state.resourceType.allIds);

    let resourceTypesById = useSelector(state => state.resourceType.byId);

    let [addCategory, setAddCategory] = React.useState(false);

    React.useEffect(() => {
        dispatch(fetchAllResourceTypes())
    }, [dispatch])

    return <Modal isOpen={isOpen} toggle={() => closeModal()} className='resource-type'>
            <ModalHeader>
                <span>Kategorie zasobów</span>
                <MdClose onClick={closeModal} className='close'/>
            </ModalHeader>
            <ModalBody>
                <div className='list'>
                    {
                        resourceTypes.filter(r => !resourceTypesById[r].is_system).map(r => <ResourceTypeRow id={r}/>)
                    }
                    {addCategory && <ResourceTypeRow id={null}
                                                     add={true}
                                onCancel={() => setAddCategory(false)}
                    />}
                </div>
                {!addCategory && <div className='add' onClick={() => setAddCategory(true)}>
                    <FaPlus/>
                    <span>Dodaj nową kategorię</span>
                </div>}
            </ModalBody>
            <ModalFooter>
                <Button text='Zamknij' onClick={() => closeModal()} variant='secondary'/>
            </ModalFooter>
    </Modal>

}
export default ResourceTypeModal