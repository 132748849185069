import React from "react";
import {Row, Col} from 'reactstrap';
import AppSkeleton from "../../core/components/skeleton/AppSkeleton";

const GalleryEditorSkeleton = () => {

    return <div className='gallery-editor'>
        <Row>
            {
                [0, 1, 2, 3, 4, 5, 6, 7].map(() => <Col lg={3} className='col'>
                    <div className='tile'>
                        <div className='tile'>
                            <AppSkeleton height={150}/>
                        </div>
                    </div>
                </Col>)
            }
        </Row>
    </div>

}

export default GalleryEditorSkeleton;
