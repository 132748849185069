import React from "react";

let ActivityDetailsColumn = ({activity}) => {


    let propertyLabels = {
        'name': 'Nazwa',
        'description': 'Opis',
        'sports': 'Sporty',
        'amenities': 'Udogodnienia'
    }

    let renderDetails = (activity) => {
        if (['login'].includes(activity.log_name)) {
            let properties = Object.keys(activity.properties);
            return <ul style={{listStyle: 'none'}}>
                {
                    properties.map(property => <li>{propertyLabels[property] ?? property}: {activity.properties[property]}</li>)
                }
            </ul>
        }
        return renderChanges(activity)
    }

    let renderChanges = activity => {
        let properties = Object.keys(activity.properties);
        let changes = [];
        for (let i = 0; i < properties.length; i++) {
            let propertyName = properties[i]
            let propertyChanges = activity.properties[properties[i]]
            if (propertyChanges.multi) {
                if (propertyChanges.attached.length > 0 || propertyChanges.detached.length > 0) {
                    changes.push(<div>
                        <p>{propertyLabels[propertyName]}</p>
                        <ul style={{listStyle: 'none'}}>
                            {
                                propertyChanges.attached.map(change => <li style={{
                                    color: 'green'
                                }}>+ {change.name}</li>)
                            }
                            {
                                propertyChanges.detached.map(change => <li style={{
                                    color: 'red',

                                }}>- {change.name}</li>)
                            }
                        </ul>
                    </div>)
                }
            } else {
                changes.push(<p>{propertyLabels[propertyName] ?? propertyName}:
                    <span style={{color: 'red', textDecoration: 'line-through'}}>{propertyChanges['old']}</span>
                    <span style={{color: 'green'}}>{propertyChanges['new']}</span>
                </p>)
            }
        }
        return changes;
    }

    let [opened, setOpened] = React.useState(false)

    return <>
        <a onClick={() => setOpened(!opened)}>{opened ? 'Zwiń' : 'Rozwiń'}</a>
        {opened && <div>
            {renderDetails(activity)}
        </div>}
    </>
}

export default ActivityDetailsColumn