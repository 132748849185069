import React from "react";
import Button from "../../../core/buttons/Button";
import {useFormContext} from "react-hook-form";
import { useController } from "react-hook-form";

let DaysOfWeekField = () => {

    let {
        control,
    } = useFormContext()
    const {
        field,
        fieldState: { invalid },
        formState: {}
    } = useController({
        name: 'days_of_week',
        control,
        rules: { required: true },
    });
    let daysOfWeek = field.value

    let dayMap = {
        1: 'PN',
        2: 'WT',
        3: 'ŚR',
        4: 'CZW',
        5: 'PT',
        6: 'SB',
        0: 'ND'
    }

    return <div>
        {
            [0,1,2,3,4,5,6].map(day => {
                return <Button text={dayMap[day]} variant={daysOfWeek?.includes(day) ? 'primary' : 'secondary'} onClick={() => {
                    if(daysOfWeek.includes(day)) {
                        if(daysOfWeek.length > 1) {
                            field.onChange(daysOfWeek.filter(d => d !== day))
                        }
                    } else {
                        field.onChange([...daysOfWeek, day].sort())
                    }
                }}/>
            })
        }
        {invalid && <p style={{color: 'red', fontSize: '11px'}}>Zaznacz conajmniej jeden dzień</p>}
    </div>
}

export default DaysOfWeekField