import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessPage from "./BusinessPage";
import BusinessReservationSettingsForm from "../../../components/forms/app/business/BusinessReservationSettingsForm";

let BusinessReservationSettingsPage = () => {

    return <BusinessPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <BusinessReservationSettingsForm/>
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessReservationSettingsPage
