
import _ from 'lodash'
import {CREATOR_SET_OPENING_HOURS} from "./creator";
import {RESOURCE_ADD_SUCCESS} from "./resource";
import {DASHBOARD_FETCH_FACILITIES_SUCCESS} from "../constants/actiontypes/dashboard";
export const FACILITY_LIST_REQUEST = 'FACILITY_LIST_REQUEST';
export const FACILITY_LIST_SUCCESS = 'FACILITY_LIST_SUCCESS';
export const FACILITY_ACTIVE_LIST_REQUEST = 'FACILITY_ACTIVE_LIST_REQUEST';
export const FACILITY_ACTIVE_LIST_SUCCESS = 'FACILITY_ACTIVE_LIST_SUCCESS';
export const FACILITY_DETAILS_REQUEST = 'FACILITY_DETAILS_REQUEST';
export const FACILITY_DETAILS_SUCCESS = 'FACILITY_DETAILS_SUCCESS';
export const FACILITY_ADD_SUCCESS = 'FACILITY_ADD_SUCCESS';
export const FACILITY_UPDATE_SUCCESS = 'FACILITY_UPDATE_SUCCESS';
export const FACILITY_UPDATE_SERVICES_SUCCESS = 'FACILITY_UPDATE_SERVICES_SUCCESS';
export const FACILITY_UPDATE_PARTNERS_SUCCESS = 'FACILITY_UPDATE_PARTNERS_SUCCESS';
export const FACILITY_UPDATE_RESOURCES_SUCCESS = 'FACILITY_UPDATE_RESOURCES_SUCCESS';
export const FACILITY_DELETE_IMAGE_SUCCESS = 'FACILITY_DELETE_IMAGE_SUCCESS';
export const FACILITY_ADD_IMAGE_SUCCESS = 'FACILITY_ADD_IMAGE_SUCCESS';
export const FACILITY_UPDATE_IMAGE_SUCCESS = 'FACILITY_UPDATE_IMAGE_SUCCESS';

export const FACILITY_OPENING_HOURS_UPDATED = 'FACILITY_OPENING_HOURS_UPDATED'

var initialState = {
    byId: {},
    allIds: [],
    myFacilities: [],
    loading: true,
    loadingDetails: true
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.facility) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.facility)
        }
    }
    switch (action.type) {
        case DASHBOARD_FETCH_FACILITIES_SUCCESS: {
            return {
                ...state,
                myFacilities: action.payload.result
            }
        }
        case FACILITY_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FACILITY_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case FACILITY_DETAILS_REQUEST: {
            return {
                ...state,
                loadingDetails: true
            }
        }
        case FACILITY_DETAILS_SUCCESS: {
            return {
                ...state,
                loadingDetails: false
            }
        }

        case FACILITY_ADD_SUCCESS: {
            return {
                ...state,
                allIds: [...state.allIds, action.payload.result]
            }
        }
        case FACILITY_DELETE_IMAGE_SUCCESS:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        images: state.byId[action.payload.facility_id].images.filter(image => image !== action.payload.id)
                    }
                }
            }
        case CREATOR_SET_OPENING_HOURS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        openingHours: action.payload.result
                    }
                }
            }
        }
        case FACILITY_UPDATE_SERVICES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        services: action.payload.services
                    }
                }
            }
        }
        case FACILITY_UPDATE_PARTNERS_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        partners: action.payload.partners
                    }
                }
            }
        }
        case FACILITY_UPDATE_RESOURCES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        resources: action.payload.resources
                    }
                }
            }
        }
        case RESOURCE_ADD_SUCCESS: {
            if(action.payload.facility_id) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.facility_id]: {
                            ...state.byId[action.payload.facility_id],
                            resources: [
                                ...state.byId[action.payload.facility_id].resources,
                                ...action.payload.result
                            ]
                        }
                    }
                }
            }
            return state;
        }
        case FACILITY_OPENING_HOURS_UPDATED: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.facility_id]: {
                        ...state.byId[action.payload.facility_id],
                        availability_type: action.payload.availability_type
                    }
                }
            }
        }
        default:
            return {...state};
    }
};