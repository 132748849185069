import React, {useState} from "react";
// import api from "../../core/api";
import AppLoading from "../AppLoading";

let PaymentMethods = () => {

    let [data, setData] = useState()

    let [loading, setLoading] = useState(true)

    let loadData = () => {
        setLoading(true)
        // api.paymentMethod.get().then(response => {
        //     setData(response.data)
        //     setLoading(false)
        // })
    }

    React.useEffect(() => {
        loadData()
    }, [])

    return <div>
        <table className='table'>
            <thead>
            <tr>
                <th>Ważność karty</th>
                <th>Numer karty</th>
            </tr>
            </thead>
            <tbody>
            {
                data?.map(paymentMethod => <tr>
                    <td>{paymentMethod.month}/{paymentMethod.year}</td>
                    <td>**** **** **** {paymentMethod.number}</td>
                </tr>)
            }
            {
                loading && <tr>
                    <td colSpan={5}><AppLoading/></td>
                </tr>
            }
            {
                !data?.length && !loading && <tr>
                    <td colSpan={5}>Brak danych</td>
                </tr>
            }
            </tbody>
        </table>
    </div>
}

export default PaymentMethods
