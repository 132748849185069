import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {AiOutlinePlus} from 'react-icons/ai';
import {loadServices} from "../../../actions/creator";
import DeleteServiceModal from "../DeleteServiceModal";
import Service from "../Service";
import ServiceModal from "../../service/ServiceModal";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

let ServiceStepForm = ({setSubmit, next}) => {

    let dispatch = useDispatch();

    let services = useSelector(state => state.creator.services)

    React.useEffect(() => {
        dispatch(loadServices())
    }, [dispatch])

    let isLoaded = useSelector(state => state.creator.servicesLoaded)

    let submit = async () => {
        next();
    }

    setSubmit(submit)

    let [serviceModalOpened, setServiceModalOpened] = React.useState(false)

    let [deleteServiceModalOpened, setDeleteServiceModalOpened] = React.useState(false)

    let toggleModal = () => setServiceModalOpened(oldValue => !oldValue)

    let [editedService, setEditedService] = React.useState(null);

    let [deletedService, setDeletedService] = React.useState(null);

    let addService = () => {
        setEditedService(() => null)
        toggleModal();
    }

    let editService = partnerId => {
        setEditedService(() => partnerId)
        setServiceModalOpened(() => true)
    }

    let removeService = serviceId => {
        setDeletedService(() => serviceId)
        setDeleteServiceModalOpened(() => true)
    }

    if (!isLoaded) {
        return <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
        </SkeletonTheme>
    }

    return isLoaded && <div className='employees'>
        <div className='employee-list'>
            {services.map(e => <Service id={e} onEdit={editService} onRemove={removeService}/>)}
        </div>
        <div className='add-employee' onClick={addService}>
            <span className='icon'>
                <AiOutlinePlus/>
            </span>
            <span className='text'>Dodaj usługę</span>
        </div>
        {serviceModalOpened && <ServiceModal
            isOpen={serviceModalOpened}
            closeModal={() => setServiceModalOpened(() => false)}
            serviceId={editedService}
            creator={true}/>}
        {deleteServiceModalOpened && <DeleteServiceModal isOpen={deleteServiceModalOpened}
                                                         closeModal={() => setDeleteServiceModalOpened(() => false)}
                                                         serviceId={deletedService}/>}
    </div>

}
export default ServiceStepForm
