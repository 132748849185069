import React from "react";
import api from "../../../core/api";
import {useSelector, useDispatch} from 'react-redux';
import {createAction} from "../../../core/utils/appUtil";
import schemas from "../../../reducers/schemas";
import {normalize} from 'normalizr';
import {CREATOR_SET_OPENING_HOURS} from "../../../reducers/creator";
import OpeningHours from "../../openinghours/OpeningHours";
import handleError from "../../../core/utils/errorUtil";
import {AVAILABILITY_TYPE_CUSTOM} from "../../../core/constants/reservationStatuses";
import {getCurrentBusiness} from "../../../selectors";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_INIT_DEFAULT} from "../../../reducers/openingHourForm";

let OpeningHoursStepForm = ({setSubmit, next, setDuringSubmit}) => {

    let dispatch = useDispatch();

    let business = useSelector(getCurrentBusiness)

    let {days} = useSelector(state => state.openingHourForm)

    let apiCall = () => {
        let data = []
        for (let i = 0; i < Object.keys(days).length; i++) {
            let day = days[i]
            let hours = days[i].hours
            if (day.enabled) {
                for (let j = 0; j < hours.length; j++) {
                    let hour = hours[j]
                    data.push({
                        day: i,
                        ...hour
                    })
                }
            }
        }
        let request = api.business.updateOpeningHours(
            business.id,
            {
                availability_type: AVAILABILITY_TYPE_CUSTOM,
                opening_hours: data
            });
        return request.then((response) => {
            let result = normalize(response.data, [schemas.openingHourSchema]);
            dispatch(createAction(CREATOR_SET_OPENING_HOURS, result));
            return result;
        }).catch(error => handleError(error, dispatch))
    }

    React.useEffect(() => {
        if(business?.creator_step > 3) {
            api.business.get(business.id, ['openingHours']).then((response) => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema)
                let business = normalizedData.entities.business[normalizedData.result]
                let payload = {
                    availability_type: business.availability_type,
                    entities: normalizedData.entities,
                    openingHours: business.openingHours.map(openingHour => normalizedData.entities.openingHour[openingHour])
                }
                dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
            })
        } else {
            dispatch(createAction(OPENING_HOUR_FORM_INIT_DEFAULT));
        }
    }, [])

    let submit = async () => {
        setDuringSubmit(true);
        let result = await apiCall();
        setDuringSubmit(false);
        if (result) {
            next();
        }
    }

    setSubmit(submit)

    return <OpeningHours onSubmit={submit} wrap={false}/>

}
export default OpeningHoursStepForm
