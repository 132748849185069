import {
    FETCH_ROLES_SUCCESS
} from "../constants/actiontypes/permission";

var initialState = {
    byId: [],
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.role) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.role
            }
        }
    }
    switch (action.type) {
        case FETCH_ROLES_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result
            }
        }
        default:
            return state;
    }
};