import React, {useState} from "react";
import api from "../../core/api";
import YesNoColumn from "../../core/components/datatable/YesNoColumn";
import Button from "../../core/buttons/Button";
import AppLoading from "../AppLoading";
import {useSelector} from "react-redux";
import {getCurrentBusiness} from "../../selectors";
import moment from 'moment';

let BusinessSubscription = () => {

    let business = useSelector(getCurrentBusiness);

    let [data, setData] = useState()

    let [loading, setLoading] = useState(true)

    let loadData = () => {
        setLoading(true)
        // api.pricingPlan.get().then(response => {
        //     setData(response.data)
        //     setLoading(false)
        // })
    }

    React.useEffect(() => {
        loadData()
    }, [])

    let [subscribing, setSubscribing] = useState(false)

    let subscribe = (planId) => {
        setSubscribing(true)
        api.subscription.create({
            pricing_plan_id: planId
        }).then(response => {
            if(!response.data.error) {
                window.location.href=response.data.link
            } else {
                setSubscribing(false)
            }
        }).catch(() => {
            setSubscribing(false)
        })
    }

    let timeLeft = (date) => {
        return 'pozostało ' + moment(date).diff(moment(), 'days') + ' dni'
    }

    return <table className='table'>
        <thead>
        <tr>
            <th>Plan</th>
            <th>Cena</th>
            <th>Status</th>
            <th>Aktywny do</th>
            <th>Akcje</th>
        </tr>
        </thead>
        <tbody>
        {loading && <tr>
            <td colSpan={5}>
                <AppLoading/>
            </td>
        </tr>}
        {!loading && <>
            {data?.map(plan => <tr key={plan.id}>
                <td>
                    {plan.name}
                </td>
                <td>
                    {plan.price} PLN
                </td>
                <td>
                    <YesNoColumn active={plan.subscribed} activeLabel='Aktywny' inactiveLabel='Nieaktywny'/>
                </td>
                <td>
                    {plan.subscribed ? (plan.to + ' (' + timeLeft(plan.to) + ')') : '-'}
                </td>
                <td>
                    {plan.subscribed ? null : <Button text='Aktywuj' onClick={() => subscribe(plan.id)} disabled={subscribing}/>}
                </td>
            </tr>)}
        </>}
        </tbody>
    </table>
}

export default BusinessSubscription
