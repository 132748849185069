import React, {useState} from "react";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardHeader from "../../../core/card/CardHeader";
import {useSelector} from 'react-redux'
import CardFooter from "../../../core/card/CardFooter";
import Button from "../../../core/buttons/Button";
import SubmitFormButton from "../../../core/buttons/SubmitFormButton";
import {useForm} from "react-hook-form";
import AppForm from "../../../components/forms/AppForm";
import FacilityNameField from "../../../components/forms/facility/FacilityNameField";
import {
    FACILITY_CREATOR_BACK,
    FACILITY_CREATOR_FORWARD,
    FACILITY_CREATOR_INIT
} from "../../../reducers/facilityCreator";
import {createAction, toastSuccess} from "../../../core/utils/appUtil";
import FacilityDescriptionField from "../../../components/forms/facility/FacilityDescriptionField";
import {setBreadcrumbs} from "../../../actions/navbar";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_FACILITY_LIST, ROUTE_FACILITY_SHOW} from "../../../routes/routeDefinitions";
import FormHeader from "../../../core/form/FormHeader";
import FacilityTypeField from "../../../components/forms/facility/FacilityTypeField";
import FacilityCustomFieldsField from "../../../components/forms/facility/FacilityCustomFieldsField";
import SwitchInput from "../../../core/form/SwitchInput";
import AddressField from "../../../components/forms/address/AddressField";
import AddressCityField from "../../../components/forms/address/AddressCityField";
import AddressPostcodeField from "../../../components/forms/address/AddressPostcodeField";
import {getCurrentBusiness} from "../../../selectors";
import AppLoading from "../../../components/AppLoading";
import * as apiLegacy from "../../../core/api";
import api from "../../../core/api";
import schemas from "../../../reducers/schemas";
import {BUSINESS_GET_DETAILS} from "../../../reducers/business";
import {normalize} from 'normalizr'
import OpeningHours from "../../../components/openinghours/OpeningHours";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../../../reducers/openingHourForm";
import {AVAILABILITY_TYPE_CUSTOM, AVAILABILITY_TYPE_INHERIT} from "../../../core/constants/reservationStatuses";
import {useNavigate} from "react-router-dom";
import {FACILITY_ADD_SUCCESS} from "../../../reducers/facility";
import GalleryEditor from "../../../components/galleryeditor/GalleryEditor";
import {GALLERY_EDITOR_RESET} from "../../../reducers/galleryEditor";
import RentalForm from "../../../components/facility/RentalForm";
import {addLeadingZeros} from "../../../core/utils/stringUtil";

let FacilityCreatePage = () => {

    let images = useSelector(state => state.galleryEditor.images)

    let navigate = useNavigate()

    let [loading, setLoading] = useState(true)

    let business = useSelector(getCurrentBusiness)

    let businessAddress = useSelector(state => state.address.byId[business?.address])

    let {step} = useSelector(state => state.facilityCreator)

    const loadDefaultValues = async () =>
        await api.business.get(business?.id, ['address', 'openingHours'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema)
                let business = normalizedData.entities.business[normalizedData.result]
                let address = normalizedData.entities.address[business.address]
                dispatch(createAction(BUSINESS_GET_DETAILS, normalizedData));
                let payload = {
                    inheritedOpeningHours: business.openingHours.map(openingHour => normalizedData.entities.openingHour[openingHour]),
                    openingHours: business.openingHours.map(openingHour => normalizedData.entities.openingHour[openingHour]),
                    availability_type: AVAILABILITY_TYPE_INHERIT,
                }
                dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
                setLoading(false)
                return {
                    parts: partialRentalAvailable ? (entireRentalAvailable ? 2 : 1) : null,
                    use_custom_address: false,
                    address: address,
                    rental: {
                        times: [],
                        season: false,
                        partial: false,
                        day_of_the_week: false,
                        hour: false,
                        lighting: null,
                        variants: [
                            {}
                        ]
                    }
                }
            })

    let formContext = useForm({
        defaultValues: loadDefaultValues
    })

    let {watch} = formContext

    let facilityTypeId = watch('type_id')

    let dispatch = useDispatch()

    let [isDuringSubmit, setIsDuringSubmit] = useState(false)

    React.useEffect(() => {
        dispatch(createAction(GALLERY_EDITOR_RESET))
        dispatch(createAction(FACILITY_CREATOR_INIT))
        dispatch(setBreadcrumbs('app.facilities', getRoutePath(ROUTE_FACILITY_LIST), 'Dodaj obiekt'))
    }, [])

    let {
        availability_type: availabilityType,
        days
    } = useSelector(state => state.openingHourForm)

    let back = () => {
        dispatch(createAction(FACILITY_CREATOR_BACK))
    }

    let facilityType = useSelector(state => state.facilityType.byId[facilityTypeId])

    let partialRentalEnabled = watch('rental.partial')

    let partialRentalAvailable = facilityType?.rental?.partial ?? false

    let entireRentalAvailable = facilityType?.rental?.entire ?? false

    let showPartialRental = (partialRentalAvailable && partialRentalEnabled) || !entireRentalAvailable

    let createFacility = (formData) => {
        formData.include = ['type', 'images']
        return api.facility.create(formData).then((response) => {
            let result = normalize(response.data, schemas.facilitySchema);
            dispatch(createAction(FACILITY_ADD_SUCCESS, result));
            return response.data;
        }).catch(error => {
            if (error?.response?.status === 422) {
                return error.response.data;
            }
        })
    }

    let getOpeningHours = () => {
        let data = []
        for (let i = 0; i < Object.keys(days).length; i++) {
            let day = days[i]
            let hours = days[i].hours
            if (day.enabled) {
                for (let j = 0; j < hours.length; j++) {
                    let hour = hours[j]
                    data.push({
                        day: i,
                        time_from: addLeadingZeros(hour.time_from.hour) + ':' + addLeadingZeros(hour.time_from.minute),
                        time_to: addLeadingZeros(hour.time_to.hour) + ':' + addLeadingZeros(hour.time_to.minute)
                    })
                }
            }
        }
        return data
    }

    let onSubmit = async formData => {
        if (step === 4) {
            let requestData = {
                name: formData.name,
                type_id: formData.type_id,
                description: formData.description,
                availability: {
                    type: availabilityType
                },
                images: images
            }
            requestData.custom_fields = []
            if (formData.custom_fields) {
                for (let i = 0; i < Object.keys(formData.custom_fields).length; i++) {
                    let key = Object.keys(formData.custom_fields)[i]
                    let value = formData.custom_fields[key]
                    requestData.custom_fields.push({
                        custom_field_id: key,
                        value: Array.isArray(value) ? null : value,
                        values: Array.isArray(value) ? value : null
                    })
                }
            }
            if (availabilityType === AVAILABILITY_TYPE_CUSTOM) {
                requestData.availability.opening_hours = getOpeningHours()
            }
            if (useCustomAddress) {
                requestData.address = {
                    city: formData.address.city,
                    postcode: formData.address.postcode,
                    address: formData.address.address
                }
            }
            let withPartialRental = !entireRentalAvailable || showPartialRental
            requestData.parts = withPartialRental ? (formData.parts ? formData.parts : null) : null;
            requestData.rental = formData.rental
            let r = await createFacility(requestData);
            navigate(getRoutePath(ROUTE_FACILITY_SHOW, r.id))
            toastSuccess('Dodano obiekt')
        }
        setIsDuringSubmit(true)
        dispatch(createAction(FACILITY_CREATOR_FORWARD))
        setIsDuringSubmit(false)
    }

    let useCustomAddress = watch('use_custom_address')

    React.useEffect(() => {
        if (useCustomAddress === false) {
            formContext.setValue('address.city', businessAddress.city)
            formContext.setValue('address.address', businessAddress.address)
            formContext.setValue('address.postcode', businessAddress.postcode)
        }
    }, [
        useCustomAddress
    ])

    return <div className='facility-creator mt-5'>
        <Row className='fitheight'>
            <Col lg={{size: 8, offset: 2}}>
                {
                    loading && <AppLoading/>
                }
                {
                    !loading && <AppForm formContext={formContext} onSubmit={onSubmit}>
                        <Card>
                            <CardHeader>
                                <div className={step === 0 ? 'fw-bold' : ''}>Szczegóły</div>
                                <div className={step === 1 ? 'fw-bold' : ''}>Lokalizacja</div>
                                <div className={step === 2 ? 'fw-bold' : ''}>Godziny otwarcia</div>
                                <div className={step === 3 ? 'fw-bold' : ''}>Konfiguracja wynajmu</div>
                                <div className={step === 4 ? 'fw-bold' : ''}>Zdjęcia obiektu</div>
                            </CardHeader>
                            <CardBody>
                                <Col lg={{size: 8, offset: 2}}>
                                    {
                                        step === 0 && <>
                                            <FormHeader text='Informacje podstawowe'/>
                                            <FacilityTypeField/>
                                            <FacilityNameField/>
                                            <FacilityDescriptionField/>
                                            {(facilityType && facilityType?.custom_fields?.length > 0) && <>
                                                <FormHeader text='Parametry obiektu'/>
                                                <FacilityCustomFieldsField type={facilityTypeId}/>
                                            </>
                                            }
                                        </>
                                    }
                                    {
                                        step === 1 && <>
                                            <SwitchInput label='Niestandardowy adres' name='use_custom_address'/>
                                            <AddressCityField/>
                                            <AddressField/>
                                            <AddressPostcodeField/>
                                        </>
                                    }
                                    {
                                        step === 2 && <>
                                            <OpeningHours
                                                withButtons={false}
                                                duringSubmit={false}
                                                allowChangeAvailabilityType={true} formType='facility'
                                            />
                                        </>
                                    }
                                    {
                                        step === 3 && <>
                                            <RentalForm/>
                                        </>
                                    }
                                    {
                                        step === 4 && <>
                                            <GalleryEditor/>
                                        </>
                                    }
                                </Col>
                            </CardBody>
                            <CardFooter>
                                <Button variant='secondary' text='Wróć' onClick={back} loading={isDuringSubmit}/>
                                <SubmitFormButton loading={isDuringSubmit} text={step === 4 ? 'Dodaj obiekt' : 'Dalej'}/>
                            </CardFooter>
                        </Card>
                    </AppForm>}
            </Col>
        </Row>
    </div>

}

export default FacilityCreatePage
