import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectPartnersModal from "../../../components/modals/SelectPartnersModal";
import {fetchAllPartners} from "../../../actions/partners";
import ServicePage from "./ServicePage";
import {fetchServiceDetails} from "../../../actions/services";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import CardFooter from "../../../core/card/CardFooter";
import CardBody from "../../../core/card/CardBody";
import Card from "../../../core/card/Card";

let ServicePartnersPage = () => {

    let params = useParams();

    let serviceId = params.id

    let service = useSelector(state => state.service.byId[serviceId])

    let loading = useSelector(state => state.service.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
            dispatch(fetchServiceDetails(serviceId, ['partners']))
            dispatch(fetchAllPartners())
    }, [serviceId, dispatch])

    let allPartners = useSelector(state => state.partner.byId)

    let [selectPartnersModalOpened, setSelectPartnersModalOpened] = React.useState(false)

    let hasPartners = service?.partners && service?.partners.length > 0

    return <ServicePage serviceId={serviceId}>
        {selectPartnersModalOpened && service && <SelectPartnersModal
            type='service'
            modalOpened={selectPartnersModalOpened}
            setModalOpened={setSelectPartnersModalOpened}
            defaultSelected={service?.partners}
            objectId={serviceId}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Pracownicy obiektu'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasPartners && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                service?.partners && service?.partners.map(partnerId => <tr>
                                    <td>
                                        {allPartners[partnerId].fullname}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz pracowników tej usługi'
                                  variant='forward'
                                  Icon={MdKeyboardArrowRight}
                                  rightIcon={true}
                                  onClick={() => setSelectPartnersModalOpened(() => true)}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </ServicePage>

}

export default ServicePartnersPage
