import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_FACILITY_RESOURCE_TYPE,
    ROUTE_RESOURCE_AVAILABILITY,
    ROUTE_RESOURCE_FACILITIES,
    ROUTE_RESOURCE_LIST, ROUTE_RESOURCE_SERVICES,
    ROUTE_RESOURCE_SHOW
} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {setBreadcrumbs} from "../../../actions/navbar";
import {useDispatch, useSelector} from 'react-redux';
import {getCurrentBusiness} from "../../../selectors";

let ResourcePage = ({resourceId, children}) => {

    let advancedResources = useSelector(state => getCurrentBusiness(state).advanced_resources)

    let pages = [
        {
            name: 'Informacje',
            path: getRoutePath(ROUTE_RESOURCE_SHOW, resourceId),
        },
        {
            name: 'Godziny dostępności',
            path: getRoutePath(ROUTE_RESOURCE_AVAILABILITY, resourceId),
        },
        // {
        //     name: 'Dostępność w obiektach',
        //     path: getRoutePath(ROUTE_RESOURCE_FACILITIES, resourceId),
        // },
        {
            name: 'Wykorzystywany przy usługach',
            path: getRoutePath(ROUTE_RESOURCE_SERVICES, resourceId),
        },
    ]

    let resource = useSelector(state => state.resource.byId[resourceId])

    let allFacilities = useSelector(state => state.facility.byId)

    let resourceType = useSelector(state => state.resourceType.byId[resource?.type_id])

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Zasoby', getRoutePath(ROUTE_RESOURCE_LIST)))
    }, [dispatch])

    React.useEffect(() => {
        if (resource?.id) {
            if(!advancedResources && resource?.facilities?.length) {
                let facility = allFacilities[resource?.facilities[0]]
                dispatch(setBreadcrumbs(facility.name, getRoutePath(ROUTE_FACILITY_RESOURCE_TYPE, {
                    id: facility.id,
                    type: resourceType.id
                }), resource.name))
            } else {
                dispatch(setBreadcrumbs('Zasoby', getRoutePath(ROUTE_RESOURCE_LIST), resource.name))
            }
        }
    }, [resource?.id, resource?.facilities, advancedResources])

    return <ObjectPage pages={pages} name='resource'>
        {children}
    </ObjectPage>

}

export default ResourcePage