import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {MdClose, MdKeyboardArrowRight} from 'react-icons/md';
import {
    Collapse,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import {BiBell} from 'react-icons/bi';
import {fetchNotifications} from "../actions/notification";
import ReservationCreated from "./notifications/ReservationCreated";
import ReservationCancelled from "./notifications/ReservationCancelled";
import ReservationConfirmed from "./notifications/ReservationConfirmed";
import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {NOTIFICATION_LOAD_MORE, NOTIFICATION_READ_REQUEST, NOTIFICATION_READ_SUCCESS} from "../reducers/notification";
import {
    RESERVATION_CANCELLED,
    RESERVATION_CONFIRMED,
    RESERVATION_CREATED,
    RESERVATION_REJECTED
} from "../core/constants/notificationTypes";
import ReservationRejected from "./notifications/ReservationRejected";
import handleError from "../core/utils/errorUtil";
import {useNavigate} from "react-router-dom";
import {DASHBOARD_EDIT_RESERVATION_MODAL_SHOW} from "../constants/actiontypes/dashboard";
import {RESERVATION_FETCH_SUCCESS} from "../constants/actiontypes/reservations";
import {normalize} from "normalizr";
import schemas from "../reducers/schemas";

let Notifications = () => {

    let navigate = useNavigate()

    let {
        byId: allNotifications,
        all: notifications,
        page
    } = useSelector(state => state.notification)

    let unreaded = notifications.length

    let hasMore = page * 3 < notifications.length

    let notificationsToShow = notifications.slice(0, page * 3)

    React.useEffect(() => {
        dispatch(fetchNotifications(page))
    }, [])

    let dispatch = useDispatch()

    let loadMore = () => {
        dispatch(createAction(NOTIFICATION_LOAD_MORE))
    }

    let getUnreadedText = () => {
        if (unreaded === 0) {
            return 'Brak nowych powiadomień';
        }
        if (unreaded === 1) {
            return '1 nowe powiadomienie';
        }
        if (unreaded < 5) {
            return unreaded + ' nowe powiadomienia';
        }
        return unreaded + ' nowych powiadomień';
    }

    let readNotification = (notificationId, e) => {
        e.stopPropagation()
        dispatch(createAction(NOTIFICATION_READ_REQUEST, notificationId))
        api.notification.delete(notificationId).then(() => {
            dispatch(createAction(NOTIFICATION_READ_SUCCESS, notificationId))
        }).catch(error => handleError(error, dispatch))
    }

    let openReservation = (notificationId) => {
        let notification = allNotifications[notificationId]
        dispatch(createAction('RESERVATION_MODAL_SHOW_RENTAL_RESERVATION', notification.data.reservationId))
    }

    let createNotification = notificationId => {
        let notification = allNotifications[notificationId]
        let params = {
            notificationId: notification.id,
            readNotification,
            openReservation
        }
        switch (notification.notification_type) {
            case RESERVATION_CREATED: {
                return <ReservationCreated {...params}/>
            }
            case RESERVATION_CANCELLED: {
                return <ReservationCancelled {...params}/>
            }
            case RESERVATION_CONFIRMED: {
                return <ReservationConfirmed {...params}/>
            }
            case RESERVATION_REJECTED: {
                return <ReservationRejected {...params}/>
            }
        }
        return null
    }

    return <Collapse navbar className='notifications'>
        <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
                    <span className="d-none d-sm-inline-block">
                      <DropdownToggle nav>
                          {notifications.length > 0 && <div className='counter'><span>{notifications.length}</span></div>}
                            <BiBell size={16}/>
                      </DropdownToggle>
                    </span>
                <DropdownMenu end className="dropdown-menu-lg py-0">
                    <div className='header'>
                        {getUnreadedText()}
                    </div>
                    {notificationsToShow.map(notificationId => <>
                        <DropdownItem divider/>
                        <div className='notification' onClick={() => openReservation(notificationId)}>
                            <MdClose onClick={(e) => readNotification(notificationId, e)} className='close'/>
                            {createNotification(notificationId)}
                        </div>
                    </>)}
                    {notificationsToShow?.length > 0 && <DropdownItem divider/>}
                    {hasMore && <div className='show-more' onClick={loadMore}>
                        Pokaż wiecej
                        <MdKeyboardArrowRight size={16}/>
                    </div>}
                </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>
    </Collapse>
}

export default Notifications