import React from "react";
import Day from "./Day";
import {DAYS} from "./Days";
import OpeningHoursTypeSelect from "../forms/OpeningHoursTypeSelect";
import {Row, Col} from 'reactstrap'
import {AVAILABILITY_TYPE_CUSTOM, AVAILABILITY_TYPE_INHERIT} from "../../core/constants/reservationStatuses";
import OpeningHoursSkeleton from "../forms/app/facility/OpeningHoursSkeleton";
import {useSelector, useDispatch} from 'react-redux'
import {createAction} from "../../core/utils/appUtil";
import {
    OPENING_HOUR_FORM_CHANGE_AVAILABILITY_TYPE,
    OPENING_HOUR_FORM_INIT,
    OPENING_HOUR_FORM_RESET
} from "../../reducers/openingHourForm";
import Card from "../../core/card/Card";
import CardBody from "../../core/card/CardBody";
import CardFooter from "../../core/card/CardFooter";
import Button from "../../core/buttons/Button";

const OpeningHours = ({
                          allowChangeAvailabilityType = false,
                          onSubmit,
                          resetForm,
                          duringSubmit,
                          wrap = true,
                          formType,
                          withButtons = true
                      }) => {

    let dispatch = useDispatch()

    let {
        inheritedOpeningHours,
        availability_type: availabilityType,
        loading,
        formChanged,
        days
    } = useSelector(state => state.openingHourForm)

    let changeAvailabilityType = (a) => {
        if (a === AVAILABILITY_TYPE_INHERIT) {
            dispatch(createAction(OPENING_HOUR_FORM_INIT, {
                availability_type: a,
                openingHours: inheritedOpeningHours
            }));
        }
        dispatch(createAction(OPENING_HOUR_FORM_CHANGE_AVAILABILITY_TYPE, a))
    }

    if (loading) {
        return <OpeningHoursSkeleton/>
    }

    let onSave = () => {
        let data = []
        for (let i = 0; i < Object.keys(days).length; i++) {
            let day = days[i]
            let hours = days[i].hours
            if (day.enabled) {
                for (let j = 0; j < hours.length; j++) {
                    let hour = hours[j]
                    data.push({
                        day: i,
                        ...hour
                    })
                }
            }
        }
        onSubmit(data)
    }

    let renderButtons = () => formChanged && <>
        <Button text='Anuluj' variant='secondary' onClick={resetForm}/>
        <Button text='Zapisz' onClick={onSave} loading={duringSubmit}/>
    </>

    let renderOpeningHours = (disabled = false) => <div>
        {allowChangeAvailabilityType && <Row>
            <Col>
                <OpeningHoursTypeSelect value={availabilityType} onChange={changeAvailabilityType} formType={formType}/>
            </Col>
        </Row>}
        <table className='opening-hours-table table table-striped'>
            <thead>
            <tr>
                <th>Dzień</th>
                <th>Dostępność</th>
                <th>Od</th>
                <th>Do</th>
            </tr>
            </thead>
            <tbody>
            {
                DAYS.map(day => <Day
                        day={day}
                        key={day}
                        readonly={availabilityType === AVAILABILITY_TYPE_INHERIT}
                    />
                )
            }
            </tbody>
        </table>
    </div>

    if (!wrap) {
        return <div>
            <div>
                {renderOpeningHours()}
            </div>
        </div>
    }

    return <Card title='Godziny otwarcia'>
        <CardBody>
            {
                renderOpeningHours()
            }
        </CardBody>
        {
            (formChanged && withButtons) && <CardFooter>
                {renderButtons()}
            </CardFooter>
        }
    </Card>

};

export default OpeningHours;
