import {
    ADD_NOTIFICATION,
    LOAD_NOTIFICATIONS_REQUEST,
    LOAD_NOTIFICATIONS_SUCCESS, RESET_UNREADED_COUNT
} from "../constants/actiontypes/notification";

var initialState = {
    byId: [],
    unreaded_count: 0,
    all: [],
    page: 1,
    last_page: 0,
    has_more: true
};
export const NOTIFICATION_READ_REQUEST = 'NOTIFICATION_READ_REQUEST';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATION_LOAD_MORE = 'NOTIFICATION_LOAD_MORE';

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_NOTIFICATIONS_REQUEST: {
            return {
                ...state
            }
        }
        case NOTIFICATION_LOAD_MORE: {
            return {
                ...state,
                page: state.page + 1,
            }
        }
        case LOAD_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                has_more: true,
                byId: {
                    ...state.byId,
                    ...action.payload.entities.notification
                },
                all: action.payload.result
            }
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                unreaded_count: state.unreaded_count + 1,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload
                },
                all: [
                    action.payload.id,
                    ...state.all
                ]
            }
        }
        case RESET_UNREADED_COUNT: {
            return {
                ...state,
                unreaded_count: 0
            }
        }
        case NOTIFICATION_READ_REQUEST: {
            return {
                ...state,
                unreaded_count: state.unreaded_count - 1,
                all: state.all.filter(id => id !== action.payload)
            }
        }
        default:
            return state;
    }
};