import React from "react";
import {useSelector} from 'react-redux'
import {RiEdit2Fill} from 'react-icons/ri'
import {AiOutlineMinusCircle} from 'react-icons/ai'

let Employee = ({id, onEdit, onRemove}) => {

    let employee = useSelector(state => state.partner.byId[id])

    return <div className='item'>
        <div className='employee-data'>
            <span className='name'>{employee.fullname}</span>
            <span className='role'>{employee.master_account ? 'Właściciel biznesu' : 'Pracownik'}</span>
        </div>
        <div className='buttons'>
            {!employee.master_account && <AiOutlineMinusCircle onClick={() => onRemove(employee.id)}/>}
            {!employee.master_account && <RiEdit2Fill onClick={() => onEdit(employee.id)}/>}
        </div>
    </div>

}

export default Employee