import React from "react";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import schemas from "../../reducers/schemas";
import {normalize} from 'normalizr';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_CLIENT_SHOW} from "../../routes/routeDefinitions";
import ClientFullnameField from "../forms/client/ClientFullnameField";
import ClientEmailField from "../forms/client/ClientEmailField";
import ClientPhoneField from "../forms/client/ClientPhoneField";
import {CLIENT_CREATE_SUCCESS} from "../../reducers/clients";
import ModalForm from "../../core/modal/ModalForm";

let ClientModal = ({isOpen, closeModal, redirectToClientPage = true, onSuccessSave = null}) => {

    let dispatch = useDispatch();

    let navigate = useNavigate();

    let onSubmit = (data) => {
        dispatch(createAction(CLIENT_CREATE_SUCCESS, normalize(data, schemas.clientSchema)))
        if (redirectToClientPage) {
            navigate(getRoutePath(ROUTE_CLIENT_SHOW, data.id))
        }
        if (onSuccessSave) {
            onSuccessSave(data)
        }
    }

    let prepareData = data => {
        return {
            fullname: data.fullname,
            email: data.email,
            phone: data.phone
        }
    }

    return <ModalForm
        prepareData={prepareData}
        isOpen={isOpen}
        closeModal={closeModal}
        title='Dodaj klienta'
        getPromise={(id, data) => api.client.create(data)}
        onSubmit={onSubmit}
    >
        <ClientFullnameField/>
        <ClientEmailField/>
        <ClientPhoneField/>
    </ModalForm>

}

export default ClientModal