import React, {useEffect, useState} from "react";
import AppModal from "./AppModal";
import {toastSuccess} from "../../core/utils/appUtil";
import {updateReservation} from "../../actions/reservation";
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment';
import api from "../../core/api";
import Loader from "../Loader";
import Checkbox from "../../core/form/Checkbox";
import FacilityPartSelect from "../forms/select/FacilityPartSelect";

const ReservationChangeConfirmationModal = (props) => {

    let {
        start,
        end,
        old_start: oldStart,
        old_end: oldEnd,
        reservation_id: reservationId,
        setModalOpened,
        revert
    } = props

    let oldDate = moment(oldStart).format('YYYY-MM-DD (dddd)')

    let newDate = moment(start).format('YYYY-MM-DD (dddd)')

    let dateChanged = oldDate !== newDate

    let oldTime = moment(oldStart).format('HH:mm') + ' - ' + moment(oldEnd).format('HH:mm')

    let newTime = moment(start).format('HH:mm') + ' - ' + moment(end).format('HH:mm')

    let timeChanged = oldTime !== newTime

    let dispatch = useDispatch();

    let [loading, setLoading] = useState(true);

    let [newTerm, setNewTerm] = useState(null);

    let [changePrice, setChangePrice] = useState(false)

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let facility = useSelector(state => state.facility.byId[reservation.facility_id])

    let [facilityPartId, setFacilityPartId] = useState(reservation.facility_part_id);

    let oldFacilityPartId = reservation.facility_part_id

    let facilityParts = useSelector(state => state.facilityPart.byId)

    let oldFacilityPart = useSelector(state => state.facilityPart.byId[oldFacilityPartId])

    let newFacilityPart = useSelector(state => state.facilityPart.byId[facilityPartId])

    let newPrice = newTerm?.price

    let isValidNewTerm = () => {
        if (facilityPartId) {
            return newTerm?.parts?.includes(facilityPartId);
        }
        return !!newTerm
    }

    let priceChanged = newPrice !== reservation.price

    let save = async () => {
        if (isPast) {
            setIsPast(false)
            return;
        }
        let params = {
            date: moment(start).format('YYYY-MM-DD'),
            time_from: moment(start).format('HH:mm'),
            time_to: moment(end).format('HH:mm'),
            facility_part_id: facilityPartId
        }
        if (changePrice) {
            params.price = newPrice
            params.custom_price = true
        }
        let result = await updateReservation(reservationId, params, dispatch);
        if (result) {
            toastSuccess('Zmieniono termin rezerwacji')
            setModalOpened(() => false)
            return true;
        }
    }

    let [isPast, setIsPast] = useState(moment(end) < moment())

    useEffect(() => {
        setLoading(true)
        let currentDate = moment(start).format('YYYY-MM-DD')
        api.service.getTerms(reservation.service_id, {
            date_from: currentDate,
            date_to: currentDate,
            reservation_ids: [reservationId],
        }).then(r => {
            let terms = r.data.filter(term => term.date === currentDate)[0].slots ?? []
            terms = terms.filter(t => t.time_from === moment(start).format('HH:mm') && t.time_to === moment(end).format('HH:mm'))
            if (terms.length > 0) {
                setNewTerm(terms[0])
            }
            setLoading(false)
        })
    }, [start])

    let declineChanges = data => {
        revert()
        setModalOpened(() => false)
    }

    let getContent = () => {
        if (loading) {
            return <Loader/>
        }
        if (isPast) {
            return <>
                <p>Czy na pewno chcesz przenieść rezerwację na termin, który już upłynął?</p>
            </>
        }
        return <>
            {(!loading && isValidNewTerm()) && <><p>Czy na pewno chcesz zmienić czas rezerwacji?</p>
                <p>Data rezerwacji: {!dateChanged && newDate} {dateChanged && <>
                    <span style={{color: 'red', textDecoration: 'line-through'}}>{oldDate}</span>
                    <span style={{color: 'green'}}>{newDate}</span>
                </>}</p>
                <p>Godzina: {!timeChanged && newTime} {timeChanged && <>
                    <span style={{color: 'red', textDecoration: 'line-through'}}>{oldTime}</span>
                    <span style={{color: 'green'}}>{newTime}</span>
                </>}</p>
                {
                    !priceChanged && <p>Cena: {reservation.price} PLN</p>
                }
                {
                    (priceChanged && !changePrice) && <p>Cena: {reservation.price}</p>
                }
                {
                    (priceChanged && changePrice) && <p>Cena:
                        <span style={{color: 'red', textDecoration: 'line-through'}}>{reservation.price} PLN</span>
                        <span style={{color: 'green'}}>{newPrice} PLN</span>
                    </p>
                }
                {
                    facilityPartId && <p>
                        Część obiektu: {facilityPartId === oldFacilityPartId ? <span>{oldFacilityPart.name}</span> : <>
                        <span style={{color: 'red', textDecoration: 'line-through'}}>{oldFacilityPart.name}</span>
                        <span style={{color: 'green'}}>{newFacilityPart.name}</span>
                    </>}
                    </p>
                }
                {priceChanged && <Checkbox value={!changePrice} onChange={selected => setChangePrice(!selected)}
                                           label={'Zachowaj starą cenę (sugerowana cena: ' + newPrice + ' PLN)'}/>}
            </>}
            {(!isValidNewTerm() && !facilityPartId && !loading) && <div>Wybrany termin jest zajęty</div>}
            {(!isValidNewTerm() && facilityPartId && !newTerm?.parts.length && !loading) &&
                <div>Wybrany termin jest zajęty</div>}
            {(!isValidNewTerm() && facilityPartId && newTerm?.parts.length > 0 && !loading) && <div>
                <b>{oldFacilityPart.name}</b> jest w terminie <b>{newDate} {newTime}</b> zajęty, wybierz inny:
                <ul>
                    {
                        facility?.parts.map(id => <li>{facilityParts[id].name + '   '}
                            {!newTerm.parts[Object.keys(newTerm.parts)[0]].includes(id) && <span style={{color: 'red'}}>Zajęty</span>}
                            {newTerm.parts[Object.keys(newTerm.parts)[0]].includes(id) &&
                                <a href='#' onClick={() => setFacilityPartId(id)}>Wybierz</a>}
                        </li>)
                    }
                </ul>
            </div>}
        </>
    }

    return <AppModal
        {...props}
        title='Potwierdź zmianę terminu rezerwacji'
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
        submitAction={save}
        includeButtons={isValidNewTerm() === true || isPast}
        setModalOpened={declineChanges}
    >
        {
            getContent()
        }
    </AppModal>
}

export default ReservationChangeConfirmationModal;

