export const DASHBOARD_FETCH_RESERVATIONS_REQUEST = 'DASHBOARD_FETCH_RESERVATIONS_REQUEST';
export const DASHBOARD_FETCH_RESERVATIONS_SUCCESS = 'DASHBOARD_FETCH_RESERVATIONS_SUCCESS';
export const DASHBOARD_FETCH_BLOCKS_REQUEST = 'DASHBOARD_FETCH_BLOCKS_REQUEST';
export const DASHBOARD_FETCH_BLOCKS_SUCCESS = 'DASHBOARD_FETCH_BLOCKS_SUCCESS';
export const DASHBOARD_FETCH_FACILITIES_REQUEST = 'DASHBOARD_FETCH_FACILITIES_REQUEST';
export const DASHBOARD_FETCH_FACILITIES_SUCCESS = 'DASHBOARD_FETCH_FACILITIES_SUCCESS';
export const DASHBOARD_FETCH_SERVICES_REQUEST = 'DASHBOARD_FETCH_SERVICES_REQUEST';
export const DASHBOARD_FETCH_SERVICES_SUCCESS = 'DASHBOARD_FETCH_SERVICES_SUCCESS';
export const DASHBOARD_FETCH_CLIENTS_REQUEST = 'DASHBOARD_FETCH_CLIENTS_REQUEST';
export const DASHBOARD_FETCH_CLIENTS_SUCCESS = 'DASHBOARD_FETCH_CLIENTS_SUCCESS';
export const DASHBOARD_SHOW_RESERVATION_MODAL_SHOW = 'DASHBOARD_SHOW_RESERVATION_MODAL_SHOW';
export const DASHBOARD_SHOW_RESERVATION_MODAL_CLOSE = 'DASHBOARD_SHOW_RESERVATION_MODAL_CLOSE';
export const DASHBOARD_CREATE_RESERVATION_MODAL_SHOW = 'DASHBOARD_CREATE_RESERVATION_MODAL_SHOW';
export const DASHBOARD_EDIT_RESERVATION_MODAL_SHOW = 'DASHBOARD_EDIT_RESERVATION_MODAL_SHOW';
export const DASHBOARD_RESERVATION_MODAL_CLOSE = 'DASHBOARD_RESERVATION_MODAL_CLOSE';
export const DASHBOARD_CREATE_RESERVATION_SUCCESS = 'DASHBOARD_CREATE_RESERVATION_SUCCESS';
export const DASHBOARD_UPDATE_RESERVATION_SUCCESS = 'DASHBOARD_UPDATE_RESERVATION_SUCCESS';
export const DASHBOARD_SET_CURRENT_DATE = 'DASHBOARD_SET_CURRENT_DATE';
export const DASHBOARD_TOGGLE_FILTER_PANEL = 'DASHBOARD_TOGGLE_FILTER_PANEL';
export const DASHBOARD_INIT_CALENDAR = 'DASHBOARD_INIT_CALENDAR';
export const DASHBOARD_SET_GLOBAL_FILTER = 'DASHBOARD_SET_GLOBAL_FILTER';
export const DASHBOARD_TOGGLE_FILTER = 'DASHBOARD_TOGGLE_FILTER';
export const DASHBOARD_RESET_FILTERS = 'DASHBOARD_RESET_FILTERS';
export const DASHBOARD_FETCH_RESOURCES_REQUEST = 'DASHBOARD_FETCH_RESOURCES_REQUEST';
export const DASHBOARD_FETCH_RESOURCES_SUCCESS = 'DASHBOARD_FETCH_RESOURCES_SUCCESS';
export const DASHBOARD_FETCH_PARTNERS_REQUEST = 'DASHBOARD_FETCH_PARTNERS_REQUEST';
export const DASHBOARD_FETCH_PARTNERS_SUCCESS = 'DASHBOARD_FETCH_PARTNERS_SUCCESS';
export const DASHBOARD_FETCH_RESERVATION_REQUEST = 'DASHBOARD_FETCH_RESERVATION_REQUEST';
export const DASHBOARD_FETCH_RESERVATION_SUCCESS = 'DASHBOARD_FETCH_RESERVATION_SUCCESS';