import React from "react";
import placeholder from '../../assets/img/image-placeholder.png'


let ImagePlaceholder = () => {
    return <div className='tile placeholder'>
        <img src={placeholder} alt=''/>
    </div>
}

export default ImagePlaceholder