import React from "react";
import ServiceNameField from "../forms/service/ServiceNameField";
import ServiceDescriptionField from "../forms/service/ServiceDescriptionField";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import schemas from "../../reducers/schemas";
import {normalize} from 'normalizr';
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_SERVICE_SUCCESS} from "../../constants/actiontypes/services";
import {useNavigate} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_SERVICE_DETAILS} from "../../routes/routeDefinitions";
import {CREATOR_ADD_SERVICE, CREATOR_UPDATE_SERVICE} from "../../reducers/creator";
import ModalForm from "../../core/modal/ModalForm";
import ServicePriceForm from "./ServicePriceForm";

let ServiceModal = ({isOpen, closeModal, creator = false, serviceId = null}) => {

    let dispatch = useDispatch();

    let service = useSelector(state => state.service.byId[serviceId])

    let navigate = useNavigate();

    let onSubmit = (data) => {
        dispatch(createAction(UPDATE_SERVICE_SUCCESS, normalize(data, schemas.serviceSchema)))
        if (!creator) {
            navigate(getRoutePath(ROUTE_SERVICE_DETAILS, data.id))
        } else {
            if (serviceId) {
                dispatch(createAction(CREATOR_UPDATE_SERVICE, normalize(data, schemas.serviceSchema)))
            } else {
                dispatch(createAction(CREATOR_ADD_SERVICE, normalize(data, schemas.serviceSchema)))
            }
        }
        closeModal()
    }

    let prepareData = data => {
        return data;
    }

    let getPromise = () => {
        if (serviceId) {
            return (id, data) => api.service.update(id, data)
        } else {
            return (id, data) => api.service.create(data)
        }
    }

    return <>
        <ModalForm
            className='service-modal'
            prepareData={prepareData}
            isOpen={isOpen}
            data={{
                ...service,
                price: {
                    day_of_the_week: false,
                    season: false,
                    time: false,
                    times: [],
                    variants: [
                        {}
                    ]
                },
            }}
            closeModal={closeModal}
            submitText={serviceId ? 'Zapisz' : 'Dodaj'}
            title={serviceId ? 'Edytuj usługę' : 'Dodaj usługę'}
            getPromise={getPromise()}
            onSubmit={onSubmit}>
            <ServiceNameField/>
            <ServiceDescriptionField/>
            <ServicePriceForm/>
        </ModalForm>
    </>
}

export default ServiceModal