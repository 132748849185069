import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import ServicePage from "./ServicePage";
import {initServicePricePage} from "../../../actions/services";
import {useDispatch, useSelector} from "react-redux";
import CardFormWithSkeleton from "../../../core/form/CardFormWithSkeleton";
import api from "../../../core/api";
import {createAction} from "../../../core/utils/appUtil";
import {SERVICE_UPDATE_SUCCESS} from "../../../reducers/service";
import schemas from "../../../reducers/schemas";
import {PRICE_FORM_SET_PRICE_TYPE} from "../../../reducers/priceForm";
import ServicePriceForm from "../../../components/service/ServicePriceForm";


let ServicePricePage = () => {

    let params = useParams();

    let serviceId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(initServicePricePage(serviceId))
    }, [dispatch, serviceId])

    let service = useSelector(state => state.service.byId[serviceId])

    let modalOpened = useSelector(state => state.priceForm.modalOpened);

    let loading = useSelector(state => state.service.loadingDetails)

    let {variants, formChanged} = useSelector(state => state.priceForm)

    let prepareData = data => {
        return {
            price: data.price
        }
    }

    return <ServicePage serviceId={serviceId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <CardFormWithSkeleton
                    title='Cena usługi'
                    skeleton={[
                        [{lg: 12, elements: 1}]
                    ]}
                    prepareData={prepareData}
                    loading={loading}
                    wasChanged={formChanged}
                    data={service}
                    getPromise={(id, data) => api.service.update(service?.id, data)}
                    onSubmit={data => {
                        dispatch(createAction(SERVICE_UPDATE_SUCCESS, normalize(data, schemas.serviceSchema)))
                    }}
                >
                    <ServicePriceForm/>
                </CardFormWithSkeleton>
            </Col>
        </Row>
    </ServicePage>

}

export default ServicePricePage
