import React from "react";
import {useIntl} from 'react-intl';
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {trans} from "../../../core/utils/translationUtil";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllFacilities} from "../../../actions/facilities";

let FacilitySelect = (props) => {

    let isAvailable = props.isAvailable

    if (!isAvailable) {
        isAvailable = () => true
    }

    let intl = useIntl();

    let dispatch = useDispatch();

    let label = props.label ?? trans(intl, 'facility');

    let name = props.isMulti ? 'facilities' : 'facility_id';

    let {allIds} = useSelector(state => state.facility)

    React.useEffect(() => {
        if (!props.skipInitialLoad) {
            dispatch(fetchAllFacilities(props.includes ?? []))
        }
    }, [dispatch, JSON.stringify(props)])

    function getData() {
        if(props.filterIds) {
            return allIds.filter(props.filterIds)
        }
        if (Array.isArray(props.data)) {
            return props.data
        }
        return allIds
    }

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz obiekt'
        stateKey='facility'
        data={getData()}
        autocomplete={false}
        isAvailable={isAvailable}
        renderLabel={(option) => <div className={'select-option detailed-select-option partners d-flex'}>
            <span>{option.name}</span>{!isAvailable(option.id) && <span className='inavailable'>Niedostępny</span>}
        </div>}
        {...props}
    />

}

export default FacilitySelect;
