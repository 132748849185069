import React from "react";
import {useSelector} from 'react-redux'
import {RiEdit2Fill} from 'react-icons/ri'
import {AiOutlineMinusCircle} from 'react-icons/ai'
import {getPriceText, getServicePriceText, pad} from "../../core/utils/appUtil";
import {useIntl} from 'react-intl'

let Service = ({id, onEdit, onRemove}) => {

    let service = useSelector(state => state.service.byId[id])

    let hours = Math.floor(service.duration / 60)

    let minutes = pad(service.duration % 60, 2)

    let intl = useIntl()

    return <div className='item'>
        <div className='employee-data'>
            <span className='name'>{service.name}</span>
            <span className='role'>{hours ? hours + 'h' : ''} {minutes ? minutes + 'min.' : ''}</span>
        </div>
        <div className='price'>
            <span>{getServicePriceText(service.price)}</span>
        </div>
        <div className='buttons'>
            <AiOutlineMinusCircle onClick={() => onRemove(service.id)}/>
            <RiEdit2Fill onClick={() => onEdit(service.id)}/>
        </div>
    </div>

}

export default Service