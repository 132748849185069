import React from "react";
import Button from "./Button";

const IconButton = (props) => {

    let {loading, type} = props;

    return <Button  {...props} type='button' text='' loading={loading} variant='icon' extraClasses={type}/>

}

export default IconButton;

