import React from "react";

import AppModal from "./AppModal";

const WarningModal = (props) => {

    return <AppModal
        {...props}
        title='Błąd'
        submitText='OK'
        cancelText={false}
        submitAction={() => props.setModalOpened(false)}
        type='confirmation'
    />
}

export default WarningModal;

