import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_BUSINESS_AVAILABILITY, ROUTE_BUSINESS_CONTACT, ROUTE_BUSINESS_GALLERY,
    ROUTE_BUSINESS_RESERVATION_SETTINGS,
    ROUTE_BUSINESS_SHOW, ROUTE_BUSINESS_SUBSCRIPTION_SETTINGS
} from "../../../routes/routeDefinitions";
import {resetBreadcrumbs} from "../../../actions/navbar";
import {useDispatch} from 'react-redux'

let BusinessPage = ({children}) => {

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
    })

    let pages = [
        {
            name: 'Informacje',
            path: ROUTE_BUSINESS_SHOW,
        },
        {
            name: 'Godziny otwarcia',
            path: ROUTE_BUSINESS_AVAILABILITY,
        },
        {
            name: 'Ustawienia rezerwacji',
            path: ROUTE_BUSINESS_RESERVATION_SETTINGS,
        },
        {
            name: 'Subskrypcja',
            path: ROUTE_BUSINESS_SUBSCRIPTION_SETTINGS,
        },
        {
            name: 'Kontakt',
            path: ROUTE_BUSINESS_CONTACT,
        },
        {
            name: 'Zdjęcia',
            path: ROUTE_BUSINESS_GALLERY,
        }
    ]

    return <ObjectPage pages={pages} name='business'>
        {children}
    </ObjectPage>

}

export default BusinessPage
