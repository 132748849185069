import React from "react";
import {Col} from 'reactstrap'
import {useParams} from "react-router-dom";
import FacilityPage from "./FacilityPage";
import OpeningHours from "../../../components/openinghours/OpeningHours";
import {openFacilityOpeningHoursPage} from "../../../actions/facilities";
import {useDispatch, useSelector} from 'react-redux';
import * as api from "../../../core/api";
import {createAction} from "../../../core/utils/appUtil";
import {toastSuccess} from "../../../core/utils/appUtil";
import handleError from "../../../core/utils/errorUtil";
import {OPENING_HOUR_FORM_SAVED} from "../../../reducers/openingHourForm";
import {FACILITY_OPENING_HOURS_UPDATED} from "../../../reducers/facility";

let FacilityOpeningHoursPage = () => {
    let params = useParams();
    let facilityId = params.id
    let dispatch = useDispatch();

    let {availability_type: availabilityType} = useSelector(state => state.openingHourForm)

    React.useEffect(() => {
        dispatch(openFacilityOpeningHoursPage(facilityId));
    }, [facilityId, dispatch])

    let apiCall = (data) => {
        let request = api.updateFacilityOpeningHours({
                    availability_type: availabilityType,
                    opening_hours: data
                }, facilityId, []);
        return request.then(() => {
            dispatch(createAction(OPENING_HOUR_FORM_SAVED))
            dispatch(createAction(FACILITY_OPENING_HOURS_UPDATED, {
                facility_id: facilityId,
                availability_type: availabilityType
            }))
            toastSuccess('Zapisano!')
        }).catch(error => handleError(error, dispatch))
    }

    let resetForm = () => {
        dispatch(openFacilityOpeningHoursPage(facilityId))
    }

    let [loading, setLoading] = React.useState(false)

    let submit = async (data) => {
        setLoading(true);
        await apiCall(data);
        setLoading(false);
    }

    return <FacilityPage facilityId={facilityId}>
        <Col lg={{size: 6, offset: 3}}>
            <OpeningHours resetForm={resetForm} onSubmit={submit} duringSubmit={loading} allowChangeAvailabilityType={true} formType='facility'/>
        </Col>
    </FacilityPage>
}

export default FacilityOpeningHoursPage
