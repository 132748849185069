import React, {Component} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import {connect} from "react-redux";
import {HIDE_ERROR} from "../constants/actiontypes/app";
import {createAction} from "../core/utils/appUtil";
const mapStateToProps = state => {
    return {
        error: state.app.error
    }
};

const mapDispatchToProps = dispatch => ({
    hideError: () => dispatch(createAction(HIDE_ERROR))
});

class ErrorModal extends Component {

    render() {
        if(!this.props.error) {
            return <div/>;
        }
        return (
            <Modal isOpen={!!this.props.error} toggle={this.props.hideError}>
                <ModalHeader>Wystąpił błąd!</ModalHeader>
                <ModalBody>
                    <p><b>Kod: </b>{this.props.error.status_code}</p>
                    <p><b>Treść błędu:</b> {this.props.error.message}</p>
                    {
                        this.props.error.debug &&
                        <p><b>Plik: </b>{this.props.error.debug.file + ':' + this.props.error.debug.line}</p>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.hideError}>OK</Button>
                </ModalFooter>
            </Modal>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
