export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_DETAILS_REQUEST = 'CLIENT_DETAILS_REQUEST';
export const CLIENT_DETAILS_SUCCESS = 'CLIENT_DETAILS_SUCCESS';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';

var initialState = {
    byId: {},
    allIds: [],
    loading: true,
    loadingDetails: false,
    pagination: {}
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.client) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.client
            }
        }
    }
    switch (action.type) {
        case CLIENT_CREATE_SUCCESS: {
            return {
                ...state,
                allIds: [
                    ...state.allIds,
                    action.payload.result
                ]
            }
        }
        case CLIENT_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case CLIENT_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false,
                pagination: action.payload.meta
            }
        case CLIENT_DETAILS_REQUEST: {
            return {
                ...state,
                loadingDetails: true
            }
        }
        case CLIENT_DETAILS_SUCCESS:
            return {
                ...state,
                loadingDetails: false
            }
        default:
            return state;
    }
};