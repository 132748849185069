import _ from "lodash";

export const FACILITY_TYPE_LIST_REQUEST = 'FACILITY_TYPE_LIST_REQUEST';
export const FACILITY_TYPE_LIST_SUCCESS = 'FACILITY_TYPE_LIST_SUCCESS';
var initialState = {
    byId: [],
    allIds: [],
    loading: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.facilityType) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.facilityType)
        }
    }
    switch (action.type) {
        case FACILITY_TYPE_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FACILITY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        default:
            return state;
    }
};