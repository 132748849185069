import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {fetchClients} from "../../../actions/clients";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {trans} from "../../../core/utils/translationUtil";

let ClientSelect = (props) => {

    let intl = useIntl();

    let dispatch = useDispatch();

    let label = props.label ?? trans(intl, 'client');

    let name = 'client_id'

    let {allIds} = useSelector(state => state.clients)

    React.useEffect(() => {
        dispatch(fetchClients([],1,1000))
    }, [dispatch])

    const filterOptions = createFilterOptions({
        stringify: ({ fullname, email, phone }) => `${fullname} ${email} ${phone}`
    });

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz Klienta'
        stateKey='clients'
        data={allIds}
        optionLabel='fullname'
        autocomplete={true}
        filterOptions={filterOptions}
        renderLabel={(option) => <div className='detailed-select-option d-flex'>
            <div className='name'>{option.fullname}</div>
            <div className='details'>
                <div>{option.email}</div>
                <div className='right'>{option.phone}</div>
            </div>
        </div>}
        {...props}
    />

}

export default ClientSelect;
