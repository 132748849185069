import React from 'react';
import {BsPlusCircle} from 'react-icons/bs';
import {CgLogIn} from 'react-icons/cg';
import {Link} from "react-router-dom";
import {ROUTE_SIGN_IN, ROUTE_SIGN_UP} from "../../routes/routeDefinitions";
import SignUpForm from "../../components/forms/app/auth/SignUpForm";
import SocialLoginButton from "../../components/buttons/SocialLoginButton";

let SignUp = () => {
    return <div className='login-page'>
        <div className='buttons'>
            <Link to={ROUTE_SIGN_IN}>
                <CgLogIn/>
                Zaloguj się
            </Link>
            <Link to={ROUTE_SIGN_UP} className='active'>
                <BsPlusCircle/>
                Utwórz konto
            </Link>
        </div>
        <SignUpForm/>
        {/*<div className='login-as'>*/}
        {/*<span className='header'>*/}
        {/*    Zarejestruj się za pomocą*/}
        {/*</span>*/}
        {/*    <SocialLoginButton type='google'/>*/}
        {/*    <SocialLoginButton type='fb'/>*/}
        {/*</div>*/}
    </div>
}

export default SignUp;