import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import {normalize} from 'normalizr';
import {changeLanguage} from "../../../../actions/app";
import {PARTNER_UPDATE_SUCCESS} from "../../../../reducers/partner";
import schemas from "../../../../reducers/schemas";
import CardForm from "../../../../core/form/CardForm";

let AccountNotificationsForm = function () {

    let user = useSelector(state => state.partner.byId[state.auth.logged_user]);

    let loading = useSelector(state => state.language.loading)

    let languages = useSelector(state => state.language.byId);

    let dispatch = useDispatch();

    let submit = data => {
        dispatch(changeLanguage(languages[data.language_id].code))
        dispatch(createAction(PARTNER_UPDATE_SUCCESS, normalize(data, schemas.partnerSchema)))
    }

    return <CardForm
        title='Preferencje'
        data={user}
        getPromise={(id, data) => api.partner.update(user.id, data)}
        onSubmit={submit}>
    </CardForm>
}

export default AccountNotificationsForm