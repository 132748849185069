import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import ServiceNameField from "../../service/ServiceNameField";
import ServiceDurationField from "../../service/ServiceDurationField";
import ServiceDescriptionField from "../../service/ServiceDescriptionField";
import ServiceVisibleField from "../../service/ServiceVisibleField";
import ServiceStatusField from "../../service/ServiceStatusField";
import {SERVICE_UPDATE_SUCCESS} from "../../../../reducers/service";
import CardForm from "../../../../core/form/CardForm";
import ServiceSportsField from "../../service/ServiceSportsField";

let ServiceDetailsForm = function ({serviceId}) {

    let service = useSelector(state => state.service.byId[serviceId])

    let loading = useSelector(state => state.service.loadingDetails)

    let dispatch = useDispatch();

    let prepareData = data => {
        return {
            name: data.name,
            description: data.description,
            enabled: data.enabled,
            visible: data.visible,
            sport_id: data.sport_id
        }
    }

    return <CardForm
        title='Informacje o usłudze'
        prepareData={prepareData}
        data={service}
        getPromise={(id, data) => api.service.update(id, data)}
        onSubmit={data => dispatch(createAction(SERVICE_UPDATE_SUCCESS, normalize(data, schemas.serviceSchema)))}
    >
        <ServiceNameField loading={loading}/>
        <ServiceDescriptionField loading={loading}/>
        <ServiceVisibleField loading={loading}/>
        <ServiceStatusField loading={loading}/>
        <ServiceSportsField/>
    </CardForm>

}
export default ServiceDetailsForm