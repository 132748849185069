var initialState = {
    previouspage: null,
    previouspageurl: null,
    sitename: null
};

export const NAVBAR_SET_BREADCRUMBS = 'NAVBAR_SET_BREADCRUMBS';
export const NAVBAR_RESET_BREADCRUMBS = 'NAVBAR_RESET_BREADCRUMBS';

export default (state = initialState, action) => {
    switch (action.type) {
        case NAVBAR_SET_BREADCRUMBS:
            return {
                ...state,
                ...action.payload
            }
        case NAVBAR_RESET_BREADCRUMBS: {
            return initialState
        }
        default:
            return state;
    }
};