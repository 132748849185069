import React from "react";

import SelectObjectsModal from "./SelectObjectsModal";
import {useDispatch} from 'react-redux';
import * as api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import {PARTNER_UPDATE_FACILITIES_SUCCESS} from "../../reducers/partner";
import {fetchAllFacilities} from "../../actions/facilities";
import {SERVICE_UPDATE_FACILITIES_SUCCESS} from "../../reducers/service";
import {useSelector} from 'react-redux'
import {RESOURCE_UPDATE_FACILITIES_SUCCESS} from "../../reducers/resource";

const SelectFacilitiesModal = (props) => {

    let dispatch = useDispatch();

    let type = props.type ?? 'service';

    let facilities = useSelector(state => state.facility.allIds)

    let onUpdate = (objectId, facilities) => {
        if(type === 'service') {
            dispatch(createAction(SERVICE_UPDATE_FACILITIES_SUCCESS, {
                service_id: objectId,
                facilities: facilities
            }))
        } else if (type === 'partner') {
            dispatch(createAction(PARTNER_UPDATE_FACILITIES_SUCCESS, {
                partner_id: objectId,
                facilities: facilities
            }))
        } else if (type === 'resource') {
            dispatch(createAction(RESOURCE_UPDATE_FACILITIES_SUCCESS, {
                resource_id: objectId,
                facilities: facilities
            }))
        }
    }

    let getAction = () => {
        if(type === 'service') {
            return api.updateServiceFacilities;
        } else if (type === 'partner') {
            return api.updatePartnerFacilities;
        } else if (type === 'resource') {
            return api.updateResourceFacilities;
        }
    }

    React.useEffect(() => {
        if(!facilities.length) {
            dispatch(fetchAllFacilities())
        }
    }, [dispatch])

    let getTitle = () => {
        return 'Wybierz obiekty';
    }

    return <SelectObjectsModal
        {...props}
        title={getTitle()}
        stateKey='facility'
        renderElement={(element) => element.name}
        action={getAction()}
        onUpdate={onUpdate}
    />
}

export default SelectFacilitiesModal;

