import React from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux'
import CustomFieldSelect from "../select/CustomFieldSelect";

let FacilityCustomFieldsField = ({loading, type}) => {

    let facilityType = useSelector(state => state.facilityType.byId)[type]

    if (loading) {
        return null;
    }
    return (facilityType?.custom_fields ?? []).map(customFieldId => <CustomFieldSelect customFieldId={customFieldId}/>)

}

export default FacilityCustomFieldsField