import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import api from "../../../core/api";
import {createAction} from "../../../core/utils/appUtil";
import {normalize} from "normalizr";
import schemas from "../../../reducers/schemas";
import handleError from "../../../core/utils/errorUtil";
import FacilityRentalForm from "../../../components/forms/app/facility/FacilityRentalForm";
import {FACILITY_DETAILS_SUCCESS} from "../../../reducers/facility";

let FacilityRentalPage = () => {

    let params = useParams();

    let facilityId = params.id

    let dispatch = useDispatch();

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        api.facility.get(facilityId)
            .then((response) => {
                dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(response.data, schemas.facilitySchema)));
                setLoading(false)
            })
            .catch(error => handleError(error, dispatch))
    }, [facilityId, dispatch])

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <FacilityRentalForm loading={loading} facilityId={facilityId}/>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityRentalPage
