import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let PartnerVisibleField = (props) => {

    return <SwitchInput
        name='visible'
        label='form.partner.visible'
        {...props}
        disabled={true}
    />

}

export default PartnerVisibleField