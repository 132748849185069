import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import FacilityDetailsForm from "../../../components/forms/app/facility/FacilityDetailsForm";
import {useDispatch} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";

let FacilityDetailsPage = () => {

    let params = useParams();

    let facilityId = params.id


    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <FacilityDetailsForm facilityId={facilityId}/>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityDetailsPage
