import React from 'react';
import update from 'immutability-helper';
import Image from "./Image";
import {useDispatch} from 'react-redux'
import {createAction} from "../../core/utils/appUtil";
import {GALLERY_EDITOR_SET_IMAGES} from "../../reducers/galleryEditor";

export const Container = ({images}) => {

    let dispatch = useDispatch()

    const moveImage = (dragIndex, hoverIndex) => {
        let newImages = update(images, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, images[dragIndex]],
            ],
        })
        dispatch(createAction(GALLERY_EDITOR_SET_IMAGES, newImages))
    }

    return images.map((image, index) => <Image key={index}
                                          index={index}
                                          moveImage={moveImage}/>)

}
