import React, {useState} from 'react';
import style from './TestInfo.module.scss'
import {MdClose} from "react-icons/md";

export default ({}) => {
    let [show, setShow] = useState(true)
    if(!show) {
        return null;
    }
    return <div className={style.testInfo}>
        <div>
        <span>
        To jest wersja demonstracyjna produktu, jeśli chcesz być poinformowany gdy system będzie gotowy lub masz do nas jakiekolwiek pytania skontaktuj się z nami korzystając z czatu lub wysyłając wiadomość na adres <a href='mailto:kontakt@bookendy.com'>kontakt@bookendy.com</a>
        </span>
            <MdClose size={30} onClick={() => setShow(false)}/>
        </div>
    </div>
}