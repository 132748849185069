import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllAmenities} from "../../../actions/amenity";
import {createAction} from "../../../core/utils/appUtil";
import {AMENITY_LIST_REQUEST, AMENITY_LIST_SUCCESS} from "../../../reducers/amenity";
import api from "../../../core/api";
import schemas from "../../../reducers/schemas";
import handleError from "../../../core/utils/errorUtil";
import {PAYMENT_METHOD_LIST_REQUEST, PAYMENT_METHOD_LIST_SUCCESS} from "../../../reducers/paymentMethod";
import {normalize} from 'normalizr';
import {useFormContext} from "react-hook-form";

let PaymentMethodSelect = (props) => {

    let {watch} = useFormContext()

    let dispatch = useDispatch();

    let {allIds, byId} = useSelector(state => state.paymentMethod)

    let selected = watch(props.name)

    allIds = allIds.filter(id => !byId[id].online || id === selected)

    React.useEffect(() => {
        dispatch(createAction(PAYMENT_METHOD_LIST_REQUEST));
        api.paymentMethod.list().then(response => {
            let normalizedData = normalize(response.data, [schemas.paymentMethodSchema]);
            dispatch(createAction(PAYMENT_METHOD_LIST_SUCCESS, normalizedData));
        }).catch(error => handleError(error, dispatch));
    }, [dispatch])

    return <ReduxSelect
        placeholder='asdf'
        stateKey='paymentMethod'
        data={allIds}
        autocomplete={false}
        {...props}
    />

}

export default PaymentMethodSelect;
