import {createAction} from "../core/utils/appUtil";
import * as apiLegacy from "../core/api";
import api from "../core/api";
import {toastSuccess} from "../core/utils/appUtil";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {
    PARTNER_DETAILS_REQUEST,
    PARTNER_DETAILS_SUCCESS,
    PARTNER_LIST_REQUEST,
    PARTNER_LIST_SUCCESS
} from "../reducers/partner";
import handleError from "../core/utils/errorUtil";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../reducers/openingHourForm";

export function fetchAllPartners(filters = {}, includes = []) {
    return function (dispatch) {
        dispatch(createAction(PARTNER_LIST_REQUEST));
        return api.partner.list(filters, includes)
            .then((response) => {
                dispatch(createAction(PARTNER_LIST_SUCCESS, normalize(response.data, [schemas.partnerSchema])));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchPartnerDetails(partnerId, includes = []) {
    return function (dispatch) {
        dispatch(createAction(PARTNER_DETAILS_REQUEST));
        return api.partner.get(partnerId, includes)
            .then(response => dispatch(createAction(PARTNER_DETAILS_SUCCESS, normalize(response.data, schemas.partnerSchema))))
            .catch(error => handleError(error, dispatch))
    };
}


export function openPartnerOpeningHoursPage(id) {
    return function (dispatch) {
        dispatch(createAction(OPENING_HOUR_FORM_RESET));
        return api.partner.get(id, ['openingHours'])
            .then(response => {
                let normalizedData = normalize(response.data, schemas.partnerSchema)
                let partner = normalizedData.entities.partner[normalizedData.result]
                let payload = {
                    availability_type: partner.availability_type,
                    entities: normalizedData.entities,
                    openingHours: partner.openingHours.map(openingHour => normalizedData.entities.openingHour[openingHour])
                }
                dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function sendAccountConfirmationMessage(partnerId, onSuccess = () => {}) {
    return function (dispatch) {
        return apiLegacy.createAccountInvitation(partnerId).then((response) => {
            toastSuccess('Wysłano zaproszenie do aplikacji')
            onSuccess()
        }).catch(error => handleError(error, dispatch));
    };
}