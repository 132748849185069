import React from "react";
import 'react-calendar/dist/Calendar.css';
import SelectInput from "./select/SelectInput";
import {formatTime} from "../utils/stringUtil";
import {timeToInt} from "../utils/appUtil";

const TimeSelect = (props) => {

    let {minTime = '00:00', maxTime = '23:55'} = props;

    let defaultTimes = [...Array(23 * 12 + 12).keys()].map(val => val * 5)

    let [times, setTimes] = React.useState(defaultTimes);

    React.useEffect(() => {
        setTimes(() => defaultTimes
            .filter(val => val >= timeToInt(minTime) && val <= timeToInt(maxTime))
        )
    }, [minTime, maxTime, JSON.stringify(defaultTimes)])

    let getOptions = () => {
        return times.map(time => {
            return {
                value: formatTime(time),
                label: formatTime(time)
            }
        })
    }

    return <SelectInput
        disableClearable={true}
        options={getOptions()}
        autocomplete={true}
        {...props}
    />
}

export default TimeSelect;

