import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from 'react-hook-form';
import api from "../../../../core/api";
import {createAction, toastError, toastSuccess} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import ReservationIntervalField from "../../business/ReservationIntervalField";
import MinTimeBeforeReservationField from "../../business/MinTimeBeforeReservationField";
import MaxReservationTimeInFutureField from "../../business/MaxReservationTimeInFutureField";
import MaxReservationChangeTimeField from "../../business/MaxReservationChangeTimeField";
import {BUSINESS_GET_DETAILS, BUSINESS_UPDATE_SUCCESS} from "../../../../reducers/business";
import {getCurrentBusiness} from "../../../../selectors";
import FormHeader from "../../../../core/form/FormHeader";
import ManualConfirmationField from "../../business/ManualConfirmationField";
import AdvancedResourcesField from "../../business/AdvancedResourcesField";
import BusinessSubdomainField from "../../business/BusinessSubdomainField";
import Card from "../../../../core/card/Card";
import CardBody from "../../../../core/card/CardBody";
import AppForm from "../../AppForm";
import SubmitButtonsFooter from "../../SubmitButtonsFooter";

let BusinessReservationSettingsForm = function () {

    let business = useSelector(getCurrentBusiness);

    let dispatch = useDispatch();

    let prepareData = data => {
        return {
            reservation_interval: data.reservation_interval,
            min_time_before_reservation: data.min_time_before_reservation,
            max_reservation_time_in_future: data.max_reservation_time_in_future,
            max_reservation_change_time: data.max_reservation_change_time,
            manual_confirmation: data.manual_confirmation,
            advanced_resources: data.advanced_resources,
            client_app_enabled: data.client_app_enabled,
            client_global_app_enabled: data.client_app_enabled ? data.client_global_app_enabled : null,
            subdomain: data.client_app_enabled ? data.subdomain : null,
        }
    }

    let getFormData = (business, address, logo) => {
        return {
            ...business
        }
    }

    const loadDefaultValues = async () => {
        setLoadingDefaultValues(true)
        return await api.business.get(business.id, ['logo', 'amenities', 'sports', 'address'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema);
                dispatch(createAction(BUSINESS_GET_DETAILS, normalizedData))
                setLoadingDefaultValues(false)
                return normalizedData.entities.business[normalizedData.result]
            }).catch(e => console.log(e))
    }

    let onSubmit = (data) => {
        let requestData = prepareData(data)
        api.business.update(business.id, requestData)
            .then((response) => {
                    let normalizedData = normalize(response.data.data, schemas.businessSchema);
                    dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalizedData))
                    let business = normalizedData.entities.business[normalizedData.result]
                    let address = normalizedData.entities.address[business.address]
                    let logo = business.logo ? normalizedData.entities.image[business.logo] : null
                    reset(getFormData(business, address, logo))
                    toastSuccess('Zapisano')
                }
            ).catch((e) => {
                console.log(e)
                toastError('Wystąpił błąd')
            }
        )
    }

    let formContext = useForm({defaultValues: loadDefaultValues})

    let {reset} = formContext

    let [loadingDefaultValues, setLoadingDefaultValues] = React.useState(true)
    return <AppForm formContext={formContext} onSubmit={onSubmit}>
        <Card>
            <CardBody>
                <FormHeader text='Ustawienia rezerwacji'/>
                <ReservationIntervalField loading={loadingDefaultValues}/>
                <MinTimeBeforeReservationField loading={loadingDefaultValues}/>
                <MaxReservationTimeInFutureField loading={loadingDefaultValues}/>
                <MaxReservationChangeTimeField loading={loadingDefaultValues}/>
                <ManualConfirmationField loading={loadingDefaultValues}/>
                <AdvancedResourcesField loading={loadingDefaultValues}/>
                <BusinessSubdomainField loading={loadingDefaultValues}/>
            </CardBody>
            <SubmitButtonsFooter/>
        </Card>
    </AppForm>
}

export default BusinessReservationSettingsForm