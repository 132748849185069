import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerPasswordField = ({label}) => {

    return <TextInput
        type='password'
        name='password'
        required={true}
        label={label ?? 'form.password'}
    />

}

export default PartnerPasswordField