import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import ClientFullnameField from "../../client/ClientFullnameField";
import ClientEmailField from "../../client/ClientEmailField";
import ClientPhoneField from "../../client/ClientPhoneField";
import {CLIENT_UPDATE_SUCCESS} from "../../../../reducers/clients";
import CardForm from "../../../../core/form/CardForm";

let ClientDetailsForm = function ({clientId}) {

    let client = useSelector(state => state.clients.byId[clientId])

    let loading = useSelector(state => state.clients.loadingDetails)

    let dispatch = useDispatch();

    let prepareData = (data) => {
        return {
            fullname: data.fullname,
            email: data.email,
            phone: data.phone
        }
    }

    return <CardForm
        data={client}
        prepareData={prepareData}
        title='Informacje podstawowe'
        getPromise={(id, data) => api.client.update(id, data)}
        onSubmit={response => dispatch(createAction(CLIENT_UPDATE_SUCCESS, normalize(response.data, schemas.clientSchema)))}>
        <ClientFullnameField loading={loading}/>
        <ClientEmailField loading={loading}/>
        <ClientPhoneField loading={loading}/>
    </CardForm>

}
export default ClientDetailsForm