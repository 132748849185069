import React from "react";
import {Row, Col} from 'reactstrap'
import AccountPage from "./AccountPage";
import AccountDetailsForm from "../../../components/forms/app/account/AccountDetailsForm";

let AccountDetailsPage = () => {

    return <AccountPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <AccountDetailsForm/>
            </Col>
        </Row>
    </AccountPage>

}

export default AccountDetailsPage
