import React from "react";
import TextInput from "../../../core/form/TextInput";
import {useFormContext} from 'react-hook-form'

let AddressField = ({loading = false, disabled = false}) => {

    let {watch} = useFormContext()

    let customAddress = watch('use_custom_address')

    return <TextInput
        name='address.address'
        namePrefix='address'
        label='form.address.address'
        required={true}
        loading={loading}
        disabled={disabled || customAddress === false}
    />

}

export default AddressField