import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {createAction} from "../../../../../core/utils/appUtil";
import { RESERVATION_MODAL_SHOW_RENTAL_CYCLE,
} from "../../../../../reducers/reservationModal";

let CycleInfo = ({cycleId}) => {
    let reservationCycle = useSelector(state => state.reservationCycle.byId[cycleId])
    let dispatch = useDispatch()
    if (!cycleId) {
        return <p>
            Powtarzaj: brak
        </p>
    }

    let days = reservationCycle.days_of_week.map(day => moment().day(day).format('dddd'))

    let showCycle = () => {
        dispatch(createAction(RESERVATION_MODAL_SHOW_RENTAL_CYCLE, reservationCycle?.id))
    }

    return <p>
        Powtarzaj:
        {reservationCycle?.interval_weeks === 1 ? ' co tydzień ' : ''}
        {reservationCycle?.interval_weeks === 2 ? ' co dwa tygodnie ' : ''}
        {'w każdy ' + days.join(',') + ' '}
        {' od ' + moment(reservationCycle?.start_at).format('YYYY-MM-DD')}
        {reservationCycle?.start_at ? (' do ' + moment(reservationCycle?.end_at).format('YYYY-MM-DD')) : ''}
        {reservationCycle?.recurrence_count ? (' w sumie ' + reservationCycle?.recurrence_count + ' rezerwacji.') : ''}
        <u onClick={showCycle}>Pokaż szczegóły cyklu</u>
    </p>
}

export default CycleInfo