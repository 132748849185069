import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let ManualConfirmationField = (props) => {

    return <SwitchInput
        name='manual_confirmation'
        label='form.business.manual_confirmation'
        {...props}
    />

}

export default ManualConfirmationField