import React from "react";
import CardForm from "./CardForm";
import {Row, Col} from 'reactstrap'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import Card from "../../core/card/Card";
import CardBody from "../../core/card/CardBody";

let CardFormWithSkeleton = function (props) {

    let {loading, title} = props;

    if (loading) {
        return <Card title={title}>
            <CardBody>
                {props.skeleton.map((row, rowKey) => <Row key={rowKey}>
                    {
                        row.map((column, columnKey) => <Col {...column} key={columnKey}>
                            {
                                Array.from(Array(column.elements).keys()).map((val, key) => <div key={key}
                                                                                          className='form-skeleton'>
                                        <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5"
                                                       className='facility'>
                                            <Skeleton height={48}/>
                                        </SkeletonTheme>
                                    </div>
                                )
                            }
                        </Col>)
                    }
                </Row>)}
            </CardBody>
        </Card>
    }

    return <CardForm {...props}/>
}

export default CardFormWithSkeleton