import React from "react";
import SelectInput from "../../../core/form/select/SelectInput";

let ReservationIntervalField = ({loading = false}) => {

    let options = [10, 15, 30, 60].map(val => {
        return {
            value: val,
            label: val + ' min.'
        }
    });

    return <SelectInput
        name='reservation_interval'
        label='form.business.reservation_interval'
        loading={loading}
        options={options}
    />

}

export default ReservationIntervalField