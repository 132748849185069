import React from "react";
import {useForm} from "react-hook-form";
import Button from "../../../../../core/buttons/Button";
import SubmitFormButton from "../../../../../core/buttons/SubmitFormButton";
import AppForm from "../../../../forms/AppForm";
import RentalDetailsForm from "../../RentalDetailsForm";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import TermForm from "./TermForm";
import TermListForm from "./TermListForm";
import SummaryForm from "./SummaryForm";
import api from "../../../../../core/api";
import {addMinutesToTime, createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {RESERVATION_ADD_SUCCESS} from "../../../../../reducers/reservation";
import schemas from "../../../../../reducers/schemas";
import {RESERVATION_MODAL_CLOSE} from "../../../../../reducers/reservationModal";
import {normalize} from 'normalizr';

let RentalCycleForm = () => {

    let dispatch = useDispatch()

    let [submitting, setSubmitting] = React.useState(false)

    let [step, setStep] = React.useState(1)

    let {
        date: selectedDate,
        resourceId
    } = useSelector(state => state.reservationModal)

    let facilityPart = useSelector(state => state.facilityPart.byId[resourceId])

    let facility = useSelector(state => state.facility.byId[resourceId])

    let formContext = useForm({
        defaultValues: {
            any_facility_part: true,
            rental_type: 'rental',
            starts_on: moment(selectedDate).format('Y-MM-DD'),
            days_of_week: [moment(selectedDate).day()],
            time_from: moment(selectedDate).format('HH:mm'),
            interval_weeks: 1,
            type: 'recurrence_count',
            recurrence_count: 10,
            lighting: false,
            terms: [],
            facility_id: facility?.id ? facility.id : (facilityPart?.facility_id ?? null),
        }
    })

    let terms = formContext.watch('terms')

    let submit = data => {
        if (step < 4) {
            setStep(step + 1)
            return;
        }
        setSubmitting(true)
        let formData = {
            client_id: data.client_id,
            service_id: data.service_id,
            price: data.price,
            facility_id: data.facility_id,
            lighting: data.lighting,
            days_of_week: data.days_of_week,
            starts_on: data.starts_on,
            ends_on: data.ends_on,
            type: data.type,
            time_from: data.time_from,
            time_to: addMinutesToTime(data.time_from, data.duration),
            recurrence_count: data.recurrence_count,
            interval_weeks: data.interval_weeks,
            terms: Object.keys(data.terms).map(date => {
                let slot = data.terms[date].slot
                return {
                    date: slot.date,
                    time_from: slot.time_from,
                    time_to: slot.time_to,
                    price: slot.price,
                    lighting_price: data.lighting ? slot.lighting_price : null
                }
            }),
            facility_part_id: data.any_facility_part ? null : data.facility_part_id,
            include: ['facilityParts']
        }
        api.reservationCycle.create(formData).then((response) => {
            dispatch(createAction(RESERVATION_ADD_SUCCESS, normalize(response.data, schemas.reservationCycleSchema)))
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            toastSuccess('Dodano rezerwację')
        }).catch(error => {
            toastError('Wystpił bład podczas dodawania rezerwacji!')
            setSubmitting(false)
        })
    }

    let cancel = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    let isButtonDisabled = step === 3 && Object.keys(terms).filter(term => terms[term].slot === null).length > 0

    return <AppForm onSubmit={submit} formContext={formContext}>
        <div style={{scroll: 'auto'}}>
            {step === 1 && <RentalDetailsForm/>}
            {step === 2 && <TermForm/>}
            <TermListForm display={step === 3}/>
            {step === 4 && <SummaryForm/>}
        </div>
        <div className='dialog-footer'>
            <div className='buttons'>
                <Button text='Wstecz' onClick={cancel} variant='secondary'/>
                <SubmitFormButton text={step === 4 ? 'Dodaj rezerwację' : 'Dalej'} disabled={isButtonDisabled} loading={submitting}/>
            </div>
        </div>
    </AppForm>
}

export default RentalCycleForm