import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import {setErrors} from "../../../../core/utils/formUtil";
import {resetPassword, sendResetPasswordLink} from "../../../../actions/auth";
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import PartnerRepeatPasswordField from "../../partner/PartnerRepeatPasswordField";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";

let ResetPasswordForm = ({id}) => {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false);

    let dispatch = useDispatch();
    let navigate = useNavigate();

    let intl = useIntl();

    let submit = async (data) => {
        setIsDuringSubmit(true);
        let result
        if(id) {
            data.type = 'partner';
            result = await resetPassword(id, data, navigate, dispatch);
        } else {
            result = await sendResetPasswordLink(data, dispatch, navigate);
        }
        setErrors(result, setError, intl);
        setIsDuringSubmit(false);
    }

    const {control, handleSubmit, setError} = useForm();

    function getButtonText() {
        if(id) {
            return 'Zresetuj hasło'
        }
        return 'Wyślij instrukcję resetu hasła'
    }

    return <Form onSubmit={handleSubmit(submit)}>
        <div>
            <p className='description'>
                Wprowadź adres email powiązany z twoim kontem, a my wyślemy wiadomość zawierającą <b>Instrukcję resetu hasła</b>.
            </p>
        </div>
        {!id && <PartnerEmailField />}
        {id && <PartnerPasswordField label='form.newpassword'/>}
        {id && <PartnerRepeatPasswordField/>}
        <SubmitFormButton loading={isDuringSubmit} text={getButtonText()}/>
    </Form>

}
export default ResetPasswordForm
