import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectPartnersModal from "../../../components/modals/SelectPartnersModal";
import {fetchAllPartners} from "../../../actions/partners";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";

let FacilityPartnersPage = () => {

    let params = useParams();

    let facilityId = params.id

    let facility = useSelector(state => state.facility.byId[facilityId])

    let loading = useSelector(state => state.facility.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchFacilityDetails(facilityId, ['partners', 'type.resourceTypes']))
        dispatch(fetchAllPartners())
    }, [facilityId, dispatch])

    let allPartners = useSelector(state => state.partner.byId)

    let [selectServiceModalOpened, setSelectServiceModalOpened] = React.useState(false)

    let hasPartners = facility?.partners && facility?.partners.length > 0

    return <FacilityPage facilityId={facilityId}>
        {selectServiceModalOpened && facility && <SelectPartnersModal
            modalOpened={selectServiceModalOpened}
            setModalOpened={setSelectServiceModalOpened}
            defaultSelected={facility?.partners}
            objectId={facility?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Pracownicy obiektu'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasPartners && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                facility?.partners && facility?.partners.map(partnerId => <tr>
                                    <td>
                                        {allPartners[partnerId].fullname}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz pracowników tego obiektu'
                                  variant='forward'
                                  Icon={MdKeyboardArrowRight}
                                  rightIcon={true}
                                  onClick={() => setSelectServiceModalOpened(() => true)}
                        />
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityPartnersPage
