import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessPage from "./BusinessPage";
import CardBody from "../../../core/card/CardBody";
import Card from "../../../core/card/Card";
import BusinessSubscription from "../../../components/business/BusinessSubscription";
import PaymentMethods from "../../../components/business/PaymentMethods";

let BusinessSubscriptionPage = () => {

    return <BusinessPage>
        <Row className='fitheight'>
            <Col lg={{size: 8}}>
                <Card title='Subskrypcje'>
                    <CardBody>
                        <BusinessSubscription/>
                    </CardBody>
                </Card>
            </Col>
            <Col lg={{size: 4}}>
                <Card title='Podpięte karty'>
                    <CardBody>
                        <PaymentMethods/>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessSubscriptionPage
