import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import CardForm from "../../../../core/form/CardForm";
import BusinessFacebookField from "../../business/BusinessFacebookField";
import BusinessInstagramField from "../../business/BusinessInstagramField";
import BusinessPhoneField from "../../business/BusinessPhoneField";
import {BUSINESS_UPDATE_SUCCESS} from "../../../../reducers/business";
import {getCurrentBusiness} from "../../../../selectors";
import BusinessWebsiteField from "../../business/BusinessWebsiteField";

let BusinessContactForm = function ({}) {
    let business = useSelector(getCurrentBusiness);

    let dispatch = useDispatch();
    let loading = false

    let prepareData = (data) => {
        return {
            website: data.website,
            phone: data.phone,
            facebook: data.facebook,
            instagram: data.instagram,
        }
    }

    return <CardForm
        clearFormOnSuccess={true}
        title='Kontakt'
        data={business}
        prepareData={prepareData}
        getPromise={(id, data) => api.business.update(id, data)}
        onSubmit={data => dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalize(data.data, schemas.businessSchema)))}
    >
        <BusinessPhoneField loading={loading}/>
        <BusinessFacebookField loading={loading}/>
        <BusinessInstagramField loading={loading}/>
        <BusinessWebsiteField/>
    </CardForm>

}
export default BusinessContactForm