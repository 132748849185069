import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import Button from "../../../core/buttons/Button";
import {fetchAllResources} from "../../../actions/resources";
import {FaPlus} from "react-icons/fa";
import ResourcesList from "../../../components/facility/ResourcesList";
import NewResourceModal from "../../../components/facility/NewResourceModal";
import FacilityPartList from "../../../components/facility/FacilityPartList";
import {getFacilityTypeText} from "../../../utils/facilityTypeUtil";

let FacilityPartsPage = () => {

    let params = useParams();

    let facilityId = params.id

    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchFacilityDetails(facilityId, ['parts', 'type']))
    }, [facilityId, dispatch])

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col className='list-page'>
                <Col lg={{size: 10, offset: 1}}>
                    <FacilityPartList facilityId={facilityId}/>
                </Col>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityPartsPage
