import React from "react";
import {useForm} from "react-hook-form";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {MdClose} from 'react-icons/md'
import {normalize} from 'normalizr';
import api from "../../core/api";
import schemas from "../../reducers/schemas";
import {createAction} from "../../core/utils/appUtil";
import {CREATOR_ADD_EMPLOYEE, CREATOR_UPDATE_EMPLOYEE} from "../../reducers/creator";
import {useDispatch} from 'react-redux';
import {setErrors} from "../../core/utils/formUtil";
import {useIntl} from 'react-intl';
import {toastSuccess} from "../../core/utils/appUtil";
import {useSelector} from 'react-redux'
import PartnerEmailField from "../forms/partner/PartnerEmailField";
import PartnerFullnameField from "../forms/partner/PartnerFullnameField";
import PartnerPhoneField from "../forms/partner/PartnerPhoneField";
import Button from "../../core/buttons/Button";
import SubmitFormButton from "../../core/buttons/SubmitFormButton";
import AppForm from "../forms/AppForm";

let EmployeeModal = ({isOpen, closeModal, partnerId}) => {

    let dispatch = useDispatch();

    let partner = useSelector(state => state.partner.byId[partnerId])

    let formContext = useForm({defaultValue: partner})

    const {setError} = formContext

    let createPartner = (formData) => {
        formData.attach_to_all_facilities = true;
        return api.partner.create(formData).then((response) => {
            let result = normalize(response.data, schemas.partnerSchema);
            dispatch(createAction(CREATOR_ADD_EMPLOYEE, result));
            return response.data;
        }).catch(error => {
            if (error?.response?.status === 422) {
                return error.response.data;
            }
        })
    }

    let updatePartner = (formData) => {
        return api.partner.update(partnerId, formData).then((response) => {
            let result = normalize(response.data, schemas.partnerSchema);
            dispatch(createAction(CREATOR_UPDATE_EMPLOYEE, result));
            return response.data;
        }).catch(error => {
            if (error?.response?.status === 422) {
                return error.response.data;
            }
        })
    }

    let [loading, setLoading] = React.useState(false);

    let intl = useIntl();

    let submit = async (data) => {
        setLoading(true);
        let result = partnerId ? await updatePartner(data) : await createPartner(data);
        setErrors(result, setError, intl);
        setLoading(false);
        if (result?.id) {
            closeModal();
            toastSuccess(partnerId ? 'Zapisano pracownika' : 'Dodano pracownika')
        }
    }

    return <Modal isOpen={isOpen} toggle={() => closeModal()}>
        <AppForm onSubmit={submit} formContext={formContext}>
            <ModalHeader>
                <span>{partnerId ? 'Edytuj' : 'Dodaj'} pracownika</span>
                <MdClose onClick={closeModal} className='close'/>
            </ModalHeader>
            <ModalBody>
                <PartnerFullnameField/>
                <PartnerPhoneField/>
                <PartnerEmailField/>
            </ModalBody>
            <ModalFooter>
                <Button text='Anuluj' onClick={() => closeModal()} variant='secondary'/>
                <SubmitFormButton loading={loading} text={partnerId ? 'Zapisz' : 'Dodaj'}/>
            </ModalFooter>
        </AppForm>
    </Modal>

}

export default EmployeeModal