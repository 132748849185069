import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {SPORT_LIST_REQUEST, SPORT_LIST_SUCCESS} from "../reducers/sport";

export function fetchAllSports() {
    return dispatch => {
        dispatch(createAction(SPORT_LIST_REQUEST));
        return api.sport.list().then(response => {
            let normalizedData = normalize(response.data, [schemas.sportSchema]);
            dispatch(createAction(SPORT_LIST_SUCCESS, normalizedData));
        }).catch(error => handleError(error, dispatch));
    }
}