import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import {ACCOUNT_UPDATE_SUCCESS} from "../../../../constants/actiontypes/auth";
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerFullnameField from "../../partner/PartnerFullnameField";
import PartnerPhoneField from "../../partner/PartnerPhoneField";
import CardForm from "../../../../core/form/CardForm";

let AccountDetailsForm = function () {

    let user = useSelector(state => state.partner.byId[state.auth.logged_user]);

    let dispatch = useDispatch();

    return <CardForm
        title='Informacje podstawowe'
        data={user}
        getPromise={(id, data) => api.partner.update(user.id, data)}
        onSubmit={data => dispatch(createAction(ACCOUNT_UPDATE_SUCCESS, data))}>
        <PartnerFullnameField/>
        <PartnerEmailField/>
        <PartnerPhoneField/>
    </CardForm>
}

export default AccountDetailsForm