import React from 'react';
import {injectIntl} from "react-intl";
import {getValidationMessage} from "../utils/validationUtil";
import TextField from '@mui/material/TextField';
import {useController} from "react-hook-form";
import {useIntl} from 'react-intl';
import {trans} from "../utils/translationUtil";
import FormWrapper from "./FormWrapper";
import moment from 'moment';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

let DateInput = ({
                     control,
                     name,
                     namePrefix = null,
                     label = '',
                     required = false,
                     defaultValue = '',
                     information = '',
                     validation = {},
                     loading = false,
                     minDate = null,
                     maxDate = null
                 }) => {

    let intl = useIntl();

    if (required) {
        validation.required = {
            value: true,
            message: getValidationMessage(intl, name, 'required')
        }
    }

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: namePrefix ? (namePrefix + '[' + name + ']') : name,
        control,
        rules: validation,
        defaultValue: defaultValue,
    });

    let errorMessage = error?.message;

    let [isOpen, setIsOpen] = React.useState(false);

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    return <FormWrapper information={information} errorMessage={errorMessage} extraClasses='date-input'>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                autoOk
                allowSameDateSelection={true}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                label={trans(intl, label)}
                value={moment(value)}
                onChange={(newValue) => {
                    onChange(moment(newValue.toISOString()).format('yyyy-MM-DD'))
                }}
                disableMaskedInput={true}
                inputFormat="yyyy-MM-dd"
                minDate={minDate}
                maxDate={maxDate}
                renderInput={(params) => {
                    let date = moment(params.inputProps.value).format('dddd, D MMMM Y')
                    return <TextField
                        {...params}
                        onClick={() => {
                            setIsOpen(true)
                            return false;
                        }}
                        inputProps={{
                            ...params.inputProps,
                            value: date,
                            onChange: () => {
                            }
                        }}
                    />
                }
                }
            />
        </LocalizationProvider>
    </FormWrapper>
}

export default injectIntl(DateInput);