import React from 'react';
import SignInForm from "../../components/forms/app/auth/SignInForm";
import {Link} from "react-router-dom";
import {BsPlusCircle} from 'react-icons/bs';
import {CgLogIn} from 'react-icons/cg';
import {ROUTE_SIGN_IN, ROUTE_SIGN_UP} from "../../routes/routeDefinitions";
import SocialLoginButton from "../../components/buttons/SocialLoginButton";

let SignIn = () => <div className='login-page'>
    <div className='buttons'>
        <Link to={ROUTE_SIGN_IN} className='active'>
            <CgLogIn/>
            Zaloguj się
        </Link>
        <Link to={ROUTE_SIGN_UP}>
            <BsPlusCircle/>
            Utwórz konto
        </Link>
    </div>
    <SignInForm/>
    {/*<div className='login-as'>*/}
    {/*    <span className='header'>*/}
    {/*        Zaloguj się za pomocą*/}
    {/*    </span>*/}
    {/*    <SocialLoginButton type='google'/>*/}
    {/*    <SocialLoginButton type='fb'/>*/}
    {/*</div>*/}
</div>

export default SignIn;