import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let ServiceStatusField = (props) => {

    return <SwitchInput
        name='enabled'
        label='form.service.enabled'
        {...props}
    />

}

export default ServiceStatusField