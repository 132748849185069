import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import FacilityContactForm from "../../../components/forms/app/facility/FacilityContactForm";

let FacilityContactPage = () => {

    let params = useParams();

    let facilityId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
            dispatch(fetchFacilityDetails(facilityId, ['type.resourceTypes']))
    }, [facilityId, dispatch])

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <FacilityContactForm facilityId={facilityId}/>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityContactPage
