import React, {useState} from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import Loader from "../../../../Loader";
import {getFacilityTypeText} from "../../../../../utils/facilityTypeUtil";
import moment from 'moment';

let SummaryForm = ({}) => {
    let formContext = useFormContext()

    let {watch} = formContext
    let facilityId = watch('facility_id');
    let serviceId = watch('service_id');
    let clientId = watch('client_id')
    let lighting = watch('lighting')
    let rentalType = watch('rental_type')
    let anyFacilityPart = watch('any_facility_part')
    let facilityPartId = watch('facility_part_id')
    let facilityPart = useSelector(state => state.facilityPart.byId[facilityPartId])
    let isPartialRental = rentalType === 'partial-rental'
    let client = useSelector(state => state.clients.byId[clientId])
    let facility = useSelector(state => state.facility.byId[facilityId])
    let facilityType = useSelector(state => state.facilityType.byId[facility.type_id])
    let service = useSelector(state => state.service.byId[serviceId])
    let terms = watch('terms')
    let totalPrice=  watch('price')

    let generateDay = day => {
        let term = terms[day]
        return <div className='d-flex flex-row term'>
            <div className='d-flex flex-row'>
                <div className='counter'>{term.index}.</div>
                <div className='date'>{moment(day).format('YYYY.MM.DD')}</div>
                <div className='time'> {term.slot.time_from} - {term.slot.time_to}</div>
            </div>
            <div className='price'>{term.slot.price} zł {lighting ? '(+' + term.slot.lighting_price + ' zł oświetlenie)' : ''}</div>
        </div>
    }
    return <div>
        <div>
            <div className='reservation-details-info'>
                <p><b>Usługa: </b>{service?.name}</p>
                {(isPartialRental && anyFacilityPart) && <p><b>{getFacilityTypeText(facilityType.code, 'part')}:</b> dowolny</p> }
                {(isPartialRental && !anyFacilityPart) && <p><b>{getFacilityTypeText(facilityType.code, 'part')}:</b> {facilityPart?.name}</p> }
                <p><b>Klient:</b> {client?.fullname}</p>
                {facility?.rental.lighting && <p><b>Dodatkowe oświetlenie:</b> {lighting ? 'TAK' : 'NIE'}</p>}
                <p><b>Cena:</b> {totalPrice} zł</p>
                <div className='cycle-term-list'>
                    <p style={{textAlign: 'center', fontWeight: 'bold'}}>Terminy rezerwacji:</p>
                    <div className='d-flex flex-column valid-terms'>
                        {
                            Object.keys(terms).filter(day => terms[day].slot !== null).map(day => generateDay(day))
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SummaryForm