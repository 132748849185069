import api from "../core/api";
import {createAction} from "../core/utils/appUtil";
import {
    CREATOR_SET_EMPLOYEE_LOADED,
    CREATOR_SET_SERVICES_LOADED
} from "../reducers/creator";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";

export function loadEmployees() {
    return dispatch => {
        return api.partner.list().then((response) => {
            let responseData = normalize(response.data, [schemas.partnerSchema]);
            dispatch(createAction(CREATOR_SET_EMPLOYEE_LOADED, responseData))
        })
    }
}

export function loadServices() {
    return dispatch => {
        return api.service.list().then((response) => {
            let responseData = normalize(response.data.filter(s => s.type !== 'rental'), [schemas.serviceSchema]);
            dispatch(createAction(CREATOR_SET_SERVICES_LOADED, responseData))
        })
    }
}