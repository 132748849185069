import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {MdClose} from 'react-icons/md'
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import {
    CREATOR_DELETE_SERVICE,
} from "../../reducers/creator";
import {useDispatch} from 'react-redux';
import {toastError, toastSuccess} from "../../core/utils/appUtil";
import {useSelector} from 'react-redux'
import Button from "../../core/buttons/Button";

let DeleteServiceModal = ({isOpen, closeModal, serviceId}) => {

    let dispatch = useDispatch();

    let service = useSelector(state => state.service.byId[serviceId])

    let deleteService = () => {
        return api.service.delete(serviceId).then(() => {
            dispatch(createAction(CREATOR_DELETE_SERVICE, serviceId));
            return true;
        }).catch(e => {
            return false;
        })
    }

    let [loading, setLoading] = React.useState(false);

    let submit = async () => {
        setLoading(true)
        let deleted = await deleteService()
        setLoading(false)
        if(deleted) {
            toastSuccess('Usunięto usługę')
        } else {
            toastError('Wystąpił błąd')
        }
        closeModal()
    }

    return <Modal isOpen={isOpen} toggle={() => closeModal()}>
        <ModalHeader>
            <span>Usuwanie usługi</span>
            <MdClose onClick={closeModal} className='close'/>
        </ModalHeader>
        <ModalBody>
            Czy na pewno chcesz usunąć usługę {service.name}?
        </ModalBody>
        <ModalFooter>
            <Button variant='secondary' text='Anuluj' onClick={() => closeModal()}/>
            <Button onClick={() => submit()} loading={loading} variant='danger' text='Usuń'/>
        </ModalFooter>
    </Modal>

}

export default DeleteServiceModal