export function getRequestParams(params, filters = {}, globalFilter = '') {
    let globalFilters = [];
    if(filters.facilities && filters.facilities.length > 0) {
        globalFilters.push({id: 'facility.uuid', value: filters.facilities});
    }
    if(filters.services && filters.services.length > 0) {
        globalFilters.push({id: 'service.uuid', value: filters.services});
    }
    if(filters.clients && filters.clients.length > 0) {
        globalFilters.push({id: 'client.uuid', value: filters.clients});
    }
    if(filters.partners && filters.partners.length > 0) {
        globalFilters.push({id: 'partners.uuid', value: filters.partners});
    }
    if(filters.source) {
        globalFilters.push({id: 'source', value: filters.source});
    }
    if(filters.number) {
        globalFilters.push({id: 'number', value: filters.number});
    }
    let requestParams = {};
    let sortBy = params.sortBy;
    if (sortBy && sortBy.length > 0) {
        requestParams.sort = (sortBy[0].desc ? '-' : '') + sortBy[0].id;
    }
    if (globalFilter) {
        requestParams['filter[search]'] = globalFilter;
    }
    if (params.filters) {
        params.filters.forEach(function (filter) {
            let value = filter.value;
            if(Array.isArray(value)) {
                value=value.join(',');
            }
            requestParams['filter[' + filter.id + ']'] = value;
        });
    }
    if (globalFilters) {
        globalFilters.forEach(function (filter) {
            let value = filter.value;
            if(Array.isArray(value)) {
                value=value.join(',');
            }
            requestParams['filter[' + filter.id + ']'] = value;
        });
    }
    requestParams.page = params.page + 1;
    requestParams.per_page = params.perPage;
    if(params.skippagination) {
        requestParams.skippagination = params.skippagination;
    }
    return requestParams;
}