import React from "react";
import {DAYS} from "../../../openinghours/Days";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import moment from "moment";

let OpeningHoursSkeleton = () => {

    return <table className='opening-hours-table table table-striped'>
        <tbody>
        {
            DAYS.map(day => <tr key={day}>
                <td width='82px'>
                    {moment().day(day).format('dd')}
                </td>
                <td>
                    <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5">
                        <Skeleton height={48}/>
                    </SkeletonTheme>
                </td>
                <td>
                    <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5">
                        <Skeleton height={48}/>
                    </SkeletonTheme>
                </td>
                <td>
                    <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5">
                        <Skeleton height={48}/>
                    </SkeletonTheme>
                </td>
            </tr>)
        }
        </tbody>
    </table>

}

export default OpeningHoursSkeleton