export let getFacilityTypeText = (facilityTypeCode, key) => {
    let texts = {
        'entire-facility': {
            'default': 'Cały obiekt',
            'sports-hall': 'Cała hala',
            'sport-field': 'Całe boisko',
            'gym': 'Cała sala'
        },
        'part': {
            'default': 'default text',
            'bowling-club': 'Tor do kręgli',
            'sports-hall': 'Sektor hali',
            'squash': 'Kort do squasha',
            'tennis-court': 'Kort do tenisa',
            'sport-field': 'Część boiska',
            'badminton': 'Kort do badmintona',
            'padel': 'Kort do padla',
            'table-tennis': 'Stół'
        },
        'parts': {
            'default': 'default text',
            'bowling-club': 'Tory do kręgli',
            'sports-hall': 'Sektory hali',
            'squash': 'Korty do squasha',
            'tennis-court': 'Korty do tenisa',
            'sport-field': 'Części boiska',
            'badminton': 'Korty do badmintona',
            'padel': 'Korty do padla',
            'table-tennis': 'Stoły'
        },
        'part-quantity': {
            'default': 'default text',
            'bowling-club': 'Ilość torów',
            'sports-hall': 'Ilość sektorów',
            'squash': 'Ilość kortów',
            'tennis-court': 'Ilość kortów',
            'sport-field': 'Ilość części',
            'badminton': 'Ilość kortów',
            'padel': 'Ilość kortów',
            'table-tennis': 'Ilość stołów'
        },
        'part-quantity-question': {
            'default': 'default text',
            'bowling-club': 'Ile posiadasz torów do kręgli?',
            'sports-hall': 'Ile posiadasz sektorów?',
            'squash': 'Ile posiadasz kortów?',
            'tennis-court': 'Ile posiadasz kortów?',
            'sport-field': 'Ile posiadasz części?',
            'badminton': 'Ile posiadasz kortów?',
            'padel': 'Ile posiadasz kortów?',
            'table-tennis': 'Ile posiadasz stołów?'
        },
        'part-question': {
            'default': 'default text',
            'sports-hall': 'Czy wynajmujesz hale na sektory?',
            'sport-field': 'Czy wynajmujesz boisko na części?',
        },
        'entire-object': {
            'default': 'default text',
            'sports-hall': 'Cała hala',
            'sport-field': 'Całe boisko'
        },
        'any-part': {
            'default': 'default text',
            'bowling-club': 'Dowolny tor',
            'sports-hall': 'Dowolny sektor',
            'squash': 'Dowolny kort',
            'tennis-court': 'Dowolny kort',
            'sport-field': 'Dowolna część',
            'badminton': 'Dowolny kort',
            'padel': 'Dowolny kort',
            'table-tennis': 'Dowolny stół'
        }
    }
    return texts[key][facilityTypeCode] ?? texts[key]['default']
}