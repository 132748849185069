import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_FACILITY_PART_DETAILS, ROUTE_FACILITY_PARTS
} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {setBreadcrumbs} from "../../../actions/navbar";
import {useDispatch, useSelector} from 'react-redux';

let FacilityPartPage = ({partId, facilityId, children}) => {

    let pages = [
        {
            name: 'Informacje',
            path: getRoutePath(ROUTE_FACILITY_PART_DETAILS, {facilityId, partId}),
        }
    ]

    let facility = useSelector(state => state.facility.byId[state.facilityPart.byId[partId]?.facility_id])

    let part = useSelector(state => state.facilityPart.byId[partId])

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setBreadcrumbs(facility?.name, getRoutePath(ROUTE_FACILITY_PARTS, facility?.id), part?.name))
    }, [dispatch, part?.name])

    return <ObjectPage pages={pages} name='resource'>
        {children}
    </ObjectPage>

}

export default FacilityPartPage