import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as apiLegacy from "../../../../core/api";
import api from "../../../../core/api";
import {createAction, hasPermissionTo, toastError} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerFullnameField from "../../partner/PartnerFullnameField";
import PartnerPhoneField from "../../partner/PartnerPhoneField";
import {Row, Col} from 'reactstrap'
import {BiEnvelope} from 'react-icons/bi'
import PartnerEnabledField from "../../partner/PartnerEnabledField";
import PartnerVisibleField from "../../partner/PartnerVisibleField";
import {PARTNER_INVITATION_SEND, PARTNER_UPDATE_SUCCESS} from "../../../../reducers/partner";
import PartnerRoleField from "../../partner/PartnerRoleField";
import DisabledTextField from "../../../../core/form/DisabledTextField";
import {toastSuccess} from "../../../../core/utils/appUtil";
import handleError from "../../../../core/utils/errorUtil";
import Button from "../../../../core/buttons/Button";
import CardFormWithSkeleton from "../../../../core/form/CardFormWithSkeleton";
import {PARTNER_INVITE} from "../../../../constants/permissions";
import {getLoggedUser} from "../../../../selectors";
import moment from 'moment';

let PartnerDetailsForm = function ({partnerId}) {

    let user = useSelector(getLoggedUser);

    let partner = useSelector(state => state.partner.byId[partnerId])

    let loading = useSelector(state => state.partner.loadingDetails)

    let dispatch = useDispatch();

    let [sendingAccountConfirmation, setSendingAccountConfirmation] = React.useState(false);

    function sendConfirmation() {
        setSendingAccountConfirmation(true)
        apiLegacy.createAccountInvitation(partner?.id).then((response) => {
            toastSuccess('Wysłano zaproszenie do aplikacji')
            setSendingAccountConfirmation(false)
            dispatch(createAction(PARTNER_INVITATION_SEND, normalize({
                ...partner,
                ...response.data
            }, schemas.partnerSchema)))
        }).catch(error => {
            if (error?.response?.status === 406) {
                toastError('Wystąpił błąd')
                setSendingAccountConfirmation(false)
                return;
            }
            handleError(error, dispatch)
        })
    }

    let canInvite = () => {
        if (partner?.invitation_accept_before === null) {
            return true;
        }
        return moment(partner?.invitation_accept_before).isBefore(moment());
    }

    let prepareData = (data) => {
        return {
            fullname: data.fullname,
            email: data.email,
            phone: data.phone,
            visible: data.visible,
            enabled: data.enabled,
            roles: [data.role_id]
        }
    }

    return <CardFormWithSkeleton
        loading={loading}
        skeleton={[
            [{lg: 6, elements: 4}, {lg: 6, elements: 4}]
        ]}
        data={{
            ...partner,
            role_id: partner?.roles?.length > 0 ? partner?.roles[0] : null
        }}
        prepareData={prepareData}
        title='Dane pracownika'
        getPromise={(id, data) => api.partner.update(id, {...data, include: ['address']})}
        onSubmit={data => dispatch(createAction(PARTNER_UPDATE_SUCCESS, normalize(data, schemas.partnerSchema)))}><Row>
        <Col lg={6}>
            <PartnerFullnameField loading={loading}/>
            <PartnerEmailField loading={loading}/>
            {hasPermissionTo(PARTNER_INVITE, user) &&
                <Button text='Wyślij zaproszenie do aplikacji' Icon={BiEnvelope} variant='forward' disabled={
                    (partner?.master_account || partner?.confirmed) || !canInvite()
                }
                        extraClasses='invite-partner-btn'
                        loading={sendingAccountConfirmation}
                        onClick={() => sendConfirmation()}
                />}
            {(
                !canInvite() && partner?.invitation_accept_before
            ) && <span style={{
                color: 'orange',
                fontSize: '12px'
            }}>Powiadomienie już wysłane, spróbuj ponownie po <b>{moment(partner.invitation_accept_before).format('YYYY-MM-DD HH:mm')}</b></span>}
        </Col>
        <Col lg={6}>
            <PartnerPhoneField loading={loading}/>
            {!partner?.master_account ?
                <PartnerRoleField loading={loading}/> : null}
            {partner?.master_account ?
                <DisabledTextField text='Właściciel' loading={loading} label='form.partner.role'/> : null}
            <div className='borderedcontainer color2'>
                <PartnerVisibleField loading={loading}/>
                <PartnerEnabledField loading={loading} disabled={partner?.master_account}/>
            </div>
        </Col>
    </Row>
    </CardFormWithSkeleton>

}
export default PartnerDetailsForm