import React, {useState} from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {
    editReservation,
    fetchReservations,
} from '../../actions/dashboard';
import {createAction, uniqueArray} from "../../core/utils/appUtil";
import {
    RESERVATION_STATUS_BACKGROUND_COLOR,
    RESERVATION_STATUS_COLOR, RESERVATION_STATUS_CONFIRMED,
    WAITING_FOR_BUSINESS_APPROVAL
} from "../../core/constants/reservationStatuses";
import DayHeader from '../../components/calendar/DayHeader';
import ReservationChangeConfirmationModal from "../modals/ReservationChangeConfirmationModal";
import {getCurrentBusiness} from "../../selectors";
import allLocales from '@fullcalendar/core/locales-all';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import WarningModal from "../modals/WarningModal";
import {
    RESERVATION_MODAL_EDIT_BLOCK,
    RESERVATION_MODAL_EDIT_RENTAL_RESERVATION, RESERVATION_MODAL_SHOW_RENTAL_RESERVATION
} from "../../reducers/reservationModal";
import {openReservationModal} from "../../actions/reservationModal.js";
import QueryString from 'query-string'
import {useLocation} from "react-router-dom";
import {DASHBOARD_SET_CURRENT_DATE} from "../../constants/actiontypes/dashboard";
import {getReservationStatus} from "../../actions/reservation";

let ReservationCalendar = ({calendarRef, view}) => {

    let currentDate = useSelector(state => state.dashboard.currentDate);

    let currentCalendarDate = moment(
        calendarRef?.current?.getApi().getDate()?.toISOString()
    ).format('YYYY-MM-DD');

    React.useEffect(() => {
            let newUrl = '/rezerwacje?type=' + view + '&date=' + currentCalendarDate
            window.history.pushState({path:newUrl},'',newUrl);
    }, [view, currentCalendarDate])

    let [time, setTime] = React.useState({});

    let [events, setEvents] = React.useState([]);

    let dispatch = useDispatch();

    let {byId: allReservations} = useSelector(state => state.reservation);

    let {byId: allBlocks} = useSelector(state => state.block);

    let {globalFilter, filters, facilities, blockIds} = useSelector(state => state.dashboard);

    let reservationIds = Object.keys(allReservations)

    let {filterOpen, loadingCalendar} = useSelector(state => state.dashboard)

    let allFacilities = useSelector(state => state.facility.byId);

    let allParts = useSelector(state => state.facilityPart.byId);

    let allServices = useSelector(state => state.service.byId);

    let [calendarResources, setCalendarResources] = React.useState([])

    let [invalidRentalTimeModalOpened, setInvalidRentalTimeModalOpened] = React.useState(false)

    let {
        min_time: minTime,
        max_time: maxTime,
        reservation_interval: reservationInterval
    } = useSelector(getCurrentBusiness)

    React.useEffect(() => {
        let calendarResources = []
        let order = 0;
        let myFacilities = facilities.map(facilityId => allFacilities[facilityId])
            .filter(facility => filters.facilities.length === 0 || filters.facilities.includes(facility.id));
        myFacilities.filter(facility => facility?.parts?.length === 0).forEach(facility => {
            calendarResources.push({id: facility.id, title: facility.name, order: order++})
        })
        myFacilities.filter(facility => facility?.parts?.length > 0).forEach(facility => {
            let resources = facility?.parts.map(partId => allParts[partId])
            resources.forEach(part => {
                calendarResources.push({
                    id: part.id,
                    title: facility.name + ' ' + part.name,
                    order: order++
                })
            })
        })
        console.log('c', calendarResources)
        setCalendarResources(calendarResources)
    }, [view, JSON.stringify(facilities)])

    let loadData = (time) => {
        if (time !== null) {
            setTime({
                time: {
                    startStr: moment(time.startStr).format('YYYY-MM-DD HH:mm'),
                    endStr: moment(time.endStr).format('YYYY-MM-DD HH:mm')
                }
            });
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (calendarRef.current && calendarRef.current.getApi) {
                calendarRef.current.getApi().updateSize();
            }
        }, 500)
    }, [filterOpen])

    React.useEffect(() => {
        if (time?.time && !loadingCalendar) {
            dispatch(fetchReservations(time.time.startStr, time.time.endStr, filters, globalFilter));
        }
    }, [time?.time?.startStr, time?.time?.endStr, filters, globalFilter, dispatch]);

    React.useEffect(() => {
        let reservationEvents = reservationIds.filter(r => {
            let reservation = allReservations[r]
            let isValidReservation = true
            if (filters.facilities.length > 0) {
                isValidReservation = isValidReservation && filters.facilities.includes(reservation.facility_id)
            }
            if (filters.services.length > 0) {
                isValidReservation = isValidReservation && filters.services.includes(reservation.service_id)
            }
            console.log(reservation.number, isValidReservation)
            return isValidReservation;
        }).map(reservationId => {
                let reservation = allReservations[reservationId]
                let facilityParts = reservation.facility_part_id ? [reservation.facility_part_id] : []
                let service = allServices[reservation.service_id]
                if (service?.required_facility) {
                    let facility = allFacilities[reservation.facility_id]
                    facilityParts.push(...facility?.parts)
                }
                return {
                    type: 'reservation',
                    id: reservation.id,
                    start: reservation.starttime,
                    end: reservation.endtime,
                    title: reservation.facility_name + ' ' + reservation.service_name,
                    reservation: reservation,
                    textColor: RESERVATION_STATUS_COLOR[getReservationStatus(reservation)],
                    backgroundColor: RESERVATION_STATUS_BACKGROUND_COLOR[getReservationStatus(reservation)],
                    borderColor: RESERVATION_STATUS_COLOR[getReservationStatus(reservation)],
                    // textColor: reservation.confirmed ? RESERVATION_STATUS_COLOR[RESERVATION_STATUS_CONFIRMED] : RESERVATION_STATUS_COLOR[WAITING_FOR_BUSINESS_APPROVAL],
                    // backgroundColor: reservation.confirmed ? RESERVATION_STATUS_BACKGROUND_COLOR[RESERVATION_STATUS_CONFIRMED] : RESERVATION_STATUS_BACKGROUND_COLOR[WAITING_FOR_BUSINESS_APPROVAL],
                    // borderColor: reservation.confirmed ? RESERVATION_STATUS_COLOR[RESERVATION_STATUS_CONFIRMED] : RESERVATION_STATUS_COLOR[WAITING_FOR_BUSINESS_APPROVAL],
                    resourceIds: [...facilityParts, reservation.facility_id]
                }
            }
        );
        let blockEvents = blockIds
            .filter(id => {
                let block = allBlocks[id]
                let isValidBlock = true
                if (filters.facilities.length > 0) {
                    isValidBlock = isValidBlock && filters.facilities.filter(value => block.facilities.includes(value)).length > 0
                }
                if (filters.partners.length > 0) {
                    isValidBlock = isValidBlock && filters.partners.filter(value => block.partners.includes(value)).length > 0
                }
                return isValidBlock;
            }).map(
                blockId => {
                    let block = allBlocks[blockId];
                    let resources = [];
                    if (block.business_block) {
                        resources.push(...Object.keys(allFacilities), ...Object.keys(allParts))
                    }
                    block.facilities.forEach(facilityId => {
                        let facility = allFacilities[facilityId]
                        resources.push(...facility.parts, facilityId)
                    });
                    resources.push(...block.facilityParts)
                    return {
                        type: 'block',
                        id: block.id,
                        start: block.starttime,
                        end: block.endtime,
                        block: block,
                        title: 'Blokada',
                        textColor: 'white',
                        backgroundColor: 'red',
                        borderColor: 'red',
                        resourceIds: uniqueArray(resources)
                    }
                }
            );
        setEvents([...reservationEvents, ...blockEvents]);
    }, [
        JSON.stringify(allReservations),
        JSON.stringify(reservationIds),
        JSON.stringify(blockIds),
        JSON.stringify(allBlocks),
    ])

    let editEvent = (info) => {
        if (info.event.extendedProps.type === 'block') {
            dispatch(createAction(RESERVATION_MODAL_EDIT_BLOCK, info.event.extendedProps.block.id));
        }
        if (info.event.extendedProps.type === 'reservation') {
            dispatch(createAction(RESERVATION_MODAL_SHOW_RENTAL_RESERVATION, info.event.extendedProps.reservation.id));
        }
    }

    let [reservationChangeModalOpened, setReservationChangeModalOpened] = useState(false);

    let [newReservationTerm, setNewReservationTerm] = useState({})

    let changeEvent = (data) => {
        let {event, oldEvent, revert} = data
        let reservation = allReservations[event.id.split('|')[0]]
        // let service = allServices[reservation.service_id]
        let end = moment(event.end);
        let start = moment(event.start)
        // let duration = moment.duration(end.diff(start)).asMinutes();
        // if (duration % service.duration !== 0) {
        //     setInvalidRentalTimeModalOpened(true)
        //     revert();
        //     return;
        // }
        setNewReservationTerm({
            reservation_id: event.id.split('|')[0],
            start: start.format('YYYY-MM-DD HH:mm'),
            end: end.format('YYYY-MM-DD HH:mm'),
            old_start: moment(oldEvent.start).format('YYYY-MM-DD HH:mm'),
            old_end: moment(oldEvent.end).format('YYYY-MM-DD HH:mm'),
            revert: data.revert
        })
        setReservationChangeModalOpened(() => true)
    }

    function getMinTime() {
        let time = minTime
        for (let i = 0; i < events.length; i++) {
            let reservationTime = moment(events[i].start).format('HH:mm:ss')
            if (reservationTime < time) {
                time = reservationTime
            }
        }
        return time
    }

    function getMaxTime() {
        let time = maxTime
        for (let i = 0; i < events.length; i++) {
            let reservationTime = moment(events[i].end).format('HH:mm:ss')
            if (reservationTime > time) {
                time = reservationTime
            }
        }
        return time
    }

    return (
        <div className='calendar' style={{backgroundColor: 'white'}}>
            {invalidRentalTimeModalOpened && <WarningModal
                setModalOpened={setInvalidRentalTimeModalOpened}
                modalOpened={invalidRentalTimeModalOpened}
                message='Wynajem musi trwać pełną godzinę lub jej wielokrotność, ponieważ długość usługi wynajmu jest ustawiona na jedną godzinę. '
            />}
            {reservationChangeModalOpened && <ReservationChangeConfirmationModal
                modalOpened={reservationChangeModalOpened}
                setModalOpened={setReservationChangeModalOpened}
                reservation_id={newReservationTerm?.reservation_id}
                start={newReservationTerm?.start}
                end={newReservationTerm?.end}
                old_start={newReservationTerm?.old_start}
                old_end={newReservationTerm?.old_end}
                revert={newReservationTerm?.revert}
            />}
            {view === 'resourceTimeGridDay' &&
                <div className='d-flex-justify pt-3'>
                    <DayHeader date={moment(currentCalendarDate)}/>
                </div>
            }
            <FullCalendar
                scrollTimeReset={false}
                nowIndicator={true}
                ref={calendarRef}
                events={events}
                datesSet={(time) => loadData(time)}
                themeSystem='standard'
                contentHeight='100%'
                expandRows={true}
                // firstDay={1}
                initialDate={new Date(currentDate)}
                firstDay={6}
                allDaySlot={false}
                eventContent={(event) => {
                    if (event.event.extendedProps.type === 'block') {
                        let block = event.event.extendedProps.block
                        return <div className='calendar-event'>
                            <div
                                className='time'>{moment(block.starttime).format('HH:mm')}-{moment(block.endtime).format('HH:mm')}</div>
                        </div>
                    }
                    let reservation = event.event.extendedProps.reservation
                    return <div className='calendar-event'>
                        <div
                            className='time'>{moment(reservation.starttime).format('HH:mm')}-{moment(reservation.endtime).format('HH:mm')}</div>
                        <div className='facility'>{reservation.facility_name}</div>
                        <div className='service'>{reservation.service_name}</div>
                    </div>
                }}
                customButtons={{
                    createBtn: {
                        text: '+ Dodaj',
                        click: () => dispatch(openReservationModal())
                    }
                }}
                buttonText={{
                    today: 'Dziś',
                    month: 'Miesiąc',
                    day: 'Dzień',
                    week: 'Tydzień'
                }}
                slotMinTime={getMinTime()}
                slotMaxTime={getMaxTime()}
                eventClick={editEvent}
                dateClick={(time) => {
                    dispatch(openReservationModal(moment(time.dateStr), time.resource?.id))
                }}
                slotDuration={'00:' + reservationInterval + ':00'}
                eventResizableFromStart={true}
                eventStartEditable={true}
                eventDurationEditable={false}
                droppable={true}
                schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                resourceOrder='order'
                resources={calendarResources}
                plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, resourceTimeGridPlugin, resourceTimelinePlugin]}
                eventChange={changeEvent}
                initialView="timeGridWeek"
                slotLabelFormat={[{hour: 'numeric', minute: 'numeric'}]}
                locale="pl"
                locales={allLocales}
                dayHeaderContent={(e) => <DayHeader date={moment(e.date)}/>}
            />
        </div>
    );
}
export default ReservationCalendar;
