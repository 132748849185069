import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";
import {PieChart} from '@mui/x-charts/PieChart';
import {BarChart} from '@mui/x-charts/BarChart';
import {LineChart} from '@mui/x-charts/LineChart';

let FacilityUsage = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('facility-usage', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }


    // let sum = data.reduce((partialSum, a) => partialSum + parseFloat(a.price), 0)
    // {
    //     data: [[1,2,3]],
    //         valueFormatter: (v) => v + ' PLN (' + (Math.round(v / sum * 100)) + '%)',
    // }
    //
    // let dataSet = [
    //     {
    //         "day": '2021-01-01',
    //         "1": 1,
    //         "2": 2,
    //     },
    //     {
    //         "day": '2021-01-02',
    //         "1": 1,
    //         "2": 2,
    //     },
    //     {
    //         "day": '2021-01-03',
    //         "1": 1,
    //         "2": 2,
    //     }
    // ]

    // const valueFormatter = (value) => `${value}mm`;

    const xLabels = [
        '2021-01-01',
        '2021-01-02',
        '2021-01-03',
        '2021-01-04',
    ];

    return <div>
        <LineChart
            xAxis={[{scaleType: 'point', data: data.labels}]}
            series={data.series}
            // series={[
            //     {
            //         label: 'Hala sportowa',
            //         data: [25, null, 34, 12],
            //     },
            //     {
            //         label: 'Korty tenisowe',
            //         data: [3, 3, 45, 4],
            //     },
            // ]}
            // width={500}
            height={300}
        />
        {/*<BarChart*/}
        {/*    dataset={dataSet}*/}
        {/*    xAxis={[{scaleType: 'band', dataKey: 'day'}]}*/}
        {/*    series={[*/}
        {/*        {dataKey: 'Hala sportowa', label: 'Hala sportowa', valueFormatter},*/}
        {/*        {dataKey: 'Korty tenisowe', label: 'Korty tenisowe', valueFormatter},*/}
        {/*    ]}*/}
        {/*    width={500}*/}
        {/*    height={300}*/}
        {/*/>*/}

        {/*<PieChart*/}
        {/*    series={[*/}
        {/*        {*/}
        {/*            arcLabel: () => '',*/}
        {/*            valueFormatter: (v) => v.value + ' PLN (' + (Math.round(v.percent/sumCount * 100)) + '%)',*/}
        {/*            data: data.map((row, key) => {*/}
        {/*                return { id: key, value: row.price, percent: row.count, label: row.facility}*/}
        {/*            }),*/}
        {/*        },*/}
        {/*    ]}*/}
        {/*    width={400}*/}
        {/*    height={200}*/}
        {/*/>*/}
    </div>
}

export default FacilityUsage