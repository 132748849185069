import React from "react";
import {useForm} from "react-hook-form";
import Button from "../../../../../core/buttons/Button";
import SubmitFormButton from "../../../../../core/buttons/SubmitFormButton";
import AppForm from "../../../../forms/AppForm";
import RentalDetailsForm from "../../RentalDetailsForm";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {normalize} from 'normalizr';
import {createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {
    RESERVATION_MODAL_CONFIRM_MODAL_HIDE,
    RESERVATION_MODAL_DELETE_MODAL_HIDE,
    RESERVATION_MODAL_SHOW_RENTAL_RESERVATION,
} from "../../../../../reducers/reservationModal";
import api from "../../../../../core/api";
import {RESERVATION_ADD_SUCCESS} from "../../../../../reducers/reservation";
import schemas from "../../../../../reducers/schemas";
import handleError from "../../../../../core/utils/errorUtil";
import Loader from "../../../../Loader";
import AppLoading from "../../../../AppLoading";
import {RESERVATION_CYCLE_FETCH_SUCCESS} from "../../../../../reducers/reservationCycle";
import {getFacilityTypeText} from "../../../../../utils/facilityTypeUtil";
import DeleteSingleRentalReservation from "../single/DeleteSingleRentalReservation";
import DeleteCycleRentalReservation from "./DeleteCycleRentalReservation";
import ConfirmCycleRentalReservation from "./ConfirmCycleRentalReservation";

let ShowRentalCycle = () => {

    let dispatch = useDispatch()

    let [step, setStep] = React.useState(1)

    let [loading, setLoading] = React.useState(false)

    let {
        date: selectedDate,
        reservationCycleId,
        deleteModalOpened,
        confirmModalOpened
    } = useSelector(state => state.reservationModal)

    React.useEffect(() => {
        setLoading(true);
        api.reservationCycle.get(reservationCycleId, ['reservations', 'facilityParts']).then((response) => {
            let normalizedData = normalize(response.data, schemas.reservationCycleSchema);
            dispatch(createAction(RESERVATION_CYCLE_FETCH_SUCCESS, normalizedData))
            setLoading(false)
        })
    }, [
        reservationCycleId
    ])

    let reservationCycle = useSelector(state => state.reservationCycle.byId[reservationCycleId])

    let client = useSelector(state => state.clients.byId[reservationCycle?.client_id])

    let facility = useSelector(state => state.facility.byId[reservationCycle?.facility_id])

    let facilityType = useSelector(state => state.facilityType.byId[facility?.type_id])

    let service = useSelector(state => state.service.byId[reservationCycle?.service_id])

    let facilityPartId = reservationCycle?.facilityParts?.length > 0 ? reservationCycle?.facilityParts[0] : null;

    let facilityPart = useSelector(state => state.facilityPart.byId[facilityPartId])

    let allReservations = useSelector(state => state.reservation.byId)

    if (loading) {
        return <AppLoading/>
    }

    let getIntervalWeeks = () => {
        if (reservationCycle.interval_weeks === 1) {
            return 'co tydzień'
        }
        return 'co ' + reservationCycle.interval_weeks + ' tygodnie';
    }

    let days = reservationCycle.days_of_week.map(day => moment().day(day).format('dddd'))

    let openReservation = reservationId => {
        dispatch(createAction(RESERVATION_MODAL_SHOW_RENTAL_RESERVATION, reservationId))
    }

    return <div className='content'>
        {deleteModalOpened && <DeleteCycleRentalReservation
            reservationCycleId={reservationCycleId}
            modalOpened={deleteModalOpened}
            setModalOpened={() => dispatch(createAction(RESERVATION_MODAL_DELETE_MODAL_HIDE))}/>}
        {confirmModalOpened && <ConfirmCycleRentalReservation
            reservationCycleId={reservationCycleId}
            modalOpened={confirmModalOpened}
            setModalOpened={() => dispatch(createAction(RESERVATION_MODAL_CONFIRM_MODAL_HIDE))}/>}
        <p>Od dnia: {moment(reservationCycle.starts_on).format('YYYY-MM-DD')}</p>
        <p>W godzinach: {reservationCycle.time_from} - {reservationCycle.time_to}</p>
        <p>Typ: {reservationCycle.ends_on ? ('kończy się dnia ' + moment(reservationCycle.ends_on).format('YYYY-MM-DD')) : ('kończy się po ' + reservationCycle.recurrence_count + ' rezerwacjach')}</p>
        <p>Powtarzanie: {getIntervalWeeks()}</p>
        <p>W dni: {days.join(',')}</p>
        <p>Cena: {reservationCycle.price} zł</p>
        <p>Klient: {client.fullname}</p>
        <p>Obiekt: {facility?.name}</p>
        <p>Typ
            rezerwacji: {getFacilityTypeText(facilityType?.code, service?.type === 'entire-rental' ? 'entire-facility' : 'part')}</p>
        {
            service?.type === 'partial-rental' &&
            <p>{getFacilityTypeText(facilityType?.code, 'part')}: {facilityPart ? facilityPart?.name : 'dowolny'}</p>
        }
        <p>Terminy:</p>
        <ul>
            {
                reservationCycle?.reservations?.map(reservationId => allReservations[reservationId]).map(reservation =>
                    <li onClick={() => openReservation(reservation.id)}>
                        <u>{moment(reservation.starttime).format('YYYY-MM-DD HH:mm')} - {moment(reservation.endtime).format('HH:mm')} Cena: {reservation.price} zł</u>
                    </li>)
            }
        </ul>
    </div>
}

export default ShowRentalCycle