import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {
    deleteFacilityImage, fetchFacilityImages, updateFacilityImages, uploadFacilityImage
} from "../../../actions/facilities";
import GalleryEditor from "../../../components/galleryeditor/GalleryEditor";
import GalleryEditorSkeleton from "../../../components/galleryeditor/GalleryEditorSkeleton";
import Card from "../../../core/card/Card";
import CardHeader from "../../../core/card/CardHeader";
import CardBody from "../../../core/card/CardBody";
import BusinessPage from "./BusinessPage";
import {getCurrentBusiness} from "../../../selectors";
import {
    deleteBusinessImage,
    fetchBusinessImages,
    updateBusinessImages,
    uploadBusinessImage
} from "../../../actions/business";

let BusinessGalleryPage = () => {

    let business = useSelector(getCurrentBusiness);
    let businessId = business?.id;
    let dispatch = useDispatch();

    let {loading, images} = useSelector(state => state.galleryEditor)

    useEffect(() => {
        dispatch(fetchBusinessImages(business?.id))
    }, [business?.id, dispatch])

    let uploadImage = (base64content, file) => {
        uploadBusinessImage(dispatch, base64content, file, businessId)
    }

    let saveImages = (images) => {
        updateBusinessImages(dispatch, businessId, images)
    }

    let deleteImage = (uuid) => deleteBusinessImage(dispatch, uuid, businessId)

    return <BusinessPage>
        <Row className='fitheight'>
            <Col xl={{size: 8, offset: 2}} lg={12}>
                <Card title='Zdjęcia'>
                    <CardHeader>
                        <span className='description'>Pierwsze zdjęcie będzie zdjęciem głównym. Przeciągaj zdjęcia na inne miejsca, aby zmienić ich kolejność. Akceptowane formaty to: jpg, jpeg, png.</span>
                    </CardHeader>
                    <CardBody>
                        {loading && <GalleryEditorSkeleton/>}
                        {!loading && <GalleryEditor
                            facilityId={businessId}
                            images={images}
                            onAdd={uploadImage}
                            onDelete={deleteImage}
                            onSubmit={saveImages}
                            submitButtons={true}
                        />}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessGalleryPage
