import {SERVICE_UPDATE_SUCCESS} from "./service";

var initialState = {
    service_id: null,
    loading: true,
    type: null,
    formChanged: false,
    value: null,
    from: null,
    to: null,
    editedVariant: null,
    modalOpened: false,
    variants: []
}

export const PRICE_FORM_RESET = 'PRICE_FORM_RESET'
export const PRICE_FORM_INIT = 'PRICE_FORM_INIT'
export const PRICE_FORM_SET_PRICE_TYPE = 'PRICE_FORM_SET_PRICE_TYPE'
export const PRICE_FORM_EDIT_VARIANT = 'PRICE_FORM_EDIT_VARIANT'
export const PRICE_FORM_ADD_VARIANT = 'PRICE_FORM_ADD_VARIANT'
export const PRICE_FORM_DELETE_VARIANT = 'PRICE_FORM_DELETE_VARIANT'
export const PRICE_FORM_UPDATE_VARIANT_SUCCESS = 'PRICE_FORM_UPDATE_VARIANT_SUCCESS'
export const PRICE_FORM_ADD_VARIANT_SUCCESS = 'PRICE_FORM_ADD_VARIANT_SUCCESS'
export const PRICE_FORM_CLOSE_MODAL = 'PRICE_FORM_CLOSE_MODAL'

export default (state = initialState, action) => {
    switch (action.type) {
        case SERVICE_UPDATE_SUCCESS: {
            return {
                ...state,
                formChanged: false
            }
        }
        case PRICE_FORM_RESET:
            return {
                ...state,
                loading: true,
                formChanged: false,
            }
        case PRICE_FORM_INIT:
            return {
                ...state,
                loading: false,
                formChanged: false,
                type: action.payload.type,
                value: action.payload.value,
                variants: action.payload.variants
            }
        case PRICE_FORM_SET_PRICE_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case PRICE_FORM_EDIT_VARIANT: {
            return {
                ...state,
                editedVariant: action.payload,
                modalOpened: true
            }
        }
        case PRICE_FORM_CLOSE_MODAL: {
            return {
                ...state,
                editedVariant: null,
                modalOpened: false
            }
        }
        case PRICE_FORM_ADD_VARIANT: {
            return {
                ...state,
                editedVariant: null,
                modalOpened: true
            }
        }
        case PRICE_FORM_ADD_VARIANT_SUCCESS: {
            return {
                ...state,
                variants: [
                    ...state.variants,
                    action.payload
                ],
                modalOpened: false,
                editedVariant: null,
                formChanged: true
            }
        }
        case PRICE_FORM_DELETE_VARIANT: {
            return {
                ...state,
                formChanged: true,
                variants: [
                    ...state.variants.slice(0, action.payload),
                    ...state.variants.slice(action.payload + 1)
                ]
            }
        }
        case PRICE_FORM_UPDATE_VARIANT_SUCCESS: {
            return {
                ...state,
                editedVariant: null,
                modalOpened: false,
                formChanged: true,
                variants: [
                    ...state.variants.slice(0, action.payload.index),
                    action.payload.data,
                    ...state.variants.slice(action.payload.index + 1)
                ]
            }
        }
        default:
            return state;
    }
};