import React from "react";
import {useDispatch} from "react-redux";
import {initPermissionPage} from "../../../actions/permission";
import PermissionsForm from "../../../components/forms/app/permission/PermissionsForm";
import {resetBreadcrumbs} from "../../../actions/navbar";

let PermissionPage = () => {

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(initPermissionPage())
        dispatch(resetBreadcrumbs())
    }, [dispatch])

    return <div className='list-page'>
        <div className='object-list'>
            <PermissionsForm/>
        </div>
    </div>

}

export default PermissionPage;

