import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_PARTNER_AVAILABILITY, ROUTE_PARTNER_FACILITIES,
    ROUTE_PARTNER_LIST,
    ROUTE_PARTNER_SERVICES,
    ROUTE_PARTNER_SHOW
} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import {useSelector} from 'react-redux';
import api from "../../../core/api";
import handleError from "../../../core/utils/errorUtil";
import {toastError, toastSuccess} from "../../../core/utils/appUtil";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setBreadcrumbs} from "../../../actions/navbar";

let PartnerPage = ({partnerId, children}) => {

    let pages = [
        {
            name: 'Dane pracownika',
            path: getRoutePath(ROUTE_PARTNER_SHOW, partnerId),
        },
        {
            name: 'Godziny pracy',
            path: getRoutePath(ROUTE_PARTNER_AVAILABILITY, partnerId),
        },
        // {
        //     name: 'Świadczone usługi',
        //     path: getRoutePath(ROUTE_PARTNER_SERVICES, partnerId),
        // },
        // {
        //     name: 'Obiekty pracownika',
        //     path: getRoutePath(ROUTE_PARTNER_FACILITIES, partnerId),
        // }
    ]

    let navigate = useNavigate()

    let dispatch = useDispatch();

    let partner = useSelector(state => state.partner.byId[partnerId])

    let [deletePartnerModal, setDeletePartnerModal] = React.useState(false)

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Twój zespół', getRoutePath(ROUTE_PARTNER_LIST)))
    }, [dispatch])

    React.useEffect(() => {
        if (partner?.id) {
            dispatch(setBreadcrumbs('Twój zespół', getRoutePath(ROUTE_PARTNER_LIST), partner.fullname))
        }
    }, [partnerId, dispatch, partner?.fullname, partner?.id])

    let deletePartner = () => {
        return api.partner.delete(partnerId).then((response) => {
            return true;
        }).catch(error => handleError(error, dispatch))
    }

    let submit = async () => {
        let result = await deletePartner();
        if(result) {
            toastSuccess('Usunięto konto partnera!')
            setDeletePartnerModal(() => false)
            navigate(ROUTE_PARTNER_LIST);
        } else {
            toastError('Wystąpił błąd!')
        }
    }

    let onRemovePartner = () => {
        if(!partner || partner.master_account) {
            return null;
        }
        return () => setDeletePartnerModal(true);
    }

    return <ObjectPage pages={pages} name='partner'
                       removeText='Usuń konto pracownika'
                       onRemove={onRemovePartner()}
    >
        <ConfirmationModal message='Czy na pewno chcesz usunąć konto pracownika?'
                           modalOpened={deletePartnerModal}
                           setModalOpened={setDeletePartnerModal}
                           submitAction={() => submit()}
        />
        {children}
    </ObjectPage>

}

export default PartnerPage
