import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import ClientPage from "./ClientPage";
import ClientDetailsForm from "../../../components/forms/app/client/ClientDetailsForm";
import {fetchClient} from "../../../actions/clients";

let ClientDetailsPage = () => {

    let params = useParams();

    let clientId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchClient(clientId))
    }, [clientId, dispatch])

    return <ClientPage clientId={clientId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <ClientDetailsForm clientId={clientId}/>
            </Col>
        </Row>
    </ClientPage>

}

export default ClientDetailsPage
