import React from 'react';
import {useMatch} from "react-router-dom";
import AccountConfirmationForm from "../../components/forms/app/account/AccountConfirmationForm";

let ConfirmAccount = (props) => {

    let match = useMatch();

    let code = match.params.code

    return <div className='login-page'>
        <p className='title'>Aktywacja konta</p>
        <AccountConfirmationForm code={code}/>
    </div>
}

export default ConfirmAccount;