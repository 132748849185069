import React from "react";
import TextInput from "../../../core/form/TextInput";

let ResourceDescriptionField = (props) => {

    return <TextInput
        name='description'
        label='form.resource.description'
        required={false}
        multiline={true}
        {...props}
    />

}

export default ResourceDescriptionField