import React, {useState, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import ReservationDateField from "../../../../forms/reservation/ReservationDateField";
import Loader from "../../../../Loader";
import api from "../../../../../core/api";
import SelectInput from "../../../../../core/form/select/SelectInput";
import SwitchInput from "../../../../../core/form/SwitchInput";
import TextInput from "../../../../../core/form/TextInput";
import PaymentMethodSelect from "../../../../forms/select/PaymentMethodSelect";

let RentalTermForm = () => {

    let [terms, setTerms] = useState([])

    let [loading, setLoading] = useState(false)

    let formContext = useFormContext()

    let {watch, setValue, getValues} = formContext

    let {
        any_facility_part: anyFacilityPart,
        facility_part_id: facilityPartId,
        service_id: serviceId
    } = getValues()

    let [date, timeFrom, duration, lighting] = watch(['date', 'time_from', 'duration', 'lighting']);

    let service = useSelector(state => state.service.byId[serviceId])

    let getCurrentTerm = () => {
        let filtered = terms.filter(term => term.duration === duration && term.time_from === timeFrom)
        return filtered.length > 0 ? filtered[0] : null
    }

    useEffect(() => {
        let currentTerm = getCurrentTerm()
        if (currentTerm) {
            setValue('time_to', currentTerm.time_to)
            setValue('price', currentTerm.price)
            if (lighting) {
                setValue('price', currentTerm.price + currentTerm.lighting_price)
            }
        }
    }, [timeFrom, lighting]);

    useEffect(() => {
        setLoading(true)
        api.service.getTerms(serviceId, {date_from: date, date_to: date}).then(r => {
            let terms = r.data.filter(term => term.date === date)[0].slots.filter(term => {
                if (service.required_facility_parts > 0) {
                    if (anyFacilityPart) {
                        return term.parts[Object.keys(term.parts)[0]]?.length > 0
                    } else {
                        return term.parts[Object.keys(term.parts)[0]]?.includes(facilityPartId)
                    }
                }
                return true;
            }) ?? []
            setTerms(terms)
            setLoading(false)
            let currentTerm = terms.filter(
                term => term.date === date
                    && term.duration === duration
                    && term.time_from === timeFrom
            )
            if (currentTerm.length === 0) {
                setValue('time_from', null)
                setValue('time_to', null)
                setValue('price', null)
            } else {
                setValue('time_from', currentTerm[0].time_from)
                setValue('time_to', currentTerm[0].time_to)
                setValue('price', currentTerm[0].price)
                if (lighting) {
                    setValue('price', currentTerm[0].price + currentTerm[0].lighting_price)
                }
            }
        })
    }, [date, serviceId])

    let customPrice = watch('custom_price')

    let paid = watch('paid')

    return <div>
        <div>
            {loading && <Loader/>}
            <SelectInput
                name='duration'
                label='Czas rezerwacji'
                disableClearable={true}
                autocomplete={false}
                options={service.price.times.map(time => {
                    return {
                        value: time,
                        label: time + ' min.'
                    }
                })}
            />
            <ReservationDateField/>
            <SelectInput
                name='time_from'
                label='Godzina'
                disableClearable={true}
                options={terms.filter(term => term.duration === duration)
                    .map(term => {
                        return {
                            value: term.time_from,
                            label: term.time_from + '-' + term.time_to + ' - ' + term.price + ' PLN'
                        }
                    })}
            />
            {
                (service.price.lighting && getCurrentTerm()) &&
                <SwitchInput name='lighting'
                             label={'Dodatkowe oświetlenie (+ ' + getCurrentTerm()?.lighting_price + ' PLN)'}/>
            }
            <SwitchInput name='custom_price' label={'Niestandardowa cena'}/>
            <TextInput name='price' label='Cena' disabled={!customPrice}/>
            <SwitchInput name='paid' label={'Rezerwacja opłacona'}/>
            {paid && <PaymentMethodSelect name='payment_method_id' label='Forma płatności' required={true}/>}

        </div>
    </div>
}

export default RentalTermForm