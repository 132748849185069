import {
    createDeleteRequest,
    createPostRequest,
    createPutRequest,
    createRequest,
    getApi,
    makeRequest,
    makeResourceRequests,
    prepareRequest
} from "../utils/apiUtil";

export const login = (email, password, type = 'client') => getApi().post('auth/login', {
    email: email,
    password: password,
    type: type
})
export const getAccount = () => getApi().get('auth/account')
export const loginPartner = (email, password) => login(email, password, 'partner')
export const register = (formData, type = 'client') => getApi().post('auth/register', {...formData, type})
export const registerPartner = (formData) => register(formData, 'partner')
export const resetPasswordLink = (formData) => makeRequest(createPostRequest('auth/resetPassword'), formData)
export const resetPassword = (id, formData) => makeRequest(createPutRequest('auth/resetPassword/:id'), formData, {id: id})
export const confirmAccount = (formData) => getApi().post('account/confirm', formData)
export const getServiceTerms = (serviceId, queryParams) => makeRequest(createRequest('service/' + serviceId + '/term'), prepareRequest({}, [], queryParams))

export function findPlace(text = '') {
    return makeRequest(createRequest('place'), prepareRequest({}, [], {search: text}));
}
export const updateFacilityServices = (uuid, services) => makeRequest(createPostRequest('facility/:id/service'), services, {'id': uuid})

export const updateFacilityResources = (uuid, services) => makeRequest(createPostRequest('facility/:id/resource'), services, {'id': uuid})

export const updateServiceResources = (uuid, services) => makeRequest(createPostRequest('service/:id/resource'), services, {'id': uuid})

export const updateServiceFacilities = (uuid, services) => makeRequest(createPostRequest('service/:id/facility'), services, {'id': uuid})

export const updateServicePartners = (uuid, services) => makeRequest(createPostRequest('service/:id/partner'), services, {'id': uuid})

export const updatePartnerFacilities = (uuid, services) => makeRequest(createPostRequest('partner/:id/facility'), services, {'id': uuid})

export const updateResourceFacilities = (uuid, services) => makeRequest(createPostRequest('resource/:id/facility'), services, {'id': uuid})
export const updatePartnerServices = (uuid, services) => makeRequest(createPostRequest('partner/:id/service'), services, {'id': uuid})

export const updateResourceServices = (uuid, services) => makeRequest(createPostRequest('resource/:id/service'), services, {'id': uuid})

export const updateFacilityPartners = (uuid, partners) => makeRequest(createPostRequest('facility/:id/partner'), partners, {'id': uuid})

export const updateFacilityOpeningHours = (openingHours, uuid) => makeRequest(createPostRequest('facility/:id/openingHour'), openingHours, {'id': uuid})

export const updatePartnerOpeningHours = (openingHours, uuid) => makeRequest(createPostRequest('partner/:id/openingHour'), openingHours, {'id': uuid})

export const updateResourceOpeningHours = (openingHours, uuid) => makeRequest(createPostRequest('resource/:id/openingHour'), openingHours, {'id': uuid})

export const addFacilityImage = (formData, facilityId) => {
    formData.facility_id = facilityId;
    return makeRequest(createPostRequest('facility/:facilityId/image'), formData, {facilityId});
}
export const updateFacilityGallery = (facilityId, images) => makeRequest(createPostRequest('facility/:facilityId/gallery'), images, {facilityId})

export const updatBusinessGallery = (businessId, images) => makeRequest(createPostRequest('business/:businessId/gallery'), images, {businessId})

export const deleteFacilityImage = (facilityId, imageId) => makeRequest(createDeleteRequest('facility/:facilityId/image/:imageId'), {}, {
    facilityId,
    imageId
})

export const addBusinessImage = (formData, businessId) => {
    formData.business_id = businessId;
    return makeRequest(createPostRequest('business/:businessId/image'), formData, {businessId});
}

export const deleteBusinessImage = (businessId, imageId) => makeRequest(createDeleteRequest('business/:businessId/image/:imageId'), {}, {
    businessId,
    imageId
})

export const refreshToken = () => makeRequest(createPostRequest('auth/refreshToken'))
export const updateServicePrice = (serviceId, formData) => makeRequest(createPostRequest('service/:id/price'), formData, {id: serviceId})
export const getBusiness = (filters = {}, includes = [], extraParams = {}) => makeRequest(createRequest('business'), prepareRequest(filters, includes, extraParams))
export const searchReservations = params => makeRequest(createRequest('reservation'), params)
export const updatePermissions = (formData) => makeRequest(createPostRequest('permission'), formData)
export const createAccountInvitation = (partnerId) => makeRequest(createPostRequest('auth/accountInvitation'), {partner_id: partnerId})
export const updateAccount = (formData) => makeRequest(createPostRequest('account'), formData)
export const updateProfile = (submitData) => getApi().post('account', submitData)
export const updateBusinessOpeningHours = (businessId, openingHours, includes = []) => makeRequest(createPostRequest('business/:id/openingHour'), openingHours, {'id': businessId})

export default {
    auth: {
        login,
        getAccount,
        loginPartner,
        register,
        registerPartner,
        resetPasswordLink,
        resetPassword,
        confirmAccount
    },
    block: makeResourceRequests('block'),
    role: makeResourceRequests('role'),
    permission: makeResourceRequests('permission'),
    client: makeResourceRequests('client'),
    facility: makeResourceRequests('facility'),
    facilityPart: makeResourceRequests('facilityPart'),
    facilityType: makeResourceRequests('facilityType'),
    business: {
        ...makeResourceRequests('business'),
        updateOpeningHours: updateBusinessOpeningHours
    },
    partner: makeResourceRequests('partner'),
    place: {
        find: findPlace
    },
    resourceType: makeResourceRequests('resourceType'),
    resource: makeResourceRequests('resource'),
    notification: makeResourceRequests('notification'),
    reservation: makeResourceRequests('reservation'),
    reservationCycle: makeResourceRequests('reservationCycle'),
    sport: makeResourceRequests('sport'),
    amenity: makeResourceRequests('amenity'),
    paymentMethod: makeResourceRequests('paymentMethod'),
    season: makeResourceRequests('season'),
    service: {
        ...makeResourceRequests('service'),
        getTerms: getServiceTerms,
    },
    payment: makeResourceRequests('payment'),
    tmpImage: makeResourceRequests('tmpImage'),
    language: makeResourceRequests('language'),
    chart: makeResourceRequests('chart'),
    activityLog: makeResourceRequests('activityLog'),
}