import {RESOURCE_UPDATE_SERVICES_SUCCESS} from "./resource";
import {SERVICE_UPDATE_SUCCESS} from "./service";
import _ from "lodash";

export const BLOCK_CREATE_REQUEST = 'BLOCK_CREATE_REQUEST';
export const BLOCK_CREATE_SUCCESS = 'BLOCK_CREATE_SUCCESS';
export const BLOCK_UPDATE_SUCCESS = 'BLOCK_UPDATE_SUCCESS';

var initialState = {
    byId: {},
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.block) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.block)
        }
    }
    switch (action.type) {
        default:
            return state;
    }
};