import React from "react";
import {useSelector} from 'react-redux';
import {RiEdit2Fill} from 'react-icons/ri'
import {Link} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_RESOURCE_SHOW} from "../../routes/routeDefinitions";
import Datatable from "../../core/components/datatable/Datatable";
import ServiceStatusColumn from "../../core/components/datatable/ServiceStatusColumn";
import ServiceVisibilityColumn from "../../core/components/datatable/ServiceVisibilityColumn";
import IconButton from "../../core/buttons/IconButton";
import ResourceName from "../resource/ResourceName";

let ResourcesList = ({facilityId, resourceTypeId}) => {

    let loading = useSelector(state => state.facility.loadingDetails)

    let facility = useSelector(state => state.facility.byId[facilityId])

    let allResources = useSelector(state => state.resource.byId)

    let resources = facility?.resources?.filter(resource => allResources[resource].type_id === resourceTypeId)

    let data = resources ? resources.map(resourceId => {
        let resource = allResources[resourceId]
        return [
            <td>
                <ResourceName resourceId={resourceId}/>
            </td>,
            <td><ServiceStatusColumn active={resource.enabled}/></td>,
            <td><ServiceVisibilityColumn active={resource.visible}/></td>,
            <td>
                <Link to={getRoutePath(ROUTE_RESOURCE_SHOW, resourceId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    }) : []

    return <Datatable
        loading={loading}
        columnHeaders={['Nazwa', 'Status', 'Widoczność', 'Edycja']}
        data={data}
    />
}

export default ResourcesList;

