import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";
import {PieChart} from '@mui/x-charts/PieChart';
import {BarChart} from '@mui/x-charts/BarChart';

let ReservationsPerDay = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('reservations-by-day', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result.data)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }


    let sum = data.reduce((partialSum, a) => partialSum + parseFloat(a.count), 0)

    return <div>
        <BarChart
            xAxis={[{scaleType: 'band', data: data.map((row, key) => row.day)}]}
            series={[{
                data: data.map(row => row.count),
                valueFormatter: (v) => v + ' (' + (Math.round(v / sum * 100)) + '%)',
            }]}
            // width={500}
            height={300}
        />

        {/*<PieChart*/}
        {/*    series={[*/}
        {/*        {*/}
        {/*            arcLabel: () => '',*/}
        {/*            valueFormatter: (v) => v.value + ' PLN (' + (Math.round(v.percent/sumCount * 100)) + '%)',*/}
        {/*            data: data.map((row, key) => {*/}
        {/*                return { id: key, value: row.price, percent: row.count, label: row.facility}*/}
        {/*            }),*/}
        {/*        },*/}
        {/*    ]}*/}
        {/*    width={400}*/}
        {/*    height={200}*/}
        {/*/>*/}
    </div>
}

export default ReservationsPerDay