import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FacilityNameField from "../../facility/FacilityNameField";
import api from "../../../../core/api";
import {createAction, toastError, toastSuccess} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {FACILITY_DETAILS_SUCCESS} from "../../../../reducers/facility";
import {normalize} from 'normalizr';
import FacilityDescriptionField from "../../facility/FacilityDescriptionField";
import FacilityClientReservationsField from "../../facility/FacilityClientReservationsField";
import FacilityVisibleInClientAppField from "../../facility/FacilityVisibleInClientAppField";
import FormHeader from "../../../../core/form/FormHeader";
import DisabledTextField from "../../../../core/form/DisabledTextField";
import FacilityCustomFieldsField from "../../facility/FacilityCustomFieldsField";
import Card from "../../../../core/card/Card";
import CardBody from "../../../../core/card/CardBody";
import AppForm from "../../AppForm";
import {useForm} from 'react-hook-form'
import SubmitButtonsFooter from "../../SubmitButtonsFooter";
import {mapCustomFieldFormData} from "../../../../utils/facilityUtil";

let FacilityDetailsForm = function ({facilityId}) {

    let facilityType = useSelector(state => state.facilityType.byId[state.facility.byId[facilityId]?.type_id])

    let dispatch = useDispatch()

    let [loadingDefaultValues, setLoadingDefaultValues] = useState(true)

    let getFormData = (facilityData, customFieldData) => {
        let customFields = {}
        if (customFieldData.length && facilityData.custom_field_values) {
            for (let i = 0; i < customFieldData.length; i++) {
                let customField = customFieldData[i]
                customFields['custom_fields[' + customField.id + ']'] = facilityData.custom_field_values
                    .filter(value => value.custom_field_id === customField.id)
                    .map(value => value.id)
            }
        }

        return {
            type_id: facilityData.type_id,
            name: facilityData.name,
            description: facilityData.description,
            client_reservations: facilityData.client_reservations,
            visible_in_client_app: facilityData.visible_in_client_app,
            ...customFields
        };
    }
    const loadDefaultValues = async () => {
        setLoadingDefaultValues(true)
        return await api.facility.get(facilityId, ['type.customFields.options', 'type.resourceTypes', 'parts'])
            .then(response => {
                dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(response.data, schemas.facilitySchema)))
                setLoadingDefaultValues(false)
                return getFormData(response.data, response.data.type.custom_fields)
            }).catch(e => console.log(e))
    }

    let formContext = useForm({defaultValues: loadDefaultValues})

    let {reset} = formContext

    let onSubmit = (formData) => {
        let requestData = {
            name: formData.name,
            description: formData.description,
            client_reservations: formData.client_reservations,
            visible_in_client_app: formData.visible_in_client_app,
            custom_fields: mapCustomFieldFormData(formData),
            include: ['type', 'customFieldValues', 'type.customFields.options', 'parts']
        }
        api.facility.update(facilityId, requestData)
            .then((response) => {
                    let normalizedData = normalize(response.data, schemas.facilitySchema)
                    dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalizedData))
                    reset(getFormData(response.data, response.data.type.custom_fields))
                    toastSuccess('Zapisano')
                }
            ).catch((e) => {
                console.log(e)
                toastError('Wystąpił błąd')
            }
        )
    }

    return <AppForm formContext={formContext} onSubmit={onSubmit}>
        <Card>
            <CardBody>
                <FormHeader text='Informacje podstawowe'/>
                <DisabledTextField text={facilityType?.name} loading={loadingDefaultValues} label='form.facility.type'/>
                <FacilityNameField loading={loadingDefaultValues}/>
                <FacilityDescriptionField loading={loadingDefaultValues}/>
                <FacilityCustomFieldsField loading={loadingDefaultValues} type={facilityType?.id}/>
                <FacilityVisibleInClientAppField/>
                <FacilityClientReservationsField/>
            </CardBody>
            <SubmitButtonsFooter/>
        </Card>
    </AppForm>
}
export default FacilityDetailsForm