import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import schemas from "../reducers/schemas";
import {
    LOAD_NOTIFICATIONS_REQUEST,
    LOAD_NOTIFICATIONS_SUCCESS
} from "../constants/actiontypes/notification";
import {normalize} from 'normalizr';
import handleError from "../core/utils/errorUtil";

export function fetchNotifications(page, perPage = 3) {
    return function (dispatch) {
        dispatch(createAction(LOAD_NOTIFICATIONS_REQUEST));
        api.notification.list({per_page: perPage, page: page, sort: '-created_at'}).then(response => {
            let normalizedNotifications = normalize(response.data.data, [schemas.notificationSchema]);
            normalizedNotifications.meta = response.data.meta;
            dispatch(createAction(LOAD_NOTIFICATIONS_SUCCESS, normalizedNotifications));
        }).catch(error => handleError(error, dispatch));
    }
}