import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_ACCOUNT_DETAILS, ROUTE_ACCOUNT_NOTIFICATIONS, ROUTE_ACCOUNT_PASSWORD, ROUTE_ACCOUNT_PREFERENCES
} from "../../../routes/routeDefinitions";

let AccountPage = ({children}) => {

    let pages = [
        {
            name: 'Informacje',
            path: ROUTE_ACCOUNT_DETAILS,
        },
        {
            name: 'Ustawienia powiadomień',
            path: ROUTE_ACCOUNT_NOTIFICATIONS,
        },
        // {
        //     name: 'Twoje preferencje',
        //     path: ROUTE_ACCOUNT_PREFERENCES,
        // },
        {
            name: 'Zmiana hasła',
            path: ROUTE_ACCOUNT_PASSWORD,
        }
    ]

    return <ObjectPage pages={pages} name='settings'>
        {children}
    </ObjectPage>

}

export default AccountPage
