import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import {
    CLIENT_DETAILS_REQUEST,
    CLIENT_DETAILS_SUCCESS,
    CLIENT_LIST_REQUEST,
    CLIENT_LIST_SUCCESS
} from "../reducers/clients";
import handleError from "../core/utils/errorUtil";


export function fetchClients(includes = [], page, perPage, searchText = '') {
    return function (dispatch) {
        dispatch(createAction(CLIENT_LIST_REQUEST));
        return api.client.list({
            search: searchText
        }, includes, {
            page: page,
            per_page: perPage
        })
            .then(function (response) {
                let normalizedUsers = normalize(response.data.data, [schemas.clientSchema]);
                normalizedUsers.meta = response.data.meta
                dispatch(createAction(CLIENT_LIST_SUCCESS, normalizedUsers));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchClient(clientId) {
    return function (dispatch) {
        dispatch(createAction(CLIENT_DETAILS_REQUEST));
        return api.client.get(clientId)
            .then(response => dispatch(createAction(CLIENT_DETAILS_SUCCESS, normalize(response.data.data, schemas.clientSchema))))
            .catch(error => handleError(error, dispatch))
    };
}