import React from "react";
import HourRow from "./HourRow";
import {DAY_LABELS} from "./Days";
import Switch from '@mui/material/Switch';
import {useIntl} from 'react-intl';
import {trans} from "../../core/utils/translationUtil";
import {increaseTime, pad} from "../../core/utils/appUtil";
import {useSelector, useDispatch} from 'react-redux'
import {createAction} from "../../core/utils/appUtil";
import moment from 'moment';
import {
    OPENING_HOUR_FORM_ADD,
    OPENING_HOUR_FORM_REMOVE,
    OPENING_HOUR_FORM_SET_ENABLED_DAY
} from "../../reducers/openingHourForm";

const Day = ({day, readonly = false}) => {

    let dispatch = useDispatch()

    let {
        enabled,
        hours
    } = useSelector(state => state.openingHourForm.days[day])

    let onAdd = () => {
        let lastHour = hours[hours.length - 1].time_to
        dispatch(createAction(OPENING_HOUR_FORM_ADD, {
            day,
            timeFrom: lastHour,
            timeTo: increaseTime(lastHour)
        }))
    }

    let onRemove = (index) => {
        dispatch(createAction(OPENING_HOUR_FORM_REMOVE, {
            day,
            index
        }))
    }

    let setEnabled = enabled => dispatch(createAction(OPENING_HOUR_FORM_SET_ENABLED_DAY, {
        day, enabled
    }))

    let sumHours = enabled ? hours.map(o => (o.time_to.hour * 60 + o.time_to.minute) - (o.time_from.hour * 60 + o.time_from.minute))
        .reduce((partial_sum, a) => partial_sum + a, 0) : 0

    let canAdd = key => {
        if(readonly || !enabled || hours.length === 0) {
            return false;
        }
        let lastHour = hours[hours.length - 1].time_to
        if (lastHour.hour === 23 && lastHour.minute === 55) {
            return false
        }
        return key === hours.length - 1
    }

    let canRemove = key => {
        if (!enabled || readonly) {
            return false
        }
        return enabled && hours.length > 1
    }

    return <tr>
        <td>
            {moment().day(day).format('dd')}
        </td>
        <td>
            <Switch checked={enabled} disabled={readonly} onChange={e => setEnabled(e.target.checked)}/>
        </td>
        <td>
            {
                hours.map((o, key) => <HourRow
                    data={o}
                    day={day}
                    onRemove={() => onRemove(key)}
                    disabled={!enabled || readonly}
                    canAdd={canAdd(key)}
                    canRemove={canRemove(key)}
                    onAdd={onAdd}
                    key={key}
                    index={key}
                />)
            }
        </td>
        <td>
            <span>
                {Math.floor(sumHours / 60)}{sumHours % 60 === 0 ? '' : ':' + pad(sumHours % 60, 2)}h
            </span>
        </td>
    </tr>
}

export default Day;
