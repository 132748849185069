import React from "react";
import ResourceTypeSelect from "../select/ResourceTypeSelect";

let ResourceTypeField = ({loading = false}) => {

    return <ResourceTypeSelect
        label='form.resource.type'
        required={true}
        loading={loading}
    />
}

export default ResourceTypeField