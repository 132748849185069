import React from "react";
import {BrowserRouter, Route, Routes as CoreRoutes} from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";

import Page404 from "../pages/auth/Page404";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import routes from './dashboard';
import ConfirmAccount from "../pages/auth/ConfirmAccount";
import ResetPassword from "../pages/auth/ResetPassword";
import * as routeDefinitions from './routeDefinitions'
import DashboardPage from "../pages/DashboardPage";
import LoginAs from "../pages/auth/LoginAs";
import CalendarPage from "../pages/dashboard/CalendarPage";

const Routes = () => {
    return <BrowserRouter>
        <CoreRoutes>
            <Route path={routeDefinitions.ROUTE_SIGN_IN} element={<AuthLayout><SignIn/></AuthLayout>}/>
            <Route path={routeDefinitions.ROUTE_SIGN_UP} element={<AuthLayout><SignUp/></AuthLayout>}/>
            <Route path={routeDefinitions.ROUTE_LOGIN_AS} element={<AuthLayout><LoginAs/></AuthLayout>}/>
            <Route path={routeDefinitions.ROUTE_CONFIRM_ACCOUNT} element={<AuthLayout skipredirect={true}><ConfirmAccount/></AuthLayout>}/>
            <Route path={routeDefinitions.ROUTE_RESET_PASSWORD} element={<AuthLayout skipredirect={true}><ResetPassword/></AuthLayout>}/>
            {routes.map(
                r => <Route
                        path={r.route}
                        exact
                        element={<DashboardLayout>{r.render()}</DashboardLayout>}
                        key={r.route}
                    />)
            }
            {/*<Route path={routeDefinitions.ROUTE_RESERVATIONS} element={<DashboardLayout extraClasses='dashboard-page' skipNavbar={true}><CalendarPage/></DashboardLayout>}/>*/}
            <Route path={routeDefinitions.ROUTE_RESERVATIONS} element={<CalendarPage/>}/>
            <Route path={routeDefinitions.ROUTE_DASHBOARD} element={<DashboardLayout><DashboardPage/></DashboardLayout>}/>
            <Route element={ <AuthLayout><Page404/></AuthLayout>}/>
        </CoreRoutes>
    </BrowserRouter>
}

export default Routes;
