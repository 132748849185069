import React from "react";

const AvatarColumn = ({name, id}) => {
    let elements = name?.split(' ');

    let colors = [
        '#df86fd', '#d27b4e', '#d2ce7f', '#1ACBAA', '#6f42c1', '#d39e00', '#bb86fc'
    ];

    let getNumber = () => parseInt(`0${id.replace(/-/g, "")}`, 16) % colors.length

    let text = '';
    if (elements.length === 1) {
        text = elements[0][0].toUpperCase();
    } else {
        text = (elements[0][0] + elements[1][0]).toUpperCase();
    }

    let color = colors[getNumber()]

    return <div className='avatar' style={{backgroundColor: color}}>
        <span>{text}</span>
    </div>;
}

export default AvatarColumn;
