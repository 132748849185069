import React from "react";
import {useSelector} from "react-redux";

let ResourceName = ({resourceId}) => {

    let resource = useSelector(state => state.resource.byId[resourceId])

    return resource.name
}

export default ResourceName