import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {MdClose} from 'react-icons/md'
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import {
    CREATOR_DELETE_EMPLOYEE,
} from "../../reducers/creator";
import {useDispatch} from 'react-redux';
import {toastError, toastSuccess} from "../../core/utils/appUtil";
import {useSelector} from 'react-redux'
import Button from "../../core/buttons/Button";

let DeleteEmployeeModal = ({isOpen, closeModal, partnerId}) => {

    let dispatch = useDispatch();

    let partner = useSelector(state => state.partner.byId[partnerId])

    let deletePartner = () => {
        return api.partner.delete(partnerId).then(() => {
            dispatch(createAction(CREATOR_DELETE_EMPLOYEE, partnerId));
            return true;
        }).catch(e => {
            return false;
        })
    }

    let [loading, setLoading] = React.useState(false);

    let submit = async () => {
        setLoading(true)
        let deleted = await deletePartner()
        setLoading(false)
        if(deleted) {
            toastSuccess('Usunięto pracownika')
        } else {
            toastError('Wystąpił błąd')
        }
        closeModal()
    }

    return <Modal isOpen={isOpen} toggle={() => closeModal()}>
        <ModalHeader>
            <span>Usuwanie pracownika</span>
            <MdClose onClick={closeModal} className='close'/>
        </ModalHeader>
        <ModalBody>
            Czy na pewno chcesz usunąć pracownika {partner.fullname} ({partner.email})?
        </ModalBody>
        <ModalFooter>
            <Button text='Anuluj' onClick={() => closeModal()}/>
            <Button onClick={() => submit()} loading={loading} text='Usuń'/>
        </ModalFooter>
    </Modal>

}

export default DeleteEmployeeModal