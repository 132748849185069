import _ from "lodash";
export const RESERVATION_CYCLE_FETCH_SUCCESS = 'RESERVATION_CYCLE_FETCH_SUCCESS';
export const RESERVATION_CYCLE_ADD_SUCCESS = 'RESERVATION_CYCLE_ADD_SUCCESS';
export const RESERVATION_CYCLE_UPDATE_SUCCESS = 'RESERVATION_CYCLE_UPDATE_SUCCESS';
export const RESERVATION_CYCLE_DELETE_SUCCESS = 'RESERVATION_CYCLE_DELETE_SUCCESS';

let initialState = {
    byId: {},
    allIds: []
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.reservationCycle) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.reservationCycle)
        }
    }
    switch (action.type) {
        case RESERVATION_CYCLE_DELETE_SUCCESS: {
            return {
                ...state,
                allIds: state.allIds.filter(id => id !== action.payload),
                byId: _.omit(state.byId, [action.payload])
            }
        }
        default:
            return state;
    }
};