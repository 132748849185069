import React from "react";
import FacilityTypeSelect from "../select/FacilityTypeSelect";
import SportsSelect from "../select/SportsSelect";
import AmenitySelect from "../select/AmenitySelect";

let BusinessAmenitiesField = ({loading = false}) => {

    return <AmenitySelect
        name='amenities'
        label='Wybierz udogodnienia'
        required={false}
        isMulti={true}
        loading={loading}
    />

}

export default BusinessAmenitiesField