import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllSports} from "../../../actions/sports";

let CustomFieldSelect = (props) => {

    let customFieldId = props.customFieldId

    let customFields = useSelector(state =>state.customField.byId)

    let customField = useSelector(state =>state.customField.byId[customFieldId])

    return <ReduxSelect
        required={true}
        name={'custom_fields[' + customFieldId + ']'}
        label={customField?.name}
        placeholder='Wybierz'
        optionLabel='value'
        stateKey={'customFieldOption'}
        data={customFields[customFieldId]?.options ?? []}
        isMulti={customField.is_multi}
        autocomplete={false}
        {...props}
    />

}

export default CustomFieldSelect;
