import React from "react";
import {useFormContext} from "react-hook-form";
import ReservationClientField from "../../forms/reservation/ReservationClientField";
import {useSelector} from 'react-redux';
import ClientModal from "../../client/ClientModal";
import {FaPlus, FaEdit} from 'react-icons/fa'
import Button from "../../../core/buttons/Button";
import FacilitySelect from "../../forms/select/FacilitySelect";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FacilityPartSelect from "../../forms/select/FacilityPartSelect";
import SwitchInput from "../../../core/form/SwitchInput";
import {getFacilityTypeText} from "../../../utils/facilityTypeUtil";

let RentalReservationForm = () => {

    let formContext = useFormContext()

    let {watch, setValue} = formContext

    let facilityId = watch('facility_id');

    let anyFacilityPart = watch('any_facility_part');

    let facility = useSelector(state => state.facility.byId[facilityId])

    let [newClientModalOpened, setNewClientModalOpened] = React.useState()

    let facilityType = useSelector(state => state.facilityType.byId[facility?.type_id])

    let addClientSuccess = client => {
        setValue('client_id', client.id)
        setNewClientModalOpened(() => false)
    }

    let rentalType = watch('rental_type')

    React.useEffect(() => {
        if(anyFacilityPart) {
            setValue('facility_part_id', null)
        }
    }, [anyFacilityPart])

    React.useEffect(() => {
        if (facility?.rental.partial && !facility?.rental.entire) {
            setValue('service_id', facility?.rental?.partial_service_id)
            setValue('rental_type', 'partial-rental')
        } else if (!facility?.rental.partial && facility?.rental.entire) {
            setValue('service_id', facility?.rental?.entire_service_id)
            setValue('rental_type', 'rental')
        } else if (facility?.rental?.partial && facility?.rental?.entire) {
            setValue('service_id', rentalType === 'rental' ? facility?.rental?.entire_service_id : facility?.rental?.partial_service_id)
        }
        setValue('any_facility_part', true)
        setValue('facility_part_id', null)
        setValue('duration', facility?.rental.times[0])
    }, [facilityId, rentalType])

    React.useEffect(() => {
        setValue('lighting', false)
    }, [facilityId])

    return <>
        {newClientModalOpened && <ClientModal
            isOpen={newClientModalOpened}
            closeModal={() => setNewClientModalOpened(() => false)}
            redirectToClientPage={false} onSuccessSave={addClientSuccess}
        />}
        <FacilitySelect label='Obiekt' includes={['parts', 'services', 'type']}/>
        {
            (facilityType?.rental.partial && facilityType?.rental.entire && facility?.rental.partial) &&
            <RadioGroup defaultValue="rental" onChange={(e) => setValue('rental_type', e.target.value)} name="rental_type">
                <FormControlLabel value="rental" control={<Radio/>} label={getFacilityTypeText(facilityType?.code, 'entire-facility')}/>
                <FormControlLabel value="partial-rental" control={<Radio/>} label={getFacilityTypeText(facilityType?.code, 'part')}/>
            </RadioGroup>
        }
        {
            (facility?.rental.partial && rentalType === 'partial-rental') &&
            <SwitchInput name='any_facility_part' label={getFacilityTypeText(facilityType?.code, 'any-part')}/>
        }
        {
            (facility?.rental.partial && rentalType === 'partial-rental' && !anyFacilityPart) &&
            <FacilityPartSelect required={true} isMulti={false} data={facility?.parts}/>
        }
        <ReservationClientField/>
        <Button text='app.add_new_client' Icon={FaPlus}
                onClick={() => setNewClientModalOpened(() => true)}
                extraClasses='add-client-btn'/>
    </>
}

export default RentalReservationForm