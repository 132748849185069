import React from "react";
import {Navigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logout} from "../actions/auth";
import * as routes from '../routes/routeDefinitions';
const Logout = () => {

    let dispatch = useDispatch();

    dispatch(logout());

    return <Navigate  to={routes.ROUTE_DASHBOARD} />;
};

export default Logout;
