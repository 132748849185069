import {
    APP_LOADED,
    SHOW_ERROR,
    HIDE_ERROR,
    APP_CHANGE_LANGUAGE,
    DISABLE_CREATOR
} from "../constants/actiontypes/app";

var initialState = {
    languages: [],
    current_language: {
        code: 'pl',
        name: 'Polish'
    },
    is_loading: true,
    error: null,
    creator: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case APP_LOADED:
            return {
                ...state,
                is_loading: false
            }
        case SHOW_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case HIDE_ERROR:
            return {
                ...state,
                error: null
            }
        case APP_CHANGE_LANGUAGE:
            return {
                ...state,
                current_language: action.payload
            }
        case DISABLE_CREATOR: {
            return {
                ...state,
                creator: false
            }
        }
        default:
            return state;
    }
};