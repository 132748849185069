import React, {useState} from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import api from "../../../core/api";
import {createAction, toastError, toastSuccess} from "../../../core/utils/appUtil";
import {FACILITY_DETAILS_SUCCESS} from "../../../reducers/facility";
import schemas from "../../../reducers/schemas";
import FormHeader from "../../../core/form/FormHeader";
import {BUSINESS_GET_DETAILS} from "../../../reducers/business";
import {normalize} from 'normalizr'
import {useSelector} from 'react-redux'
import {getCurrentBusiness} from "../../../selectors";
import SwitchInput from "../../../core/form/SwitchInput";
import AddressCityField from "../../../components/forms/address/AddressCityField";
import AddressField from "../../../components/forms/address/AddressField";
import AddressPostcodeField from "../../../components/forms/address/AddressPostcodeField";
import {useForm} from "react-hook-form";
import AppForm from "../../../components/forms/AppForm";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import SubmitButtonsFooter from "../../../components/forms/SubmitButtonsFooter";

let FacilityAddressPage = () => {

    let dispatch = useDispatch()

    let business = useSelector(getCurrentBusiness)

    let params = useParams();

    let facilityId = params.id

    let facilities = useSelector(state => state.facility.byId)

    let addresses = useSelector(state => state.address.byId)

    let getFormData = () => {
        let facility = facilities[facilityId]
        let address = addresses[facility.address]
        return {
            id: facilityId,
            address: address,
            use_custom_address: !address.business_address
        };
    }

    let [loadingDefaultValues, setLoadingDefaultValuesDefaultValues] = useState(true)

    let getDefaultValues = async () => {
        setLoadingDefaultValuesDefaultValues(true)
        return Promise.all([
            api.business.get(business?.id, ['address']),
            api.facility.get(facilityId, ['address', 'type.resourceTypes'])
        ]).then(responses => {
            let [businessResponse, facilityResponse] = responses
            dispatch(createAction(BUSINESS_GET_DETAILS, normalize(businessResponse.data.data, schemas.businessSchema)))
            dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(facilityResponse.data, schemas.facilitySchema)))
            setLoadingDefaultValuesDefaultValues(false)
            return getFormData()
        }).catch(() => {
            setLoadingDefaultValuesDefaultValues(false)
            toastError('Wystąpił błąd')
        })
    }

    let formContext = useForm({defaultValues: getDefaultValues})

    let {reset, watch, setValue} = formContext

    let useCustomAddress = watch('use_custom_address')

    React.useEffect(() => {
        if(!useCustomAddress) {
            setValue('address.address', addresses[business?.address_id].address)
            setValue('address.city', addresses[business?.address_id].city)
            setValue('address.postcode', addresses[business?.address_id].postcode)
        }
    }, [useCustomAddress]);

    let onSubmit = formData => {
        let requestData = {
            use_custom_address: formData.use_custom_address
        }
        if(formData.use_custom_address) {
            requestData.address = {
                address: formData.address.address,
                city: formData.address.city,
                postcode: formData.address.postcode
            }
        }
        requestData.include = ['address']
        api.facility.update(facilityId, requestData)
            .then((response) => {
                    dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(response.data, schemas.facilitySchema)))
                    reset(getFormData())
                    toastSuccess('Zapisano')
                }
            ).catch(() => {
                toastError('Wystąpił błąd')
            }
        )
    }

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <AppForm formContext={formContext} onSubmit={onSubmit}>
                    <Card>
                        <CardBody>
                            <FormHeader text='Lokalizacja'/>
                            <SwitchInput label='Niestandardowy adres' name='use_custom_address'/>
                            <AddressCityField loading={loadingDefaultValues}/>
                            <AddressField loading={loadingDefaultValues}/>
                            <AddressPostcodeField loading={loadingDefaultValues}/>
                        </CardBody>
                        <SubmitButtonsFooter/>
                    </Card>
                </AppForm>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityAddressPage
