import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {pad} from "../../core/utils/appUtil";

const HourInput = ({
                       data,
                       disabled = false,
                       start = false,
                       update,
                       includeMidnight = false,
                       minHour = -1,
                       minMinute = -1,
                       maxHour = 999,
                       maxMinute = 999
                   }) => {

    let [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        let newOptions = []
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 30) {
                if(i < minHour) {
                    continue
                }
                if(i === minHour && j < minMinute) {
                    continue
                }
                if(i > maxHour) {
                    continue
                }
                if(i === maxHour && j > maxMinute) {
                    continue
                }
                newOptions.push({
                    hour: i,
                    minute: j
                })
            }
        }
        setOptions(() => newOptions)
    }, [
        minHour, minMinute, maxHour, maxMinute
    ])

    if (includeMidnight) {
        options.push({
            hour: 24,
            minute: 0
        })
    }

    React.useEffect(() => {
        setValue(data)
    }, [JSON.stringify(data)])

    let defaultValue = start ? {hour: 8, minute: 0} : {hour: 18, minute: 0};

    let [value, setValue] = React.useState(data ?? defaultValue);

    return <Autocomplete
        className={'time-input ' + (disabled ? 'disabled' : '')}
        disablePortal
        value={value}
        onChange={(event, newValue) => {
            if (newValue) {
                update(newValue);
                setValue(newValue);
            }
        }}
        options={options}
        disabled={disabled}
        getOptionLabel={(option) => pad(option.hour) + ':' + pad(option.minute)}
        renderInput={(props) => {
            return <TextField {...props}
                              label={false}
                              variant="outlined"
            />
        }
        }
    />
}

export default HourInput;

