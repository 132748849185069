import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerRepeatPasswordField = () => {

    return <TextInput
        type='password'
        name='password_confirmation'
        label='form.password_confirmation'
        required={true}
    />

}

export default PartnerRepeatPasswordField