import React from 'react'
import SelectInput from "../../core/form/select/SelectInput";
import TimeSelect from "../../core/form/TimeSelect";
import {useFormContext, useFieldArray} from "react-hook-form";
import {MdClose} from 'react-icons/md'
import FormHeader from "../../core/form/FormHeader";
import TextInput from "../../core/form/TextInput";
import SwitchInput from "../../core/form/SwitchInput";
import Button from "../../core/buttons/Button";

let ServicePriceForm = () => {

    let times = [
        {value: 30, label: '30 min.'},
        {value: 60, label: '1 godz.'},
        {value: 90, label: '1 godz. 30 min.'},
        {value: 120, label: '2 godz.'}
    ];

    let availableDays = [
        {value: 1, label: 'Poniedziałek'},
        {value: 2, label: 'Wtorek'},
        {value: 3, label: 'Środa'},
        {value: 4, label: 'Czwartek'},
        {value: 5, label: 'Piątek'},
        {value: 6, label: 'Sobota'},
        {value: 0, label: 'Niedziela'}
    ]

    let {watch, control} = useFormContext()

    let [selectedTimes, seasonPriceEnabled, priceByTimeEnabled, dayOfWeekEnabled] =
        watch(['price.times', 'price.season', 'price.time', 'price.day_of_the_week'])

    const {fields, append, remove} = useFieldArray({
        control,
        name: "price.variants"
    });

    let hasVariants = priceByTimeEnabled || dayOfWeekEnabled
    return <div className='rental-form'>
        <SelectInput name='price.times' options={times} label='Dostępne czasy rezerwacji' isMulti={true}/>
        <FormHeader text='Konfiguracja cen'/>
        <SwitchInput name='price.season' label='Czy cena jest zależna od sezonu?'/>
        <SwitchInput name='price.day_of_the_week' label='Czy cena jest zależna od dnia tygodnia?'/>
        <SwitchInput name='price.time' label='Czy cena jest zależna od pory dnia?'/>
        {
            fields.map((field, key) => <div className='card mb-5 variant'>
                {fields.length > 1 && <MdClose className='close' onClick={() => remove(key)}/>}
                {hasVariants && <p>Wariant #{key + 1}</p>}
                <div className='d-flex'>
                    {dayOfWeekEnabled &&
                        <SelectInput name={'price.variants.' + key + '.days'} extraClassName='day-select' isMulti={true}
                                     options={availableDays} label='Dzień tygodnia'/>}
                    {priceByTimeEnabled && <>
                        <TimeSelect name={'price.variants.' + key + '.time_from'} label={'Od'}/>
                        <TimeSelect name={'price.variants.' + key + '.time_to'} label={'Do'}/>
                    </>}
                </div>
                <table className='price-table'>
                    <thead>
                    {seasonPriceEnabled && <tr>
                        <th></th>
                        <th valign='middle'>Sezon letni</th>
                        <th>Sezon zimowy</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {
                        selectedTimes.map(selectedTime => <tr>
                            <td className='time'>
                                {times.filter(t => t.value === selectedTime)[0].label}
                            </td>
                            <td>
                                <TextInput style={{display: 'none'}}
                                           name={'price.variants.' + key + '.' + (seasonPriceEnabled ? 'summer' : 'allSeasons') + '.time' + selectedTime}
                                           label='Cena' required={true}/>
                            </td>
                            {
                                seasonPriceEnabled && <>
                                    <td>
                                        <TextInput name={'price.variants.' + key + '.winter.time' + selectedTime}
                                                   label='Cena' required={true}/>
                                    </td>
                                </>
                            }
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>)
        }
        <div className='d-flex justify-content-center'>
            {(hasVariants) &&
                <Button text='Dodaj wariant' extraClasses='mb-2' onClick={() => append()}/>}
        </div>
    </div>
}

export default ServicePriceForm