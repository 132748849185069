import React from "react";

import {useSelector, useDispatch} from 'react-redux'
import AppModal from "./AppModal";
import {toastSuccess} from "../../core/utils/appUtil";
import handleError from "../../core/utils/errorUtil";
import Checkbox from "../../core/form/Checkbox";

const SelectObjectsModal = (props) => {

    let {defaultSelected, stateKey, objectId, renderElement, action, setModalOpened, onUpdate} = props;

    let allElements = useSelector(state => state[stateKey].allIds);
    if (props.elements) {
        allElements = props.elements
    }

    let all = useSelector(state => state[stateKey].byId);

    let [selected, setSelected] = React.useState(defaultSelected);

    let dispatch = useDispatch();

    let updateObjects = () => {
        return action(objectId, selected).then((response) => {
            onUpdate(objectId, selected)
            return true;
        }).catch(error => handleError(error, dispatch))
    }


    let submitAction = async () => {
        let result = await updateObjects(objectId, selected);
        if (result) {
            toastSuccess('Zapisano')
            setModalOpened(() => false)
            return true;
        }
    }

    let setSelectedObject = (object, selected) => {
        if (selected) {
            setSelected(state => [...state, object])
        } else {
            setSelected(state => [...state.filter(o => o !== object)])
        }
    }

    return <AppModal {...props}
                     submitAction={submitAction}
                     includeButtons={allElements?.length > 0}
    >
        <div>
            {
                !allElements?.length && <div className='d-flex justify-content-center mb-3'>
                    <span>Brak dostępnych</span>
                </div>
            }
            {
                allElements.map(element => <Checkbox label={renderElement(all[element])}
                                                     onChange={(selected) => setSelectedObject(element, selected)}
                                                     value={selected.includes(element)}/>)
            }
        </div>
    </AppModal>
}

export default SelectObjectsModal;

