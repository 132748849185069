import {NAVBAR_RESET_BREADCRUMBS, NAVBAR_SET_BREADCRUMBS} from "../reducers/navbar";
import {createAction} from "../core/utils/appUtil";

export function setBreadcrumbs(previouspage, previouspageurl, sitename) {
    return dispatch => {
        dispatch(createAction(NAVBAR_SET_BREADCRUMBS, {
            previouspage: previouspage,
            previouspageurl: previouspageurl,
            sitename: sitename
        }))
    }
}

export function resetBreadcrumbs() {
    return dispatch => {
        dispatch(createAction(NAVBAR_RESET_BREADCRUMBS))
    }
}