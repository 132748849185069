import React from "react";
import {useFormContext, useFieldArray} from "react-hook-form";
import FormHeader from "../../core/form/FormHeader";
import {useSelector} from 'react-redux'
import SwitchInput from "../../core/form/SwitchInput";
import SelectInput from "../../core/form/select/SelectInput";
import TextInput from "../../core/form/TextInput";
import TimeSelect from "../../core/form/TimeSelect";
import Button from "../../core/buttons/Button";
import RentalTimes from "./rental/RentalTimes";
import {MdClose} from 'react-icons/md'
import {getFacilityTypeText} from "../../utils/facilityTypeUtil";

let RentalForm = ({edit = false}) => {

    let {watch, control} = useFormContext()

    let times = [
        {value: 30, label: '30 min.'},
        {value: 60, label: '1 godz.'},
        {value: 90, label: '1 godz. 30 min.'},
        {value: 120, label: '2 godz.'}
    ];

    let availableDays = [
        {value: 1, label: 'Poniedziałek'},
        {value: 2, label: 'Wtorek'},
        {value: 3, label: 'Środa'},
        {value: 4, label: 'Czwartek'},
        {value: 5, label: 'Piątek'},
        {value: 6, label: 'Sobota'},
        {value: 0, label: 'Niedziela'}
    ]

    let [selectedTimes, lightingEnabled, seasonRentalEnabled, partialRentalEnabled, rentalByTimeEnabled, dayOfWeekEnabled, facilityTypeId] =
        watch(['rental.times', 'rental.with_lighting', 'rental.season', 'rental.partial', 'rental.time', 'rental.day_of_the_week', 'type_id'])

    let facilityType = useSelector(state => state.facilityType.byId[facilityTypeId])

    let partialRentalAvailable = facilityType?.rental?.partial ?? false

    let entireRentalAvailable = facilityType?.rental?.entire ?? false

    let showPartialRental = (partialRentalAvailable && partialRentalEnabled) || !entireRentalAvailable

    let minParts = control._defaultValues.parts ?? (entireRentalAvailable ? 2 : 1)

    const {fields, append, remove} = useFieldArray({
        control,
        name: "rental.variants"
    });

    let hasVariants = rentalByTimeEnabled || dayOfWeekEnabled

    return <div className='rental-form'>
        <FormHeader text='Konfiguracja wynajmu'/>
        <div className='parts'>
            {(partialRentalAvailable && entireRentalAvailable) &&
                <SwitchInput name='rental.partial' label={getFacilityTypeText(facilityType?.code, 'part-question')} disabled={edit}/>}
            {
                ((partialRentalEnabled || !entireRentalAvailable)) && <div className='form-field switch'>
                    <label>{getFacilityTypeText(facilityType?.code, 'part-quantity-question')}</label>
                    <SelectInput name='parts' options={[...Array(10).keys()].map(v => v + minParts).map(option => {
                        return {
                            value: option,
                            label: option
                        }
                    })} label={getFacilityTypeText(facilityType?.code, 'part-quantity')} disabled={edit}/>
                </div>
            }
        </div>
        <RentalTimes/>
        <FormHeader text='Konfiguracja cen'/>
        <SwitchInput name='rental.season' label='Czy cena jest zależna od sezonu?'/>
        <SwitchInput name='rental.day_of_the_week' label='Czy cena jest zależna od dnia tygodnia?'/>
        <SwitchInput name='rental.time' label='Czy cena jest zależna od pory dnia?'/>
        <SwitchInput name='rental.with_lighting' label='Dodatkowa opłata za oświetlenie'/>
        {
            fields.map((field, key) => <div className='card mb-5 variant'>
                {fields.length > 1 && <MdClose className='close' onClick={() => remove(key)}/>}
                {hasVariants && <p>Wariant #{key + 1}</p>}
                <div className='d-flex'>
                    {dayOfWeekEnabled &&
                        <SelectInput name={'rental.variants.' + key + '.days'} extraClassName='day-select' isMulti={true} options={availableDays} label='Dzień tygodnia'/>}
                    {rentalByTimeEnabled && <>
                        <TimeSelect name={'rental.variants.' + key + '.time_from'} label={'Od'}/>
                        <TimeSelect name={'rental.variants.' + key + '.time_to'} label={'Do'}/>
                    </>}
                </div>
                <table className='price-table'>
                    <thead>
                    {seasonRentalEnabled && <tr>
                        <th></th>
                        <th colSpan={(entireRentalAvailable && partialRentalEnabled) ? 2 : 1} valign='middle'>Sezon
                            letni
                        </th>
                        <th colSpan={(entireRentalAvailable && partialRentalEnabled) ? 2 : 1}>Sezon zimowy</th>
                    </tr>}
                    {(entireRentalAvailable && showPartialRental) && <tr>
                        <th></th>
                        <th>{getFacilityTypeText(facilityType?.code, 'entire-object')}</th>
                        <th>{getFacilityTypeText(facilityType?.code, 'part')}</th>
                        {seasonRentalEnabled && <>
                            <th>{getFacilityTypeText(facilityType?.code, 'entire-object')}</th>
                            <th>{getFacilityTypeText(facilityType?.code, 'part')}</th>
                        </>}
                    </tr>}
                    </thead>
                    <tbody>
                    {
                        selectedTimes.map(selectedTime => <tr>
                            <td className='time'>
                                {times.filter(t => t.value === selectedTime)[0].label}
                            </td>
                            {entireRentalAvailable && <td>
                                <TextInput style={{display: 'none'}} name={'rental.variants.' + key + '.entire.' + (seasonRentalEnabled ? 'summer' : 'allSeasons') + '.time' + selectedTime} label='Cena' required={true}/>
                            </td>}
                            {showPartialRental && <td>
                                <TextInput name={'rental.variants.' + key + '.partial.' + (seasonRentalEnabled ? 'summer' : 'allSeasons') + '.time' + selectedTime} label='Cena' required={true}/>
                            </td>}
                            {
                                seasonRentalEnabled && <>
                                    {entireRentalAvailable && <td>
                                        <TextInput name={'rental.variants.' + key + '.entire.winter.time' + selectedTime} label='Cena' required={true}/>
                                    </td>}
                                    {showPartialRental && <td>
                                        <TextInput name={'rental.variants.' + key + '.partial.winter.time' + selectedTime} label='Cena' required={true}/>
                                    </td>}
                                </>
                            }
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>)
        }
        <div className='d-flex justify-content-center'>
            {(hasVariants) &&
                <Button text='Dodaj wariant' extraClasses='mb-2' onClick={() => append()}/>}
        </div>
        {lightingEnabled && <div className='card lighting-form'>
            <FormHeader text='Cennik oświetlenia'/>
            <table>
                <thead>
                {seasonRentalEnabled && <tr>
                    <th></th>
                    <th colSpan={(entireRentalAvailable && partialRentalEnabled) ? 2 : 1} valign='middle'>Sezon
                        letni
                    </th>
                    <th colSpan={(entireRentalAvailable && partialRentalEnabled) ? 2 : 1}>Sezon zimowy</th>
                </tr>}
                {(entireRentalAvailable && showPartialRental) && <tr>
                    <th></th>
                    <th>{getFacilityTypeText(facilityType?.code, 'entire-object')}</th>
                    <th>{getFacilityTypeText(facilityType?.code, 'part')}</th>
                    {seasonRentalEnabled && <>
                        <th>{getFacilityTypeText(facilityType?.code, 'entire-object')}</th>
                        <th>{getFacilityTypeText(facilityType?.code, 'part')}</th>
                    </>}
                </tr>}
                </thead>
                {
                    selectedTimes.map(selectedTime => <tr>
                        <td className='time'>
                            {times.filter(t => t.value === selectedTime)[0].label}
                        </td>
                        {entireRentalAvailable && <td>
                            <TextInput name={'rental.lighting.entire.' + (seasonRentalEnabled ? 'summer' : 'allSeasons') + '.time' + selectedTime} label='Cena' required={true}/>
                        </td>}
                        {showPartialRental && <td>
                            <TextInput name={'rental.lighting.partial.' + (seasonRentalEnabled ? 'summer' : 'allSeasons') + '.time' + selectedTime} label='Cena' required={true}/>
                        </td>}
                        {
                            seasonRentalEnabled && <>
                                {entireRentalAvailable && <td>
                                    <TextInput name={'rental.lighting.entire.winter.time' + selectedTime} label='Cena' required={true}/>
                                </td>}
                                {showPartialRental && <td>
                                    <TextInput name={'rental.lighting.partial.winter.time' + selectedTime} label='Cena' required={true}/>
                                </td>}
                            </>
                        }
                    </tr>)
                }
            </table>
        </div>
        }
    </div>
}

export default RentalForm