import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {trans} from "../../../core/utils/translationUtil";
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {fetchAllServices} from "../../../actions/services";
import {getPriceText, getServicePriceText} from "../../../core/utils/appUtil";

let ServiceSelect = (props) => {

    let isAvailable = props.isAvailable

    if(!isAvailable) {
        isAvailable = () => true
    }

    let facilityId = props.facilityId

    let intl = useIntl();

    let dispatch = useDispatch();

    let label = props.label ?? trans(intl, 'service');

    let name = 'service_id'

    let {allIds} = useSelector(state => state.service)

    function getData() {
        if (Array.isArray(props.data)) {
            return props.data
        }
        return allIds
    }

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz usługę'
        stateKey='service'
        data={getData()}
        autocomplete={false}
        renderLabel={(option) => {
            let minDuration = Math.min(...option.price.times)
            let maxDuration = Math.max(...option.price.times)
            return <>
                <div className='name'>{option.name}{!isAvailable(option.id) && <span className='inavailable'>Niedostępna</span>}</div>
                <div className='details'>
                    <div>{getServicePriceText(option?.price)}</div>
                    <div className='right'>{minDuration === maxDuration ? minDuration : minDuration + '-' + maxDuration} min</div>
                </div>
            </>}
        }
        {...props}
    />

}

export default ServiceSelect;
