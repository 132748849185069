import React from "react";
import DateInput from "../../../core/form/DatetInput";

let ReservationTimeToField = (props) => {

    return <DateInput
        name='date'
        label='form.reservation.date'
        {...props}
    />

}

export default ReservationTimeToField