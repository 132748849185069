import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_FACILITY_ADDRESS,
    ROUTE_FACILITY_GALLERY,
    ROUTE_FACILITY_LIST,
    ROUTE_FACILITY_OPENING_HOURS,
    ROUTE_FACILITY_PARTS,
    ROUTE_FACILITY_PERSONNEL,
    ROUTE_FACILITY_RENTAL,
    ROUTE_FACILITY_RESOURCES,
    ROUTE_FACILITY_SHOW
} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import api from "../../../core/api";
import {toastError, toastSuccess} from "../../../core/utils/appUtil";
import {useNavigate} from "react-router-dom";
import handleError from "../../../core/utils/errorUtil";
import {useDispatch, useSelector} from 'react-redux'
import {setBreadcrumbs} from "../../../actions/navbar";
import {getCurrentBusiness} from "../../../selectors";
import {getFacilityTypeText} from "../../../utils/facilityTypeUtil";

let FacilityPage = ({facilityId, children}) => {

    let advancedResources = useSelector(state => getCurrentBusiness(state).advanced_resources)

    let pages = [
        {
            name: 'Informacje',
            path: getRoutePath(ROUTE_FACILITY_SHOW, facilityId),
        },
        {
            name: 'Lokalizacja',
            path: getRoutePath(ROUTE_FACILITY_ADDRESS, facilityId),
        },
        {
            name: 'Wynajem obiektu',
            path: getRoutePath(ROUTE_FACILITY_RENTAL, facilityId),
        },
        {
            name: 'Zasoby',
            path: getRoutePath(ROUTE_FACILITY_RESOURCES, facilityId),
            disabled: !advancedResources
        },
        {
            name: 'Godziny otwarcia',
            path: getRoutePath(ROUTE_FACILITY_OPENING_HOURS, facilityId),
        },
        {
            name: 'Zdjęcia',
            path: getRoutePath(ROUTE_FACILITY_GALLERY, facilityId),
        },
    ]

    let [deleteFacilityModalOpened, setDeleteFacilityModalOpened] = React.useState(false)

    let navigate = useNavigate()

    let dispatch = useDispatch()

    let facility = useSelector(state => state.facility.byId[facilityId])

    let parts = useSelector(state => state.facility.byId[facilityId]?.parts?.length)

    let facilityType = useSelector(state => state.facilityType.byId[state.facility.byId[facilityId]?.type_id])

    if (parts > 0) {
        pages.push({
            name: getFacilityTypeText(facilityType?.code, 'parts'),
            path: getRoutePath(ROUTE_FACILITY_PARTS, {
                id: facilityId
            }),
        })
    }

    React.useEffect(() => {
        dispatch(setBreadcrumbs('app.facilities', getRoutePath(ROUTE_FACILITY_LIST)))
    }, [dispatch])

    React.useEffect(() => {
        if (facility?.id) {
            dispatch(setBreadcrumbs('app.facilities', getRoutePath(ROUTE_FACILITY_LIST), facility.name))
        }
    }, [facility, dispatch])

    let deleteFacility = () => {
        return api.facility.delete(facilityId).then((response) => {
            return true;
        }).catch(error => handleError(error, dispatch))
    }

    let submit = async () => {
        let result = await deleteFacility();
        if (result) {
            toastSuccess('Usunięto obiekt!')
            setDeleteFacilityModalOpened(() => false)
            navigate(ROUTE_FACILITY_LIST);
        } else {
            toastError('Wystąpił błąd!')
        }
    }

    return <ObjectPage pages={pages} onRemove={() => setDeleteFacilityModalOpened(true)} name='facility'
                       removeText='app.facility_remove'
    >
        <ConfirmationModal message='app.facility_remove_confirmation'
                           modalOpened={deleteFacilityModalOpened}
                           setModalOpened={setDeleteFacilityModalOpened}
                           submitAction={() => submit()}
        />
        {children}
    </ObjectPage>

}

export default FacilityPage
