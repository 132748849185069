import React from "react";
import TextInput from "../../../core/form/TextInput";

import {useFormContext} from 'react-hook-form'
let AddressPostcodeField = ({loading, disabled = false}) => {

    let {watch} = useFormContext()

    let validation = {
        pattern: '^[0-9]{2}-[0-9]{3}$'
    }

    let customAddress = watch('use_custom_address')

    return <TextInput
        mask='99-999'
        validation={validation}
        name='address.postcode'
        namePrefix='address'
        label='form.address.postcode'
        required={true}
        loading={loading}
        disabled={disabled || customAddress === false}
    />

}

export default AddressPostcodeField