import React from "react";
import {Col} from "reactstrap";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment'
import Datatable from "../../../core/components/datatable/Datatable";
import api from "../../../core/api";
import ActivityDetailsColumn from "../../../components/activities/ActivityDetailsColumn";

let ActivitiesPage = () => {

    let [loading, setLoading] = React.useState(true)

    let [data, setData] = React.useState([])

    let dispatch = useDispatch();

    React.useEffect(() => {
        setLoading(true)
        api.activityLog.list({}, []).then(response => {
            setData(response.data);
            setLoading(false)
        });
    }, [dispatch])

    let logNames = {
        'login': 'Logowanie',
        'business_update': 'Aktualizacja ustawień biznesu'
    }

    let prepareData = data => {
        return data.map(activity => {
            return [
                <td>{moment(activity.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>,
                <td>{logNames[activity.log_name]}</td>,
                <td>{activity.partner.fullname}</td>,
                <td style={{minWidth: '100px'}}><ActivityDetailsColumn activity={activity}/></td>,
            ]
        })
    }

    return <Col className='list-page'>\
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['Czas', 'Akcja', 'Użytkownik', 'Szczegóły']}
                data={prepareData(data)}
            />
        </Col>
    </Col>
}
export default ActivitiesPage;

