import React from 'react'
import AppSkeleton from "./AppSkeleton";

let TableSkeleton = ({rows = 1, cols = 1}) => <>
    {
        Array.from(Array(rows).keys()).map((k) => <tr className='skeleton' key={k}>
            {Array.from(Array(cols).keys()).map((key) => <td key={key}>
                <AppSkeleton/>
            </td>)}
        </tr>)
    }
</>

export default TableSkeleton