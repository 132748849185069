import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {FACILITY_UPDATE_SUCCESS} from "../../../../reducers/facility";
import {normalize} from 'normalizr';
import {Row, Col} from 'reactstrap';
import FacilityPhoneField from "../../facility/FacilityPhoneField";
import FacilityWebsiteField from "../../facility/FacilityWebsiteField";
import FacilityFacebookField from "../../facility/FacilityFacebookField";
import FacilityInstagramField from "../../facility/FacilityInstagramField";
import CardForm from "../../../../core/form/CardForm";

let FacilityContactForm = function ({facilityId}) {

    let facility = useSelector(state => state.facility.byId[facilityId])

    let loading = useSelector(state => state.facility.loadingDetails)

    let dispatch = useDispatch();

    let prepareData = (data) => {
        return {
            website: data.website,
            phone: data.phone,
            facebook: data.facebook,
            instagram: data.instagram,
        }
    }

    return <CardForm
        title='Kontakt'
        data={facility}
        prepareData={prepareData}
        getPromise={(id, data) => api.facility.update(id, {...data, include: ['type.resourceTypes']})}
        onSubmit={data => dispatch(createAction(FACILITY_UPDATE_SUCCESS, normalize(data, schemas.facilitySchema)))}>
        <Row>
            <Col>
                <FacilityWebsiteField loading={loading}/>
            </Col>
            <Col>
                <FacilityPhoneField loading={loading}/>
            </Col>
        </Row>
        <FacilityFacebookField loading={loading}/>
        <FacilityInstagramField loading={loading}/>
    </CardForm>

}
export default FacilityContactForm