import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_SERVICE_FACILITIES,
    ROUTE_SERVICE_LIST,
    ROUTE_SERVICE_PARTNERS,
    ROUTE_SERVICE_PRICE,
    ROUTE_SERVICE_RESERVATIONS_CONFIG, ROUTE_SERVICE_RESOURCES,
    ROUTE_SERVICE_SHOW
} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {setBreadcrumbs} from "../../../actions/navbar";
import {useDispatch, useSelector} from 'react-redux';

let ServicePage = ({serviceId, children}) => {

    let pages = [
        {
            name: 'Informacje',
            path: getRoutePath(ROUTE_SERVICE_SHOW, serviceId),
        },
        {
            name: 'Ceny',
            path: getRoutePath(ROUTE_SERVICE_PRICE, serviceId),
        },
        // {
        //     name: 'Obiekty',
        //     path: getRoutePath(ROUTE_SERVICE_FACILITIES, serviceId),
        // },
        {
            name: 'Pracownicy',
            path: getRoutePath(ROUTE_SERVICE_PARTNERS, serviceId),
        },
        // {
        //     name: 'Zasoby',
        //     path: getRoutePath(ROUTE_SERVICE_RESOURCES, serviceId),
        // },
        {
            name: 'Warunki rezerwacji',
            path: getRoutePath(ROUTE_SERVICE_RESERVATIONS_CONFIG, serviceId),
        },
    ]

    let service = useSelector(state => state.service.byId[serviceId])

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Usługi', getRoutePath(ROUTE_SERVICE_LIST)))
    }, [dispatch])

    React.useEffect(() => {
        if (service?.id) {
            dispatch(setBreadcrumbs('Usługi', getRoutePath(ROUTE_SERVICE_LIST), service.name))
        }
    }, [service?.id, dispatch])

    return <ObjectPage pages={pages} name='service'>
        {children}
    </ObjectPage>

}

export default ServicePage