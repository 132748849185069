import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import {useLocation} from "react-router-dom";
import {Navigate} from 'react-router-dom'
import {ROUTE_DASHBOARD, ROUTE_SIGN_IN} from "../routes/routeDefinitions";
import {useSelector} from 'react-redux';
import routes from "../routes";
import {hasPermissionTo} from "../core/utils/appUtil";
import {getLoggedUser} from "../selectors";
import ReservationModal from "../components/modals/event/ReservationModal";
import CreatorPage from "../pages/CreatorPage";
import {
    Spinner
} from "reactstrap";

let Dashboard = ({headerText, children, extraClasses = '', navbarContent = null, beforeMain = null}) => {

    let {
        opened: reservationModalOpened,
    } = useSelector(state => state.reservationModal)

    let {is_loading: loading} = useSelector(state => state.app);

    let loggedUser = useSelector(getLoggedUser)

    let creatorStep = useSelector(state => state.business.byId[state.auth.current_business]?.creator_step);

    const location = useLocation();

    let hasPermission = true;
    routes.forEach((category, index) => {
        if(!loggedUser) {
            hasPermission = false;
            return;
        }
        if(location.pathname.startsWith(category.path) && category.permission && !hasPermissionTo(category.permission, loggedUser)) {
            hasPermission = false
        }
        if (category.children) {
            category.children.forEach((route, index) => {
                if(location.pathname.startsWith(route.path) && route.permission && !hasPermissionTo(route.permission, loggedUser)) {
                    hasPermission = false
                }
            })
        }
    })

    if (!loggedUser) {
        return <Navigate to={ROUTE_SIGN_IN}/>
    }

    if(!hasPermission) {
        return <Navigate to={ROUTE_DASHBOARD}/>
    }

    if (creatorStep !== null) {
        return <CreatorPage/>
    }

    if (loading) {
        return <div className='wrapper'>
            <Sidebar/>
            <Main className='dashboard-page'>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    opacity: 0.5,
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '100px',
                    zIndex: 999
                }}>
                    <Spinner color="primary" style={{opacity: 1}}/>
                </div>
            </Main>
        </div>
    }

    return <div className='wrapper'>
        <Sidebar/>
        <Main className={extraClasses}>
            {beforeMain && beforeMain()}
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Navbar headerText={headerText}>
                    {navbarContent && navbarContent()}
                </Navbar>
                <div className="content">{children}</div>
                <Footer/>
            </div>
            {reservationModalOpened && <ReservationModal/>}
        </Main>
    </div>


}

export default Dashboard
