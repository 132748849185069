export const ROUTE_DASHBOARD = '/';
export const ROUTE_RESERVATION_SHOW = '/rezerwacja/:id';
export const ROUTE_CLIENT_SHOW = '/klienci/:id';
export const ROUTE_CLIENT_LIST = '/klienci';

export const ROUTE_FACILITY_SHOW = '/obiekty/:id';
export const ROUTE_FACILITY_LIST = '/obiekty';
export const ROUTE_FACILITY_OPENING_HOURS = '/obiekty/:id/godzinyotwarcia';
export const ROUTE_FACILITY_GALLERY = '/obiekty/:id/galeria';
export const ROUTE_FACILITY_RESOURCES = '/obiekty/:id/zasoby';
export const ROUTE_FACILITY_SERVICES = '/obiekty/:id/uslugi';
export const ROUTE_FACILITY_ADDRESS = '/obiekty/:id/adres';
export const ROUTE_FACILITY_CREATE = '/obiekty/nowy';
export const ROUTE_FACILITY_RENTAL = '/obiekty/:id/wynajem';
export const ROUTE_FACILITY_PERSONNEL = '/obiekty/:id/pracownicy';
export const ROUTE_FACILITY_CONTACT = '/obiekty/:id/kontakt';
export const ROUTE_FACILITY_RESOURCE_TYPE = '/obiekty/:id/zasob/:type';
export const ROUTE_FACILITY_PARTS = '/obiekty/:id/czesci';
export const ROUTE_FACILITY_PART_DETAILS = '/obiekty/:facilityId/czesci/:partId';

export const ROUTE_SERVICE_DETAILS = '/uslugi/:id';
export const ROUTE_SERVICE_PRICE = '/uslugi/:id/cena';
export const ROUTE_SERVICE_SHOW = '/uslugi/:id';
export const ROUTE_SERVICE_FACILITIES = '/uslugi/:id/obiekty';
export const ROUTE_SERVICE_PARTNERS = '/uslugi/:id/pracownicy';
export const ROUTE_SERVICE_RESOURCES = '/uslugi/:id/zasoby';
export const ROUTE_SERVICE_RESERVATIONS_CONFIG = '/uslugi/:id/warunkirezerwacji';
export const ROUTE_SERVICE_LIST = '/uslugi';
export const ROUTE_PAYMENTS_PAID_LIST = '/platnosci/oplacone';
export const ROUTE_PAYMENTS_UNPAID_LIST = '/platnosci/oczekujace';
export const ROUTE_PAYMENTS_TO_RETURN_LIST = '/platnosci/do-zwrotu';
export const ROUTE_PAYMENTS_RETURNED_LIST = '/platnosci/zwrocone';
export const ROUTE_RESERVATIONS = '/rezerwacje';

export const ROUTE_PARTNER_LIST = '/personel';
export const ROUTE_PARTNER_SHOW = '/personel/:id';
export const ROUTE_PARTNER_SERVICES = '/personel/:id/uslugi';
export const ROUTE_PARTNER_AVAILABILITY = '/personel/:id/godzinypracy';
export const ROUTE_PARTNER_FACILITIES = '/personel/:id/obiekty';

export const ROUTE_RESOURCE_LIST = '/zasoby';
export const ROUTE_RESOURCE_SHOW = '/zasoby/:id';
export const ROUTE_RESOURCE_AVAILABILITY = '/zasoby/:id/dostepnosc';
export const ROUTE_RESOURCE_FACILITIES = '/zasoby/:id/obiekty';
export const ROUTE_RESOURCE_SERVICES = '/zasoby/:id/uslugi';

export const ROUTE_CONFIRM_ACCOUNT_SUCCESS = '/potwierdz-konto/sukces';
export const ROUTE_CONFIRM_ACCOUNT = '/potwierdz/:code';
export const ROUTE_RESET_PASSWORD = '/reset-hasla/:id?';
export const ROUTE_SEND_RESET_PASSWORD_LINK = '/reset-hasla';
export const ROUTE_PERMISSIONS = '/uprawnienia';

export const ROUTE_BUSINESS_SHOW = '/biznes';
export const ROUTE_BUSINESS_RESERVATION_SETTINGS = '/biznes/rezerwacje';
export const ROUTE_BUSINESS_SUBSCRIPTION_SETTINGS = '/biznes/subskrypcja';
export const ROUTE_BUSINESS_AVAILABILITY = '/biznes/dostepnosc';
export const ROUTE_BUSINESS_CONTACT = '/biznes/kontakt';
export const ROUTE_BUSINESS_GALLERY = '/biznes/galeria';

export const ROUTE_ACCOUNT_DETAILS = '/ustawienia';
export const ROUTE_ACCOUNT_NOTIFICATIONS = '/ustawienia/powiadomienia';
export const ROUTE_ACCOUNT_PREFERENCES = '/ustawienia/preferencje';
export const ROUTE_ACCOUNT_PASSWORD = '/ustawienia/zmiana-hasla';

export const ROUTE_LOGOUT = '/wyloguj'
export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_SIGN_UP = '/signup';
export const ROUTE_LOGIN_AS = '/loginas/:partnerId/:token';
export const ROUTE_ACTIVITIES = '/aktywnosci';
