import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessPage from "./BusinessPage";
import BusinessContactForm from "../../../components/forms/app/business/BusinessContactForm";

let BusinessContactPage = () => <BusinessPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <BusinessContactForm/>
            </Col>
        </Row>
    </BusinessPage>

export default BusinessContactPage
