import React from "react";
import {useSelector} from 'react-redux'

const RoleColumn = ({partner}) => {

    let role = '';

    let roles = useSelector(state => state.role.byId)

    if(partner.master_account) {
        role = 'Właściciel';
    } else {
        role = partner?.roles?.map(r => roles[r].name).join(',');
    }

    return <span className={partner.master_account ? 'bold' : ''}>{role ? role : 'Brak'}</span>

}

export default RoleColumn;
