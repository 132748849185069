import { combineReducers } from 'redux';
import app from './reducers/app';
import auth from './reducers/auth';
import sidebar from './reducers/sidebar';
import profileSettings from './reducers/profileSettings';
import resource from './reducers/resource';
import resourceType from './reducers/resourceType';
import clients from './reducers/clients';
import dashboard from './reducers/dashboard';
import business from './reducers/business';
import notification from './reducers/notification';
import reservation from './reducers/reservation';
import partner from './reducers/partner';
import facility from './reducers/facility';
import service from './reducers/service';
import facilityType from './reducers/facilityType';
import openingHours from './reducers/openingHours'
import permissions from './reducers/permissions';
import inavailabilityHour from './reducers/inavailabilityHour';
import payment from './reducers/payment';
import paymentType from './reducers/paymentType';
import creator from './reducers/creator';
import address from './reducers/address';
import image from './reducers/image';
import navbar from './reducers/navbar';
import role from './reducers/role';
import language from './reducers/language';
import priceForm from './reducers/priceForm';
import galleryEditor from './reducers/galleryEditor';
import openingHourForm from './reducers/openingHourForm';
import sport from './reducers/sport';
import amenity from './reducers/amenity';
import season from './reducers/season';
import customField from './reducers/customField';
import customFieldOption from './reducers/customFieldOption';
import paymentMethod from './reducers/paymentMethod';
import reservationModal from './reducers/reservationModal';
import block from './reducers/block';
import reservationCycle from './reducers/reservationCycle';
import facilityCreator from './reducers/facilityCreator';
import facilityPart from './reducers/facilityPart';

export default combineReducers({
    address,
    app,
    auth,
    business,
    clients,
    creator,
    customField,
    customFieldOption,
    dashboard,
    facility,
    facilityCreator,
    facilityType,
    galleryEditor,
    image,
    inavailabilityHour,
    language,
    navbar,
    notification,
    openingHours,
    openingHourForm,
    partner,
    payment,
    paymentType,
    permissions,
    priceForm,
    profileSettings,
    reservation,
    resource,
    resourceType,
    role,
    service,
    sidebar,
    sport,
    amenity,
    paymentMethod,
    reservationModal,block, reservationCycle, season, facilityPart
});