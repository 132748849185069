import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import Button from "../../../core/buttons/Button";
import {fetchAllResources} from "../../../actions/resources";
import {FaPlus} from "react-icons/fa";
import ResourcesList from "../../../components/facility/ResourcesList";
import NewResourceModal from "../../../components/facility/NewResourceModal";

let FacilityResourceTypePage = () => {

    let params = useParams();

    let facilityId = params.id

    let resourceTypeId = params.type

    let resourceType = useSelector(state => state.resourceType.byId[resourceTypeId])

    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchAllResources())
        dispatch(fetchFacilityDetails(facilityId, ['resources', 'type.resourceTypes']))
    }, [facilityId, dispatch])

    let loading = useSelector(state => state.facility.loadingDetails)

    let [addModalOpened, setAddModalOpened] = React.useState(false);

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col className='list-page'>
                {addModalOpened && <NewResourceModal
                    isOpen={addModalOpened}
                    closeModal={() => setAddModalOpened(() => false)}
                    facilityId={facilityId}
                    resourceTypeId={resourceTypeId}
                />}
                {!loading && <div className='button-panel'>
                    <Button text={'Dodaj ' + resourceType?.name?.toLowerCase()} Icon={FaPlus}
                            onClick={() => setAddModalOpened(() => true)}
                    />
                </div>}
                <Col lg={{size: 10, offset: 1}}>
                    <ResourcesList facilityId={facilityId} resourceTypeId={resourceTypeId}/>
                </Col>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityResourceTypePage
