export const RESERVATION_LIST_REQUEST = 'RESERVATION_LIST_REQUEST';
export const RESERVATION_LIST_SUCCESS = 'RESERVATION_LIST_SUCCESS';
export const RESERVATION_LIST_ERROR = 'RESERVATION_LIST_ERROR';
export const RESERVATION_FETCH_REQUEST = 'RESERVATION_FETCH_REQUEST';
export const RESERVATION_FETCH_SUCCESS = 'RESERVATION_FETCH_SUCCESS';
export const RESERVATION_UPDATE_REQUEST = 'RESERVATION_UPDATE_REQUEST';
export const RESERVATION_UPDATE_SUCCESS = 'RESERVATION_UPDATE_SUCCESS';
export const RESERVATION_STATUS_UPDATE_SUCCESS = 'RESERVATION_STATUS_UPDATE_SUCCESS';
export const RESERVATION_SET_FILTERS = 'RESERVATION_SET_FILTERS';
export const RESERVATIONS_SET_CALENDAR_VIEW = 'RESERVATIONS_SET_CALENDAR_VIEW';
export const RESERVATION_SKIP_RERENDER = 'RESERVATION_SKIP_RERENDER';
