export const FACILITY_CREATOR_INIT = 'FACILITY_CREATOR_INIT';
export const FACILITY_CREATOR_BACK = 'FACILITY_CREATOR_BACK';
export const FACILITY_CREATOR_FORWARD = 'FACILITY_CREATOR_FORWARD';

var initialState = {
    step: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FACILITY_CREATOR_INIT: {
            return {
                step: 0
            }
        }
        case FACILITY_CREATOR_BACK: {
            return {
                step: state.step > 0 ? state.step - 1 : 0
            }
        }
        case FACILITY_CREATOR_FORWARD: {
            return {
                step: state.step + 1
            }
        }
        default:
            return state;
    }
};