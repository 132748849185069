import React from "react";
import * as actions from '../../actions/auth';

import {
    Collapse,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import {BiLogOut, BiQuestionMark, BiInfoCircle} from 'react-icons/bi';
import {GiSettingsKnobs} from 'react-icons/gi';
import {MdKeyboardArrowDown, MdOpenInNew} from 'react-icons/md';
import {useNavigate} from "react-router-dom";
import Notifications from "../Notifications";
import avatar1 from "../../assets/img/avatars/avatar.jpg";
import {ROUTE_ACCOUNT_DETAILS} from "../../routes/routeDefinitions";
import {getRoutePath} from "../../core/utils/routeUtil";
import {useDispatch, useSelector} from 'react-redux'
import AvatarColumn from "../../core/components/datatable/AvatarColumn";

let RightPanel = () => {

    let dispatch = useDispatch();

    let user = useSelector(state => state.partner.byId[state.auth.logged_user]);

    let navigate = useNavigate();

    let logout = () => {
        dispatch(actions.logout())
    }

    let openAccountSettings = () => {
        navigate(getRoutePath(ROUTE_ACCOUNT_DETAILS))
    }

    return <div className='right-panel'>
        <Collapse navbar className='help-guide'>
            <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav inNavbar>
                            <span className="d-none d-sm-inline-block">
                              <DropdownToggle nav>
                                    <BiQuestionMark size={16}/>
                              </DropdownToggle>
                            </span>
                    <DropdownMenu className="dropdown-menu-lg py-0" end>
                        <div className='c1'>
                            <div className='header'>
                                <div className='icon'>
                                    <BiInfoCircle size={26}/>
                                </div>
                                <span className='text'>Do czego służy kalendarz rezerwacji?</span>
                            </div>
                            <div className='content-text'>
                                Kalendarz pozwala szybko rezerwować obiekty, a także ustawiać
                                przypomnienia
                                o nadchodzących działaniach. Dzięki temu zawsze wiesz, co Cię czeka.
                                Kalendarz został opracowany z myślą o współpracy z klientem, dlatego
                                umożliwia udostępnianie harmonogramów innym osobom.
                            </div>
                            <div className='more'>
                                <MdOpenInNew size={16}/>
                                <a href={'/'}>Dowiedz się więcej</a>
                            </div>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Collapse>
        <Notifications/>
        {/*<Collapse navbar>*/}
        {/*    <Nav className="ml-auto" navbar>*/}
        {/*        <UncontrolledDropdown nav inNavbar>*/}
        {/*                    <span className="d-none d-sm-inline-block">*/}
        {/*                      <DropdownToggle nav>*/}
        {/*                            <BiEnvelope size={16}/>*/}
        {/*                      </DropdownToggle>*/}
        {/*                    </span>*/}
        {/*            <DropdownMenu end>*/}
        {/*                <div>*/}
        {/*                    Wiadomości*/}
        {/*                </div>*/}
        {/*            </DropdownMenu>*/}
        {/*        </UncontrolledDropdown>*/}
        {/*    </Nav>*/}
        {/*</Collapse>*/}
        <Collapse navbar className='settings'>
            <Nav navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                        <div className='avatar-container'>
                            <div style={{width: '48px', height: '48px'}}>
                                <AvatarColumn name={user.fullname} id={user.id}/>
                            </div>
                            <div className='active'/>
                        </div>
                        <MdKeyboardArrowDown size={20}/>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <div className='menu-list'>
                            <DropdownItem onClick={openAccountSettings}>
                                <GiSettingsKnobs size={12}/>
                                <span>Ustawienia konta</span>
                            </DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem className='logout' onClick={logout}>
                                <BiLogOut size={12}/>
                                <span>Wyloguj się</span>
                            </DropdownItem>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Collapse>
    </div>
}

export default RightPanel;