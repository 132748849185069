import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";

let TopClients = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('top-clients', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result.data)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }

    return <table className='table'>
        {
            data.map((row, index) => <tr style={{listStyle: 'none'}}>
                <td>{index + 1}.</td>
                <td>{row.client.fullname}</td>
                <td>{row.price} PLN</td>
            </tr>)
        }
    </table>
}

export default TopClients