import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Spinner
} from "reactstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import {createAction} from "../core/utils/appUtil";
import {RiArrowDropRightLine} from 'react-icons/ri';
import {BsPlus} from 'react-icons/bs';
import Button from '@mui/material/Button';
import AccountDataStepForm from "../components/creator/steps/AccountDataStepForm";
import BusinessDataStepForm from "../components/creator/steps/BusinessDataStepForm";
import api from "../core/api";
import OpeningHoursStepForm from "../components/creator/steps/OpeningHoursStepForm";
import EmployeeStepForm from "../components/creator/steps/EmployeeStepForm";
import ServiceStepForm from "../components/creator/steps/ServiceStepForm";
import {appError} from "../actions/app";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {BUSINESS_UPDATE_SUCCESS} from "../reducers/business";
import {getCurrentBusiness} from "../selectors";

let pages = [
    {
        title: 'Informacje o koncie'
    },
    {
        title: 'Informacje o biznesie'
    },
    // {
    //     title: 'Wynajem'
    // },
    {
        title: 'Godziny otwarcia biznesu'
    },
    {
        title: 'Dodaj pracowników'
    },
    {
        title: 'Dodaj usługi'
    }
];

let CreatorPage = () => {

    let business = useSelector(getCurrentBusiness);

    let [currentStep, setCurrentStep] = useState(business.creator_step ?? 0);

    let dispatch = useDispatch();

    let [loading, setLoading] = useState(false);

    let disableCreator = () => {
        api.business.update(business.id, {creator_step: null})
            .then((response) => {
                setLoading(false);
                dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalize(response.data.data, schemas.businessSchema)))
                setLoading(false)
                setCurrentStep(null)
            })
    }

    let prev = () => {
        if (currentStep > 0 && !loading) {
            setCurrentStep(currentStep - 1)
        }
    }

    let goNextStep = () => {
        let nextStep = currentStep + 1;
        setLoading(true);
        if (business.creator_step < nextStep) {
            api.business.update(business.id, {creator_step: nextStep})
                .then((response) => {
                    dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalize(response.data.data, schemas.businessSchema)))
                })
                .catch((err) => appError(dispatch, err))
        }
        setCurrentStep(currentStep + 1)
        setLoading(false)
    }

    let submitStep = null;

    let next = () => {
        if (submitStep) {
            submitStep = submitStep();
            if(typeof submitStep === 'function') {
                submitStep();
            }
        } else {
            goNextStep();
        }
    };

    let getStep = () => {
        let FormComponent = null;
        switch (currentStep) {
            case 0: {
                FormComponent = AccountDataStepForm
                break;
            }
            case 1: {
                FormComponent = BusinessDataStepForm
                break;
            }
            // case 2: {
            //     FormComponent = FacilityDataStepForm
            //     break;
            // }
            case 2: {
                FormComponent = OpeningHoursStepForm
                break;
            }
            case 3: {
                FormComponent = EmployeeStepForm
                break;
            }
            case 4: {
                FormComponent = ServiceStepForm
                break;
            }
            default: {
                return null
            }
        }
        if(FormComponent) {
            return <FormComponent setSubmit={submitAction => {
                submitStep = submitAction
            }} next={goNextStep} setDuringSubmit={loading => setLoading(loading)}/>
        }
        return <p/>
    }

    return <div className='creator-page'>
        <div className='content'>
            <div className='container'>
                <span className='header-text'>Konfiguracja nowego biznesu</span>
                <div className='navigation'>
                    {
                        pages.map((value, key) => {
                            return <React.Fragment key={key}>
                                {key > 0 && key < pages.length && <RiArrowDropRightLine size={25}/>}
                                <div
                                    className={'navigation-item ' + (key === currentStep ? 'active ' : ' ') + (key < currentStep ? 'completed ' : ' ')}>
                                    <div className='number'>
                                        <div>
                                            {key + 1}
                                        </div>
                                    </div>
                                    <div className='text'>{value.title}</div>
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
                <div className={'content-data step-' + currentStep}>
                    {
                        currentStep < pages.length &&
                        <React.Fragment>
                            <div className='header-text'>
                                {pages[currentStep]?.title}
                            </div>
                            {getStep()}
                        </React.Fragment>
                    }
                    {
                        currentStep >= pages.length &&
                        <React.Fragment>
                            <div className='content-text step-complete p-5 d-flex align-items-center flex-column h-100'>
                                <div>Gratulacje!</div>
                                <div>Proces konfiguracji <span>zakończony.</span></div>
                                <AiFillCheckCircle size={100} style={{color: '#ADCAFF'}} className='mt-5'/>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <div className='footer'>
                    {currentStep < pages.length && <React.Fragment>
                        <div onClick={() => prev()} className='prev'>{'<'}</div>
                        <div onClick={() => next()} className='next'>{loading ?
                            <Spinner size='sm' className='loader'/> : '>'}</div>
                    </React.Fragment>}
                    {currentStep >= pages.length && <React.Fragment>
                        <Button onClick={disableCreator}>Przejdź do aplikacji<BsPlus/></Button>
                    </React.Fragment>}
                </div>
            </div>
        </div>
    </div>

}

export default CreatorPage;