export default {
    "email": "Adres email",
    'fullname': 'Imię i nazwisko',
    'phone': 'Numer telefonu',
    'password': 'Hasło',
    'newpassword': 'Nowe hasło',
    'password.information': 'Hasło musi zawierać 8 znaków.',
    'password_confirmation': 'Powtórz hasło',
    'currentpassword': 'Aktualne hasło',
    'facility.type': 'Typ obiektu',
    'facility.name': 'Nazwa obiektu',
    'facility.description': 'Opis',
    'facility.website': 'Adres strony internetowej',
    'facility.phone': 'Numer telefonu',
    'facility.facebook': 'Facebook',
    'facility.instagram': 'Instagram',
    'facility.enabled': 'Włączony',
    'facility.visible': 'Widoczny w aplikacji',
    'address.city': 'Miasto',
    'address.address': 'Ulica i numer budynku/lokalu',
    'address.postcode': 'Kod pocztowy',
    'service.name': 'Nazwa usługi',
    'service.price': 'Cena usługi',
    'service.duration': 'Czas trwania usługi',
    'service.duration.hour': 'GODZ.',
    'service.duration.minute': 'MIN.',
    'service.description': 'Opis usługi',
    'service.visible': 'Widoczna w aplikacji',
    'service.enabled': 'Aktywna',
    'business.name': 'Nazwa firmy',
    'business.subdomain': 'Link do profilu firmy',
    'business.language': 'Język',
    'business.region': 'Region',
    'business.currency': 'Waluta',
    'business.timezone': 'Strefa czasowa',
    'business.facebook': 'Facebook',
    'business.website': 'Adres strony internetowej',
    'business.instagram': 'Instagram',
    'business.manual_confirmation': 'Ręczne potwierdzanie rezerwacji',
    'business.advanced_resources': 'Zaawansowane zarządzanie zasobami',
    'business.reservation_interval': 'Interwały czasowe w kalendarzu',
    'business.min_time_before_reservation': 'Minimalny czas rezerwacji',
    'business.min_time_before_reservation_information': 'Jaki musi być minimalny czas między rezerwacją a terminem rezerwacji?',
    'business.max_reservation_time_in_future': 'Maksymalny czas rezerwacji',
    'business.max_reservation_time_in_future_information': 'Jak daleko w przyszłość można rezerwować usługi w Twoich obiektach?',
    'business.max_reservation_change_time': 'Zmiana rezerwacji',
    'business.max_reservation_change_time_information': 'W jakim terminie przed rozpoczęciem świadczenia usługi klient może wprowadzić zmianę terminu rezerwacji?',
    'partner.phone': 'Numer telefonu',
    'partner.email': 'Adres e-mail',
    'partner.fullname': 'Imię i nazwisko',
    'partner.enabled': 'Czy konto jest aktywne?',
    'partner.visible': 'Czy powinien być dostępny do wyboru w aplikacji dla klientów?',
    'partner.notification_web': 'Powiadomienia push',
    'partner.notification_email': 'Powiadomienia email',
    'partner.language': 'Język',
    'partner.role': 'Rola',
    'client.phone': 'Numer telefonu',
    'client.email': 'Adres e-mail',
    'client.fullname': 'Imię i nazwisko',
    'resource.name': 'Nazwa zasobu',
    'resource.type': 'Kategoria zasobu',
    'resource.description': 'Opis',
    'resource.enabled': 'Czy zasób jest aktywny?',
    'resource.visible': 'Czy powinien być dostępny do wyboru w aplikacji dla klientów?',
    'resourcetype.name': 'Nazwa kategorii zasobu',
    'reservation.status': 'Status',
    'reservation.service': 'Usługa',
    'reservation.facility': 'Obiekt',
    'reservation.client': 'Klient',
    'reservation.date': 'Termin',
    'reservation.time_from': 'Od',
    'reservation.time_to': 'Do',
    'reservation.partner': 'Pracownicy',
    'price.type': 'Typ ceny',
    'price.value': 'Cena',
    'price.from': 'Cena od',
    'price.to': 'Cena do',
    'price.time.from': 'Od',
    'price.time.to': 'Do',
    'price.day': 'Dni tygodnia',
    'price.default': 'Domyślna cena',
    'price.type_fixed': 'Stała',
    'price.type_variable': 'Zmienna',
    'price.type_range': 'Zakres cen',
    'price.type_individual': 'Ustalana indywidualnie',
    'price.type_free': 'Darmowa',
}