import React from "react";
import {Row, Col} from 'reactstrap';
import {HTML5Backend} from 'react-dnd-html5-backend'
import {useSelector} from 'react-redux'
import {DndProvider} from 'react-dnd'
import AddImage from "./AddImage";
import ImagePlaceholder from "./ImagePlaceholder";
import {Container} from "./Container";
import Button from "../../core/buttons/Button";

const GalleryEditor = ({onSubmit = (images) => {}, submitButtons = false}) => {

    let {images, formChanged, saving} = useSelector(state => state.galleryEditor)

    let calculateMissingPlaceholders = () => {
        let currenttiles = images.length + 1;
        if (currenttiles < 8) {
            return 8 - currenttiles;
        }
        return 4 - (currenttiles % 4)
    }

    let missingPlaceholders = calculateMissingPlaceholders();

    return <div className='gallery-editor'>
        <Row>
            <DndProvider backend={HTML5Backend}>
                <Container images={images}/>
                <Col lg={3} className='col'>
                    <AddImage/>
                </Col>
                {
                    Array.from(Array(missingPlaceholders).keys()).map(i => <Col lg={3} className='col'>
                        <ImagePlaceholder/>
                    </Col>)
                }
            </DndProvider>
        </Row>
        {
            (formChanged && submitButtons) && <Row>
                <div className='d-flex-justify'>
                    <Button variant='secondary' text='Anuluj'/>
                    <Button loading={saving} text='Zapisz' onClick={() => onSubmit(images)}/>
                </div>
            </Row>
        }
    </div>
}
export default GalleryEditor;
