import axios from "axios";

export const makeCreateRequest = (path, data) => makeRequest(createPostRequest(path), data)
export const makeUpdateRequest = (path, id, data) => makeRequest(createPutRequest(path + '/:id'), data, {id})
export const makeDeleteRequest = (path, id) => makeRequest(createDeleteRequest(path + '/:id'), {}, {id})
export const makeShowRequest = (path, id, includes, params) => makeRequest(createRequest(path + '/:id'), prepareRequest({}, includes, params), {id})
export const makeGetListRequest = (path, filters = {}, includes = {}, extraParams = {}) => makeRequest(createRequest(path), prepareRequest(filters, includes, extraParams))
export const makeResourceRequests = (resource) => {
    let requests = {}
    requests.list = (filters = {}, includes = [], extraParams={}) => makeGetListRequest(resource, filters, includes, extraParams)
    requests.get = (id, includes = [], params = {}) => makeShowRequest(resource, id, includes, params)
    requests.create = (data) => makeCreateRequest(resource, data)
    requests.update = (id, data) => makeUpdateRequest(resource, id, data)
    requests.delete = (id) => makeDeleteRequest(resource, id)
    return requests
}

export function getApi() {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });
}

export function createRequest(url, method = 'GET') {
    return {url: url, method: method}
}

export function createPostRequest(url) {
    return createRequest(url, 'POST')
}

export function createPutRequest(url) {
    return createRequest(url, 'PUT')
}

export function createDeleteRequest(url) {
    return createRequest(url, 'DELETE')
}

export function makeRequest({url, method}, params = {}, urlParams = {}) {
    for (let urlParam in urlParams) {
        if (urlParams.hasOwnProperty(urlParam)) {
            url = url.replace(':' + urlParam, urlParams[urlParam])
        }
    }
    switch (method.toLowerCase()) {
        case 'get':
            if (params.filter) {
                for (let filter in params.filter) {
                    if (params.filter.hasOwnProperty(filter)) {
                        params['filter[' + filter + ']'] = params.filter[filter];
                    }
                }
                params.filter = undefined;
            }
            params = {params: params};
            break;
        default:
            break;
    }
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
    if (method.toLowerCase() === 'GET') {
        params.headers = {
            Authorization: 'Bearer ' + token
        }
        return getApi()[method.toLowerCase()](url, params);
    }
    var headers = {};
    headers.headers = {
        Authorization: 'Bearer ' + token
    }
    return getApi()[method.toLowerCase()](url, params, headers);

}

export function getFilters(filters) {
    let result = [];
    let filterNames = Object.keys(filters)
    for (let i = 0; i < filterNames.length; i++) {
        let filterName = filterNames[i]
        result['filter[' + filterName + ']'] = filters[filterName]
    }
    return result;
}

export let prepareRequest = (filters = {}, includes = [], extraParams = {}) => {
    let request = {
        ...extraParams,
        ...getFilters(filters)
    }
    if (includes.length > 0) {
        request.include = includes.join(',')
    }
    return request
}