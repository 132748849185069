import React from "react";
import TextInput from "../../../core/form/TextInput";
import TextEditorInput from "../../../core/form/TextEditorInput";
import ImageInput from "../../../core/form/ImageInput";
import {useController, useFormContext} from "react-hook-form";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

let BusinessLogoField = ({loading}) => {

    let {setValue, watch, control, register} = useFormContext()

    const {
        field: {onChange}
    } = useController({
        name: 'logo.content',
        control
    })

    let logo = watch('logo')

    let setLogoData = (file, t, content) => {
        content = content.replace('data:image/jpeg;base64,', '')
            .replace('data:image/jpg;base64,', '')
            .replace('data:image/png;base64,', '');
        setValue('logo.name', file.name)
        setValue('logo.mimetype', file.type)
        onChange(content)
    }

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    return <ImageInput onAdd={setLogoData}
                       imageId={logo.id}
                tmpContent={ logo?.content}
    />

}

export default BusinessLogoField