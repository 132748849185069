import React from "react";
import moment from 'moment';
import 'moment/locale/pl';

const DayHeader = (props) => {

    let {date} = props;

    let getExtraClassess = () => {
        return date.format('Y-M-D') === moment().format('Y-M-D') ? 'active' : '';
    }

    return <div className={'day-header ' + (getExtraClassess())}>
        <span style={{textTransform: "uppercase"}}>{date.format('ddd')}</span>
        <span>{date.format('DD')}</span>
    </div>

}

export default DayHeader;
