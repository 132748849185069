import React from "react";
import SelectObjectsModal from "./SelectObjectsModal";
import {useDispatch} from 'react-redux';
import * as api from "../../core/api";
import {FACILITY_UPDATE_RESOURCES_SUCCESS} from "../../reducers/facility";
import {createAction} from "../../core/utils/appUtil";
import {useSelector} from 'react-redux'
import {fetchAllResources} from "../../actions/resources";
import {SERVICE_UPDATE_RESOURCES_SUCCESS} from "../../reducers/service";
import ResourceName from "../resource/ResourceName";

const SelectResourcesModal = (props) => {

    let dispatch = useDispatch();

    let type = props.type ?? 'facility';

    let resources = useSelector(state => state.resource.allIds)

    let onUpdate = (objectId, resources) => {
        if(type === 'facility') {
            dispatch(createAction(FACILITY_UPDATE_RESOURCES_SUCCESS, {
                facility_id: objectId,
                resources: resources
            }))
        } else if (type === 'service') {
            dispatch(createAction(SERVICE_UPDATE_RESOURCES_SUCCESS, {
                service_id: objectId,
                resources: resources
            }))
        }
    }

    let getAction = () => {
        if(type === 'facility') {
            return api.updateFacilityResources;
        } else if (type === 'service') {
            return api.updateServiceResources;
        }
    }

    let getTitle = () => {
        if(type === 'facility') {
            return 'Wybierz zasoby dostępne w tym obiekcie';
        } else if (type === 'service') {
            return 'Wybierz zasoby dostępne w tej usłudze';
        }
        return '';
    }

    React.useEffect(() => {
        if(!resources.length) {
            dispatch(fetchAllResources(true))
        }
    }, [dispatch])

    return <SelectObjectsModal
        {...props}
        title={getTitle()}
        stateKey='resource'
        renderElement={(element) => <ResourceName resourceId={element.id}/>}
        action={getAction()}
        onUpdate={onUpdate}
    />
}

export default SelectResourcesModal;

