import Echo from 'laravel-echo';
import {createAction} from "../core/utils/appUtil";
import {ADD_NOTIFICATION} from "../constants/actiontypes/notification";
import {toastSuccess} from "../core/utils/appUtil";
import pusher from 'pusher-js';

let handleNotification = (notification, dispatch) => {
    toastSuccess('Nowe powiadomienie!')
    dispatch(createAction(ADD_NOTIFICATION, notification))
};

export function initWebsockets(partnerId, dispatch) {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    const options = {
        broadcaster: 'pusher',
        key: '2855a685ad23ec8a4d32',
        cluster: 'eu',
        encrypted: true,
        authEndpoint: process.env.REACT_APP_API_URL + '/broadcasting/auth',
        auth: {
            headers: {
                'secure-key': 'QiTEqWsViaInQTvbjBYg',
                Authorization: 'Bearer ' + token,
            }
        }
    };
    let echoInstance = new Echo(options);
    // echoInstance.channel('my-channel').listen('.my-event', (e) => console.log(e));
    echoInstance.private('partners.' + partnerId).listen('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (notification) => handleNotification(notification, dispatch));
}

export default initWebsockets;
