import {RESOURCE_TYPE_REMOVE} from "../constants/actiontypes/resourceType";

export const RESOURCE_LIST_REQUEST = 'RESOURCE_LIST_REQUEST';
export const RESOURCE_LIST_SUCCESS = 'RESOURCE_LIST_SUCCESS';
export const RESOURCE_DETAILS_REQUEST = 'RESOURCE_DETAILS_REQUEST';
export const RESOURCE_DETAILS_SUCCESS = 'RESOURCE_DETAILS_SUCCESS';
export const RESOURCE_ADD_SUCCESS = 'RESOURCE_ADD_SUCCESS';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_SERVICES_SUCCESS = 'RESOURCE_UPDATE_SERVICES_SUCCESS';
export const RESOURCE_UPDATE_FACILITIES_SUCCESS = 'RESOURCE_UPDATE_FACILITIES_SUCCESS';
export const RESOURCE_REMOVE = 'RESOURCE_REMOVE';

var initialState = {
    byId: [],
    allIds: [],
    loading: true,
    loadingDetails: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.resource) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.resource
            }
        }
    }
    switch (action.type) {
        case RESOURCE_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case RESOURCE_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case RESOURCE_DETAILS_REQUEST: {
            return {
                ...state,
                loadingDetails: true
            }
        }
        case RESOURCE_DETAILS_SUCCESS:
            return {
                ...state,
                loadingDetails: false
            }
        case RESOURCE_ADD_SUCCESS: {
            return {
                ...state,
                allIds: [
                    ...state.allIds,
                    ...action.payload.result
                ]
            }
        }
        case RESOURCE_UPDATE_SERVICES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.resource_id]: {
                        ...state.byId[action.payload.resource_id],
                        services: action.payload.services
                    }
                }
            }
        }
        case RESOURCE_UPDATE_FACILITIES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.resource_id]: {
                        ...state.byId[action.payload.resource_id],
                        facilities: action.payload.facilities
                    }
                }
            }
        }
        case RESOURCE_REMOVE: {
            return {
                ...state,
                allIds: state.allIds.filter(id => id !== action.payload)
            }
        }
        default:
            return state;
    }
};