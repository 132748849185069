import React from "react";
import {Row, Col} from 'reactstrap'
import AccountPage from "./AccountPage";
import AccountPreferencesForm from "../../../components/forms/app/account/AccountPreferencesForm";

let AccountPreferencesPage = () => {

    return <AccountPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <AccountPreferencesForm/>
            </Col>
        </Row>
    </AccountPage>

}

export default AccountPreferencesPage
