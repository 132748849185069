import React from "react";
import {MdKeyboardArrowDown} from 'react-icons/md';
import {
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

let DatatablePagination = ({currentPage, lastPage = 1, page, setPage, perPage, setPerPage, perPages = [10, 20, 30]}) => {

    let prev = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    let next = () => {
        if (page < lastPage - 1) {
            setPage(page + 1)
        }
    }

    return <div className='pagination'>
        <div className='length'>
            <UncontrolledDropdown>
                            <span className="d-sm-inline-block">
                              <DropdownToggle nav>
                                  <span>{perPage}</span>
                                    <MdKeyboardArrowDown/>
                              </DropdownToggle>
                            </span>
                <DropdownMenu className="dropdown-menu-lg py-0">
                    {
                        perPages.map(option => <DropdownItem onClick={() => setPerPage(option)}>{option}</DropdownItem>)
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
        <div className='pages'>
            <div className='prev' onClick={prev}>{'<'}</div>
            {
                Array.from(Array(lastPage).keys()).map(page =>
                    page > 0 && <div className={'page ' + (page === currentPage ? 'current' : '')}
                                     onClick={() => setPage(page)}>{page}</div>
                )
            }
            <div className='next' onClick={next}>{'>'}</div>
        </div>
    </div>
}

export default DatatablePagination