export const LANGUAGE_FETCH_REQUEST = 'LANGUAGE_FETCH_REQUEST';
export const LANGUAGE_FETCH_SUCCESS = 'LANGUAGE_FETCH_SUCCESS';

var initialState = {
    byId: {},
    allIds: [],
    loading: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.language) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.language
            }
        }
    }
    switch (action.type) {
        case LANGUAGE_FETCH_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case LANGUAGE_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                allIds: action.payload.result
            }
        }
        default:
            return state;
    }
};