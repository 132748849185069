import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectServicesModal from "../../../components/modals/SelectServicesModal";
import {fetchAllServices} from "../../../actions/services";
import PartnerPage from "./PartnerPage";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import {fetchPartnerDetails} from "../../../actions/partners";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import {getPriceText, getServicePriceText} from "../../../core/utils/appUtil";
import {useIntl} from 'react-intl'

let PartnerServicesPage = () => {

    let params = useParams();

    let intl = useIntl()

    let partnerId = params.id

    let partner = useSelector(state => state.partner.byId[partnerId])

    let loading = useSelector(state => state.partner.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchPartnerDetails(partnerId, ['services']))
        dispatch(fetchAllServices())
    }, [partnerId, dispatch])

    let allServices = useSelector(state => state.service.byId)

    let hasServices = partner?.services && partner?.services.length > 0

    let [selectServiceModalOpened, setSelectServiceModalOpened] = useState(false);

    return <PartnerPage partnerId={partnerId}>
        {selectServiceModalOpened && partner && <SelectServicesModal
            type='partner'
            modalOpened={selectServiceModalOpened}
            setModalOpened={setSelectServiceModalOpened}
            defaultSelected={partner?.services}
            objectId={partner?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Świadczone usługi'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasServices && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasServices && partner?.services.map(serviceId => <tr>
                                    <td>
                                        {allServices[serviceId].name}
                                    </td>
                                    <td>
                                        {getServicePriceText(allServices[serviceId].price)}
                                    </td>
                                    <td>
                                        {allServices[serviceId].duration} min
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz usługi świadczone przez tego pracownika'
                                  variant='forward'
                                  onClick={() => setSelectServiceModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </PartnerPage>

}

export default PartnerServicesPage
