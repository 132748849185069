import React from "react";
import {useSelector} from 'react-redux';
import {RiEdit2Fill} from 'react-icons/ri'
import logo from '../../assets/img/missing-image.png';
import {Link} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_FACILITY_SHOW} from "../../routes/routeDefinitions";
import Button from "../../core/buttons/Button";


let Facility = ({facilityId}) => {

    let facility = useSelector(state => state.facility.byId[facilityId])

    let address = useSelector(state => state.address.byId[facility?.address])

    let facilityType = useSelector(state => state.facilityType.byId[facility.type_id])

    let images = useSelector(state => state.image.byId)

    let getImage = () => {
        if(facility.images?.length > 0) {
            return process.env.REACT_APP_API_URL + '/image/' + images[facility?.images[0]]?.image_id
        }
        return logo;
    }

    let addressData = [];
    if(address?.city) {
        addressData.push(address?.city)
    }
    if(address?.street) {
        addressData.push(address?.street)
    }
    if(address?.number) {
        addressData.push(address?.number)
    }

    let secondaryAddress = []
    if(address?.postcode) {
        secondaryAddress.push(address?.postcode)
    }
    if(address?.city) {
        secondaryAddress.push(address?.city)
    }

    return <div className='facility'>
        <span className='title'>{facility.name}</span>
        <img src={getImage()} className='img' alt='facility'/>
        <span className='type'>{facilityType.name}</span>
        <span className='address'>{addressData.join(' ')}</span>
        <span className='address'>{secondaryAddress.join(' ')}</span>
        <Link to={getRoutePath(ROUTE_FACILITY_SHOW, facilityId)}>
            <Button text='app.facility_update' Icon={RiEdit2Fill} rightIcon={true}/>
        </Link>
    </div>

}

export default Facility;

