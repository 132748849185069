import {INAVAILABILITY_CREATE_SUCCESS} from "../constants/actiontypes/inavailability";
import {ACCOUNT_UPDATE_SUCCESS} from "../constants/actiontypes/auth";
export const PARTNER_LIST_REQUEST = 'PARTNER_LIST_REQUEST';
export const PARTNER_LIST_SUCCESS = 'PARTNER_LIST_SUCCESS';
export const PARTNER_DETAILS_REQUEST = 'PARTNER_DETAILS_REQUEST';
export const PARTNER_DETAILS_SUCCESS = 'PARTNER_DETAILS_SUCCESS';
export const PARTNER_FACILITY_LIST_SUCCESS = 'PARTNER_FACILITY_LIST_SUCCESS';
export const PARTNER_CREATE_SUCCESS = 'PARTNER_CREATE_SUCCESS';
export const PARTNER_UPDATE_SERVICES_SUCCESS = 'PARTNER_UPDATE_SERVICES_SUCCESS';
export const PARTNER_UPDATE_FACILITIES_SUCCESS = 'PARTNER_UPDATE_FACILITIES_SUCCESS';
export const PARTNER_UPDATE_SUCCESS = 'PARTNER_UPDATE_SUCCESS';
export const PARTNER_INVITATION_SEND = 'PARTNER_INVITATION_SEND';

var initialState = {
    byId: {},
    allIds: [],
    loading: false,
    loadingDetails: false,
    byFacility: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.partner) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.partner
            }
        }
    }
    switch (action.type) {
        case PARTNER_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case PARTNER_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case PARTNER_DETAILS_REQUEST: {
            return {
                ...state,
                loadingDetails: true
            }
        }
        case PARTNER_DETAILS_SUCCESS:
            return {
                ...state,
                loadingDetails: false
            }
        case INAVAILABILITY_CREATE_SUCCESS: {
            if (action.payload.entities.inavailabilityHour[action.payload.result].partner_id) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.entities.inavailabilityHour[action.payload.result].partner_id]: {
                            ...state.byId[action.payload.entities.inavailabilityHour[action.payload.result].partner_id],
                            inavailability_hours: [
                                ...state.byId[action.payload.entities.inavailabilityHour[action.payload.result].partner_id].inavailability_hours,
                                action.payload.result
                            ]
                        }
                    }
                }
            }
            return state;
        }
        case PARTNER_CREATE_SUCCESS: {
            return {
                ...state,
                allIds: [
                    ...state.allIds,
                    action.payload.result
                ]
            }
        }
        case PARTNER_UPDATE_SERVICES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.partner_id]: {
                        ...state.byId[action.payload.partner_id],
                        services: action.payload.services
                    }
                }
            }
        }
        case PARTNER_UPDATE_FACILITIES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.partner_id]: {
                        ...state.byId[action.payload.partner_id],
                        facilities: action.payload.facilities
                    }
                }
            }
        }
        case PARTNER_FACILITY_LIST_SUCCESS: {
            return {
                ...state,
                byFacility: {
                    ...state.byFacility,
                    [action.payload.facility_id]: action.payload.result
                }
            }
        }
        case ACCOUNT_UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        ...action.payload
                    }
                }
            }
        }
        default:
            return state;
    }
};