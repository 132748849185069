import React from "react";
import SelectInput from "../../../core/form/select/SelectInput";

let MaxReservationChangeTimeField = ({loading = false}) => {

    let options = [
        {
            value: 30,
            label: 'Do pół godziny przed rezerwacją'
        },
        {
            value: 60,
            label: 'Do godziny przed rezerwacją'
        },
        {
            value: 60 * 24,
            label: 'Do dnia przed rezerwacją'
        }
    ]

    let fieldName = 'max_reservation_change_time'

    return <SelectInput
        name={fieldName}
        label={'form.business.' + fieldName}
        loading={loading}
        options={options}
        information={'form.business.' + fieldName + '_information'}/>
}

export default MaxReservationChangeTimeField