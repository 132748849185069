import React from "react";
import SelectInput from "../../../core/form/select/SelectInput";

let MinTimeBeforeReservationField = ({loading = false}) => {

    let options = [
        {value: 0, label: 'BRAK'}
    ];
    options = [...options,
        ...[30, 45].map(val => {
            return {
                value: val,
                label: val + ' min.'
            }
        })
    ];

    return <SelectInput
        name='min_time_before_reservation'
        label='form.business.min_time_before_reservation'
        loading={loading}
        options={options}
        information='form.business.min_time_before_reservation_information'
    />

}

export default MinTimeBeforeReservationField