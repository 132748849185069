import React from "react";
import {useForm} from "react-hook-form";
import Button from "../../../../../core/buttons/Button";
import AppForm from "../../../../forms/AppForm";
import RentalDetailsForm from "../../RentalDetailsForm";
import RentalTermForm from "./RentalTermForm";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import ReservationSummaryForm from "./ReservationSummaryForm";
import {normalize} from 'normalizr';
import {createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {
    RESERVATION_MODAL_CLOSE,
    RESERVATION_MODAL_OPEN_MAIN_FORM,
} from "../../../../../reducers/reservationModal";
import api from "../../../../../core/api";
import {RESERVATION_ADD_SUCCESS} from "../../../../../reducers/reservation";
import schemas from "../../../../../reducers/schemas";
let SingleRentalReservationForm = () => {

    let dispatch = useDispatch()

    let [step, setStep] = React.useState(1)

    let [submitting, setSubmitting] = React.useState(false)

    let {
        date: selectedDate,
        resourceId
    } = useSelector(state => state.reservationModal)

    let facilityPart = useSelector(state => state.facilityPart.byId[resourceId])

    let facility = useSelector(state => state.facility.byId[resourceId])

    let formContext = useForm({
        defaultValues: {
            any_facility_part: true,
            rental_type: 'rental',
            date: moment(selectedDate).format('Y-MM-DD'),
            time_from: moment(selectedDate).format('HH:mm'),
            lighting: false,
            facility_id: facility?.id ? facility.id : (facilityPart?.facility_id ?? null),
            custom_price: false
        }
    })

    let {handleSubmit} = formContext

    let submit = async (data) => {
        if (step < 3) {
            setStep(step + 1)
            return;
        }
        let formData = {
            client_id: data.client_id,
            service_id: data.service_id,
            date: data.date,
            time_from: data.time_from,
            time_to: data.time_to,
            lighting_price: data.lighting ? data.lighting_price : null,
            price: data.price,
            facility_part_id: data.facility_part_id,
            facility_id: data.facility_id,
            lighting: data.lighting,
            custom_price: data.custom_price,
            include: ['facilityParts'],
            payment_method_id: data.paid ? data.payment_method_id : null
        }
        setSubmitting(true)
        api.reservation.create(formData).then((response) => {
            dispatch(createAction(RESERVATION_ADD_SUCCESS, normalize(response.data, schemas.reservationSchema)))
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            toastSuccess('Dodano rezerwację')
        }).catch(error => {
            toastError('Wystpił bład podczas dodawania rezerwacji!')
            setSubmitting(false)
        })
    }

    let cancel = () => {
        if (step > 1) {
            setStep(step - 1)
        }
        if (step === 1) {
            dispatch(createAction(RESERVATION_MODAL_OPEN_MAIN_FORM))
        }
    }

    return <>
        <AppForm onSubmit={submit} formContext={formContext} className='content single-rental-reservation-form'>
            {step === 1 && <RentalDetailsForm/>}
            {step === 2 && <RentalTermForm/>}
            {step === 3 && <ReservationSummaryForm/>}
        </AppForm>
        <div className='dialog-footer'>
            <div className='buttons'>
                <Button text='Wstecz' onClick={cancel} variant='secondary'/>
                <Button text={step === 3 ? 'Zarezerwuj' : 'Dalej'} loading={submitting} onClick={handleSubmit(submit)}/>
            </div>
        </div>
    </>
}

export default SingleRentalReservationForm