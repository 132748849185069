import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {MdKeyboardArrowRight} from 'react-icons/md'
import PartnerPage from "./PartnerPage";
import SelectFacilitiesModal from "../../../components/modals/SelectFacilitiesModal";
import {fetchAllFacilities} from "../../../actions/facilities";
import {fetchPartnerDetails} from "../../../actions/partners";
import Button from "../../../core/buttons/Button";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";

let PartnerFacilitiesPage = () => {

    let params = useParams();
    let partnerId = params.id

    let partner = useSelector(state => state.partner.byId[partnerId])

    let loading = useSelector(state => state.partner.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAllFacilities())
        dispatch(fetchPartnerDetails(partnerId, ['facilities']))
    }, [partnerId, dispatch])

    let allFacilities = useSelector(state => state.facility.byId)

    let hasFacilities = partner?.facilities && partner?.facilities.length > 0

    let [selectServiceModalOpened, setSelectFacilitiesModalOpened] = useState(false);

    return <PartnerPage partnerId={partnerId}>
        {selectServiceModalOpened && partner && <SelectFacilitiesModal
            type='partner'
            modalOpened={selectServiceModalOpened}
            setModalOpened={setSelectFacilitiesModalOpened}
            defaultSelected={partner?.facilities}
            objectId={partner?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Obiekty'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasFacilities && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasFacilities && partner?.facilities.map(facilityId => <tr>
                                    <td>
                                        {allFacilities[facilityId].name}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz obiekty, w których usługi świadczy ten pracownik'
                                  variant='forward'
                                  onClick={() => setSelectFacilitiesModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </PartnerPage>

}

export default PartnerFacilitiesPage
