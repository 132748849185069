import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import PartnerPage from "./PartnerPage";
import PartnerDetailsForm from "../../../components/forms/app/partner/PartnerDetailsForm";
import {fetchPartnerDetails} from "../../../actions/partners";

let PartnerDetailsPage = () => {

    let params = useParams();

    let partnerId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchPartnerDetails(partnerId, ['roles']))
    }, [partnerId, dispatch])

    return <PartnerPage partnerId={partnerId}>
        <Row className='fitheight'>
            <Col lg={{size: 8, offset: 2}}>
                <PartnerDetailsForm partnerId={partnerId}/>
            </Col>
        </Row>
    </PartnerPage>

}

export default PartnerDetailsPage
