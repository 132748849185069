import React from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {AVAILABILITY_TYPE_INHERIT, AVAILABILITY_TYPE_CUSTOM} from "../../core/constants/reservationStatuses";
import FormWrapper from "../../core/form/FormWrapper";

let OpeningHoursTypeSelect = ({onChange, value, formType}) => {

    return <FormWrapper>
        <FormControl fullWidth>
            <InputLabel>Dostępność</InputLabel>
            <Select
                onChange={(val) => onChange(val.target.value)}
                value={value}>
                <MenuItem key={AVAILABILITY_TYPE_INHERIT} value={AVAILABILITY_TYPE_INHERIT}>Dostępność w godzinach pracy {formType === 'facility' ? 'biznesu' : 'obiektu'}</MenuItem>
                <MenuItem key={AVAILABILITY_TYPE_CUSTOM} value={AVAILABILITY_TYPE_CUSTOM}>Własne ustawienia dostępności</MenuItem>
            </Select>
        </FormControl>
    </FormWrapper>
}

export default OpeningHoursTypeSelect