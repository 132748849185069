import {
    DASHBOARD_RESERVATION_MODAL_CLOSE,
    DASHBOARD_CREATE_RESERVATION_MODAL_SHOW,
    DASHBOARD_CREATE_RESERVATION_SUCCESS,
    DASHBOARD_EDIT_RESERVATION_MODAL_SHOW,
    DASHBOARD_FETCH_CLIENTS_SUCCESS,
    DASHBOARD_FETCH_FACILITIES_SUCCESS,
    DASHBOARD_FETCH_RESERVATIONS_REQUEST,
    DASHBOARD_FETCH_RESERVATIONS_SUCCESS,
    DASHBOARD_FETCH_SERVICES_SUCCESS,
    DASHBOARD_SHOW_RESERVATION_MODAL_CLOSE,
    DASHBOARD_SHOW_RESERVATION_MODAL_SHOW,
    DASHBOARD_UPDATE_RESERVATION_SUCCESS,
    DASHBOARD_SET_CURRENT_DATE,
    DASHBOARD_TOGGLE_FILTER_PANEL,
    DASHBOARD_INIT_CALENDAR,
    DASHBOARD_SET_GLOBAL_FILTER,
    DASHBOARD_TOGGLE_FILTER,
    DASHBOARD_RESET_FILTERS,
    DASHBOARD_FETCH_RESOURCES_SUCCESS,
    DASHBOARD_FETCH_PARTNERS_SUCCESS,
    DASHBOARD_FETCH_RESERVATION_REQUEST, DASHBOARD_FETCH_RESERVATION_SUCCESS, DASHBOARD_FETCH_BLOCKS_SUCCESS
} from "../constants/actiontypes/dashboard";
import {RESERVATION_UPDATE_SUCCESS} from "../constants/actiontypes/reservations";
import {RESERVATION_ADD_SUCCESS} from "./reservation";
import {
    FINISHED,
    RESERVATION_STATUS_CONFIRMED,
    WAITING_FOR_BUSINESS_APPROVAL
} from "../core/constants/reservationStatuses";
import {BLOCK_CREATE_SUCCESS} from "./block";

var initialState = {
    loadingCalendar: false,
    loading: true,
    filterOpen: false,
    currentDate: null,
    next: false,
    loadingReservations: false,
    reservation_modal: {
        loading: false,
        show: false,
        reservation_id: null,
        initial_date: null
    },
    reservation_details_modal: {
        show: false,
        reservation_id: null
    },
    clients: [],
    facilities: [],
    services: [],
    globalFilter: '',
    filters: {
        facilities: [],
        services: [],
        clients: [],
        partners: [],
    },
    resources: [],
    partners: [],
    reservationIds: [],
    blockIds: []
};
export const DASHBOARD_SET_NEXT = 'DASHBOARD_SET_NEXT'

export default (state = initialState, action) => {
    switch (action.type) {
        case RESERVATION_ADD_SUCCESS: {
            return {
                ...state,
                reservationIds: [
                    ...state.reservationIds,
                    action.payload.result
                ]
            }
        }
        case BLOCK_CREATE_SUCCESS: {
            return {
                ...state,
                blockIds: [
                    ...state.blockIds,
                    action.payload.result
                ]
            }
        }
        case DASHBOARD_FETCH_RESERVATIONS_REQUEST: {
            return {
                ...state,
                loadingCalendar: true,
                loadingReservations: false,
                reservations: []
            }
        }
        case DASHBOARD_FETCH_RESERVATIONS_SUCCESS: {
            return {
                ...state,
                loadingCalendar: false,
                loadingReservations: false,
                reservationIds: action.payload.result,
            }
        }
        case DASHBOARD_FETCH_BLOCKS_SUCCESS: {
            return {
                ...state,
                blockIds: action.payload.result,
            }
        }
        case DASHBOARD_CREATE_RESERVATION_MODAL_SHOW: {
            return {
                ...state,
                reservation_modal: {
                    ...state.reservation_modal,
                    show: true,
                    initial_date: action.payload,
                    reservation_id: null
                },
            }
        }
        case DASHBOARD_RESERVATION_MODAL_CLOSE: {
            return {
                ...state,
                reservation_modal: {
                    ...state.reservation_modal,
                    show: false
                }
            }
        }
        case DASHBOARD_FETCH_CLIENTS_SUCCESS: {
            return {
                ...state,
                clients: action.payload
            }
        }
        case DASHBOARD_FETCH_FACILITIES_SUCCESS: {
            return {
                ...state,
                facilities: action.payload.result
            }
        }
        case DASHBOARD_FETCH_SERVICES_SUCCESS: {
            return {
                ...state,
                services: action.payload.result
            }
        }
        case DASHBOARD_CREATE_RESERVATION_SUCCESS: {
            return {
                ...state,
                reservation_modal: {
                    show: false
                },
                // reservations: {
                //     ...state.reservations,
                //     [action.payload.id]: action.payload
                // }
            }
        }
        case DASHBOARD_SHOW_RESERVATION_MODAL_SHOW: {
            return {
                ...state,
                reservation_details_modal: {
                    ...state.reservation_details_modal,
                    show: true,
                    reservation_id: state.reservations[action.payload]
                }
            }
        }
        case DASHBOARD_SHOW_RESERVATION_MODAL_CLOSE: {
            return {
                ...state,
                reservation_details_modal: {
                    ...state.reservation_details_modal,
                    show: false,
                    reservation_id: null
                }
            }
        }
        case DASHBOARD_EDIT_RESERVATION_MODAL_SHOW: {
            return {
                ...state,
                reservation_details_modal: {
                    show: false
                },
                reservation_modal: {
                    show: true,
                    reservation_id: action.payload
                }
            }
        }
        case DASHBOARD_UPDATE_RESERVATION_SUCCESS: {
            return {
                ...state,
                reservation_modal: {
                    ...state.reservation_modal,
                    show: false,
                    reservation_id: null
                },
                reservations: {
                    ...state.reservations,
                    [action.payload.id]: action.payload
                }
            }
        }
        case DASHBOARD_SET_CURRENT_DATE: {
            return {
                ...state,
                currentDate: action.payload
            }
        }
        case DASHBOARD_TOGGLE_FILTER_PANEL: {
            return {
                ...state,
                filterOpen: !state.filterOpen
            }
        }
        case DASHBOARD_INIT_CALENDAR: {
            return {
                ...state,
                loading: false,
                currentDate: action.payload
            }
        }
        case DASHBOARD_SET_GLOBAL_FILTER: {
            return {
                ...state,
                globalFilter: action.payload
            }
        }
        case DASHBOARD_TOGGLE_FILTER: {
            if (state.filters[action.payload.type].filter(filter => filter === action.payload.id).length > 0) {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.payload.type]: state.filters[action.payload.type].filter(filter => filter !== action.payload.id)
                    }
                }
            } else {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.payload.type]: [
                            ...state.filters[action.payload.type], action.payload.id
                        ]
                    }
                }
            }
        }
        case DASHBOARD_RESET_FILTERS: {
            return {
                ...state,
                globalFilter: '',
                filters: {
                    services: [],
                    statuses: [],
                    facilities: [],
                    partners: []
                }
            }
        }
        case DASHBOARD_FETCH_RESOURCES_SUCCESS: {
            return {
                ...state,
                resources: action.payload.result
            }
        }
        case DASHBOARD_FETCH_PARTNERS_SUCCESS: {
            return {
                ...state,
                partners: action.payload.result
            }
        }
        case DASHBOARD_FETCH_RESERVATION_REQUEST: {
            return {
                ...state,
                reservation_modal: {
                    ...state.reservation_modal,
                    loading: true
                }
            }
        }
        case DASHBOARD_FETCH_RESERVATION_SUCCESS: {
            return {
                ...state,
                reservation_modal: {
                    ...state.reservation_modal,
                    loading: false
                }
            }
        }
        case DASHBOARD_SET_NEXT: {
            return {
                ...state,
                next: action.payload
            }
        }
        default:
            return state;
    }
};