import React from "react";
import TextInput from "../../../core/form/TextInput";
import TextEditorInput from "../../../core/form/TextEditorInput";

let FacilityDescriptionField = ({loading = false}) => {

    return <TextEditorInput
        name='description'
        label='form.facility.description'
        multiline={true}
        minRows={2}
        loading={loading}
    />

}

export default FacilityDescriptionField