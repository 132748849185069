import {RESOURCE_TYPE_REMOVE} from "../constants/actiontypes/resourceType";

export const RESOURCE_TYPE_LIST_REQUEST = 'RESOURCE_TYPE_LIST_REQUEST';
export const RESOURCE_TYPE_LIST_SUCCESS = 'RESOURCE_TYPE_LIST_SUCCESS';
export const RESOURCE_TYPE_ADD_SUCCESS = 'RESOURCE_TYPE_ADD_SUCCESS';
export const RESOURCE_TYPE_UPDATE_SUCCESS = 'RESOURCE_TYPE_UPDATE_SUCCESS';

var initialState = {
    byId: [],
    allIds: [],
    loading: false,
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.resourceType) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.resourceType
            }
        }
    }
    switch (action.type) {
        case RESOURCE_TYPE_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case RESOURCE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case RESOURCE_TYPE_ADD_SUCCESS: {
            return {
                ...state,
                allIds: [
                    ...state.allIds,
                    action.payload.result
                ]
            }
        }
        case RESOURCE_TYPE_REMOVE: {
            return {
                ...state,
                allIds: state.allIds.filter(id => id !== action.payload)
            }
        }
        default:
            return state;
    }
};