import React from "react";
import TextInput from "../../../core/form/TextInput";

let FacilityInstagramField = ({loading = false}) => {

    return <TextInput
        name='instagram'
        label='form.facility.instagram'
        required={true}
        loading={loading}
    />

}

export default FacilityInstagramField