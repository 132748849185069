import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import handleError from "../core/utils/errorUtil";
import {
    SERVICE_DETAILS_REQUEST,
    SERVICE_DETAILS_SUCCESS,
    SERVICE_LIST_REQUEST,
    SERVICE_LIST_SUCCESS
} from "../reducers/service";
import {PRICE_FORM_INIT, PRICE_FORM_RESET} from "../reducers/priceForm";

export function fetchAllServices(includes = [], filters = {}) {
    return function (dispatch) {
        dispatch(createAction(SERVICE_LIST_REQUEST));
        return api.service.list(filters, includes)
            .then((response) => {
                dispatch(createAction(SERVICE_LIST_SUCCESS, normalize(response.data, [schemas.serviceSchema])));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchServiceDetails(serviceId, includes = []) {
    return function (dispatch) {
        dispatch(createAction(SERVICE_DETAILS_REQUEST));
        return api.service.get(serviceId, includes)
            .then(response => dispatch(createAction(SERVICE_DETAILS_SUCCESS, normalize(response.data, schemas.serviceSchema))))
            .catch(error => handleError(error, dispatch))
    }
}

export function initServicePricePage(serviceId) {
    return function (dispatch) {
        dispatch(createAction(PRICE_FORM_RESET))
        dispatch(createAction(SERVICE_DETAILS_REQUEST))
        return api.service.get(serviceId)
            .then(response => {
                dispatch(createAction(SERVICE_DETAILS_SUCCESS, normalize(response.data, schemas.serviceSchema)))
                dispatch(createAction(PRICE_FORM_INIT, {
                    service: response.data
                }))
            })
            .catch(error => handleError(error, dispatch))
    }
}