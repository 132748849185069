import React from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import {getFacilityTypeText} from "../../../../../utils/facilityTypeUtil";

let RentalTermForm = ({}) => {
    let formContext = useFormContext()

    let {getValues} = formContext
    let formData = getValues()
    let anyFacilityPart = formData.any_facility_part
    let facilityPart = useSelector(state => state.facilityPart.byId[formData.facility_part_id])
    let client = useSelector(state => state.clients.byId[formData.client_id])
    let facility = useSelector(state => state.facility.byId[formData.facility_id])
    let facilityType = useSelector(state => state.facilityType.byId[facility.type_id])
    let service = useSelector(state => state.service.byId[formData.service_id])

    return <div>
        <div>
            <div className='reservation-details-info'>
                <p><b>Usługa: </b>{service?.name}</p>
                {
                    service.required_facility_parts > 0 && <p>
                        <b>{getFacilityTypeText(facilityType.code, 'part')}:</b> {anyFacilityPart ? 'dowolny' : facilityPart?.name}
                    </p>
                }
                <p><b>Klient:</b> {client?.fullname}</p>
                <p><b>Termin:</b> {formData.date} {formData.time_from} - {formData.time_to}</p>
                {service.price.lighting &&
                    <p>
                        <b>Dodatkowe oświetlenie:</b>
                        {formData.lighting ? 'TAK' : 'NIE'}
                    </p>}
                <p><b>Cena:</b> {formData.price} PLN</p>
            </div>
        </div>
    </div>
}

export default RentalTermForm