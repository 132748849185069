import {createAction} from "../core/utils/appUtil";
import {
    RESERVATION_STATUS_UPDATE_SUCCESS, RESERVATION_UPDATE_SUCCESS
} from "../constants/actiontypes/reservations";
import api from "../core/api";
import {toastSuccess} from "../core/utils/appUtil";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {RESERVATION_MODAL_SHOW_RENTAL_RESERVATION} from "../reducers/reservationModal";
import {
    FINISHED,
    RESERVATION_STATUS_CONFIRMED,
    WAITING_FOR_BUSINESS_APPROVAL
} from "../core/constants/reservationStatuses";

import moment from 'moment';

export function updateReservationStatus(reservationId, status) {
    return function (dispatch) {
        return api.reservation.update(reservationId, {status}).then((response) => {
            dispatch(createAction(RESERVATION_STATUS_UPDATE_SUCCESS, {
                reservation_id: reservationId,
                status: status
            }));
            toastSuccess('Zapisano')
        }).catch(error => handleError(error, dispatch));
    };
}

export function openReservationDetailsPage(reservationId, history) {}


export function updateReservation(reservationId, formData, dispatch) {
    return api.reservation.update(reservationId, formData).then((response) => {
        dispatch(createAction(RESERVATION_UPDATE_SUCCESS, normalize(response.data, schemas.reservationSchema)))
        return response.data;
    }).catch(error => handleError(error, dispatch))
}

export function openReservation(reservationId, dispatch) {
    dispatch(createAction(RESERVATION_MODAL_SHOW_RENTAL_RESERVATION, reservationId))
}

export const getPriceStatusText = (status) => {
    if(status === 'paid') {
        return 'Opłacona';
    }
    if(status === 'unpaid') {
        return 'Nie opłacona';
    }
    if(status === 'cancelled') {
        return 'Anulowana';
    }
    if(status === 'returned') {
        return 'Zwrócona';
    }
    if(status === 'to-return') {
        return 'Do zwrotu';
    }
    return '-';
}

export const getReservationStatus = reservation => {
    if(!reservation.confirmed) {
        return WAITING_FOR_BUSINESS_APPROVAL;
    }
    if(reservation.endtime < moment().format('YYYY-MM-DD HH:ii')) {
        return FINISHED
    }
    return RESERVATION_STATUS_CONFIRMED;
}