import React from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import ResourcePage from "./ResourcePage";
import {fetchResource} from "../../../actions/resources";
import ResourceDetailsForm from "../../../components/forms/app/resource/ResourceDetailsForm";

let ResourceDetailsPage = () => {

    let params = useParams();

    let resourceId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchResource(resourceId, ['facilities']))
    }, [resourceId, dispatch])

    return <ResourcePage resourceId={resourceId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <ResourceDetailsForm resourceId={resourceId}/>
            </Col>
        </Row>
    </ResourcePage>

}

export default ResourceDetailsPage
