import React from "react";
import {FiMinusCircle} from 'react-icons/fi'
import {AiFillPlusCircle} from 'react-icons/ai'
import {useSelector, useDispatch} from 'react-redux'
import HourInput from "./HourInput";
import {createAction} from "../../core/utils/appUtil";
import {
    OPENING_HOUR_FORM_CHANGE_TIME
} from "../../reducers/openingHourForm";
import {decreaseTime, increaseTime} from "../../core/utils/appUtil";

const HourRow = ({
                     index,
                     day,
                     data,
                     disabled,
                     canAdd = false,
                     canRemove = false,
                     onAdd,
                     onRemove,
                     updateDay,
                     includeMidnight = false
                 }) => {

    let dispatch = useDispatch()

    let updateStartTime = time => {
        dispatch(createAction(OPENING_HOUR_FORM_CHANGE_TIME, {
            index,
            day,
            time: {...data, time_from: time}
        }))
    }
    let updateEndTime = time => {
        dispatch(createAction(OPENING_HOUR_FORM_CHANGE_TIME, {
            index,
            day,
            time: {...data, time_to: time}
        }))
    }

    let {
        hours
    } = useSelector(state => state.openingHourForm.days[day])

    let getMinTime = (checkPrevious = false) => {
        if (checkPrevious) {
            if (index > 0) {
                let previousHour = hours[index - 1]
                return previousHour.time_to
            } else {
                return {
                    hour: 0,
                    minute: 0
                }
            }
        }
        return increaseTime(data.time_from)
    }

    let getMaxTime = (checkNext = false) => {
        if (checkNext) {
            if (index < hours.length - 1) {
                let nextHour = hours[index + 1]
                return nextHour.time_from
            } else {
                return {
                    hour: 23,
                    minute: 55
                }
            }
        }
        return decreaseTime(data.time_to)
    }

    return <div className='hour-row'>
        <HourInput disabled={disabled}
                   start={true}
                   data={data.time_from}
                   update={updateStartTime}
                   minHour={getMinTime(true).hour}
                   minMinute={getMinTime(true).minute}
                   maxHour={getMaxTime().hour}
                   maxMinute={getMaxTime().minute}
        />
        <HourInput disabled={disabled}
                   start={false}
                   data={data.time_to}
                   includeMidnight={includeMidnight}
                   minHour={getMinTime().hour}
                   minMinute={getMinTime().minute}
                   maxHour={getMaxTime(true).hour}
                   maxMinute={getMaxTime(true).minute}
                   update={updateEndTime}/>
        {canRemove && <FiMinusCircle className='remove' onClick={onRemove}/>}
        {canAdd && <AiFillPlusCircle className='add' onClick={onAdd}/>}
    </div>

};

export default HourRow;
