import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessWebsiteField = () => {

    return <TextInput
        name='website'
        label='form.business.website'
    />

}

export default BusinessWebsiteField