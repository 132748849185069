import React from 'react';
import * as routes from "./routeDefinitions";
import ServicesPage from "../pages/dashboard/service/ServicesPage";
import FacilityDetailsPage from "../pages/dashboard/facility/FacilityDetailsPage";
import FacilitiesPage from "../pages/dashboard/facility/FacilitiesPage";
import FacilityOpeningHoursPage from "../pages/dashboard/facility/FacilityOpeningHoursPage";
import FacilityServicesPage from "../pages/dashboard/facility/FacilityServicesPage";
import FacilityResourcesPage from "../pages/dashboard/facility/FacilityResourcesPage";
import FacilityGalleryPage from "../pages/dashboard/facility/FacilityGalleryPage";
import PartnersPage from "../pages/dashboard/partners/PartnersPage";
import ClientsPage from "../pages/dashboard/clients/ClientsPage";
import BusinessDetailsPage from "../pages/dashboard/business/BusinessDetailsPage";
import AccountDetailsPage from "../pages/dashboard/account/AccountDetailsPage";
import ServiceDetailsPage from "../pages/dashboard/service/ServiceDetailsPage";
import PartnerDetailsPage from "../pages/dashboard/partners/PartnerDetailsPage";
import ClientDetailsPage from "../pages/dashboard/clients/ClientDetailsPage";
import FacilityPartnersPage from "../pages/dashboard/facility/FacilityPartnersPage";
import ResourcesPage from "../pages/dashboard/resource/ResourcesPage";
import {ROUTE_LOGOUT} from "./routeDefinitions";
import Logout from "../components/Logout";
import PartnerAvailabilityPage from "../pages/dashboard/partners/PartnerAvailabilityPage";
import PartnerFacilitiesPage from "../pages/dashboard/partners/PartnerFacilitiesPage";
import PartnerServicesPage from "../pages/dashboard/partners/PartnerServicesPage";
import ServiceFacilitiesPage from "../pages/dashboard/service/ServiceFacilitiesPage";
import ServicePartnersPage from "../pages/dashboard/service/ServicePartnersPage";
import ServicePricePage from "../pages/dashboard/service/ServicePricePage";
import ServiceResourcesPage from "../pages/dashboard/service/ServiceResourcesPage";
import ServiceReservationConfigPage from "../pages/dashboard/service/ServiceReservationConfigPage";
import FacilityContactPage from "../pages/dashboard/facility/FacilityContactPage";
import ResourceDetailsPage from "../pages/dashboard/resource/ResourceDetailsPage";
import ResourceAvailabilityPage from "../pages/dashboard/resource/ResourceAvailabilityPage";
import ResourceFacilitiesPage from "../pages/dashboard/resource/ResourceFacilitiesPage";
import ResourceServicesPage from "../pages/dashboard/resource/ResourceServicesPage";
import PermissionPage from "../pages/dashboard/permission/PermissionPage";
import BusinessReservationSettingsPage from "../pages/dashboard/business/BusinessReservationSettingsPage";
import BusinessSubscriptionPage from "../pages/dashboard/business/BusinessSubscriptionPage";
import AccountNotificationsPage from "../pages/dashboard/account/AccountNotificationsPage";
import AccountPreferencesPage from "../pages/dashboard/account/AccountPreferencesPage";
import AccountPasswordPage from "../pages/dashboard/account/AccountPasswordPage";
import FacilityResourceTypePage from "../pages/dashboard/facility/FacilityResourceTypePage";
import BusinessAvailabilityPage from "../pages/dashboard/business/BusinessAvailabilityPage";
import BusinessContactPage from "../pages/dashboard/business/BusinessContactPage";
import FacilityRentalPage from "../pages/dashboard/facility/FacilityRentalPage";
import BusinessGalleryPage from "../pages/dashboard/business/BusinessGalleryPage";
import FacilityCreatePage from "../pages/dashboard/facility/FacilityCreatePage";
import FacilityAddressPage from "../pages/dashboard/facility/FacilityAddressPage";
import FacilityPartsPage from "../pages/dashboard/facility/FacilityPartsPage";
import FacilityPartDetailsPage from "../pages/dashboard/facilityPart/FacilityPartDetailsPage";
import PaymentsListPage from "../pages/dashboard/payment/PaymentsListPage";
import ActivitiesPage from "../pages/dashboard/activities/ActivitiesPage";

let createRoute = (r, Component, render = null, pageProps = {}) => {
    return {route: r, render: (render ?? (() => <Component {...pageProps}/>))}
};

let getFacilityRoutes = () => [
    createRoute(routes.ROUTE_FACILITY_CREATE, FacilityCreatePage),
    createRoute(routes.ROUTE_FACILITY_RENTAL, FacilityRentalPage),
    createRoute(routes.ROUTE_FACILITY_SERVICES, FacilityServicesPage),
    createRoute(routes.ROUTE_FACILITY_PERSONNEL, FacilityPartnersPage),
    createRoute(routes.ROUTE_FACILITY_ADDRESS, FacilityAddressPage),
    createRoute(routes.ROUTE_FACILITY_RESOURCES, FacilityResourcesPage),
    createRoute(routes.ROUTE_FACILITY_OPENING_HOURS, FacilityOpeningHoursPage),
    createRoute(routes.ROUTE_FACILITY_GALLERY, FacilityGalleryPage),
    createRoute(routes.ROUTE_FACILITY_SHOW, FacilityDetailsPage),
    createRoute(routes.ROUTE_FACILITY_CONTACT, FacilityContactPage),
    createRoute(routes.ROUTE_FACILITY_LIST, FacilitiesPage),
    createRoute(routes.ROUTE_FACILITY_RESOURCE_TYPE, FacilityResourceTypePage),
    createRoute(routes.ROUTE_FACILITY_PARTS, FacilityPartsPage),
    createRoute(routes.ROUTE_FACILITY_PART_DETAILS, FacilityPartDetailsPage)
]

let getPaymentRoutes = () => [
    createRoute(routes.ROUTE_PAYMENTS_PAID_LIST, PaymentsListPage, null, {status: 'paid'}),
    createRoute(routes.ROUTE_PAYMENTS_UNPAID_LIST, PaymentsListPage, null, {status: 'unpaid'}),
    createRoute(routes.ROUTE_PAYMENTS_RETURNED_LIST, PaymentsListPage, null, {status: 'returned'}),
    createRoute(routes.ROUTE_PAYMENTS_TO_RETURN_LIST, PaymentsListPage, null, {status: 'to-return'}),
]

let getServiceRoutes = () => [
    createRoute(routes.ROUTE_SERVICE_DETAILS, ServiceDetailsPage),
    createRoute(routes.ROUTE_SERVICE_FACILITIES, ServiceFacilitiesPage),
    createRoute(routes.ROUTE_SERVICE_PARTNERS, ServicePartnersPage),
    createRoute(routes.ROUTE_SERVICE_PRICE, ServicePricePage),
    createRoute(routes.ROUTE_SERVICE_RESOURCES, ServiceResourcesPage),
    createRoute(routes.ROUTE_SERVICE_RESERVATIONS_CONFIG, ServiceReservationConfigPage),
    createRoute(routes.ROUTE_SERVICE_LIST, ServicesPage)
]

let getPartherRoutes = () => [
    createRoute(routes.ROUTE_PARTNER_AVAILABILITY, PartnerAvailabilityPage),
    createRoute(routes.ROUTE_PARTNER_FACILITIES, PartnerFacilitiesPage),
    createRoute(routes.ROUTE_PARTNER_SERVICES, PartnerServicesPage),
    createRoute(routes.ROUTE_PARTNER_SHOW, PartnerDetailsPage),
    createRoute(routes.ROUTE_PARTNER_LIST, PartnersPage),
]

let getClientRoutes = () => [
    createRoute(routes.ROUTE_CLIENT_SHOW, ClientDetailsPage),
    createRoute(routes.ROUTE_CLIENT_LIST, ClientsPage),
]

let getResourceRoutes = () => [
    createRoute(routes.ROUTE_RESOURCE_SHOW, ResourceDetailsPage),
    createRoute(routes.ROUTE_RESOURCE_AVAILABILITY, ResourceAvailabilityPage),
    createRoute(routes.ROUTE_RESOURCE_FACILITIES, ResourceFacilitiesPage),
    createRoute(routes.ROUTE_RESOURCE_SERVICES, ResourceServicesPage),
    createRoute(routes.ROUTE_RESOURCE_LIST, ResourcesPage),
]

let getBusinessRoutes = () => [
    createRoute(routes.ROUTE_BUSINESS_SHOW, BusinessDetailsPage),
    createRoute(routes.ROUTE_BUSINESS_RESERVATION_SETTINGS, BusinessReservationSettingsPage),
    createRoute(routes.ROUTE_BUSINESS_SUBSCRIPTION_SETTINGS, BusinessSubscriptionPage),
    createRoute(routes.ROUTE_BUSINESS_AVAILABILITY, BusinessAvailabilityPage),
    createRoute(routes.ROUTE_BUSINESS_CONTACT, BusinessContactPage),
    createRoute(routes.ROUTE_BUSINESS_GALLERY, BusinessGalleryPage),
]

let getAccountRoutes = () => [
    createRoute(routes.ROUTE_ACCOUNT_DETAILS, AccountDetailsPage),
    createRoute(routes.ROUTE_ACCOUNT_NOTIFICATIONS, AccountNotificationsPage),
    createRoute(routes.ROUTE_ACCOUNT_PREFERENCES, AccountPreferencesPage),
    createRoute(routes.ROUTE_ACCOUNT_PASSWORD, AccountPasswordPage),
]

export default [
    createRoute(ROUTE_LOGOUT, Logout),
    ...getPaymentRoutes(),
    ...getFacilityRoutes(),
    ...getServiceRoutes(),
    ...getPartherRoutes(),
    ...getClientRoutes(),
    ...getResourceRoutes(),
    ...getAccountRoutes(),
    ...getBusinessRoutes(),
    ...getAccountRoutes(),
    createRoute(routes.ROUTE_PERMISSIONS, PermissionPage),
    createRoute(routes.ROUTE_ACTIVITIES, ActivitiesPage),
]