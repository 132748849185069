import {createAction} from "../core/utils/appUtil";
import * as actionTypes from "../constants/actiontypes/dashboard";
import * as apiLegacy from "../core/api";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import {getRequestParams} from "../core/utils/requestUtil";
import {
    DASHBOARD_FETCH_FACILITIES_SUCCESS,
    DASHBOARD_FETCH_PARTNERS_SUCCESS,
    DASHBOARD_FETCH_SERVICES_SUCCESS,
    DASHBOARD_INIT_CALENDAR
} from "../constants/actiontypes/dashboard";

export function initDashboard(date) {
    return function (dispatch) {
        Promise.all([
            api.service.list(),
            api.facility.list({}, ['parts']),
            api.partner.list()
        ]).then(data => {
            let services = normalize(data[0].data, [schemas.serviceSchema]);
            let facilities = normalize(data[1].data, [schemas.facilitySchema]);
            let partners = normalize(data[2].data, [schemas.partnerSchema]);
            dispatch(createAction(DASHBOARD_FETCH_SERVICES_SUCCESS, services))
            dispatch(createAction(DASHBOARD_FETCH_FACILITIES_SUCCESS, facilities))
            dispatch(createAction(DASHBOARD_FETCH_PARTNERS_SUCCESS, partners))
            dispatch(createAction(DASHBOARD_INIT_CALENDAR, date))
        })
    }
}

export function fetchReservations(start, end, filters, globalFilter) {
    filters.number = globalFilter ?? '';
    let requestParams = getRequestParams({skippagination: true}, filters);
    requestParams.starttime = start;
    requestParams.endtime = end;
    requestParams.include = 'resources,service,facilityParts';
    return function (dispatch) {
        dispatch(createAction(actionTypes.DASHBOARD_FETCH_RESERVATIONS_REQUEST));
        let blockRequest = Object.assign({}, requestParams)
        delete blockRequest['filter[status]']
        delete blockRequest['include']
        delete blockRequest['page']
        delete blockRequest['skippagination']
        Promise.all([
            apiLegacy.searchReservations(requestParams),
            api.block.list(blockRequest)
        ]).then(responses => {
            let normalizedData = normalize(responses[0].data.data, [schemas.reservationSchema]);
            dispatch(createAction(actionTypes.DASHBOARD_FETCH_RESERVATIONS_SUCCESS, normalizedData))
            let normalizedData2 = normalize(responses[1].data.data, [schemas.blockSchema]);
            dispatch(createAction(actionTypes.DASHBOARD_FETCH_BLOCKS_SUCCESS, normalizedData2))
        })
    };
}