import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {AMENITY_LIST_REQUEST, AMENITY_LIST_SUCCESS} from "../reducers/amenity";

export function fetchAllAmenities() {
    return dispatch => {
        dispatch(createAction(AMENITY_LIST_REQUEST));
        return api.amenity.list().then(response => {
            let normalizedData = normalize(response.data, [schemas.amenitySchema]);
            dispatch(createAction(AMENITY_LIST_SUCCESS, normalizedData));
        }).catch(error => handleError(error, dispatch));
    }
}