import React from "react";
import TextInput from "../../../core/form/TextInput";

let FacilityNameField = ({loading = false}) => {

    return <TextInput
        name='name'
        label='form.facility.name'
        required={true}
        loading={loading}
    />

}

export default FacilityNameField