import React from "react";

import {BiInfoCircle} from 'react-icons/bi';

const YesNoColumn = ({active, activeLabel = 'Aktywny', inactiveLabel = 'Nieaktywny'}) => {
    return <div className={'partner-availability ' + (active ? 'active' : '')}>
        <BiInfoCircle size={15}/>
        <span>
            {active ? activeLabel : inactiveLabel}
        </span>
    </div>
}

export default YesNoColumn;
