import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_PAYMENTS_PAID_LIST, ROUTE_PAYMENTS_RETURNED_LIST, ROUTE_PAYMENTS_TO_RETURN_LIST,
    ROUTE_PAYMENTS_UNPAID_LIST
} from "../../../routes/routeDefinitions";

let PaymentsPage = ({children}) => {

    let pages = [
        {
            name: 'Oczekujące',
            path: ROUTE_PAYMENTS_UNPAID_LIST,
        },
        {
            name: 'Opłacone',
            path: ROUTE_PAYMENTS_PAID_LIST,
        },
        {
            name: 'Do zwrotu',
            path: ROUTE_PAYMENTS_TO_RETURN_LIST,
        },
        {
            name: 'Zwrócone',
            path: ROUTE_PAYMENTS_RETURNED_LIST,
        },
    ];

    return <ObjectPage pages={pages} name='payments'>
        {children}
    </ObjectPage>
}
export default PaymentsPage;

