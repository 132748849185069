export const GALLERY_EDITOR_INIT = 'GALLERY_EDITOR_INIT';
export const GALLERY_EDITOR_SET_IMAGES = 'GALLERY_EDITOR_SET_IMAGES';
export const GALLERY_EDITOR_RESET = 'GALLERY_EDITOR_RESET';
export const GALLERY_EDITOR_ADD = 'GALLERY_EDITOR_ADD';
export const GALLERY_EDITOR_DELETE = 'GALLERY_EDITOR_DELETE';
export const GALLERY_EDITOR_SAVING = 'GALLERY_EDITOR_SAVING';
export const GALLERY_EDITOR_SAVED = 'GALLERY_EDITOR_SAVED';
var initialState = {
    loading: false,
    images: [{
        imageId: null,
        editorId: '1',
        url: 'https://api.bookendy.com/api/image/5b0b0183-3e99-48a2-8f68-2b6dfb6dde5c'
    },{
        imageId: null,
        editorId: '2',
        url: 'https://api.bookendy.com/api/image/5b0b0183-3e99-48a2-8f68-2b6dfb6dde5c'
    },{
        imageId: null,
        editorId: '3',
        url: 'https://api.bookendy.com/api/image/5b0b0183-3e99-48a2-8f68-2b6dfb6dde5c'
    }],
    formChanged: false,
    saving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GALLERY_EDITOR_RESET: {
            return {
                ...state,
                loading: true,
                images: []
            }
        }
        case GALLERY_EDITOR_INIT: {
            return {
                ...state,
                images: action.payload,
                loading: false
            }
        }
        case GALLERY_EDITOR_SET_IMAGES: {
            return {
                ...state,
                images: action.payload,
                formChanged: true
            }
        }
        case GALLERY_EDITOR_ADD: {
            return {
                ...state,
                images: [
                    ...state.images,
                    {
                        imageId: null,
                        tmpImageId: action.payload.tmpImageId,
                        url: process.env.REACT_APP_API_URL + '/tmpImage/' + action.payload.tmpImageId,
                        name: action.payload.name,
                        mimetype: action.payload.mimetype
                    }
                ],
                formChanged: true
            }
        }
        case GALLERY_EDITOR_DELETE: {
            return {
                ...state,
                images: [
                    ...state.images.filter(image => image.editorId !== action.payload)
                ],
                formChanged: true
            }
        }
        case GALLERY_EDITOR_SAVING: {
            return {
                ...state,
                saving: true
            }
        }
        case GALLERY_EDITOR_SAVED: {
            return {
                ...state,
                saving: false,
                formChanged: false
            }
        }
        default:
            return state;
    }
}