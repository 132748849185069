import {BsFillPersonFill} from "react-icons/bs";
import {FiSettings} from 'react-icons/fi';
import ServicesPage from "../pages/dashboard/service/ServicesPage";
import * as routes from "./routeDefinitions";
import {
    FACILITY_MANAGEMENT,
    SERVICE_MANAGEMENT,
    RESOURCE_MANAGEMENT,
    BUSINESS_MANAGEMENT, PARTNER_MANAGEMENT,
    PERMISSION_MANAGEMENT, CLIENT_MANAGEMENT
} from "../constants/permissions";
import BusinessPage from "../pages/dashboard/business/BusinessPage";
import {
    ROUTE_FACILITY_LIST,
    ROUTE_PARTNER_LIST,
    ROUTE_RESOURCE_LIST, ROUTE_PERMISSIONS, ROUTE_ACTIVITIES
} from "./routeDefinitions";
import FacilitiesPage from "../pages/dashboard/facility/FacilitiesPage";
import PartnersPage from "../pages/dashboard/partners/PartnersPage";
import ClientsPage from "../pages/dashboard/clients/ClientsPage";
import ResourcesPage from "../pages/dashboard/resource/ResourcesPage";
import PermissionPage from "../pages/dashboard/permission/PermissionPage";
import DashboardPage from "../pages/DashboardPage";
import {ReactComponent as CalendarIcon} from '../assets/img/icons/calendar.svg'
import {ReactComponent as FacilitiesIcon} from '../assets/img/icons/facilities.svg'
import {ReactComponent as ResourcesIcon} from '../assets/img/icons/resources.svg'
import {ReactComponent as ServicesIcon} from '../assets/img/icons/services.svg'
import {ReactComponent as TeamIcon} from '../assets/img/icons/team.svg'
import {getCurrentBusiness} from "../selectors";
import CalendarPage from "../pages/dashboard/CalendarPage";
import {FaDollarSign, FaChartArea} from 'react-icons/fa'
import ActivitiesPage from "../pages/dashboard/activities/ActivitiesPage";

const dashboard = {
    path: "/",
    name: "Dashboard",
    headerText: 'sidebar.reservations',
    component: DashboardPage,
    icon: FaChartArea,
    children: null
}

const facilities = {
    permission: FACILITY_MANAGEMENT,
    path: ROUTE_FACILITY_LIST,
    name: "sidebar.facilities",
    headerText: 'sidebar.facilities',
    component: FacilitiesPage,
    icon: FacilitiesIcon,
    children: null
}

const payments = {
    icon: FaDollarSign,
    permission: SERVICE_MANAGEMENT,
    path: routes.ROUTE_PAYMENTS_PAID_LIST,
    name: 'Rejestr płatności',
    component: ServicesPage,
    children: null
}

const reservations = {
    icon: CalendarIcon,
    permission: SERVICE_MANAGEMENT,
    path: routes.ROUTE_RESERVATIONS,
    name: 'Kalnedarz rezerwacji',
    component: CalendarPage,
    children: null
}

const offer = {
    icon: ServicesIcon,
    permission: SERVICE_MANAGEMENT,
    path: routes.ROUTE_SERVICE_LIST,
    name: 'sidebar.services',
    component: ServicesPage,
    children: null
}

const personnel = {
    path: ROUTE_PARTNER_LIST,
    name: 'sidebar.personnel',
    component: PartnersPage,
    icon: TeamIcon,
    permission: PARTNER_MANAGEMENT,
    children: [
        {
            path: ROUTE_PARTNER_LIST,
            name: 'sidebar.partners',
            component: PartnersPage,
            icon: TeamIcon,
            permission: PARTNER_MANAGEMENT,
            children: null
        },
        {
            path: ROUTE_PERMISSIONS,
            permission: PERMISSION_MANAGEMENT,
            name: 'sidebar.permissions',
            component: PermissionPage,
            children: null
        }
    ]
}

const resources = {
    permission: RESOURCE_MANAGEMENT,
    path: ROUTE_RESOURCE_LIST,
    name: 'sidebar.resources',
    component: ResourcesPage,
    icon: ResourcesIcon,
    children: null,
    extraCondition: state => getCurrentBusiness(state).advanced_resources
}

const clients = {
    permission: CLIENT_MANAGEMENT,
    path: '/klienci',
    name: 'sidebar.clients',
    component: ClientsPage,
    icon: BsFillPersonFill,
    children: null
}

const business = {
    permission: BUSINESS_MANAGEMENT,
    path: routes.ROUTE_BUSINESS_SHOW,
    name: 'sidebar.business',
    icon: FiSettings,
    component: BusinessPage
}

const activityLog = {
    icon: FiSettings,
    permission: BUSINESS_MANAGEMENT,
    path: routes.ROUTE_ACTIVITIES,
    name: 'Log aktywności',
    component: ActivitiesPage,
    children: null
}

export default [
    dashboard, reservations, offer, facilities, payments, personnel, resources, clients, business, activityLog
];