import _ from "lodash";

export const PAYMENT_METHOD_LIST_REQUEST = 'PAYMENT_METHOD_LIST_REQUEST';
export const PAYMENT_METHOD_LIST_SUCCESS = 'PAYMENT_METHOD_LIST_SUCCESS';
var initialState = {
    byId: {},
    allIds: [],
    loading: false
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.paymentMethod) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.paymentMethod)
        }
    }
    switch (action.type) {
        case PAYMENT_METHOD_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case PAYMENT_METHOD_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        default:
            return state;
    }
}