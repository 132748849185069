import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllResourceTypes} from "../../../actions/resourceTypes";

let ResourceTypeSelect = (props) => {
    let dispatch = useDispatch();
    let label = 'Kategoria zasobu'
    let name = 'type_id'
    let {allIds} = useSelector(state => state.resourceType)

    React.useEffect(() => {
        if (!props.skipInitialLoad) {
            dispatch(fetchAllResourceTypes(null, false))
        }
    }, [dispatch, props.skipInitialLoad])

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz usługę'
        stateKey='resourceType'
        data={allIds}
        autocomplete={true}
        loadingData={true}
        {...props}
    />

}

export default ResourceTypeSelect;
