import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectFacilitiesModal from "../../../components/modals/SelectFacilitiesModal";
import {fetchAllFacilities} from "../../../actions/facilities";
import ServicePage from "./ServicePage";
import {fetchServiceDetails} from "../../../actions/services";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";

let ServiceFacilitiesPage = () => {

    let params = useParams();

    let serviceId = params.id

    let service = useSelector(state => state.service.byId[serviceId])

    let loading = useSelector(state => state.service.loadingDetails)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAllFacilities())
        dispatch(fetchServiceDetails(serviceId, ['facilities']))
    }, [serviceId])

    let allFacilities = useSelector(state => state.facility.byId)

    let hasFacilities = service?.facilities && service?.facilities.length > 0

    let [selectFacilitiesModalOpened, setSelectFacilitiesModalOpened] = useState(false);

    return <ServicePage serviceId={serviceId}>
        {selectFacilitiesModalOpened && service && <SelectFacilitiesModal
            type='service'
            modalOpened={selectFacilitiesModalOpened}
            setModalOpened={setSelectFacilitiesModalOpened}
            defaultSelected={service?.facilities}
            objectId={serviceId}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Obiekty'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasFacilities && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasFacilities && service?.facilities.map(facilityId => <tr>
                                    <td>
                                        {allFacilities[facilityId].name}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz obiekty'
                                  variant='forward'
                                  onClick={() => setSelectFacilitiesModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </ServicePage>

}

export default ServiceFacilitiesPage
