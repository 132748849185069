import {
    FETCH_ALL_SERVICES_REQUEST,
    FETCH_ALL_SERVICES_SUCCESS,
    UPDATE_SERVICE_REQUEST,
    UPDATE_SERVICE_SUCCESS
} from "../constants/actiontypes/services";
import _ from "lodash";
export const SERVICE_LIST_REQUEST = 'SERVICE_LIST_REQUEST';
export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS';
export const SERVICE_FACILITY_LIST_REQUEST = 'SERVICE_FACILITY_LIST_REQUEST';
export const SERVICE_FACILITY_LIST_SUCCESS = 'SERVICE_FACILITY_LIST_SUCCESS';
export const SERVICE_DETAILS_REQUEST = 'SERVICE_DETAILS_REQUEST';
export const SERVICE_DETAILS_SUCCESS = 'SERVICE_DETAILS_SUCCESS';
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_PARTNERS_SUCCESS = 'SERVICE_UPDATE_PARTNERS_SUCCESS';
export const SERVICE_UPDATE_FACILITIES_SUCCESS = 'SERVICE_UPDATE_FACILITIES_SUCCESS';
export const SERVICE_UPDATE_RESOURCES_SUCCESS = 'SERVICE_UPDATE_RESOURCES_SUCCESS';

var initialState = {
    byId: {},
    allIds: [],
    loading: false,
    loadingDetails: true,
    byFacility: {}
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.service) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.service)
        }
    }
    switch (action.type) {
        case SERVICE_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case SERVICE_LIST_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case SERVICE_DETAILS_REQUEST: {
            return {
                ...state,
                loadingDetails: true,
            }
        }
        case SERVICE_DETAILS_SUCCESS:
        case SERVICE_UPDATE_SUCCESS:
            return {
                ...state,
                loadingDetails: false,
                byId: {
                    ...state.byId,
                    [action.payload.result]: {
                        ...state.byId[action.payload.result],
                        resourceTypeCounts: action.payload.entities.resourceType
                    }
                }
            }
        case UPDATE_SERVICE_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_SERVICE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case FETCH_ALL_SERVICES_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_ALL_SERVICES_SUCCESS:
            return {
                ...state,
                allIds: action.payload.result,
                loading: false
            }
        case SERVICE_UPDATE_FACILITIES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.service_id]: {
                        ...state.byId[action.payload.service_id],
                        facilities: action.payload.facilities
                    }
                }
            }
        }
        case SERVICE_UPDATE_PARTNERS_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.service_id]: {
                        ...state.byId[action.payload.service_id],
                        partners: action.payload.partners
                    }
                }
            }
        }
        case SERVICE_UPDATE_RESOURCES_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.service_id]: {
                        ...state.byId[action.payload.service_id],
                        resources: action.payload.resources
                    }
                }
            }
        }
        case SERVICE_FACILITY_LIST_SUCCESS: {
            return {
                ...state,
                byFacility: {
                    ...state.byFacility,
                    [action.payload.facility_id]: action.payload.result
                }
            }
        }
        default:
            return state;
    }
};