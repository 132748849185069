import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessDetailsForm from "../../../components/forms/app/business/BusinessDetailsForm";
import BusinessPage from "./BusinessPage";
import {openBusinessDetailsPage, openBusinessOpeningHoursPage} from "../../../actions/business";
import {useDispatch, useSelector} from 'react-redux';
import {getCurrentBusiness} from "../../../selectors";

let BusinessDetailsPage = () => {

    let business = useSelector(getCurrentBusiness);

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(openBusinessDetailsPage(business?.id))
    }, [business?.id, dispatch])

    return <BusinessPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <BusinessDetailsForm/>
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessDetailsPage
