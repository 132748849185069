import {OPENING_HOUR_ADD_SUCCESS, OPENING_HOUR_UPDATE_SUCCESS} from "../constants/actiontypes/openingHours";

var initialState = {
    byId: {},
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.openingHour) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.openingHour
            }
        }
    }
    switch (action.type) {
        case OPENING_HOUR_ADD_SUCCESS:
        case OPENING_HOUR_UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload
                }
            }
        }
        default:
            return state;
    }
};