import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form, Button} from 'reactstrap';
import {useNavigate} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {injectIntl} from "react-intl";
import {setErrors} from "../../../../core/utils/formUtil";
import {submitRegister} from "../../../../actions/auth";
import {ROUTE_SIGN_IN} from "../../../../routes/routeDefinitions";
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerRepeatPasswordField from "../../partner/PartnerRepeatPasswordField";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import {CREATOR_RESET} from "../../../../reducers/creator";
import {createAction} from "../../../../core/utils/appUtil";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import AppForm from "../../AppForm";

const mapStateToProps = state => {
    return {
        loggedUser: state.auth.logged_user,
        current_language: state.app.current_language
    }
};

const mapDispatchToProps = () => ({
    register: (formData, languageId, dispatch, navigate) => submitRegister(formData, languageId, dispatch, navigate)
});

let SignUpForm = function (props) {

    let navigate = useNavigate()

    let [isDuringSubmit, setIsDuringSubmit] = useState(false);

    let [success] = useState(false);

    let dispatch = useDispatch();
    let submit = async (data) => {
        data.type = 'partner';
        setIsDuringSubmit(true);
        let result = await props.register(data, props.current_language.id, navigate, dispatch);
        setErrors(result, setError, props.intl);
        setIsDuringSubmit(false);
        dispatch(createAction(CREATOR_RESET))
    }

    let formContext = useForm({
        defaultValue: {
            email: '',
            password: '',
            password_confirmation: ''
        }
    });
    const {control, setError} = formContext

    if (success) {
        return <div>
            <p>Twoje konto zostało zarejestrowane. Przejdź na podany adres e-mail i potwierdź swoje konto.</p>
            <Button onClick={() => navigate(ROUTE_SIGN_IN)}>Powrót do strony logowania</Button>
        </div>
    }

    return <AppForm onSubmit={submit} formContext={formContext} className='signup'>
        <PartnerEmailField/>
        <PartnerPasswordField/>
        <PartnerRepeatPasswordField/>
        <span className='terms-text'>Rejestrując się, akceptujesz
            <a href='/pdf/Bookendy_Regulamin_dla_Partnera.docx.pdf' target='_blank'
               className='extra' style={{textDecoration: "none"}}> regulamin</a> i
            <a href='/pdf/Polityka_prywatności_Bookendy.pdf' target='_blank'
               className='extra' style={{textDecoration: "none"}}> politykę prywatności</a>
        </span>
        {/*<CheckboxInput name='terms'*/}
        {/*               label={() => <span>Zgoda na <span className='extra'>komunikację marketingową</span></span>}*/}
        {/*/>*/}
        <SubmitFormButton loading={isDuringSubmit} text='app.signup'/>
    </AppForm>

}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SignUpForm));