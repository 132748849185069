import React from "react";
import {useForm} from "react-hook-form";
import {setErrors} from "../../../core/utils/formUtil";
import api from "../../../core/api";
import {useDispatch, useSelector} from 'react-redux';
import {composeUtils, createAction} from "../../../core/utils/appUtil";
import PartnerPhoneField from "../../forms/partner/PartnerPhoneField";
import PartnerFullnameField from "../../forms/partner/PartnerFullnameField";
import handleError from "../../../core/utils/errorUtil";
import {getLoggedUser} from "../../../selectors";
import {PARTNER_UPDATE_SUCCESS} from "../../../reducers/partner";
import {normalize} from "normalizr";
import schemas from "../../../reducers/schemas";
import AppForm from "../../forms/AppForm";

let AccountDataStepForm = ({setSubmit, next, setDuringSubmit, intl}) => {

    let user = useSelector(getLoggedUser);

    let dispatch = useDispatch();

    const formContext = useForm({
        defaultValues: {
            fullname: user.fullname,
            phone: user.phone
        }
    });

    const {handleSubmit, setError} = formContext

    let apiCall = (formData) => {
        return api.partner.update(user.id, formData)
            .then((response) => {
                return response.data
            })
            .catch(error => handleError(error, dispatch))
    }

    let submit = async (data) => {
        setDuringSubmit(true);
        let result = await apiCall(data);
        setErrors(result, setError, intl);
        setDuringSubmit(false);
        if (!result?.errors) {
            dispatch(createAction(PARTNER_UPDATE_SUCCESS, normalize(result, schemas.partnerSchema)))
            next();
        }
    }

    setSubmit(() => handleSubmit(submit))

    return <AppForm onSubmit={submit} formContext={formContext}>
        <PartnerFullnameField/>
        <PartnerPhoneField/>
    </AppForm>

}
export default composeUtils(AccountDataStepForm)
