import React from "react";
import SportsSelect from "../select/SportsSelect";

let BusinessSportsField = ({loading = false}) => {

    return <SportsSelect
        name='sports'
        label='Wybierz sporty'
        required={false}
        isMulti={true}
        loading={loading}
    />

}

export default BusinessSportsField