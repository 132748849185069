import {createAction, toastError, toastSuccess} from "../core/utils/appUtil";
import * as apiLegacy from "../core/api"
import api from "../core/api";
import {normalize} from "normalizr";
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {
    BUSINESS_GET_DETAILS,
} from "../reducers/business";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../reducers/openingHourForm";
import {
    GALLERY_EDITOR_ADD, GALLERY_EDITOR_DELETE,
    GALLERY_EDITOR_INIT,
    GALLERY_EDITOR_RESET, GALLERY_EDITOR_SAVED,
    GALLERY_EDITOR_SAVING
} from "../reducers/galleryEditor";
import {FACILITY_ADD_IMAGE_SUCCESS, FACILITY_DELETE_IMAGE_SUCCESS} from "../reducers/facility";

export function openBusinessOpeningHoursPage() {
    return function (dispatch) {
        dispatch(createAction(OPENING_HOUR_FORM_RESET));
        return apiLegacy.getBusiness([],['openingHours'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema)
                let business = normalizedData.entities.business[normalizedData.result]
                let payload = {
                    availability_type: business.availability_type,
                    entities: normalizedData.entities,
                    openingHours: business.openingHours.map(openingHour => normalizedData.entities.openingHour[openingHour])
                }
                dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function openBusinessDetailsPage(businessId) {
    return function (dispatch) {
        return api.business.get(businessId,['sports', 'amenities', 'address'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema)
                dispatch(createAction(BUSINESS_GET_DETAILS, normalizedData));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchBusinessImages(id, includes = []) {
    return function (dispatch) {
        dispatch(createAction(GALLERY_EDITOR_RESET))
        return api.business.get(id, ['images'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema)
                normalizedData.images = normalizedData.entities.business[normalizedData.result].images
                let images = normalizedData.entities.business[normalizedData.result].images.map((i, key) => {
                    return {
                        imageId: i,
                        url: process.env.REACT_APP_API_URL + '/image/' + normalizedData.entities.image[i].image_id
                    }
                })
                dispatch(createAction(GALLERY_EDITOR_INIT, images))
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function uploadBusinessImage(dispatch, base64content, file, businessId) {
    let formData = {};
    formData.file = {};
    formData.file.content = base64content.replace('data:image/jpeg;base64,', '')
        .replace('data:image/jpg;base64,', '')
        .replace('data:image/png;base64,', '');
    formData.file.name = file.name;
    formData.file.mimetype = file.type;
    return apiLegacy.addBusinessImage(formData, businessId)
        .then((response) => {
            let data = normalize(response.data, schemas.imageSchema)
            dispatch(createAction(FACILITY_ADD_IMAGE_SUCCESS, data));
            dispatch(createAction(GALLERY_EDITOR_ADD, data.result))
            toastSuccess('Dodano');
        })
        .catch(error => {
            if (error?.response?.status === 400) {
                toastError('Zły format pliku!')
            } else {
                toastError('Wystąpił błąd podczas uploadu pliku!')
                handleError(error, dispatch);
            }
        });
}

export function updateBusinessImages(dispatch, businessId, images) {
    dispatch(createAction(GALLERY_EDITOR_SAVING));
    return apiLegacy.updatBusinessGallery(businessId, images)
        .then((response) => {
            dispatch(createAction(GALLERY_EDITOR_SAVED));
            toastSuccess('Zapisano');
        })
        .catch(error => handleError(error, dispatch))
}

export function deleteBusinessImage(dispatch, uuid, businessId) {
    return apiLegacy.deleteBusinessImage(businessId, uuid)
        .then(() => {
            dispatch(createAction(FACILITY_DELETE_IMAGE_SUCCESS, {id: uuid, facility_id: businessId}));
            dispatch(createAction(GALLERY_EDITOR_DELETE, uuid))
            toastSuccess('Usunięto');
        })
        .catch(error => handleError(error, dispatch));
}