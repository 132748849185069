import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerFullnameField = () => {

    return <TextInput
        name='fullname'
        label='form.partner.fullname'
        required={true}
    />

}

export default PartnerFullnameField