import React, {useState} from "react";
import {FiMinusCircle} from 'react-icons/fi'
import {RiEditFill} from 'react-icons/ri'
import {useDispatch, useSelector} from 'react-redux';
import api from "../../../core/api";
import handleError from "../../../core/utils/errorUtil";
import {toastSuccess} from "../../../core/utils/appUtil";
import {normalize} from 'normalizr';
import schemas from "../../../reducers/schemas";
import {createAction} from "../../../core/utils/appUtil";
import {RESOURCE_TYPE_ADD_SUCCESS, RESOURCE_TYPE_UPDATE_SUCCESS} from "../../../reducers/resourceType";
import Button from "../../../core/buttons/Button";
import ConfirmationModal from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import {RESOURCE_TYPE_REMOVE} from "../../../constants/actiontypes/resourceType";

let ResourceTypeRow = function ({id, add = false, onCancel = null}) {

    let [edit, setEdit] = React.useState(add);

    let resourceType = useSelector(state => state.resourceType.byId[id]);

    let [name, setName] = React.useState('');

    let [isDuringSubmit, setIsDuringSubmit] = React.useState(false);

    let dispatch = useDispatch();

    React.useEffect(() => {
        setName(resourceType?.name)
    }, [resourceType])

    let cancelEdit = () => {
        if (onCancel) {
            onCancel()
        } else {
            setName(resourceType?.name)
            setEdit(false)
        }
    }

    let updateResourceType = () => {
        setIsDuringSubmit(true)
        if (resourceType?.id) {
            api.resourceType.update(resourceType.id, {name: name}).then(response => {
                let responseData = normalize(response.data, schemas.resourceTypeSchema)
                dispatch(createAction(RESOURCE_TYPE_UPDATE_SUCCESS, responseData))
                setIsDuringSubmit(false)
                toastSuccess('Zapisano')
                setEdit(false)
            }).catch(error => handleError(error, dispatch))
        } else {
            api.resourceType.create({name: name}).then(response => {
                let responseData = normalize(response.data, schemas.resourceTypeSchema)
                dispatch(createAction(RESOURCE_TYPE_ADD_SUCCESS, responseData))
                setIsDuringSubmit(false)
                toastSuccess('Zapisano')
                setEdit(false)
                if (onCancel) {
                    onCancel();
                }
            }).catch(error => handleError(error, dispatch))
        }
    }

    let deleteResourceType = () => {
        if (resourceType?.resource_count) {
            setErrorModalOpened(() => true)
        } else {
            setConfirmationModalOpened(() => true)
        }
    }

    let removeResourceType = async () => {
        let result = await api.resourceType.delete(resourceType?.id).then((response) => {
            dispatch(createAction(RESOURCE_TYPE_REMOVE, resourceType?.id))
            toastSuccess('Usunięto')
            setConfirmationModalOpened(() => false)
        }).catch(error => handleError(error, dispatch))
        if (result) {
            setConfirmationModalOpened(() => false)
            return true;
        }
    }

    let [confirmationModalOpened, setConfirmationModalOpened] = useState(false)

    let [errorModalOpened, setErrorModalOpened] = useState(false)

    return <div className='item'>
        <div className='title'>
            {!edit && <span>
                {resourceType?.name}({resourceType?.resource_count})
            </span>}
            {edit && <input type='text' value={name} onChange={e => setName(e.target.value)}/>}
        </div>
        <div className='edit'>
            {!edit && <RiEditFill size={20} onClick={() => setEdit(true)}/>}
            {edit && <Button text='Anuluj' onClick={cancelEdit} variant='secondary'/>}
        </div>
        <div className='remove'>
            {!edit && <FiMinusCircle style={{color: 'red'}} size={20} onClick={() => deleteResourceType()}/>}
            {edit && <Button text={add ? 'Dodaj' : 'Zapisz'} onClick={updateResourceType} loading={isDuringSubmit}/>}
        </div>
        {confirmationModalOpened && <ConfirmationModal message={'Czy na pewno chcesz usunąć kateorie ' + resourceType.name + '?'}
                           submitAction={removeResourceType}
                           setModalOpened={setConfirmationModalOpened}
                           modalOpened={confirmationModalOpened}/>}
        {errorModalOpened && <ErrorModal message='Nie można usunąć. Będzie można usunąć kategorię tylko wtedy kiedy nie będzie żadnego zasobu z tą kategorią.'
                    modalOpened={errorModalOpened}
                    setModalOpened={setErrorModalOpened}
        />}
    </div>
}
export default ResourceTypeRow