import React from "react";
import {useIntl} from 'react-intl';
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {trans} from "../../../core/utils/translationUtil";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllPartners} from "../../../actions/partners";

let PartnerSelect = (props) => {

    let isAvailable = props.isAvailable

    if(!isAvailable) {
        isAvailable = () => true
    }

    let intl = useIntl();

    let dispatch = useDispatch();

    let label = props.label ?? trans(intl, 'facility');

    let name = props.isMulti ? 'partners' : 'partner_id';

    let {allIds} = useSelector(state => state.partner)

    let filters = props.extraFilters ?? {}

    React.useEffect(() => {
        if (!props.skipInitialLoad) {
            dispatch(fetchAllPartners(filters))
        }
    }, [dispatch, JSON.stringify(props), JSON.stringify(filters)])

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz pracownika'
        stateKey='partner'
        optionLabel='fullname'
        data={allIds}
        isAvailable={isAvailable}
        renderLabel={(option) => <div className={'select-option detailed-select-option partners d-flex'}>
            <span>{option.fullname}</span>{!isAvailable(option.id) && <span className='inavailable'>Niedostępny</span>}
        </div>}
        {...props}
    />

}

export default PartnerSelect;
