import React, {useState} from "react";
import FacilityPage from "./FacilityPage";
import {useMatch} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import SelectServicesModal from "../../../components/modals/SelectServicesModal";
import {fetchAllServices} from "../../../actions/services";
import CardFooter from "../../../core/card/CardFooter";
import CardBody from "../../../core/card/CardBody";
import Card from "../../../core/card/Card";
import {getPriceText, getServicePriceText} from "../../../core/utils/appUtil";
import {useIntl} from 'react-intl'
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";

let FacilityServicesPage = () => {

    let match = useMatch();

    let intl = useIntl()

    let facilityId = match.params.id

    let facility = useSelector(state => state.facility.byId[facilityId])

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchAllServices())
        dispatch(fetchFacilityDetails(facilityId, ['services', 'type.resourceTypes']))
    }, [facilityId, dispatch])

    let allServices = useSelector(state => state.service.byId)

    let hasServices = facility?.services && facility?.services.length > 0

    let [selectServiceModalOpened, setSelectServiceModalOpened] = useState(false);

    let loading = useSelector(state => state.facility.loadingDetails)

    return <FacilityPage facilityId={facilityId}>
        {selectServiceModalOpened && facility && <SelectServicesModal
            modalOpened={selectServiceModalOpened}
            setModalOpened={setSelectServiceModalOpened}
            defaultSelected={facility?.services}
            objectId={facility?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Świadczone usługi'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasServices && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasServices && facility?.services.map(serviceId => <tr>
                                    <td>
                                        {allServices[serviceId].name}
                                    </td>
                                    <td>
                                        {getServicePriceText(allServices[serviceId].price, intl)}
                                    </td>
                                    <td>
                                        {allServices[serviceId].duration} min
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz usługi świadczone w tym obiekcie'
                                  variant='forward'
                                  onClick={() => setSelectServiceModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityServicesPage
