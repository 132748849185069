import React, {useRef} from "react";
import {BsPlusCircle} from 'react-icons/bs'
import {useDispatch} from 'react-redux'
import {createAction, toastError} from "../../core/utils/appUtil";
import {GALLERY_EDITOR_ADD} from "../../reducers/galleryEditor";
import {v4 as uuidv4} from 'uuid';
import api from '../../core/api'
import {convertImagesToBase64} from "../../actions/facilities";

let AddImage = ({}) => {

    let dispatch = useDispatch()

    let imageRef = useRef();

    let onSelectImage = async function (e) {
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            if(!['image/jpeg','image/jpg','image/png'].includes(file.type)) {
                toastError('Błędny typ obrazu, dozwolone formaty JPG, PNG, JPEG');
                return;
            }
        }
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            let url = URL.createObjectURL(file);
            convertImagesToBase64([{
                url: url,
                name: file.name,
                mimetype: file.type
            }])
                .then(r => {
                    api.tmpImage.create(r[0])
                        .then(response => {
                            let id = response.data.id
                            dispatch(createAction(GALLERY_EDITOR_ADD, {
                                tmpImageId: id,
                                name: file.name,
                                mimetype: file.type
                            }))
                        })
                        .catch(e => console.log(e));
                })
                .catch(e => console.log(e))
        }
    }

    let openImageSelect = e => {
        imageRef.current.click();
    }

    return <div className='tile'>
        <div className='add' onClick={openImageSelect}>
            <input type='file' onChange={onSelectImage} ref={imageRef} multiple="multiple"
                   accept=".png,.jpg,.jpeg"/>
            <BsPlusCircle size={20}/>
            <span>Dodaj zdjęcie</span>
        </div>
    </div>
}

export default AddImage