import React from "react";
import FacilityTypeSelect from "../select/FacilityTypeSelect";

let FacilityTypeField = ({loading = false}) => {

    return <FacilityTypeSelect
        name='type_id'
        label='form.facility.type'
        required={true}
        loading={loading}
    />

}

export default FacilityTypeField