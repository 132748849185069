import React, {useEffect} from "react";
import SwitchInput from "../../../core/form/SwitchInput";
import {useFormContext} from 'react-hook-form'

let FacilityVisibleInClientAppField = (props) => {

    let {watch, setValue} = useFormContext()

    let clientReservations = watch('client_reservations')

    useEffect(() => {
        if(clientReservations) {
            setValue('visible_in_client_app', true)
        }
    }, [
        clientReservations
    ])

    return <SwitchInput
        disabled={clientReservations === true}
        name='visible_in_client_app'
        label='Widoczny w aplikacji klienta'
        {...props}
    />

}

export default FacilityVisibleInClientAppField