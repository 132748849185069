import React from 'react';
import {getValidationMessage} from "../utils/validationUtil";
import TextField from '@mui/material/TextField';
import {useController} from "react-hook-form";
import {trans} from "../utils/translationUtil";
import FormWrapper from "../form/FormWrapper";
import InputMask from 'react-input-mask';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import InputAdornment from '@mui/material/InputAdornment';
import {useFormContext} from "react-hook-form";
import {composeUtils} from "../utils/appUtil";

let TextInput = ({
                     type = 'text',
                     name,
                     label = '',
                     required = false,
                     information = '',
                     mask = null,
                     validation = {},
                     Icon = null,
                     loading = false,
                     multiline = false,
                     minRows = 1,
                     onClickInput = null,
                     suffix = null,
                     disabled = false,
                     intl
                 }) => {

    let {control, formState: {isLoading}} = useFormContext()

    if (required) {
        validation.required = {
            value: true,
            message: getValidationMessage(intl, name, 'required')
        }
    }

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: name,
        control,
        rules: validation
    });

    let errorMessage = error?.message;

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    if (mask) {
        return <FormWrapper information={information} errorMessage={errorMessage}>
            <InputMask mask={mask}
                       maskChar=" "
                       value={value}
                       onChange={onChange}
                       disabled={disabled}>
                {(inputProps) => <TextField
                    type={type}
                    {...inputProps}
                    name={name}
                    multiline={multiline}
                    label={trans(intl, label)}
                    variant="outlined"
                    error={!!errorMessage}
                    minRows={minRows}
                    disabled={disabled}
                />}
            </InputMask>
        </FormWrapper>
    }

    let inputProps = {}

    if (onClickInput) {
        inputProps = {
            onClick: onClickInput
        }
    }

    if (Icon) {
        inputProps = {
            startAdornment: (
                <InputAdornment position="start">
                    <Icon color='red'/>
                </InputAdornment>
            )
        }
    }
    if (suffix) {
        inputProps = {
            ...inputProps,
            endAdornment: (
                <InputAdornment position="end">
                    PLN
                </InputAdornment>
            )
        }
    }

    return <FormWrapper information={information} errorMessage={errorMessage}>
        <TextField
            type={type}
            autoComplete='off'
            name={name}
            label={trans(intl, label)}
            variant="outlined"
            value={value ?? ''}
            multiline={multiline}
            InputProps={inputProps}
            disabled={disabled}
            onChange={onChange}
            error={!!errorMessage}
            minRows={minRows}
        />
    </FormWrapper>

}

export default composeUtils(TextInput);