import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let ResourceVisibleField = (props) => {

    return <SwitchInput
        name='visible'
        label='form.resource.visible'
        required={false}
        {...props}
    />
}

export default ResourceVisibleField