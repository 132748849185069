import {createAction} from "../core/utils/appUtil"
import api from "../core/api"
import {normalize} from 'normalizr'
import schemas from "../reducers/schemas"
import handleError from "../core/utils/errorUtil"
import {
    RESOURCE_DETAILS_REQUEST,
    RESOURCE_DETAILS_SUCCESS,
    RESOURCE_LIST_REQUEST,
    RESOURCE_LIST_SUCCESS
} from "../reducers/resource"
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../reducers/openingHourForm";
import _ from "lodash";

export function fetchAllResources(withoutSystemResources = false) {
    return function (dispatch) {
        let filters = {}
        if (withoutSystemResources) {
            filters.system = false
        }
        dispatch(createAction(RESOURCE_LIST_REQUEST))
        return api.resource.list(filters)
            .then((response) => {
                dispatch(createAction(RESOURCE_LIST_SUCCESS, normalize(response.data, [schemas.resourceSchema])))
            })
            .catch(error => handleError(error, dispatch))
    }
}
export function fetchResource(resourceId, includes = []) {
    return function (dispatch) {
        dispatch(createAction(RESOURCE_DETAILS_REQUEST))
        return api.resource.get(resourceId, includes)
            .then(response => dispatch(createAction(RESOURCE_DETAILS_SUCCESS, normalize(response.data, schemas.resourceSchema))))
            .catch(error => handleError(error, dispatch))
    }
}
export function openResourceOpeningHoursPage(id) {
    return function (dispatch) {

        dispatch(createAction(OPENING_HOUR_FORM_RESET));
        api.resource.get(id, ['openingHours', 'facilities']).then((resourceResponse) => {
            api.facility.get(resourceResponse.data.facilities[0].id, ['openingHours']).then((facilityResponse) => {
                let normalizedFacilityData = normalize(facilityResponse.data, schemas.facilitySchema)
                let normalizedResourceData = normalize(resourceResponse.data, schemas.resourceSchema)
                let facility = normalizedFacilityData.entities.facility[normalizedFacilityData.result]
                let resource = normalizedResourceData.entities.resource[normalizedResourceData.result]
                let payload = {
                    inheritedOpeningHours: facility.openingHours.map(openingHour => normalizedFacilityData.entities.openingHour[openingHour]),
                    availability_type: resource.availability_type,
                    entities: _.merge(normalizedFacilityData.entities, normalizedResourceData.entities),
                    openingHours: resource.openingHours.map(openingHour => normalizedResourceData.entities.openingHour[openingHour])
                }
                dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
            })
        })
        // Promise.all([
        //     api.getFacility(facilityId, ['openingHours']),
        //     api.getResource(id, ['openingHours'])
        // ]).then(response => {
        //     let normalizedFacilityData = normalize(facilityResponse, schemas.facilitySchema)
        //     let normalizedResourceData = normalize(resourceResponse, schemas.resourceSchema)
        //     let facility = normalizedFacilityData.entities.facility[normalizedFacilityData.result]
        //     let resource = normalizedResourceData.entities.business[normalizedResourceData.result]
        //     let payload = {
        //         inheritedOpeningHours: facility.openingHours.map(openingHour => normalizedFacilityData.entities.openingHour[openingHour]),
        //         availability_type: resource.availability_type,
        //         entities: _.merge(normalizedFacilityData.entities, normalizedResourceData.entities),
        //         openingHours: resource.openingHours.map(openingHour => normalizedResourceData.entities.openingHour[openingHour])
        //     }
        //     dispatch(createAction(OPENING_HOUR_FORM_INIT, payload));
        // }).catch(error => handleError(error, dispatch));

        // dispatch(createAction(OPENING_HOUR_FORM_RESET));
        // return api.getResource(id, ['openingHours'])
        //     .then(response => {
        //         let data = normalize(response.data, schemas.resourceSchema)
        //         data.availability_type = data.entities.resource[data.result].availability_type
        //         dispatch(createAction(OPENING_HOUR_FORM_INIT, data));
        //     })
        //     .catch(error => handleError(error, dispatch));
    };
}
