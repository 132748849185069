import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let ServiceVisibleField = (props) => {

    return <SwitchInput
        name='visible'
        label='form.service.visible'
        {...props}
    />

}

export default ServiceVisibleField