import React from "react";
import ClientSelect from "../select/ClientSelect";

let ReservationClientField = (props) => {

    return <ClientSelect
        required={true}
        label='form.reservation.client'
        {...props}
    />

}

export default ReservationClientField