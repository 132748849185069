import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerCurrentPasswordField = () => {

    return <TextInput
        type='password'
        name='currentpassword'
        label='form.currentpassword'
        required={true}
    />

}

export default PartnerCurrentPasswordField