import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import {SERVICE_UPDATE_SUCCESS} from "../../../../reducers/service";
import CardForm from "../../../../core/form/CardForm";
import ServiceNameField from "../../service/ServiceNameField";
import ServiceDescriptionField from "../../service/ServiceDescriptionField";
import ServiceVisibleField from "../../service/ServiceVisibleField";
import ServiceStatusField from "../../service/ServiceStatusField";
import ServiceSportsField from "../../service/ServiceSportsField";
import {getFacilityTypeText} from "../../../../utils/facilityTypeUtil";
import SelectInput from "../../../../core/form/select/SelectInput";
import AppLoading from "../../../AppLoading";
import SwitchInput from "../../../../core/form/SwitchInput";
import {useFormContext} from "react-hook-form";
import FacilitySelect from "../../select/FacilitySelect";

let ServiceReservationConfigForm = function ({serviceId}) {

    let {watch, setValue} = useFormContext()

    let facilities = useSelector(state => state.facility.allIds.map(facilityId => state.facility.byId[facilityId]))

    let service = useSelector(state => state.service.byId[serviceId])

    let loading = useSelector(state => state.service.loadingDetails)

    let dispatch = useDispatch();

    let prepareData = data => {
        return {
            required_personnel: data.required_personnel,
        }
    }

    let type = watch('type')

    React.useEffect(() => {
        if (type === 'facility_part') {
            setValue('facilities', [])
            setValue('required_facility_parts', 1)
        }
    }, [type])

    return <>
        <SelectInput name='required_personnel' options={[...Array(5).keys()].map(v => v).map(option => {
            return {
                value: option,
                label: option ? option : 'BRAK'
            }
        })} label='Wymagany personel'/>
        <SelectInput name='type' options={[
            {value: 'none', label: 'BRAK'},
            {value: 'facility', label: 'Wolny obiekt'},
            {value: 'facility_part', label: 'Wolna część obiektu'}
        ]} label='Wymagalność obiektu sportowego'/>
        {
            type === 'facility' && <FacilitySelect includes={['parts']} isMulti={true} label='Wybierz obiekty'/>
        }
        {type === 'facility_part' && <FacilitySelect includes={['parts']} isMulti={true} label='Wybierz obiekty' data={
            facilities.filter(facility => facility.parts?.length > 0).map(facility => facility.id)
        }/>}
        {/*{type === 'facility_part' &&*/}
        {/*    <SelectInput name='required_facility_parts' options={[...Array(1).keys()].map(v => v).map(option => {*/}
        {/*        return {*/}
        {/*            value: option + 1,*/}
        {/*            label: option + 1*/}
        {/*        }*/}
        {/*    })} label='Wymagane wolne części obiektu'/>}*/}
    </>

}
export default ServiceReservationConfigForm