import React from "react";
import {useSelector} from 'react-redux';
import {RiEdit2Fill} from 'react-icons/ri'
import {Link} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_FACILITY_PART_DETAILS} from "../../routes/routeDefinitions";
import Datatable from "../../core/components/datatable/Datatable";
import IconButton from "../../core/buttons/IconButton";

let FacilityPartList = ({facilityId}) => {

    let loading = useSelector(state => state.facility.loadingDetails)

    let facility = useSelector(state => state.facility.byId[facilityId])

    let parts = useSelector(state => state.facilityPart.byId)

    let data = (facility?.parts ?? []).map(partId => [
        <td>
            {parts[partId].name}
        </td>,
        <td>
            <Link to={getRoutePath(ROUTE_FACILITY_PART_DETAILS, {facilityId: facility?.id, partId})}>
                <IconButton Icon={RiEdit2Fill}/>
            </Link>
        </td>,
    ])

    return <Datatable
        loading={loading}
        columnHeaders={['Nazwa', 'Akcje']}
        data={data}
    />
}

export default FacilityPartList;

