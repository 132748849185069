import React from "react";

import AppModal from "./AppModal";

const ConfirmationModal = (props) => {

    return <AppModal
        {...props}
        title='Potwierdź'
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
    />
}

export default ConfirmationModal;

