import React from "react";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import schemas from "../../reducers/schemas";
import {normalize} from 'normalizr';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_PARTNER_SHOW} from "../../routes/routeDefinitions";
import PartnerEmailField from "../forms/partner/PartnerEmailField";
import PartnerFullnameField from "../forms/partner/PartnerFullnameField";
import PartnerPhoneField from "../forms/partner/PartnerPhoneField";
import {PARTNER_CREATE_SUCCESS} from "../../reducers/partner";
import ModalForm from "../../core/modal/ModalForm";

let PartnerModal = ({isOpen, closeModal}) => {

    let dispatch = useDispatch();

    let navigate = useNavigate();

    let onSubmit = (data) => {
        dispatch(createAction(PARTNER_CREATE_SUCCESS, normalize(data, schemas.partnerSchema)))
        navigate(getRoutePath(ROUTE_PARTNER_SHOW, data.id))
    }

    let prepareData = data => {
        return {
            fullname: data.fullname,
            email: data.email,
            phone: data.phone
        }
    }

    return <ModalForm
        prepareData={prepareData}
        isOpen={isOpen}
        closeModal={closeModal}
        title='Dodaj pracownika'
        getPromise={(id, data) => api.partner.create(data)}
        onSubmit={onSubmit}
    >
        <PartnerFullnameField/>
        <PartnerEmailField/>
        <PartnerPhoneField/>
    </ModalForm>

}

export default PartnerModal