import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createAction} from "../../../../core/utils/appUtil";
import {ACCOUNT_UPDATE_SUCCESS} from "../../../../constants/actiontypes/auth";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import PartnerRepeatPasswordField from "../../partner/PartnerRepeatPasswordField";
import {updateProfile} from "../../../../core/api";
import PartnerCurrentPasswordField from "../../partner/PartnerCurrentPasswordField";
import CardForm from "../../../../core/form/CardForm";

let AccountPasswordForm = function () {

    let user = useSelector(state => state.partner.byId[state.auth.logged_user]);

    let dispatch = useDispatch();

    let prepareData = (data) => {
        return {
            password: data.password,
            currentpassword: data.currentpassword
        }
    }

    return <CardForm
        title='Zmiana hasła'
        clearFormOnSuccess={true}
        prepareData={prepareData}
        data={user}
        saveText='Hasło zostało zmienione'
        getPromise={(id, data) => updateProfile(data)}
        onSubmit={data => dispatch(createAction(ACCOUNT_UPDATE_SUCCESS, data))}>
        <PartnerCurrentPasswordField/>
        <PartnerPasswordField/>
        <PartnerRepeatPasswordField/>
    </CardForm>
}

export default AccountPasswordForm