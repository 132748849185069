import React, {useState} from "react";
import FacilityPage from "./FacilityPage";
import {Navigate, useMatch} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {fetchFacilityDetails} from "../../../actions/facilities";
import Button from "../../../core/buttons/Button";
import {MdKeyboardArrowRight} from 'react-icons/md'
import {fetchAllResources} from "../../../actions/resources";
import SelectResourcesModal from "../../../components/modals/SelectResourcesModal";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import CardFooter from "../../../core/card/CardFooter";
import TableSkeleton from "../../../core/components/skeleton/TableSkeleton";
import {ROUTE_DASHBOARD} from "../../../routes/routeDefinitions";
import {getCurrentBusiness} from "../../../selectors";

let FacilityResourcesPage = () => {

    let match = useMatch();

    let facilityId = match.params.id

    let facility = useSelector(state => state.facility.byId[facilityId])

    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(fetchAllResources(true))
        dispatch(fetchFacilityDetails(facilityId, ['resources', 'type.resourceTypes']))
    }, [facilityId, dispatch])

    let allResources = useSelector(state => state.resource.byId)

    let allResourceTypes = useSelector(state => state.resourceType.byId)

    let facilityResources = facility?.resources?.filter(resourceId => {
        return !allResourceTypes[allResources[resourceId].type_id].is_system
    }) ?? []

    let hasResources = facilityResources.length > 0

    let advancedResources = useSelector(state => getCurrentBusiness(state).advanced_resources)

    let [selectResourcesModalOpened, setSelectResourcesModalOpened] = useState(false)

    let loading = useSelector(state => state.facility.loadingDetails)

    if(!advancedResources) {
        return <Navigate to={ROUTE_DASHBOARD}/>
    }

    return <FacilityPage facilityId={facilityId}>
        {selectResourcesModalOpened && facility && <SelectResourcesModal
            modalOpened={selectResourcesModalOpened}
            setModalOpened={setSelectResourcesModalOpened}
            defaultSelected={facilityResources}
            objectId={facility?.id}
        />}
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <Card title='Dostępne zasoby'>
                    <CardBody>
                        <table className='data-list'>
                            <tbody>
                            {
                                loading && <TableSkeleton cols={1} rows={3}/>
                            }
                            {
                                !loading && !hasResources && <tr>
                                    <td colSpan={3} align='center'>Brak</td>
                                </tr>
                            }
                            {
                                hasResources && facilityResources.map(resourceId => <tr>
                                    <td>
                                        {allResources[resourceId].name}
                                    </td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter>
                        <Button text='Wybierz zasoby dostępne w tym obiekcie'
                                  variant='forward'
                                  onClick={() => setSelectResourcesModalOpened(() => true)}
                                  Icon={MdKeyboardArrowRight} rightIcon={true}/>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityResourcesPage
