import React from "react";

import AppModal from "../../../../modals/AppModal";
import {createAction, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {useDispatch} from 'react-redux'
import {RESERVATION_MODAL_CLOSE} from "../../../../../reducers/reservationModal";
import api from "../../../../../core/api";
import {RESERVATION_CYCLE_DELETE_SUCCESS} from "../../../../../reducers/reservationCycle";

const DeleteCycleRentalReservation = ({reservationCycleId, modalOpened, setModalOpened}) => {

    let dispatch = useDispatch()

    let deleteReservation = () => {
        api.reservationCycle.delete(reservationCycleId).then(() => {
            toastSuccess('Cykl rezerwacji został usunięty')
            setModalOpened(false)
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            dispatch(createAction(RESERVATION_CYCLE_DELETE_SUCCESS, reservationCycleId))
        }).catch((e) => {
            console.log(e)
            toastError('Wystąpił błąd');
            setModalOpened(false)
        })
    }

    return <AppModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        title='Czy na pewno chcesz usunąć cykl rezerwacji?'
        cancelText='Nie'
        submitText='Tak'
        type='confirmation'
        submitAction={deleteReservation}
    />
}

export default DeleteCycleRentalReservation;

