import React from 'react';
import {injectIntl} from "react-intl";
import {getValidationMessage} from "../utils/validationUtil";
import {useController, useFormContext} from "react-hook-form";
import {useIntl} from 'react-intl';
import {trans} from "../utils/translationUtil";
import FormWrapper from "./FormWrapper";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import Switch from '@mui/material/Switch';

let SwitchInput = ({
                       name,
                       namePrefix = null,
                       label = '',
                       required = false,
                       information = '',
                       validation = {},
                       loading = false,
                       disabled = false,
                       style = {}
                   }) => {

    let {control, formState: {isLoading}} = useFormContext()

    let intl = useIntl();

    if (required) {
        validation.required = {
            value: true,
            message: getValidationMessage(intl, name, 'required')
        }
    }

    const {
        field: {onChange, value},
        fieldState: {error}
    } = useController({
        name: name,
        control,
        rules: validation
    });

    let errorMessage = error?.message;

    if (loading) {
        return <div className='form-skeleton'>
            <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
                <Skeleton height={48}/>
            </SkeletonTheme>
        </div>;
    }

    return <FormWrapper information={information} errorMessage={errorMessage} extraClasses='switch'>
        {label && <label style={style}>{trans(intl, label)}</label>}
        <Switch checked={!!value} onChange={e => onChange(e.target.checked)} disabled={disabled}/>
    </FormWrapper>

}

export default injectIntl(SwitchInput);