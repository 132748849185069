import React from "react";
import SwitchInput from "../../../core/form/SwitchInput";

let AdvancedResourcesField = (props) => {

    return <SwitchInput
        name='advanced_resources'
        label='form.business.advanced_resources'
        {...props}
    />

}

export default AdvancedResourcesField