import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessInstagramField = () => {

    return <TextInput
        name='instagram'
        label='form.business.instagram'
    />

}

export default BusinessInstagramField