import React from "react";
import {useParams} from "react-router-dom";
import {Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import ServicePage from "./ServicePage";
import ServiceDetailsForm from "../../../components/forms/app/service/ServiceDetailsForm";
import {fetchServiceDetails} from "../../../actions/services";

let ServiceDetailsPage = () => {

    let params = useParams();

    let serviceId = params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchServiceDetails(serviceId))
    }, [serviceId, dispatch])

    return <ServicePage serviceId={serviceId}>
        <Col lg={{size: 4, offset: 4}}>
            <ServiceDetailsForm serviceId={serviceId}/>
        </Col>
    </ServicePage>

}

export default ServiceDetailsPage
