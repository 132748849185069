import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {getAllRoles} from "../../../actions/permission";

let RoleSelect = (props) => {

    let dispatch = useDispatch();

    let label = 'form.partner.role'

    let name = 'role_id'

    let {allIds} = useSelector(state => state.role)

    React.useEffect(() => {
        dispatch(getAllRoles())
    }, [dispatch])

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz rolę'
        stateKey='role'
        data={allIds}
        autocomplete={false}
        {...props}
    />

}

export default RoleSelect;
