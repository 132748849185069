import React from "react";
import {Form} from 'reactstrap';
import {FormProvider} from "react-hook-form";

let AppForm = ({formContext, onSubmit, children, className = '', style={}}) => {
    return <FormProvider {...formContext} >
        <Form onSubmit={formContext.handleSubmit(onSubmit)} className={className} style={style}>
            {children}
        </Form>
    </FormProvider>

}
export default AppForm