import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Col, Row} from 'reactstrap';
import {setErrors} from "../../../core/utils/formUtil";
import api from "../../../core/api";
import {useSelector, useDispatch} from 'react-redux';
import {composeUtils, createAction} from "../../../core/utils/appUtil";
import BusinessNameField from "../../forms/business/BusinessNameField";
import BusinessSubdomainField from "../../forms/business/BusinessSubdomainField";
import handleError from "../../../core/utils/errorUtil";
import {normalize} from 'normalizr';
import schemas from "../../../reducers/schemas";
import {BUSINESS_GET_DETAILS, BUSINESS_UPDATE_SUCCESS} from "../../../reducers/business";
import {getCurrentBusiness, getCurrentBusinessAddress} from "../../../selectors";
import FormHeader from "../../../core/form/FormHeader";
import AddressCityField from "../../forms/address/AddressCityField";
import AddressField from "../../forms/address/AddressField";
import AddressPostcodeField from "../../forms/address/AddressPostcodeField";
import BusinessSportsField from "../../forms/business/BusinessSportsField";
import BusinessAmenitiesField from "../../forms/business/BusinessAmenitiesField";
import AppForm from "../../forms/AppForm";

let BusinessDataStepForm = ({setSubmit, next, setDuringSubmit, intl}) => {

    let business = useSelector(getCurrentBusiness);

    let allAddresses = useSelector(state => state.address.byId);

    let dispatch = useDispatch();

    let submitApiCall = (formData) => {
        return api.business.update(business.id, formData)
            .then((response) => {
                let result = response.data.data;
                dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalize(result, schemas.businessSchema)))
                return result;
            })
            .catch(error => handleError(error, dispatch))
    }

    let submit = async (data) => {
        setDuringSubmit(true);
        let result = await submitApiCall(data);
        setErrors(result, setError, intl);
        setDuringSubmit(false);
        if (!result?.errors) {
            next();
        }
    }

    setSubmit(() => handleSubmit(submit));

    const loadDefaultValues = async () =>
        api.business.list({}, ['address', 'amenities', 'sports'])
            .then(response => {
                dispatch(createAction(BUSINESS_GET_DETAILS, normalize(response.data.data, schemas.businessSchema)));
                return {
                    name: business.name,
                    subdomain: business.subdomain,
                    sports: business.sports,
                    amenities: business.amenities,
                    address: allAddresses[business.address]
                }
            })

    const form = useForm({
        defaultValues: loadDefaultValues
    })

    const {handleSubmit, setError} = form

    return <AppForm onSubmit={submit} formContext={form}>
        <BusinessNameField/>
        <BusinessSubdomainField/>
        <BusinessSportsField/>
        <BusinessAmenitiesField/>
        <FormHeader text='Lokalizacja'/>
        <Row>
            <AddressCityField/>
            <AddressField/>
            <AddressPostcodeField/>
        </Row>
    </AppForm>

}
export default composeUtils(BusinessDataStepForm)
