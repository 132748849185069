import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllSports} from "../../../actions/sports";

let SportsSelect = (props) => {

    let dispatch = useDispatch();

    let {name, label} = props

    let {allIds} = useSelector(state => state.sport)

    React.useEffect(() => {
        dispatch(fetchAllSports())
    }, [dispatch])

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz sporty'
        stateKey='sport'
        data={allIds}
        autocomplete={false}
        {...props}
    />

}

export default SportsSelect;
