import React from "react";
import SelectInput from "../../../core/form/select/SelectInput";

let MaxReservationTimeInFutureField = ({loading = false}) => {

    let options = [
        {
            value: 60 * 24 * 30,
            label: '1 miesiąc'
        },
        {
            value: 60 * 24 * 30 * 2,
            label: '2 miesiące'
        },
        {
            value: 60 * 24 * 30 * 3,
            label: '3 miesiące'
        }
    ];

    let fieldName = 'max_reservation_time_in_future'

    return <SelectInput
        name={fieldName}
        label={'form.business.' + fieldName}
        loading={loading}
        options={options}
        information={'form.business.' + fieldName + '_information'}/>
}

export default MaxReservationTimeInFutureField