import React from "react";

import SelectObjectsModal from "./SelectObjectsModal";
import {fetchAllServices} from "../../actions/services";
import {useDispatch} from 'react-redux';
import * as api from "../../core/api";
import {FACILITY_UPDATE_SERVICES_SUCCESS} from "../../reducers/facility";
import {createAction} from "../../core/utils/appUtil";
import {PARTNER_UPDATE_SERVICES_SUCCESS} from "../../reducers/partner";
import {useSelector} from 'react-redux'
import {RESOURCE_UPDATE_SERVICES_SUCCESS} from "../../reducers/resource";

const SelectServicesModal = (props) => {
    let excludedTypes = props.excludedTypes ?? ['rental']
    let dispatch = useDispatch();

    let type = props.type ?? 'facility';

    let services = useSelector(state => state.service.allIds)

    let allServices = useSelector(state => state.service.byId)

    let onUpdate = (objectId, services) => {
        if(type === 'facility') {
            dispatch(createAction(FACILITY_UPDATE_SERVICES_SUCCESS, {
                facility_id: objectId,
                services: services
            }))
        } else if (type === 'partner') {
            dispatch(createAction(PARTNER_UPDATE_SERVICES_SUCCESS, {
                partner_id: objectId,
                services: services
            }))
        } else if (type === 'resource') {
            dispatch(createAction(RESOURCE_UPDATE_SERVICES_SUCCESS, {
                resource_id: objectId,
                services: services
            }))
        }
    }

    let getAction = () => {
        if(type === 'facility') {
            return api.updateFacilityServices;
        } else if (type === 'partner') {
            return api.updatePartnerServices;
        } else if (type === 'resource') {
            return api.updateResourceServices;
        }
        return api.updateFacilityServices;
    }

    React.useEffect(() => {
        if(!services.length) {
            dispatch(fetchAllServices())
        }
    }, [dispatch, services.length])

    let getTitle = () => {
        if(type === 'facility') {
            return 'Wybierz usługi świadczone w tym obiekcie';
        } else if (type === 'partner') {
            return 'Wybierz usługi świadczone przez tego pracownika';
        } else if (type === 'resource') {
            return 'Wybierz usługi świadczone w tym zasobie';
        }
        return '';
    }

    return <SelectObjectsModal
        {...props}
        title={getTitle()}
        stateKey='service'
        elements={services.map(id => allServices[id]).filter(service => !excludedTypes.includes(service.type)).map(service => service.id)}
        renderElement={(element) => element.name}
        action={getAction()}
        onUpdate={onUpdate}
    />
}

export default SelectServicesModal;

