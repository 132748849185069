import React from "react";
import {useIntl} from 'react-intl';
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {trans} from "../../../core/utils/translationUtil";
import {useFormContext} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllFacilities, fetchAllFacilityParts} from "../../../actions/facilities";
import SelectInput from "../../../core/form/select/SelectInput";
import {uniqueArray} from "../../../core/utils/appUtil";
import {getFacilityTypeText} from "../../../utils/facilityTypeUtil";

let FacilityPartSelect = (props) => {

    let {watch} = useFormContext()

    let dispatch = useDispatch();

    let {allIds: allPartIds, byId: allParts} = useSelector(state => state.facilityPart)

    let {byId: allFacilities} = useSelector(state => state.facility)

    React.useEffect(() => {
        dispatch(fetchAllFacilityParts())
    }, [dispatch, JSON.stringify(props)])

    let facilityId = watch('facility_id')

    let facilityType = useSelector(state => state.facilityType.byId[state.facility.byId[facilityId]?.type_id])

    function getData() {
        if (Array.isArray(props.data)) {
            return props.data
        }
        return allPartIds
    }

    let isMulti = props.isMulti ?? true

    return <SelectInput
        name={isMulti ? 'facilityParts' : 'facility_part_id'}
        label={getFacilityTypeText(facilityType?.code, isMulti ? 'parts' : 'part')}
        isMulti={isMulti}
        options={getData().map(partId => {
            return {
                value: partId,
                label: allFacilities[allParts[partId].facility_id].name + " - " + allParts[partId].name
            }
        })}
        {...props}
    />

}

export default FacilityPartSelect;
