import React from "react";
import {Col} from "reactstrap";
import {useSelector, useDispatch} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {FaPlus} from 'react-icons/fa'
import {RiEdit2Fill} from 'react-icons/ri'
import {ROUTE_RESOURCE_SHOW, ROUTE_DASHBOARD} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {Link, Navigate} from "react-router-dom";
import {fetchAllResources} from "../../../actions/resources";
import ResourceModal from "../../../components/resource/ResourceModal";
import IconButton from "../../../core/buttons/IconButton";
import ResourceTypeModal from "../../../components/modals/resourceType/ResourceTypeModal";
import {resetBreadcrumbs} from "../../../actions/navbar";
import YesNoColumn from "../../../core/components/datatable/YesNoColumn";
import Datatable from "../../../core/components/datatable/Datatable";
import {FiMinusCircle} from "react-icons/fi";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import api from "../../../core/api";
import {createAction, toastSuccess} from "../../../core/utils/appUtil";
import handleError from "../../../core/utils/errorUtil";
import {RESOURCE_REMOVE} from "../../../reducers/resource";
import {getCurrentBusiness} from "../../../selectors";

let ResourcesPage = () => {

    let [deleteResourceModalOpened, setDeleteResourceModalOpened] = React.useState(false)

    let dispatch = useDispatch();

    let {allIds: allResources, loading} = useSelector(state => state.resource)

    let resources = useSelector(state => state.resource.byId)

    let resourceTypes = useSelector(state => state.resourceType.byId)

    let [addModalOpened, setAddModalOpened] = React.useState(false);

    let [categoriesModalOpened, setCategoriesModalOpened] = React.useState(false);

    let [deletedResource, setDeletedResource] = React.useState(null)

    let advancedResources = useSelector(state => getCurrentBusiness(state).advanced_resources)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs())
        dispatch(fetchAllResources(true))
    }, [dispatch])

    let deleteResource = (resourceId) => {
        setDeletedResource(resourceId)
        setDeleteResourceModalOpened(() => true)
    }

    let removeResource = async () => {
        let result = await api.resource.delete(deletedResource).then((response) => {
            dispatch(createAction(RESOURCE_REMOVE, deletedResource))
            toastSuccess('Usunięto')
            setDeleteResourceModalOpened(() => false)
        }).catch(error => handleError(error, dispatch))
        if (result) {
            setDeleteResourceModalOpened(() => false)
            return true;
        }
    }

    if(!advancedResources) {
        return <Navigate to={ROUTE_DASHBOARD}/>
    }

    let data = allResources.map(resourceId => {
        let resource = resources[resourceId]
        return [
            <td>{resource.name}</td>,
            <td>{resourceTypes[resource.type].name}</td>,
            <td>{resource.description}</td>,
            <td><YesNoColumn active={resource.enabled}/></td>,
            <td>
                <YesNoColumn active={resource.visible} activeLabel='Dostępny w aplikacji'
                             inactiveLabel='Niedostępny w aplikacji'/>
            </td>,
            <td>
                <div className='d-flex flex-row align-items-center'>
                    <Link to={getRoutePath(ROUTE_RESOURCE_SHOW, resourceId)}>
                        <IconButton Icon={RiEdit2Fill}/>
                    </Link>
                    <FiMinusCircle style={{color: 'red'}}
                                   className='cursor-pointer'
                                   size={20}
                                   onClick={() => deleteResource(resourceId)}/>
                </div>
            </td>
        ]
    })

    return <Col className='list-page'>
        {deleteResourceModalOpened &&
            <ConfirmationModal message={'Czy na pewno chcesz usunąć zasób ' + resources[deletedResource]?.name + '?'}
                               submitAction={removeResource}
                               setModalOpened={setDeleteResourceModalOpened}
                               modalOpened={deleteResourceModalOpened}/>}
        {addModalOpened && <ResourceModal isOpen={addModalOpened} closeModal={() => setAddModalOpened(() => false)}/>}
        {categoriesModalOpened && <ResourceTypeModal isOpen={categoriesModalOpened}
                                                     closeModal={() => setCategoriesModalOpened(() => false)}/>}
        <div className='button-panel'>
            <Button text='Dodaj nowy zasób' Icon={FaPlus} onClick={() => setAddModalOpened(() => true)}/>
            <Button text='Kategorie zasobów' onClick={() => setCategoriesModalOpened(() => true)}
                    extraClasses='color1'/>
        </div>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['Nazwa', 'Kategoria zasobu', 'Opis', 'Włączony', 'Widoczność', '']}
                data={data}
            />
        </Col>
    </Col>
}
export default ResourcesPage;

