import React from "react";

import SelectObjectsModal from "./SelectObjectsModal";
import {useDispatch} from 'react-redux';
import * as api from "../../core/api";
import {FACILITY_UPDATE_PARTNERS_SUCCESS} from "../../reducers/facility";
import {createAction} from "../../core/utils/appUtil";
import {fetchAllPartners} from "../../actions/partners";
import {SERVICE_UPDATE_PARTNERS_SUCCESS} from "../../reducers/service";
import {useSelector} from 'react-redux'

const SelectPartnersModal = (props) => {

    let dispatch = useDispatch();

    let type = props.type ?? 'facility';

    let partners = useSelector(state => state.partner.allIds)

    let onUpdate = (objectId, partners) => {
        if(type === 'facility') {
            dispatch(createAction(FACILITY_UPDATE_PARTNERS_SUCCESS, {
                facility_id: objectId,
                partners: partners
            }))
        } else if (type === 'service') {
            dispatch(createAction(SERVICE_UPDATE_PARTNERS_SUCCESS, {
                service_id: objectId,
                partners: partners
            }))
        }
    }

    let getAction = () => {
        if(type === 'facility') {
            return api.updateFacilityPartners;
        } else if (type === 'service') {
            return api.updateServicePartners;
        }
    }

    React.useEffect(() => {
        if(!partners.length) {
            dispatch(fetchAllPartners())
        }
    }, [dispatch])

    let getTitle = () => {
        if(type === 'facility') {
            return 'Wybierz pracowników tego obiektu';
        } else if (type === 'service') {
            return 'Wybierz pracowników tej usługi';
        }
        return '';
    }

    return <SelectObjectsModal
        {...props}
        title={getTitle()}
        stateKey='partner'
        renderElement={(element) => element.fullname}
        action={getAction()}
        onUpdate={onUpdate}
    />
}

export default SelectPartnersModal;

