import {
    PROFILE_SETTINGS_INIT_FORM_REQUEST,
    PROFILE_SETTINGS_INIT_FORM_SUCCESS, PROFILE_SETTINGS_UPDATE_PROFILE_SUCCESS
} from "../constants/actiontypes/profileSettings";

var initialState = {
    isLoadingForm: true,
    profile: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_SETTINGS_INIT_FORM_REQUEST: {
            return {
                ...state,
                isLoadingForm: true
            }
        }
        case PROFILE_SETTINGS_INIT_FORM_SUCCESS: {
            return {
                ...state,
                isLoadingForm: false,
                profile: action.payload
            }
        }
        case PROFILE_SETTINGS_UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.payload
                }
            }
        }
        default:
            return state;
    }
};