import React from "react";
import {
    Navbar
} from "reactstrap";
import RightPanel from "./RightPanel";
import {AiOutlineLeft} from 'react-icons/ai'
import {useNavigate} from "react-router-dom";
import {useSelector} from 'react-redux';
import Button from "../../core/buttons/Button";

let NavbarComponent = ({children, breadcrumbs=true}) => {

    let navigate = useNavigate();

    let back = () => {
        navigate(previouspageurl)
    }

    let {previouspage, previouspageurl, sitename} = useSelector(state => state.navbar);

    return <Navbar expand>
        <div className='left-panel'>
            {children}
            {breadcrumbs && <div className='breadcrumbs'>
                {previouspage && <Button text={previouspage} Icon={AiOutlineLeft} variant='navbar' onClick={back}/>}
                {sitename && <div className='currentsitename'>{sitename}</div>}
            </div>}
        </div>
        <RightPanel/>
    </Navbar>
}

export default NavbarComponent;