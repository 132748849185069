import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessFacebookField = () => {

    return <TextInput
        name='facebook'
        label='form.business.facebook'
    />

}

export default BusinessFacebookField