import React from "react";
import TextInput from "../../../core/form/TextInput";
import {useFormContext} from "react-hook-form";
import SwitchInput from "../../../core/form/SwitchInput";

let BusinessSubdomainField = (props) => {

    let {watch, setValue, trigger} = useFormContext()

    React.useEffect(() => {
        const subscription = watch(({name: businessName}, {name: fieldName}) => {
            if (fieldName === 'name' && setValue) {
                setValue('subdomain', businessName.toLowerCase().replace(/[\W_]+/g, ""))
                trigger('subdomain')
            }
        });
        return () => subscription.unsubscribe();
    }, [
        watch, setValue
    ])

    let subdomain = watch('subdomain')

    let clientAppEnabled = watch('client_app_enabled')

    let clientGlobalAppEnabled = watch('client_global_app_enabled')

    return <>
        <SwitchInput
            name='client_app_enabled'
            label='Aplikacja klienta'
            loading={props.loading}
        />
        {clientAppEnabled && <>
            <div className='subdomain-field'>
                <TextInput
                    {...props}
                    name='subdomain'
                    label='form.business.subdomain'
                    required={true}
                />
            </div>
            <SwitchInput
                name='client_global_app_enabled'
                label='Globalna plikacja klienta'
                loading={props.loading}
            />
        </>}
        {
            clientAppEnabled && <>
                <div className='subdomain-field'>
                    <div className='domain'>
                        <span>https://{subdomain}.bookendy.com</span>
                    </div>
                </div>
                {clientGlobalAppEnabled && <div className='subdomain-field'>
                    <div className='domain'>
                        <span>https://bookendy.com/biznes/{subdomain}</span>
                    </div>
                </div>}
            </>
        }
    </>

}

export default BusinessSubdomainField