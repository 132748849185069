import React from "react";

import AppModal from "./AppModal";

const ErrorModal = (props) => {

    return <AppModal
        {...props}
        title='Błąd'
        submitText='Tak'
        type='confirmation'
        includeButtons={false}
    />
}

export default ErrorModal;

