import _ from 'lodash'

export const FACILITY_PART_DETAILS_SUCCESS = 'FACILITY_PART_DETAILS_SUCCESS';
export const FACILITY_PART_LIST_SUCCESS = 'FACILITY_PART_LIST_SUCCESS';

var initialState = {
    byId: {},
    allIds: []
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.facilityPart) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.facilityPart)
        }
    }
    switch (action.type) {
        case FACILITY_PART_LIST_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result
            }
        }
        default:
            return {...state};
    }
};