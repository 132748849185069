import {
    RESERVATION_LIST_ERROR,
    RESERVATION_SET_FILTERS,
    RESERVATION_STATUS_UPDATE_SUCCESS,
    RESERVATIONS_SET_CALENDAR_VIEW
} from "../constants/actiontypes/reservations";
import {
    DASHBOARD_FETCH_RESERVATIONS_REQUEST,
    DASHBOARD_FETCH_RESERVATIONS_SUCCESS
} from "../constants/actiontypes/dashboard";
import {PAYMENT_CREATE_SUCCESS, PAYMENT_REMOVE_SUCCESS} from "../constants/actiontypes/payment";
import _ from "lodash";
import {RESERVATION_CYCLE_DELETE_SUCCESS} from "./reservationCycle";

var initialState = {
        byId: [],
        all: [],
        datatable: {
            skipRerender: false,
            data: []
        },
        current: null,
        calendarView: true,
        loadingCalendar: true
    }

export const RESERVATION_ADD_SUCCESS = 'RESERVATION_ADD_SUCCESS';
export const RESERVATION_UPDATE_SUCCESS = 'RESERVATION_UPDATE_SUCCESS';
export const RESERVATION_DELETE_SUCCESS = 'RESERVATION_DELETE_SUCCESS';

export default (state = initialState, action) => {
    if (action.payload?.entities?.reservation) {
        state = {
            ...state,
            byId: _.merge(state.byId, action.payload.entities.reservation)
        }
    }
    switch (action.type) {
        case RESERVATION_CYCLE_DELETE_SUCCESS: {
            return {
                ...state,
                all: state.all.filter(id => state.byId[id].reservation_cycle_id !== action.payload),
                byId: _.omit(state.byId, Object.keys(state.byId).filter(id => state.byId[id].reservation_cycle_id === action.payload))
            }
        }
        case RESERVATION_DELETE_SUCCESS: {
            return {
                ...state,
                all: state.all.filter(id => id !== action.payload),
                byId: _.omit(state.byId, [action.payload])
            }
        }
        case DASHBOARD_FETCH_RESERVATIONS_REQUEST: {
            return {
                ...state,
                loadingCalendar: true
            }
        }
        case RESERVATIONS_SET_CALENDAR_VIEW: {
            return {
                ...state,
                calendarView: action.payload
            };
        }
        case DASHBOARD_FETCH_RESERVATIONS_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.payload.entities.reservation
                },
                reservationIds: action.payload.result,
                loadingCalendar: false
            }
        }
        case RESERVATION_STATUS_UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.reservation_id]: {
                        ...state.byId[action.payload.reservation_id],
                        status: action.payload.status
                    }
                }
            }
        }
        case PAYMENT_CREATE_SUCCESS: {
            if (action.payload.entities.payment[action.payload.result].reservation_id) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.entities.payment[action.payload.result].reservation_id]: {
                            ...state.byId[action.payload.entities.payment[action.payload.result].reservation_id],
                            payments: [
                                ...state.byId[action.payload.entities.payment[action.payload.result].reservation_id].payments,
                                action.payload.result
                            ]
                        }
                    }
                }
            }
            return state;
        }
        case PAYMENT_REMOVE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        payments: state.byId[action.payload.id].payments.filter(payment => payment !== action.payload.paymentId)
                    }
                }
            }
        }
        case RESERVATION_SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
                datatable: {
                    ...state.datatable,
                    pageCount: 0
                }
            }
        }
        case RESERVATION_LIST_ERROR : {
            return {
                ...state,
                datatable: {
                    ...state.datatable,
                    loading: false
                }
            }
        }
        default:
            return state;
    }
}