import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch} from 'react-redux';
import ResourceDetailsForm from "../../../components/forms/app/resource/ResourceDetailsForm";
import FacilityPartPage from "./FacilityPartPage";
import {fetchFacilityPart} from "../../../actions/facilities";
import api from "../../../core/api";
import {createAction, toastError, toastSuccess} from "../../../core/utils/appUtil";
import {normalize} from 'normalizr';
import {FACILITY_DETAILS_SUCCESS} from "../../../reducers/facility";
import schemas from "../../../reducers/schemas";
import {mapCustomFieldFormData} from "../../../utils/facilityUtil";
import AppForm from "../../../components/forms/AppForm";
import Card from "../../../core/card/Card";
import CardBody from "../../../core/card/CardBody";
import FormHeader from "../../../core/form/FormHeader";
import SubmitButtonsFooter from "../../../components/forms/SubmitButtonsFooter";
import TextInput from "../../../core/form/TextInput";
import {FACILITY_PART_DETAILS_SUCCESS} from "../../../reducers/facilityPart";
import {useForm} from 'react-hook-form'

let FacilityPartDetailsPage = () => {

    let params = useParams();

    let {partId, facilityId} = params

    let dispatch = useDispatch()

    let [loadingDefaultValues, setLoadingDefaultValues] = useState(true)

    const loadDefaultValues = async () => {
        setLoadingDefaultValues(true)
        return await api.facilityPart.get(partId, ['facility'])
            .then(response => {
                dispatch(createAction(FACILITY_PART_DETAILS_SUCCESS, normalize(response.data, schemas.facilityPartSchema)))
                setLoadingDefaultValues(false)
                return {
                    name: response.data.name
                }
            }).catch(e => console.log(e))
    }

    let formContext = useForm({defaultValues: loadDefaultValues})

    let {reset} = formContext

    let onSubmit = (formData) => {
        let requestData = {
            name: formData.name,
            include: ['facility']
        }
        api.facilityPart.update(partId, requestData)
            .then((response) => {
                    let responseData = response.data
                    dispatch(createAction(FACILITY_DETAILS_SUCCESS, normalize(responseData, schemas.facilityPartSchema)))
                    reset({
                        name: responseData.name
                    })
                    toastSuccess('Zapisano')
                }
            ).catch((e) => {
                console.log(e)
                toastError('Wystąpił błąd')
            }
        )
    }

    return <FacilityPartPage partId={partId} facilityId={facilityId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <AppForm formContext={formContext} onSubmit={onSubmit}>
                    <Card>
                        <CardBody>
                            <FormHeader text='Informacje podstawowe'/>
                            <TextInput
                                name='name'
                                label='Nazwa'
                                required={true}
                                loading={loadingDefaultValues}
                            />
                        </CardBody>
                        <SubmitButtonsFooter/>
                    </Card>
                </AppForm>
            </Col>
        </Row>
    </FacilityPartPage>

}

export default FacilityPartDetailsPage
