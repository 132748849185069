import React from "react";
import {useForm} from "react-hook-form";
import {Row, Col} from "reactstrap";
import {useDispatch} from 'react-redux';
import {addMinutesToTime, createAction, toastSuccess} from "../../../../core/utils/appUtil";
import {useSelector} from 'react-redux';
import ReservationTimeFromField from "../../../forms/reservation/ReservationTimeFromField";
import moment from 'moment';
import ReservationDateField from "../../../forms/reservation/ReservationDateField";
import Loader from "../../../Loader";
import Button from "../../../../core/buttons/Button";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import AppForm from "../../../forms/AppForm";
import {RESERVATION_MODAL_CLOSE, RESERVATION_MODAL_FETCH_BLOCK} from "../../../../reducers/reservationModal";
import BlockTypeSelect from "./BlockTypeSelect";
import {createBlock, updateBlock} from "../../../../actions/reservationModal";
import FacilitySelect from "../../../forms/select/FacilitySelect";
import PartnerSelect from "../../../forms/select/PartnerSelect";
import {normalize} from 'normalizr';
import FacilityPartSelect from "../../../forms/select/FacilityPartSelect";
import SwitchInput from "../../../../core/form/SwitchInput";
import api from "../../../../core/api";
import schemas from "../../../../reducers/schemas";
import TimeSelect from "../../../../core/form/TimeSelect";
import {RESERVATION_ADD_SUCCESS} from "../../../../reducers/reservation";
import {BLOCK_UPDATE_SUCCESS} from "../../../../reducers/block";

let BlockForm = () => {

    let [loading, setLoading] = React.useState(false)

    let {
        blockId,
        date
    } = useSelector(state => state.reservationModal)

    let {allIds: allFacilities} = useSelector(state => state.facility)

    let {allIds: allPartners} = useSelector(state => state.partner)

    let {allIds: allFacilityParts} = useSelector(state => state.facilityPart)

    let dispatch = useDispatch()

    let formContext = useForm({
        defaultValues: async () => {
            if (!blockId) {
                return {
                    type: 'business',
                    date: date,
                    time_from: moment(date).format('HH:mm'),
                }
            }
            return api.block.get(blockId, ['facilityParts', 'facilities', 'partners'])
                .then((response) => {
                    let normalizedData = normalize(response.data, schemas.blockSchema);
                    dispatch(createAction(RESERVATION_MODAL_FETCH_BLOCK, normalizedData))
                    let block = normalizedData.entities.block[normalizedData.result]
                    let type = null;
                    let facilityId = null
                    if (block.business_block) {
                        type = 'business';
                    } else if (block.partners.length > 0) {
                        type = 'partner'
                    } else {
                        type = 'facility';
                        if (block.facilityParts.length > 0) {
                            let part = normalizedData.entities.facilityPart[block.facilityParts[0]]
                            facilityId = part.facility_id
                        } else {
                            facilityId = block.facilities[0]
                        }
                    }

                    return {
                        ...block,
                        type: type,
                        date: moment(block.starttime).format('YYYY-MM-DD'),
                        time_from: moment(block.starttime).format('HH:mm'),
                        time_to: moment(block.endtime).format('HH:mm'),
                        facility_id: facilityId,
                        parts: type === 'facility' && block.facilityParts.length > 0
                    }
                })
        }
    })

    const {
        formState: {isDirty},
        watch
    } = formContext

    let type = watch('type')

    let submit = async (data) => {
        data = prepareData(data)
        setLoading(true)
        let result = blockId ? await updateBlock(blockId, data, dispatch) : await createBlock(data, dispatch)
        setLoading(false)
        if (result?.id) {
            console.log(result);
            dispatch(createAction(BLOCK_UPDATE_SUCCESS, normalize(result, schemas.blockSchema)))
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            toastSuccess(blockId ? 'Zapisano blokadę' : 'Dodano blokadę')
        }
    }

    let loadingForm = formContext.formState.isLoading

    let [formSubmitting, setFormSubmitting] = React.useState(false);

    let showLoader = loadingForm || formSubmitting

    let facilityId = watch('facility_id')

    let timeFrom = watch('time_from')

    let parts = watch('parts')

    let facility = useSelector(state => state.facility.byId[facilityId])

    let prepareData = (data) => {
        return {
            business_block: data.type === 'business',
            date: moment(data.date).format('YYYY-MM-DD'),
            time_from: data.time_from,
            time_to: data.time_to,
            partners: data.type === 'partner' ? data.partners : [],
            facilities: (data.type === 'facility' && !(facility?.parts.length > 0 && data.parts)) ? [data.facility_id] : [],
            facilityParts: (data.type === 'facility' && facility?.parts.length > 0 && data.parts) ? data.facilityParts : []
        }
    }

    return <AppForm onSubmit={submit} formContext={formContext}>
        {(showLoader) && <Loader/>}
        <div className='dialog-body'>
            <ReservationDateField/>
            <Row>
                <Col>
                    <ReservationTimeFromField/>
                </Col>
                <Col>
                    <TimeSelect
                        name='time_to'
                        minTime={addMinutesToTime(timeFrom, 5)}
                        step={5}
                        label='form.reservation.time_to'
                    />
                </Col>
                <BlockTypeSelect/>
                {
                    type === 'partner' && <PartnerSelect
                        name='partners'
                        label='Wybierz pracownika'
                        data={allPartners}
                        isMulti={true}
                        autocomplete={false}
                        required={false}
                    />
                }
                {
                    type === 'facility' && <>
                        <FacilitySelect
                            name='facility_id'
                            label='Wybierz obiekt'
                            data={allFacilities}
                            isMulti={false}
                            required={true}
                            includes={['parts']}
                        />
                        {facility?.parts.length > 0 &&
                            <SwitchInput name='parts' label={'Części??'}/>
                        }
                        {(parts && facility?.parts.length > 0) && <FacilityPartSelect
                            name='facilityParts'
                            label='Wybierz część'
                            data={facility?.parts}
                            isMulti={true}
                            autocomplete={false}
                            required={true}
                        />}
                    </>
                }
                {isDirty && <div className='dialog-footer'>
                    <div className='buttons'>
                        <Button text='Anuluj' onClick={() => closeModal()} variant='secondary'/>
                        <SubmitFormButton loading={loading} text={blockId ? 'Zapisz' : 'Dodaj'}/>
                    </div>
                </div>}
            </Row>
        </div>
    </AppForm>
}

export default BlockForm