import React from "react";
import {Row, Col} from "reactstrap";
import {fetchAllFacilities} from "../../../actions/facilities";
import {useSelector, useDispatch} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {FaPlus} from 'react-icons/fa'
import Facility from "../../../components/facility/Facility";
import FacilitySkeleton from "../../../components/facility/FacilitySkeleton";
import {resetBreadcrumbs} from "../../../actions/navbar";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_FACILITY_CREATE} from "../../../routes/routeDefinitions";
import {Link} from "react-router-dom";

let FacilitiesPage = () => {

    let dispatch = useDispatch();

    let isLoading = useSelector(state => state.facility.loading)

    let loggedUser = useSelector(state => state.partner.byId[state.auth.logged_user]);

    let facilities = useSelector(state => state.facility.allIds)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
        dispatch(fetchAllFacilities(['images']))
    }, [dispatch])

    return <div className='list-page'>
        <div className='button-panel'>
            <Link to={getRoutePath(ROUTE_FACILITY_CREATE)}>
                <Button text='app.add_new_facility' Icon={FaPlus}/>
            </Link>
        </div>
        <div className='object-list'>
            <Row>
                {isLoading && Array.from(Array(loggedUser.meta.facilities_count).keys()).map(
                    (k) => <Col lg={6} md={12} key={k} sm={12} xs={12} xl={3}>
                    <FacilitySkeleton/>
                </Col>)}
                {!isLoading && facilities.map(id => <Col key={id} lg={6} md={12} sm={12} xs={12} xl={3}>
                    <Facility facilityId={id}/>
                </Col>)}
            </Row>
        </div>
    </div>

}

export default FacilitiesPage;

