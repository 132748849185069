import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Button from "../../../../../core/buttons/Button";
import AppForm from "../../../../forms/AppForm";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {normalize} from 'normalizr';
import {createAction, timeToInt, toastError, toastSuccess} from "../../../../../core/utils/appUtil";
import {
    RESERVATION_MODAL_CLOSE,
} from "../../../../../reducers/reservationModal";
import api from "../../../../../core/api";
import {RESERVATION_UPDATE_SUCCESS} from "../../../../../reducers/reservation";
import schemas from "../../../../../reducers/schemas";
import DisabledTextField from "../../../../../core/form/DisabledTextField";
import FacilityPartSelect from "../../../../forms/select/FacilityPartSelect";
import AppLoading from "../../../../AppLoading";
import SwitchInput from "../../../../../core/form/SwitchInput";
import TextInput from "../../../../../core/form/TextInput";
import PaymentMethodSelect from "../../../../forms/select/PaymentMethodSelect";

let EditSingleRentalReservationForm = () => {

    let [wasChanged, setWasChanged] = React.useState(false)

    let {
        reservationId
    } = useSelector(state => state.reservationModal)

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let dispatch = useDispatch()

    let [submitting, setSubmitting] = React.useState(false)

    let [loading, setLoading] = React.useState(true)

    let facility = useSelector(state => state.facility.byId[reservation.facility_id])

    let service = useSelector(state => state.service.byId[reservation.service_id])

    let formContext = useForm({
        defaultValues: {
            date: moment(reservation.starttime).format('Y-MM-DD'),
            facility_part_id: reservation.facility_part_id,
            facility_id: reservation.facility_id,
            time_from: reservation.time_from,
            time_to: reservation.time_to,
            price: reservation.price,
            lighting: reservation.lighting,
            lighting_price: reservation.lighting_price,
            custom_price: reservation.custom_price,
            paid: !!reservation.payment?.payment_method_id,
            payment_method_id: reservation.payment?.payment_method_id
        }
    })

    let {handleSubmit, watch, setValue} = formContext

    let timeFrom = watch('time_from')

    let timeTo = watch('time_to')

    let submit = async (data) => {
        let formData = {
            lighting: data.lighting,
            lighting_price: data.lighting ? data.lighting_price : null,
            time_from: data.time_from,
            time_to: data.time_to,
            date: data.date,
            price: data.price,
            facility_part_id: data.facility_part_id,
            custom_price: data.custom_price,
            payment_method_id: data.payment_method_id
        }
        setSubmitting(true)
        api.reservation.update(reservationId, formData).then((response) => {
            dispatch(createAction(RESERVATION_UPDATE_SUCCESS, normalize(response.data, schemas.reservationSchema)))
            dispatch(createAction(RESERVATION_MODAL_CLOSE))
            toastSuccess('Zaktualizowano rezerwację')
        }).catch(error => {
            toastError('Wystpił bład podczas aktualizacji rezerwacji')
            setSubmitting(false)
        })
    }

    let cancel = () => {
        // if (step > 1) {
        //     setStep(step - 1)
        // }
        // if (step === 1) {
        //     dispatch(createAction(RESERVATION_MODAL_OPEN_MAIN_FORM))
        // }
    }

    let lighting = watch('lighting')

    let currentDate = watch('date')

    let customPrice = watch('custom_price')

    let [terms, setTerms] = useState([])

    let facilityPartId = watch('facility_part_id')

    let paid = watch('paid')

    useEffect(() => {
        setLoading(true)
        api.service.getTerms(reservation.service_id, {
            date_from: currentDate,
            date_to: currentDate,
            reservation_ids: [reservationId],
        }).then(r => {
            let terms = r.data.filter(term => term.date === currentDate)[0].slots ?? []
            setTerms(terms)
            setLoading(false)
        })
    }, [currentDate])

    let selectTerm = term => {
        setValue('time_from', term.time_from)
        setValue('time_to', term.time_to)
        setValue('price', term.price)
        if (lighting) {
            setValue('price', term.price + term.lighting_price)
        }
        setWasChanged(true)
    }

    React.useEffect(() => {
        if (getCurrentTerm()) {
            if (lighting) {
                setValue('price', getCurrentTerm().price + getCurrentTerm().lighting_price)
            } else {
                setValue('price', getCurrentTerm().price)
            }
        }
    }, [lighting, customPrice])

    let duration = timeToInt(reservation.time_to) - timeToInt(reservation.time_from)

    let isActive = term => term.time_from === timeFrom && term.time_to === timeTo

    let getCurrentTerm = () => {
        let current = terms.filter(term => term.time_from === timeFrom && term.time_to === timeTo)
        if (current.length > 0) {
            return current[0]
        }
        return null;
    }

    return <>
        <AppForm onSubmit={submit} formContext={formContext} className='content edit-single-rental-reservation-form'>
            <div>
                <p>Rezerwacja: <b>#{reservation.number}</b></p>
                {service.required_facility_parts > 0 &&
                    <FacilityPartSelect required={true} isMulti={false} data={facility?.parts}/>}
                <DisabledTextField label='Data rezerwacji' text={currentDate}/>
                <DisabledTextField label='Godzina' text={timeFrom + '-' + timeTo}/>
                {
                    (facility?.rental.lighting && getCurrentTerm()) &&
                    <SwitchInput name='lighting'
                                 label={'Dodatkowe oświetlenie (+ ' + getCurrentTerm().lighting_price + ' PLN)'}/>
                }
                <SwitchInput name='custom_price' label={'Niestandardowa cena'}/>
                <TextInput name='price' label='Cena' disabled={!customPrice}/>
                <SwitchInput name='paid' label={'Rezerwacja opłacona'} disabled={!!reservation.payment?.payment_method_id}/>
                {paid && <PaymentMethodSelect name='payment_method_id' label='Forma płatności' required={true} disabled={!!reservation.payment?.payment_method_id}/>}
                <p>Dostępne inne terminy:</p>
                <div className='cycle-term-list'>
                    {terms.length === 0 && <p>Brak innych wolnych terminów</p>}
                    {terms.filter(term => term.duration === duration)
                        .filter(term => {
                            if (service.type === 'partial-rental') {
                                return term.parts[Object.keys(term.parts)[0]].includes(facilityPartId)
                            }
                            return true;
                        })
                        .map((term, index) => <div
                                className={'d-flex flex-row term ' + (isActive(term) ? 'active' : '')}
                                onClick={() => selectTerm(term)}>
                                <div className='d-flex flex-row'>
                                    <div className='counter'>{index + 1}</div>
                                    <div className='time'>{term.time_from}-{term.time_to}</div>
                                </div>
                                <div className='price'>{term.price} zł</div>
                            </div>
                        )
                    }
                    {
                        loading && <AppLoading/>
                    }
                </div>
            </div>
        </AppForm>
        {
            (formContext.formState.isDirty || reservation.time_from !== timeFrom || reservation.facility_part_id !== facilityPartId) &&
            <div className='dialog-footer'>
                <div className='buttons'>
                    <Button text='Anuluj' onClick={cancel} variant='secondary'/>
                    <Button type='submit' text='Zapisz' loading={loading} onClick={handleSubmit(submit)}/>
                </div>
            </div>
        }
    </>
}

export default EditSingleRentalReservationForm