import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import {setErrors} from "../../../../core/utils/formUtil";
import {confirmAccount} from "../../../../actions/auth";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import PartnerRepeatPasswordField from "../../partner/PartnerRepeatPasswordField";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import AppForm from "../../AppForm";

let AccountConfirmationForm = ({code}) => {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false);

    let dispatch = useDispatch();

    let navigate = useNavigate();

    let intl = useIntl();

    let submit = async (data) => {
        data.confirmation_code = code
        setIsDuringSubmit(true);
        let result = await confirmAccount(data, dispatch, navigate);
        setErrors(result, setError, intl);
        setIsDuringSubmit(false);
    }

    let formContext = useForm();

    const {setError} = formContext

    return <AppForm onSubmit={submit} formContext={formContext}>
        <PartnerPasswordField/>
        <PartnerRepeatPasswordField/>
        <SubmitFormButton loading={isDuringSubmit} text='Aktywuj konto'/>
    </AppForm>

}
export default AccountConfirmationForm
