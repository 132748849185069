import React from "react";
import {Spinner} from "reactstrap";

const Loader = () => (
    <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        opacity: 0.5,
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1000,
        alignItems: 'center'
    }}>
        <Spinner color="primary" style={{opacity: 1, top: '50%'}}/>
    </div>
);

export default Loader;
