import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {AiOutlinePlus} from 'react-icons/ai';
import {loadEmployees} from "../../../actions/creator";
import Employee from "../Employee";
import EmployeeModal from "../EmployeeModal";
import DeleteEmployeeModal from "../DeleteEmployeeModal";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

let EmployeeStepForm = ({setSubmit, next, setDuringSubmit}) => {

    let dispatch = useDispatch();

    let employees = useSelector(state => state.creator.employees)

    React.useEffect(() => {
        dispatch(loadEmployees())
    }, [dispatch])

    let isLoaded = useSelector(state => state.creator.employeeLoaded)

    let submit = async () => {
        next();
    }

    setSubmit(submit)

    let [employeeModalOpened, setEmployeeModalOpened] = React.useState(false)

    let [deleteEmployeeModalOpened, setDeleteEmployeeModalOpened] = React.useState(false)

    let toggleModal = () => setEmployeeModalOpened(oldValue => !oldValue)

    let [editedPartner, setEditedPartner] = React.useState(null);

    let [removedPartner, setRemovedPartner] = React.useState(null);

    let addEmployee = () => {
        setEditedPartner(() => null)
        toggleModal();
    }

    let editEmployee = partnerId => {
        setEditedPartner(() => partnerId)
        setEmployeeModalOpened(() => true)
    }

    let removePartner = partnerId => {
        setRemovedPartner(() => partnerId)
        setDeleteEmployeeModalOpened(() => true)
    }

    if(!isLoaded) {
        return <SkeletonTheme baseColor="#E3EFFF" highlightColor="#9cc2f5" className='facility'>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
        </SkeletonTheme>
    }

    return isLoaded && <div className='employees'>
        <div className='employee-list'>
            {employees.map(e => <Employee id={e} onEdit={editEmployee} onRemove={removePartner}/>)}
        </div>
        <div className='add-employee' onClick={addEmployee}>
            <span className='icon'>
                <AiOutlinePlus/>
            </span>
            <span className='text'>Dodaj pracownika</span>
        </div>
        {employeeModalOpened && <EmployeeModal isOpen={employeeModalOpened} closeModal={() => setEmployeeModalOpened(() => false)} partnerId={editedPartner}/>}
        {deleteEmployeeModalOpened && <DeleteEmployeeModal isOpen={deleteEmployeeModalOpened} closeModal={() => setDeleteEmployeeModalOpened(() => false)} partnerId={removedPartner}/>}
    </div>

}
export default EmployeeStepForm
