import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import ResourceNameField from "../../resource/ResourceNameField";
import ResourceEnabledField from "../../resource/ResourceEnabledField";
import ResourceVisibleField from "../../resource/ResourceVisibleField";
import {RESOURCE_UPDATE_SUCCESS} from "../../../../reducers/resource";
import DisabledTextField from "../../../../core/form/DisabledTextField";
import ResourceDescriptionField from "../../resource/ResourceDescriptionField";
import CardForm from "../../../../core/form/CardForm";

let ResourceDetailsForm = function ({resourceId}) {

    let resource = useSelector(state => state.resource.byId[resourceId])

    let loading = useSelector(state => state.resource.loadingDetails)

    let dispatch = useDispatch();

    let resourceType = useSelector(state => state.resourceType.byId[resource?.type])

    let prepareData = (data) => {
        return {
            name: data.name,
            enabled: data.enabled,
            visible: data.visible,
            description: data.description
        }
    }

    return <CardForm
        title='Informacje o zasobie'
        data={resource}
        prepareData={prepareData}
        getPromise={(id, data) => api.resource.update(id, data)}
        onSubmit={data => dispatch(createAction(RESOURCE_UPDATE_SUCCESS, normalize(data, schemas.resourceSchema)))}>
        <DisabledTextField text={resourceType?.name} loading={loading} label='form.resource.type'/>
        <ResourceNameField loading={loading}/>
        <ResourceDescriptionField loading={loading}/>
        <div className='borderedcontainer color1'>
            <ResourceEnabledField loading={loading}/>
            <ResourceVisibleField loading={loading}/>
        </div>
    </CardForm>

}
export default ResourceDetailsForm