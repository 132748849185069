import React from "react";
import FacilityPage from "./FacilityPage";
import {useParams} from "react-router-dom";
import {Row, Col} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux';
import {
    deleteFacilityImage, fetchFacilityImages, updateFacilityImages, uploadFacilityImage
} from "../../../actions/facilities";
import GalleryEditor from "../../../components/galleryeditor/GalleryEditor";
import GalleryEditorSkeleton from "../../../components/galleryeditor/GalleryEditorSkeleton";
import Card from "../../../core/card/Card";
import CardHeader from "../../../core/card/CardHeader";
import CardBody from "../../../core/card/CardBody";

let FacilityGalleryPage = () => {

    let params = useParams();

    let facilityId = params.id

    let facility = useSelector(state => state.facility.byId[facilityId])

    let {loading, images} = useSelector(state => state.galleryEditor)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchFacilityImages(facilityId))
    }, [facilityId, dispatch])

    let saveImages = (images) => {
        updateFacilityImages(dispatch, facilityId, images)
    }

    return <FacilityPage facilityId={facilityId}>
        <Row className='fitheight'>
            <Col xl={{size: 8, offset: 2}} lg={12}>
                <Card title='Zdjęcia'>
                    <CardHeader>
                        <span className='description'>Pierwsze zdjęcie będzie zdjęciem głównym. Przeciągaj zdjęcia na inne miejsca, aby zmienić ich kolejność. Akceptowane formaty to: jpg, jpeg, png.</span>
                    </CardHeader>
                    <CardBody>
                        {loading && <GalleryEditorSkeleton/>}
                        {!loading && <GalleryEditor
                            submitButtons={true}
                            onSubmit={saveImages}
                        />}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </FacilityPage>

}

export default FacilityGalleryPage
