import React from "react";
import _ from 'lodash'
import {useSelector} from "react-redux";
import SelectInput from "./SelectInput";
import {uniqueArray} from "../../utils/appUtil";
import {useFormContext} from "react-hook-form";

let ReduxSelect = (props) => {

    let {control} = useFormContext()

    let {
        stateKey,
        data,
        optionLabel = 'name',
        optionValue = 'id',
        autocomplete = true,
        isAvailable = () => true,
        renderSelectedOption = value => value[optionLabel],
        renderEmptyOption = null
    } = props;

    data = props.elements ? props.elements : data

    let byId = useSelector(state => state[stateKey].byId);

    let defaultValue = control?._defaultValues[props.name]

    if (props.isMulti && !defaultValue) {
        defaultValue = []
    }

    if (props.isMulti) {
        if (defaultValue?.length > 0) {
            for (let i = 0; i < defaultValue.length; i++) {
                if (!data.filter(o => o === defaultValue[i]).shift()) {
                    data.push(defaultValue[i])
                }
            }
        }
    } else {
        let val = control._formValues[props.name]
        if (val && defaultValue && val === defaultValue && !data.filter(o => o === val).shift()) {
            data.push(val)
        }
    }

    let getOption = (value) => {
        if(renderEmptyOption && value === -1) {
            return {id: -1, name: renderEmptyOption}
        }
        return byId[value]
    }

    let getOptions = () => {
        let options = _.clone(data)
        if(renderEmptyOption && !options.includes(-1)){
            options.push(-1)
        }
        return options.map(d => getOption(d))
    }

    return <SelectInput
        {...props}
        options={getOptions()}
        optionLabel={optionLabel}
        optionValue={optionValue}
        autocomplete={autocomplete}
        renderValue={(value) => {
            if (props.isMulti) {
                value = uniqueArray(value)
                return value.map((val, key) => {
                    let p = byId[val]
                    return <span
                        key={props.name + '_' + val}
                        className={!isAvailable(p?.id) ? 'inavailable' : ''}>{renderSelectedOption(p)}{key === value.length - 1 ? '' : ', '}</span>
                })
            }
            return <span
                className={!isAvailable(value) ? 'inavailable' : ''}>{getOption(value) ? renderSelectedOption(getOption(value)) : ''}</span>
        }}
    />
}

export default ReduxSelect;
