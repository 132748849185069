import React from "react";
import SelectInput from "../../../core/form/select/SelectInput";

let RentalTimes = () => {

    let times = [
        {value: 30, label: '30 min.'},
        {value: 60, label: '1 godz.'},
        {value: 90, label: '1 godz. 30 min.'},
        {value: 120, label: '2 godz.'}
    ];

    return <SelectInput name='rental.times' options={times} label='Dostępne czasy rezerwacji' isMulti={true}/>
}

export default RentalTimes