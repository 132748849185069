import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {store} from './store';
import {ToastContainer} from "react-toastify";
import './assets/scss/app.scss';
import 'react-loading-skeleton/dist/skeleton.css'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import { createRoot } from 'react-dom/client';

if (process.env.REACT_APP_SENTRY_URL) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <StrictMode>
    <ToastContainer/>
    <App/>
    </StrictMode>
</Provider>);

