import React from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../../core/api";
import {createAction, toastError, toastSuccess} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {useForm} from 'react-hook-form';
import {normalize} from 'normalizr';
import BusinessSubdomainField from "../../business/BusinessSubdomainField";
import BusinessNameField from "../../business/BusinessNameField";
import {BUSINESS_GET_DETAILS, BUSINESS_UPDATE_SUCCESS} from "../../../../reducers/business";
import {getCurrentBusiness} from "../../../../selectors";
import CardForm from "../../../../core/form/CardForm";
import FormHeader from "../../../../core/form/FormHeader";
import DisabledTextField from "../../../../core/form/DisabledTextField";
import BusinessSportsField from "../../business/BusinessSportsField";
import BusinessAmenitiesField from "../../business/BusinessAmenitiesField";
import {Row} from "reactstrap";
import AddressCityField from "../../address/AddressCityField";
import AddressField from "../../address/AddressField";
import AddressPostcodeField from "../../address/AddressPostcodeField";
import BusinessPhoneField from "../../business/BusinessPhoneField";
import BusinessDescriptionField from "../../business/BusinessDescriptionField";
import ImageInput from "../../../../core/form/ImageInput";
import BusinessLogoField from "../../business/BusinessLogoField";
import {FACILITY_DETAILS_SUCCESS} from "../../../../reducers/facility";
import Card from "../../../../core/card/Card";
import CardBody from "../../../../core/card/CardBody";
import AppForm from "../../AppForm";
import {mapCustomFieldFormData} from "../../../../utils/facilityUtil";
import SubmitButtonsFooter from "../../SubmitButtonsFooter";

let BusinessDetailsForm = function () {

    let business = useSelector(getCurrentBusiness);

    let address = useSelector(state => state.address.byId[business?.address])

    let dispatch = useDispatch();

    let [loadingDefaultValues, setLoadingDefaultValues] = React.useState(true)

    let getFormData = (business, address, logo) => {
        return {
            ...business,
            address,
            logo
        }
    }

    const loadDefaultValues = async () => {
        setLoadingDefaultValues(true)
        return await api.business.get(business.id, ['logo', 'amenities', 'sports', 'address'])
            .then(response => {
                let normalizedData = normalize(response.data.data, schemas.businessSchema);
                dispatch(createAction(BUSINESS_GET_DETAILS, normalizedData))
                setLoadingDefaultValues(false)
                let business = normalizedData.entities.business[normalizedData.result]
                let address = normalizedData.entities.address[business.address]
                let logo = business.logo ? normalizedData.entities.image[business.logo] : null
                return getFormData(business, address, logo)
            }).catch(e => console.log(e))
    }


    let formContext = useForm({defaultValues: loadDefaultValues})

    let {reset} = formContext

    let onSubmit = (data) => {
        let requestData = {
            name: data.name,
            description: data.description,
            subdomain: data.subdomain,
            timezone: data.timezone,
            sports: data.sports,
            amenities: data.amenities,
            includes: [
                'amenities', 'sports'
            ],
            address: {
                city: data.address.city,
                address: data.address.address,
                postcode: data.address.postcode
            },
            logo: data.logo
        }
        api.business.update(business.id, {...requestData, include: ['logo', 'amenities', 'sports', 'address']})
            .then((response) => {
                    let normalizedData = normalize(response.data.data, schemas.businessSchema);
                    dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalizedData))
                    let business = normalizedData.entities.business[normalizedData.result]
                    let address = normalizedData.entities.address[business.address]
                    let logo = business.logo ? normalizedData.entities.image[business.logo] : null
                    reset(getFormData(business, address, logo))
                    toastSuccess('Zapisano')
                }
            ).catch((e) => {
                console.log(e)
                toastError('Wystąpił błąd')
            }
        )
    }

    return <AppForm formContext={formContext} onSubmit={onSubmit}>
        <Card>
            <CardBody>
                <FormHeader text='Ustawienia biznesu'/>
                <BusinessNameField loading={loadingDefaultValues}/>
                <BusinessDescriptionField loading={loadingDefaultValues}/>
                <DisabledTextField text='Polski' label='form.business.language' loading={loadingDefaultValues}/>
                <DisabledTextField text='Polska' label='form.business.region' loading={loadingDefaultValues}/>
                <DisabledTextField text='PLN' label='form.business.currency' loading={loadingDefaultValues}/>
                <DisabledTextField text='Europe/Warsaw' label='form.business.timezone' loading={loadingDefaultValues}/>
                <BusinessSportsField loading={loadingDefaultValues}/>
                <BusinessAmenitiesField loading={loadingDefaultValues}/>
                <FormHeader text='Lokalizacja'/>
                <Row>
                    <AddressCityField loading={loadingDefaultValues}/>
                    <AddressField loading={loadingDefaultValues}/>
                    <AddressPostcodeField loading={loadingDefaultValues}/>
                </Row>
                <FormHeader text='Logo'/>
                <BusinessLogoField loading={loadingDefaultValues}/>
            </CardBody>
            <SubmitButtonsFooter/>
        </Card>
    </AppForm>
}

export default BusinessDetailsForm