import {SET_LOGGED_USER, LOGOUT} from "../constants/actiontypes/auth";

var initialState = {
    logged_user: null,
    current_business: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGGED_USER:
            return {
                ...state,
                logged_user: action.payload.result,
                current_business: action.payload.entities?.partner[action.payload.result]?.business
            }
        case LOGOUT:
            return {
                ...state,
                logged_user: null
            }
        default:
            return state;
    }
};