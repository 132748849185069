import React, {useState, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SelectInput from "../../../../../core/form/select/SelectInput";
import SwitchInput from "../../../../../core/form/SwitchInput";
import DateInput from "../../../../../core/form/DatetInput";
import DaysOfWeekField from "../../DaysOfWeekField";
import {intToTime, timeToInt} from "../../../../../core/utils/appUtil";
import FormControlLabel from '@mui/material/FormControlLabel';
import {getCurrentBusiness} from "../../../../../selectors";

let TermForm = () => {

    let business = useSelector(getCurrentBusiness)

    let [terms, setTerms] = useState([])

    let formContext = useFormContext()

    let {watch, setValue} = formContext

    let facilityId = watch('facility_id');

    let serviceId = watch('service_id');

    let daysOfWeek = watch('days_of_week')

    let facility = useSelector(state => state.facility.byId[facilityId])

    let service = useSelector(state => state.service.byId[serviceId])

    let openingHours = useSelector(state => state.facility.byId[facilityId].openingHours.map(id => state.openingHours.byId[id]))

    let duration = watch('duration')

    let timeFrom = watch('time_from')

    let type = watch('type')

    let isPartialRental = service?.type === 'partial-rental'

    useEffect(() => {
        let newTerms = []
        let start = 0
        for (let i = start; i < 24 * 60; i += business?.reservation_interval) {
            if (daysOfWeek.length > 0) {
                let valid = true
                for (let j = 0; j < daysOfWeek.length; j++) {
                    let day = daysOfWeek[j]
                    let openingHoursByDay = openingHours.filter(o => o.day === day)
                    let dayValid = false
                    for (let k = 0; k < openingHoursByDay.length; k++) {
                        let timeFrom = timeToInt(openingHoursByDay[k].time_from2)
                        let timeTo = timeToInt(openingHoursByDay[k].time_to2)
                        if (i >= timeFrom && (i + duration) <= timeTo) {
                            dayValid = true
                        }
                    }
                    if (!dayValid) {
                        valid = false;
                    }
                }
                if (!valid) {
                    continue;
                }
            }
            newTerms.push({
                value: intToTime(i),
                label: intToTime(i) + ' - ' + intToTime(i + duration)
            })
        }
        setTerms(newTerms)
        if(newTerms.filter(t => t.value === timeFrom).length === 0) {
            setValue('time_from', null)
        }
    }, [
        duration, JSON.stringify(daysOfWeek)
    ])

    let getLightingPrice = () => {
        let key = isPartialRental ? 'partial' : 'entire'
        let prices = []
        let rentalConfig = facility?.rental?.lighting[key]
        for (let i = 0; i < Object.keys(rentalConfig).length; i++) {
            let seasonConfig = rentalConfig[Object.keys(rentalConfig)[i]]
            if(seasonConfig && Object.keys(seasonConfig).includes('time' + duration)) {
                prices.push(seasonConfig['time' + duration])
            }
        }
        let min = Math.min(...prices)
        let max = Math.max(...prices)
        return min !== max ? (min + '-' + max) : (min);
    }

    return <div>
        <div>
            <SelectInput
                name='duration'
                label='Czas rezerwacji'
                disableClearable={true}
                autocomplete={false}
                options={facility?.rental.times.map(time => {
                    return {
                        value: time,
                        label: time + ' min.'
                    }
                })}
            />
            <DateInput
                name='starts_on'
                label='Data rozpoczęcia'
            />
            <SelectInput
                name='time_from'
                label='Godzina'
                disableClearable={true}
                options={terms}
                disabled={!duration}
            />
            {
                (facility?.rental.lighting) && <SwitchInput name='lighting' label={'Dodatkowe oświetlenie (' + getLightingPrice() + ' zł)'}/>
            }
            <p>Powtarzaj:</p>
            <RadioGroup defaultValue="1" onChange={(e) => setValue('interval_weeks', e.target.value)}
                        name="interval_weeks">
                <FormControlLabel value={1} control={<Radio/>} label='co tydzień'/>
                <FormControlLabel value={2} control={<Radio/>} label='co dwa tygodnie'/>
            </RadioGroup>
            <p>Powtarzaj w dni:</p>
            <DaysOfWeekField/>
            <br/>
            <p>Powtarzanie kończy się:</p>
            <RadioGroup defaultValue="recurrence_count" onChange={(e) => setValue('type', e.target.value)} name="type"
                        style={{flexDirection: 'column'}}>
                <FormControlLabel value='ends_on' control={<Radio/>} label='w określonym terminie'/>
                <FormControlLabel value='recurrence_count' control={<Radio/>} label='po określonej liczbie rezerwacji'/>
            </RadioGroup>
            {type === 'ends_on' && <DateInput
                name='ends_on'
                label='Data rozpoczęcia'
                required={true}
            />}
            {type === 'recurrence_count' && <SelectInput
                name='recurrence_count'
                label='Liczba powtórzeń'
                disableClearable={true}
                autocomplete={false}
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i => {
                    return {
                        value: i,
                        label: i
                    }
                })}
            />}
        </div>
    </div>
}

export default TermForm