import React from "react";
import TextInput from "../../../core/form/TextInput";

let ResourceNameField = (props) => {

    return <TextInput
        name='name'
        label='form.resource.name'
        required={true}
        {...props}
    />

}

export default ResourceNameField