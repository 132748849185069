import React from "react";
import {Row, Col} from 'reactstrap'
import AccountPage from "./AccountPage";
import AccountPasswordForm from "../../../components/forms/app/account/AccountPasswordForm";

let AccountPasswordPage = () => {

    return <AccountPage>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <AccountPasswordForm/>
            </Col>
        </Row>
    </AccountPage>

}

export default AccountPasswordPage
