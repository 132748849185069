import React, {useRef} from 'react';
import FormWrapper from "../form/FormWrapper";
import {composeUtils} from "../utils/appUtil";
import missingImage from "../../assets/img/missing-image.png";
import Button from "../buttons/Button";

let ImageInput = ({onAdd, imageId, tmpContent}) => {

    let imageRef = useRef();

    let onSelectImage = function (e) {
        for(let i = 0;i < e.target.files.length; i++) {
            let file = e.target.files[i];
            let url = URL.createObjectURL(file);
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                let base64 = reader.result;
                onAdd(file, url, base64);
            };
        }
    }

    let openImageSelect = e => {
        imageRef.current.click();
    }

    let imageUrl = null
    if(imageId) {
        imageUrl = process.env.REACT_APP_API_URL + '/image/' + imageId
    }
    if(tmpContent) {
        imageUrl = 'data:image/png;base64, ' + tmpContent
    }

    return <FormWrapper>
        <div>
            {imageUrl && <img src={imageUrl} style={{maxHeight: '100px'}}/>}
            <Button text={imageUrl ? 'Zmień' : 'Dodaj'} onClick={openImageSelect}/>
        </div>
       <input type='file'
              onChange={onSelectImage}
              style={{display: 'none'}}
              ref={imageRef}
              accept=".png,.jpg,.jpeg"
       />
    </FormWrapper>

}

export default composeUtils(ImageInput);