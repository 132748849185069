import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {FACILITY_TYPE_LIST_REQUEST, FACILITY_TYPE_LIST_SUCCESS} from "../reducers/facilityType";

export function fetchAllFacilityTypes() {
    return dispatch => {
        dispatch(createAction(FACILITY_TYPE_LIST_REQUEST));
        return api.facilityType.list({}, ['resourceTypes']).then(response => {
            let normalizedData = normalize(response.data.data, [schemas.facilityTypeSchema]);
            dispatch(createAction(FACILITY_TYPE_LIST_SUCCESS, normalizedData));
        }).catch(error => handleError(error, dispatch));
    }
}