import React from 'react';
import AppLoading from "../AppLoading";
import api from "../../core/api";
import {PieChart} from '@mui/x-charts/PieChart';
import {BarChart} from '@mui/x-charts/BarChart';

let ReservationSourceSales = ({from, to}) => {

    let [data, setData] = React.useState([])

    let [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        setLoading(true)
        api.chart.get('reservations-by-source', [], {from: from, to: to}).then(response => {
            setLoading(false)
            setData(response.data.result.data)
        })
    }, [from, to])

    if (loading) {
        return <AppLoading/>
    }


    let sum = data.reduce((partialSum, a) => partialSum + parseFloat(a.price), 0)

    let getSource = source => {
        if(source === 'partner-app') {
            return 'dodana ręcznie'
        }
        if(source === 'global-client-app') {
            return 'Globalna aplikacja'
        }
        if(source === 'business-client-app') {
            return 'Dedykowana aplikacja biznesu'
        }
    }

    return <div>
        <PieChart
            series={[
                {
                    arcLabel: () => '',
                    valueFormatter: (v) => v.value + ' PLN (' + (Math.round(parseFloat(v.value)/sum * 100)) + '%)',
                    data: data.map((row, key) => {
                        return { id: key, value: row.price, percent: row.count, label: getSource(row.source)}
                    }),
                },
            ]}
            width={700}
            height={200}
        />
    </div>
}

export default ReservationSourceSales