import React from "react";
import ReduxSelect from "../../../core/form/select/ReduxSelect";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllAmenities} from "../../../actions/amenity";

let AmenitySelect = (props) => {

    let dispatch = useDispatch();

    let {name, label} = props

    let {allIds} = useSelector(state => state.amenity)

    React.useEffect(() => {
        dispatch(fetchAllAmenities())
    }, [dispatch])

    return <ReduxSelect
        name={name}
        label={label}
        placeholder='Wybierz udogodnienia'
        stateKey='amenity'
        data={allIds}
        autocomplete={false}
        {...props}
    />

}

export default AmenitySelect;
