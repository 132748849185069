import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import {normalize} from 'normalizr';
import {createAction} from "../../../../../core/utils/appUtil";
import api from "../../../../../core/api";
import schemas from "../../../../../reducers/schemas";
import AppLoading from "../../../../AppLoading";
import {DASHBOARD_FETCH_RESERVATION_SUCCESS} from "../../../../../constants/actiontypes/dashboard";
import CycleInfo from "./CycleInfo";
import {getFacilityTypeText} from "../../../../../utils/facilityTypeUtil";
import DeleteSingleRentalReservation from "./DeleteSingleRentalReservation";
import {
    RESERVATION_MODAL_CONFIRM_MODAL_HIDE,
    RESERVATION_MODAL_DELETE_MODAL_HIDE
} from "../../../../../reducers/reservationModal";
import {getPriceStatusText} from "../../../../../actions/reservation";
import ConfirmSingleRentalReservation from "./ConfirmSingleRentalReservation";

let ShowRentalReservation = () => {

    let dispatch = useDispatch()

    let [loading, setLoading] = React.useState(false)

    let {
        date: selectedDate,
        deleteModalOpened,
        reservationId,
        confirmModalOpened
    } = useSelector(state => state.reservationModal)

    React.useEffect(() => {
        setLoading(true);
        api.reservation.get(reservationId, ['cycle', 'facilityParts', 'client', 'facility', 'service']).then((response) => {
            dispatch(createAction(DASHBOARD_FETCH_RESERVATION_SUCCESS, normalize(response.data, schemas.reservationSchema)))
            setLoading(false)
        })
    }, [
        reservationId
    ])

    let reservation = useSelector(state => state.reservation.byId[reservationId])

    let client = useSelector(state => state.clients.byId[reservation?.client_id])

    let facility = useSelector(state => state.facility.byId[reservation?.facility_id])

    let facilityType = useSelector(state => state.facilityType.byId[facility?.type_id])

    let service = useSelector(state => state.service.byId[reservation?.service_id])

    let facilityPartId = reservation?.facilityParts?.length > 0 ? reservation?.facilityParts[0] : null;

    let facilityPart = useSelector(state => state.facilityPart.byId[facilityPartId])

    if (loading || !reservation || !client) {
        return <AppLoading/>
    }

    let renderReservationStatus = () => {
        if (reservation.deleted_at) {
            return <span style={{color: 'red'}}>Anulowana</span>
        }
        if (reservation.endtime < moment().format('YYYY-MM-DD HH:ii')) {
            return <span style={{color: 'blue'}}>Zakończona</span>
        }
        if (reservation.confirmed) {
            return <span style={{color: 'lightgreen'}}>Potwierdzona</span>
        }
        if (!reservation.confirmed) {
            return <span style={{color: 'orange'}}>Niepotwierdzona</span>
        }
    }


    return <div className='content'>
        {deleteModalOpened && <DeleteSingleRentalReservation
            reservationId={reservationId}
            modalOpened={deleteModalOpened}
            setModalOpened={() => dispatch(createAction(RESERVATION_MODAL_DELETE_MODAL_HIDE))}/>}
        {confirmModalOpened && <ConfirmSingleRentalReservation
            reservationId={reservationId}
            modalOpened={confirmModalOpened}
            setModalOpened={() => dispatch(createAction(RESERVATION_MODAL_CONFIRM_MODAL_HIDE))}/>}
        <p><b>{service.name}</b></p>
        <p>Rezerwacja nr <b>#{reservation.number}</b></p>
        <p>{moment(reservation.starttime).format('dddd, DD MMMM YYYY HH:mm')}-{moment(reservation.endtime).format('HH:mm')}</p>
        <CycleInfo cycleId={reservation?.reservation_cycle_id}/>
        <p>Status: {renderReservationStatus()}</p>
        {/*<p>Potwierdzona: </p>*/}
        <p>Klient: {client.fullname}</p>
        <p>Obiekt: {facility?.name}</p>
        {
            facilityPart && <>
                <p>Część boiska: {facilityPart?.name}</p>
            </>
        }
        <p>Cena: {reservation.price} zł</p>
        {reservation.payment?.payment_method && <p>Forma płatności: {reservation.payment?.payment_method.name}</p>}
        <p>Status płatności: <span
            style={{color: (reservation.payment?.status === 'paid' ? 'lightgreen' : 'orange')}}>{getPriceStatusText(reservation.payment?.status)}</span>
        </p>
        {reservation.lighting === true && <p>Cena za oświetlenie: {reservation.lighting_price} zł</p>}
        {reservation.lighting === true && <p>Razem: {reservation.total_price} zł</p>}
    </div>
}

export default ShowRentalReservation