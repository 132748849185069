import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {FaPlus} from 'react-icons/fa'
import {RiEdit2Fill} from 'react-icons/ri'
import {fetchAllPartners} from "../../../actions/partners";
import PartnerModal from "../../../components/partner/PartnerModal";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_PARTNER_SHOW} from "../../../routes/routeDefinitions";
import {Link} from "react-router-dom";
import {Col} from 'reactstrap'
import {resetBreadcrumbs} from "../../../actions/navbar";
import IconButton from "../../../core/buttons/IconButton";
import Email from "../../../core/components/datatable/Email";
import PartnerAvailabilityColumn from "../../../core/components/datatable/PartnerAvailabilityColumn";
import PartnerEnabledColumn from "../../../core/components/datatable/PartnerEnabledColumn";
import RoleColumn from "../../../core/components/datatable/RoleColumn";
import AvatarColumn from "../../../core/components/datatable/AvatarColumn";
import Datatable from "../../../core/components/datatable/Datatable";

let PartnersPage = () => {

    let dispatch = useDispatch();

    let {allIds: allPartners, loading} = useSelector(state => state.partner)

    let partners = useSelector(state => state.partner.byId)

    let [addModalOpened, setAddModalOpened] = React.useState(false);

    React.useEffect(() => {
        dispatch(resetBreadcrumbs())
        dispatch(fetchAllPartners({}, ['roles']))
    }, [dispatch])

    let data = allPartners.map(partnerId => {
        let partner = partners[partnerId]
        return [
            <td className='avatar-column'><AvatarColumn name={partner.fullname} id={partner.id}/></td>,
            <td>{partner.fullname}</td>,
            <td><Email email={partner.email}/></td>,
            <td><PartnerAvailabilityColumn active={partner.visible}/></td>,
            <td><PartnerEnabledColumn active={partner.enabled}/></td>,
            <td><RoleColumn partner={partner}/></td>,
            <td>
                <Link to={getRoutePath(ROUTE_PARTNER_SHOW, partnerId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    })

    return <div className='list-page'>
        {addModalOpened && <PartnerModal isOpen={addModalOpened} closeModal={() => setAddModalOpened(() => false)}/>}
        <div className='button-panel'>
            <Button text='Dodaj nowego pracownika' Icon={FaPlus} onClick={() => setAddModalOpened(() => true)}/>
        </div>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['', 'Imię i nazwisko', 'Email', 'WIdoczność dla klientów', 'Status konta', 'Rola', 'Edycja']}
                data={data}
            />
        </Col>
    </div>
}
export default PartnersPage;

