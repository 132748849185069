import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import handleError from "../core/utils/errorUtil";
import {
    RESERVATION_MODAL_CONFIRM_MODAL_OPEN,
    RESERVATION_MODAL_DELETE_MODAL_OPEN,
    RESERVATION_MODAL_EDIT_BLOCK,
    RESERVATION_MODAL_EDIT_RENTAL_CYCLE,
    RESERVATION_MODAL_EDIT_RENTAL_RESERVATION,
    RESERVATION_MODAL_OPEN_AND_RESET
} from "../reducers/reservationModal";

import moment from 'moment';
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {BLOCK_CREATE_SUCCESS, BLOCK_UPDATE_SUCCESS} from "../reducers/block";
import {store} from '../store'

export const MODAL_FORM_TRAINING_RESERVATION = 'training-reservation';
export const MODAL_FORM_TRAINING_CYCLE = 'training-cycle';
export const MODAL_FORM_RENTAL_RESERVATION = 'rental-reservation';
export const MODAL_FORM_RENTAL_CYCLE = 'rental-cycle';
export const MODAL_FORM_BLOCK = 'block';
export const MODAL_STATE_EDIT = 'edit'
export const MODAL_STATE_SHOW = 'show'
export const MODAL_STATE_CREATE = 'create'

export function openReservationModal(date = null, resourceId = null) {
    if (!date) {
        date = moment()
    }
    return function (dispatch) {
        dispatch(createAction(RESERVATION_MODAL_OPEN_AND_RESET, {
            date: date.format('YYYY-MM-DD HH:mm:ss'),
            resourceId: resourceId
        }))
    }
}

export function createBlock(formData, dispatch) {
    return api.block.create(formData).then((response) => {
        dispatch(createAction(BLOCK_CREATE_SUCCESS, normalize(response.data, schemas.blockSchema)))
        return response.data;
    }).catch(error => handleError(error, dispatch))
}

export function updateBlock(blockId, formData, dispatch) {
    return api.block.update(blockId, formData).then((response) => {
        dispatch(createAction(BLOCK_UPDATE_SUCCESS, normalize(response.data, schemas.blockSchema)))
        return response.data;
    }).catch(error => handleError(error, dispatch))
}

export const handleEdit = () => {
    const state = store.getState();
    const {form, formState, reservationId, blockId, reservationCycleId} = state.reservationModal
    if (formState !== MODAL_STATE_SHOW) {
        return null;
    }
    if (form === MODAL_FORM_RENTAL_RESERVATION) {
        return () => store.dispatch(createAction(RESERVATION_MODAL_EDIT_RENTAL_RESERVATION, reservationId));
    }
    if (form === MODAL_FORM_RENTAL_CYCLE) {
        return () => store.dispatch(createAction(RESERVATION_MODAL_EDIT_RENTAL_CYCLE, reservationCycleId));
    }
    if (form === MODAL_FORM_BLOCK) {
        return () => store.dispatch(createAction(RESERVATION_MODAL_EDIT_BLOCK, blockId));
    }
    return null;
}


export const handleConfirm = () => {
    const {form, formState, reservationId, reservationCycleId} = store.getState().reservationModal
    let reservation = store.getState().reservation.byId[reservationId]
    let reservationCycle = store.getState().reservationCycle.byId[reservationCycleId]
    if (formState === MODAL_STATE_SHOW && (
        form === MODAL_FORM_RENTAL_RESERVATION ||
        form === MODAL_FORM_RENTAL_CYCLE
    )) {
        if (form === MODAL_FORM_RENTAL_RESERVATION) {
            if(!reservation?.confirmed) {
                return () => store.dispatch(createAction(RESERVATION_MODAL_CONFIRM_MODAL_OPEN));
            }
        }
        if (form === MODAL_FORM_RENTAL_CYCLE) {
            if(!reservationCycle?.confirmed) {
                return () => store.dispatch(createAction(RESERVATION_MODAL_CONFIRM_MODAL_OPEN));
            }
        }
        return null;
    }
    return null;
}


export const handleRemove = () => {
    const {form, formState} = store.getState().reservationModal
    if (formState === MODAL_STATE_SHOW && (
        form === MODAL_FORM_RENTAL_RESERVATION ||
        form === MODAL_FORM_RENTAL_CYCLE
    )) {
        return () => store.dispatch(createAction(RESERVATION_MODAL_DELETE_MODAL_OPEN));
    }
    return null;
}


export const getHeaderText = () => {
    const state = store.getState();
    const {form, formState} = state.reservationModal
    if (form === null) {
        return 'Wybierz rodzaj zdarzenia';
    }
    if (form === MODAL_FORM_BLOCK) {
        if (formState === MODAL_STATE_CREATE) {
            return 'Dodaj blokadę'
        }
        if (formState === MODAL_STATE_EDIT) {
            return 'Edytuj blokadę'
        }
        if (formState === MODAL_STATE_SHOW) {
            return 'Blokada'
        }
    }
    if (form === MODAL_FORM_RENTAL_RESERVATION) {
        if (formState === MODAL_STATE_CREATE) {
            return 'Dodaj wynajem obiektu'
        }
        if (formState === MODAL_STATE_EDIT) {
            return 'Edytuj rezerwację'
        }
        if (formState === MODAL_STATE_SHOW) {
            return 'Podgląd rezerwacji'
        }
    }
    if (form === MODAL_FORM_TRAINING_RESERVATION) {
        if (formState === MODAL_STATE_CREATE) {
            return 'Dodaj trening lub wizytę'
        }
        if (formState === MODAL_STATE_EDIT) {
            return 'Edytuj trening lub wizytę'
        }
        if (formState === MODAL_STATE_SHOW) {
            return 'Trening lub wizyta'
        }
    }
    if (form === MODAL_FORM_RENTAL_CYCLE) {
        if (formState === MODAL_STATE_CREATE) {
            return 'Dodaj wynajem obiektu - cyklicznie'
        }
        if (formState === MODAL_STATE_EDIT) {
            return 'Edytuj rezerwację - cyklicznie'
        }
        if (formState === MODAL_STATE_SHOW) {
            return 'Wynajem obiektu - cyklicznie'
        }
    }
    return null;
}