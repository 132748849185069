import React from "react";
import api from "../../core/api";
import {createAction} from "../../core/utils/appUtil";
import schemas from "../../reducers/schemas";
import {normalize} from 'normalizr';
import {useDispatch} from "react-redux";
import ModalForm from "../../core/modal/ModalForm";
import ResourceEnabledField from "../forms/resource/ResourceEnabledField";
import ResourceVisibleField from "../forms/resource/ResourceVisibleField";
import {RESOURCE_ADD_SUCCESS} from "../../reducers/resource";

let NewResourceModal = ({isOpen, closeModal, facilityId, resourceTypeId}) => {

    let dispatch = useDispatch();

    let onSubmit = (data) => {
        let result = normalize(data, [schemas.resourceSchema])
        result.facility_id = facilityId
        dispatch(createAction(RESOURCE_ADD_SUCCESS, result))
        closeModal()
    }

    let prepareData = data => {
        return {
            facility_id: facilityId,
            type_id: resourceTypeId,
            enabled: data.enabled ?? false,
            visible: data.visible ?? false
        }
    }

    let getPromise = () => {
        return (id, data) => api.resource.create(data)
    }

    return <ModalForm
        className='service-modal'
        prepareData={prepareData}
        isOpen={isOpen}
        data={{
            enabled: false,
            visible: false,
        }}
        closeModal={closeModal}
        submitText='Dodaj'
        title={'Dodaj'}
        getPromise={getPromise()}
        onSubmit={onSubmit}
    >
        <ResourceEnabledField/>
        <ResourceVisibleField/>
    </ModalForm>

}

export default NewResourceModal