import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {ROUTE_CLIENT_LIST, ROUTE_CLIENT_SHOW} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {setBreadcrumbs} from "../../../actions/navbar";
import {useDispatch, useSelector} from 'react-redux'

let ClientPage = ({clientId, children}) => {

    let pages = [
        {
            name: 'Informacje podstawowe',
            path: getRoutePath(ROUTE_CLIENT_SHOW, clientId),
        }
    ]

    let dispatch = useDispatch();

    let client = useSelector(state => state.clients.byId[clientId])

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Klienci', getRoutePath(ROUTE_CLIENT_LIST)))
    }, [dispatch])

    React.useEffect(() => {
        if (client?.id) {
            dispatch(setBreadcrumbs('Klienci', getRoutePath(ROUTE_CLIENT_LIST), client.fullname))
        }
    }, [client, dispatch])

    return <ObjectPage pages={pages} name='client'>
        {children}
    </ObjectPage>

}

export default ClientPage
