import React, {useState, useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from 'react-redux';
import moment from 'moment';
import api from "../../../../../core/api";
import Loader from "../../../../Loader";
import InvalidDay from "./InvalidDay";

let TermListForm = ({display}) => {

    let [loading, setLoading] = useState(false)

    let [availableTerms, setAvailableTerms] = useState([])

    let formContext = useFormContext()

    let {watch, setValue} = formContext

    let serviceId = watch('service_id');

    let duration = watch('duration')

    let startsOn = watch('starts_on')

    let endsOn = watch('ends_on')

    let selectedTerms = watch('terms')

    let recurrenceCount = watch('recurrence_count')

    let daysOfWeek = watch('days_of_week')

    let type = watch('type')

    let timeFrom = watch('time_from')

    let totalPrice = watch('price')

    let intervalWeeks = watch('interval_weeks')

    let lighting = watch('lighting')

    useEffect(() => {
        if(!serviceId) {
            return;
        }
        setLoading(true)
        let params = {
            type: 'cycle',
            cycle_type: type,
            days_of_week: daysOfWeek.join(','),
            recurrence_count: recurrenceCount,
            starts_on: startsOn,
            ends_on: endsOn,
            interval_weeks: intervalWeeks,
        };
        api.service.getTerms(serviceId, params).then(r => {
            let days = r.data ?? []
            let dates = {}
            for (let i = 0; i < days.length; i++) {
                let date = days[i].date
                dates[date] = {
                    index: i + 1,
                    slot: days.filter(d => d.date === date)[0]?.slots?.filter(
                        slot => slot.time_from === timeFrom && slot.duration === duration
                    )[0] ?? null
                }
            }
            setValue('terms', dates)
            setAvailableTerms(days)
            setLoading(false)
        })
    }, [type, daysOfWeek.join(','), recurrenceCount, startsOn, endsOn, intervalWeeks, timeFrom, serviceId])

    useEffect(() => {
        setValue('price', Object.keys(selectedTerms).map(date => selectedTerms[date]).reduce((total, term) => total + parseInt(term.slot?.price ?? 0) + (lighting ? parseInt(term.slot?.lighting_price ?? 0) : 0), 0))
    }, [JSON.stringify(selectedTerms)]);

    let generateDay = day => {
        let term = selectedTerms[day]
        return <div className='d-flex flex-row term'>
            <div className='d-flex flex-row'>
                <div className='counter'>{term.index}.</div>
                <div className='date'>{moment(day).format('YYYY.MM.DD')}</div>
                <div className='time'> {term.slot.time_from} - {term.slot.time_to}</div>
            </div>
            <div className='price'>{term.slot.price} zł {lighting ? '(+' + term.slot.lighting_price + ' zł oświetlenie)' : ''}</div>
        </div>
    }

    let removeTerm = date => {
        delete selectedTerms[date]
        let i = 1;
        for (let j = 0; j < Object.keys(selectedTerms).length; j++) {
            selectedTerms[Object.keys(selectedTerms)[j]].index = i++
        }
        setValue('terms', selectedTerms)
    }

    let setTerm = (date, slot) => {
        selectedTerms[date].slot = slot
        setValue('terms', selectedTerms)
    }

    let generateInvalidDay = day => {
        let term = selectedTerms[day]
        return <InvalidDay
            term={term}
            day={day}
            timeFrom={timeFrom}
            duration={duration}
            removeTerm={removeTerm}
            availableTerms={availableTerms}
            setTerm={setTerm}
        />
    }

    let invalidTerms = Object.keys(selectedTerms).filter(day => selectedTerms[day].slot === null)

    if(!display) {
        return null;
    }

    return <div>
        {loading && <Loader/>}
        {invalidTerms.length > 0 &&
            <p style={{color: 'red'}}>Uwaga! Poniższy termin rezerwacji jest niedostępny. Usuń lub edytuj termin , aby
                przejść dalej.</p>}
        <div className='cycle-term-list'>
            {invalidTerms.length > 0 && <div className='d-flex flex-column invalid-terms'>
                {
                    invalidTerms.map(day => generateInvalidDay(day))
                }
            </div>
            }
            <div className='d-flex flex-column valid-terms'>
                {
                    Object.keys(selectedTerms).filter(day => selectedTerms[day].slot !== null).map(day => generateDay(day))
                }
            </div>
            <br/>
            <div className='summary-price d-flex flex-row justify-content-between'>
                <div>Cena całkowita:</div>
                <div>{totalPrice} zł</div>
            </div>
        </div>
    </div>
}

export default TermListForm