import React from "react";
import {useFormContext} from "react-hook-form";
import ReservationClientField from "../../forms/reservation/ReservationClientField";
import ClientModal from "../../client/ClientModal";
import {FaPlus, FaEdit} from 'react-icons/fa'
import Button from "../../../core/buttons/Button";
import FacilitySelect from "../../forms/select/FacilitySelect";
import FacilityPartSelect from "../../forms/select/FacilityPartSelect";
import SwitchInput from "../../../core/form/SwitchInput";
import {getFacilityTypeText} from "../../../utils/facilityTypeUtil";
import ServiceSelect from "../../forms/select/ServiceSelect";
import {fetchAllServices} from "../../../actions/services";
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllFacilities} from "../../../actions/facilities";

let TrainingDetailsForm = () => {

    let formContext = useFormContext()

    let {watch, setValue} = formContext

    let serviceId = watch('service_id');

    let facilityId = watch('facility_id');

    let anyFacilityPart = watch('any_facility_part');

    let dispatch = useDispatch();

    let service = useSelector(state => state.service.byId[serviceId])

    let facility = useSelector(state => state.facility.byId[facilityId])

    let [newClientModalOpened, setNewClientModalOpened] = React.useState()

    let facilityType = useSelector(state => state.facilityType.byId[facility?.type_id])

    React.useEffect(() => {
        dispatch(fetchAllServices(['facilities'], {type: 'training'}))
        dispatch(fetchAllFacilities())
    }, [])

    React.useEffect(() => {
        if(service && service.required_facility && service.facilities.length === 1) {
            setValue('facility_id', service.facilities[0])
        } else {
            setValue('facility_id', null)
        }
        if(service) {
            setValue('duration', service.price.times[0])
        }
    }, [serviceId])

    let addClientSuccess = client => {
        setValue('client_id', client.id)
        setNewClientModalOpened(() => false)
    }

    return <>
        {newClientModalOpened && <ClientModal
            isOpen={newClientModalOpened}
            closeModal={() => setNewClientModalOpened(() => false)}
            redirectToClientPage={false} onSuccessSave={addClientSuccess}
        />}
        <ServiceSelect label='Trening/wizyta'/>
        {(service && (service.required_facility || service.required_facility_parts > 0)) &&
            <FacilitySelect label='Obiekt'
                            includes={['parts', 'services', 'type']}
                            filterIds={(fId) => service.facilities?.includes(fId)}
            />}
        {
            (facilityId && service?.required_facility_parts > 0) &&
            <SwitchInput name='any_facility_part' label={getFacilityTypeText(facilityType?.code, 'any-part')}/>
        }
        {
            (service?.required_facility_parts > 0 && !anyFacilityPart) &&
            <FacilityPartSelect required={true} isMulti={false} data={facility?.parts}/>
        }
        <ReservationClientField/>
        <Button text='app.add_new_client' Icon={FaPlus}
                onClick={() => setNewClientModalOpened(() => true)}
                extraClasses='add-client-btn'/>
    </>
}

export default TrainingDetailsForm