import React from 'react';
import ResetPasswordForm from "../../components/forms/app/auth/ResetPasswordForm";
import {Link, useParams} from "react-router-dom";
import {AiOutlineArrowLeft} from 'react-icons/ai'
import {getRoutePath} from "../../core/utils/routeUtil";
import {ROUTE_SIGN_IN} from "../../routes/routeDefinitions";
import {FaEnvelope} from 'react-icons/fa'

let ResetPassword = () => {

    let params = useParams();

    let id = params.id

    return <div className='login-page'>
        <Link to={getRoutePath(ROUTE_SIGN_IN)} className='back'>
            <AiOutlineArrowLeft/>
            <span className='back'>Przypomnij hasło</span>
        </Link>
        <div className='d-flex-justify'>
            <FaEnvelope size={100}/>
        </div>
        <ResetPasswordForm id={id}/>
    </div>
}

export default ResetPassword;