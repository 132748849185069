import React from "react";
import RoleSelect from "../select/RoleSelect";

let PartnerRoleField = (props) => {

    return <RoleSelect
        {...props}
    />

}

export default PartnerRoleField

