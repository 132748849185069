import React from "react";
import {Col} from "reactstrap";
import {useSelector, useDispatch} from 'react-redux';
import Button from "../../../core/buttons/Button";
import {FaPlus} from 'react-icons/fa'
import {fetchAllServices} from "../../../actions/services";
import {RiEdit2Fill} from 'react-icons/ri'
import {ROUTE_SERVICE_DETAILS} from "../../../routes/routeDefinitions";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {Link} from "react-router-dom";
import ServiceModal from "../../../components/service/ServiceModal";
import {resetBreadcrumbs} from "../../../actions/navbar";
import {useIntl} from 'react-intl'
import {getPriceText, getServicePriceText} from "../../../core/utils/appUtil";
import IconButton from "../../../core/buttons/IconButton";
import ServiceStatusColumn from "../../../core/components/datatable/ServiceStatusColumn";
import ServiceVisibilityColumn from "../../../core/components/datatable/ServiceVisibilityColumn";
import Datatable from "../../../core/components/datatable/Datatable";

let ServicesPage = () => {

    let dispatch = useDispatch();

    let {allIds: allServices, loading} = useSelector(state => state.service)

    let services = useSelector(state => state.service.byId)

    let [addModalOpened, setAddModalOpened] = React.useState(false);

    React.useEffect(() => {
        dispatch(fetchAllServices())
        dispatch(resetBreadcrumbs())
    }, [dispatch])

    let data = allServices ? allServices
        .filter(serviceId => {
            let service = services[serviceId]
            return service.type !== 'entire-rental' && service.type !== 'partial-rental'
        })
        .map(serviceId => {
        let service = services[serviceId]
            let minDuration = Math.min(...service.price.times)
            let maxDuration = Math.max(...service.price.times)
        return [
            <td>{service.name}</td>,
            <td>{service.description}</td>,
            <td>{getServicePriceText(service.price)}</td>,
            <td>{minDuration === maxDuration ? minDuration : minDuration + '-' + maxDuration} min.</td>,
            <td><ServiceStatusColumn active={service.enabled}/></td>,
            <td><ServiceVisibilityColumn active={service.visible}/></td>,
            <td>
                <Link to={getRoutePath(ROUTE_SERVICE_DETAILS, serviceId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    }) : []

    return <Col className='list-page'>
        {addModalOpened && <ServiceModal isOpen={addModalOpened} closeModal={() => setAddModalOpened(() => false)}/>}
        <div className='button-panel'>
            <Button text='Dodaj nową usługę' Icon={FaPlus} onClick={() => setAddModalOpened(() => true)}/>
        </div>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['Nazwa', 'Opis usługi', 'Cena', 'Czas trwania', 'Status', 'Widoczność', 'Edycja']}
                data={data}
            />
        </Col>
    </Col>
}
export default ServicesPage;

