import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import {setErrors} from "../../../../core/utils/formUtil";
import {submitLogin} from "../../../../actions/auth";
import {ROUTE_SEND_RESET_PASSWORD_LINK} from "../../../../routes/routeDefinitions";
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import AppForm from "../../AppForm";

let SignInForm = () => {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false);

    let dispatch = useDispatch();
    let navigate = useNavigate();

    let intl = useIntl();

    let submit = async (data) => {
        setIsDuringSubmit(true);
        let result = await submitLogin(dispatch, navigate, data);
        setErrors(result, setError, intl);
        setIsDuringSubmit(false);
    }

    let formContext = useForm();
    const {control, setError} = formContext;

    return <AppForm onSubmit={submit} formContext={formContext}>
        <PartnerEmailField />
        <PartnerPasswordField/>
        <div>
            <Link to={ROUTE_SEND_RESET_PASSWORD_LINK} className='reset-password'>
                Przypomnij hasło
            </Link>
        </div>
        <SubmitFormButton loading={isDuringSubmit} text='app.signin'/>
    </AppForm>

}
export default SignInForm
