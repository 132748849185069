import React from "react";
import {Col} from 'reactstrap'
import {useParams} from "react-router-dom";
import OpeningHours from "../../../components/openinghours/OpeningHours";
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux';
import api from "../../../core/api";
import {toastSuccess} from "../../../core/utils/appUtil";
import handleError from "../../../core/utils/errorUtil";
import {createAction} from "../../../core/utils/appUtil";
import {OPENING_HOUR_FORM_SAVED} from "../../../reducers/openingHourForm";
import BusinessPage from "./BusinessPage";
import {openBusinessOpeningHoursPage} from "../../../actions/business";
import {getCurrentBusiness} from "../../../selectors";

let BusinessAvailabilityPage = () => {

    let business = useSelector(getCurrentBusiness);

    let params = useParams();

    let resourceId = params.id

    let {availability_type: availabilityType} = useSelector(state => state.openingHourForm)

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(openBusinessOpeningHoursPage(['openingHours']))
    }, [resourceId, dispatch])

    let apiCall = (data) => {
        let request = api.business.updateOpeningHours(
            business.id,
            {
                availability_type: availabilityType,
                opening_hours: data
            });
        return request.then(() => {
            dispatch(createAction(OPENING_HOUR_FORM_SAVED))
            toastSuccess('Zapisano!')
        }).catch(error => handleError(error, dispatch))
    }

    let [loading, setLoading] = React.useState(false)

    let submit = async (data) => {
        setLoading(true);
        await apiCall(data);
        setLoading(false);
    }

    let resetForm = () => {
        dispatch(openBusinessOpeningHoursPage(['openingHours']))
    }

    return <BusinessPage>
        <Col lg={{size: 6, offset: 3}}>
            <OpeningHours resetForm={resetForm} onSubmit={submit} duringSubmit={loading}
                          allowChangeAvailabilityType={false}/>
        </Col>
    </BusinessPage>
}

export default BusinessAvailabilityPage
